import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';



interface IFootPrintManager_billing_contracts_gridComponentEntity {
  Id?: number, AutoRenewalPercentage?: number, EndDate?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Name?: string, Notes?: string, StartDate?: string, StatusId?: number, Status?: { Name?: string }, Project?: { Name?: string, Owner?: { Name?: string } }, AutoRenewalType?: { Name?: string }, Account?: { Name?: string }, TaxSchedule?: { Label?: string }}

interface IFootPrintManager_billing_contracts_gridComponentInParams {
  ownerId?: number, projectId?: number, statusIds?: number[], endDate?: string}


class FootPrintManager_billing_contracts_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_billing_contracts_gridComponent;
  entity: IFootPrintManager_billing_contracts_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    Id: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    Name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    Project_Owner_Name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    Project_Name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    Account_Name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    Status_Name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    DaysRemaining: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    StartDate: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    EndDate: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    ModifiedSysDateTime: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    ModifiedSysUser: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    AutoRenewalType_Name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    Notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_billing_contracts_gridComponent, entity: IFootPrintManager_billing_contracts_gridComponentEntity) {
    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_billing_contracts_gridComponent, entity?: IFootPrintManager_billing_contracts_gridComponentEntity) {
    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      statusIds:  $grid.inParams.statusIds ,
      accountId:  null ,
      fullTextSearch:  $grid.fullTextSearch ,
      endDate:  $grid.vars.endDate ,
    };
    const data = await this.datasources.Invoices.ds_billing_contracts_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootPrintManager_billing_contracts_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.Id.displayControl as TextModel).text = $row.entity.Id?.toString();
    (this.cells.Name.displayControl as TextModel).text = $row.entity.Name;
    (this.cells.Project_Owner_Name.displayControl as TextModel).text = $row.entity.Project?.Owner?.Name;
    (this.cells.Project_Name.displayControl as TextModel).text = $row.entity.Project?.Name;
    (this.cells.Account_Name.displayControl as TextModel).text = $row.entity.Account?.Name;
    (this.cells.Status_Name.displayControl as TextModel).text = $row.entity.Status?.Name;
    (this.cells.ModifiedSysUser.displayControl as TextModel).text = $row.entity.ModifiedSysUser;
    (this.cells.AutoRenewalType_Name.displayControl as TextModel).text = $row.entity.AutoRenewalType?.Name;
    (this.cells.Notes.displayControl as TextModel).text = $row.entity.Notes;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'Id' : {
        this.on_id_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_billing_contracts_gridComponentRowModel,
  $grid: FootPrintManager_billing_contracts_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // O.Arias 06/19/2023
  
  
  // Set Date/Time Formats
  const format = `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  if ($utils.isDefined(format)) {
  
      if ($utils.isDefined($row.entity.StartDate)) {
          $row.cells.StartDate.displayControl.text = $utils.date.format($row.entity.StartDate, format);
      };
  
      if ($utils.isDefined($row.entity.EndDate)) {
          $row.cells.EndDate.displayControl.text = $utils.date.format($row.entity.EndDate, format);
      };
  
      if ($utils.isDefined($row.entity.ModifiedSysDateTime)) {
          $row.cells.ModifiedSysDateTime.displayControl.text = $utils.date.format($row.entity.ModifiedSysDateTime, format);
      };
  
  };
  
  // Set the Days Remaining Column
  
  if ($row.entity.Status.Name === "Active") {
  
      let end = new Date(add_local_time_zone($row.entity.EndDate));
      let today = new Date($utils.date.now());
      let difference = 0;
      today.setHours(0, 0, 0, 0);
  
      //Get the difference in milliseconds
      let time = end.getTime() - today.getTime();
  
      if (time >= 0) {
  
          //Conver the milliseconds to date
          difference = Math.floor(time / (1000 * 3600 * 24));
  
      } else {
  
          difference = 0
  
      };
  
      $row.cells.DaysRemaining.displayControl.text = difference.toString();
  
  };
  
  function add_local_time_zone(dateString) {
  
      //This is assuming you are using a date string that follows this: 2023-05-25T00:00:00.000Z
      //When this is supplied, the Date() function assumes it is UTC, but the output is in Local Time
      //This function helps you manipulate the date as intended in midgnight local time
      //new Date(add_local_time_zone("2023-05-25T00:00:00.000Z")) = "Thu May 25 2023 00:00:00 GMT-0400"
  
      const date = new Date(dateString);
      const time = new Date($utils.date.now());
  
      time.setHours(0, 0, 0, 0);
  
      const combinedDateTime = new Date(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          time.getHours(),
          time.getMinutes(),
          time.getSeconds()
      );
  
      return combinedDateTime.toISOString();
  }
  
  }
  on_id_clicked(event = null) {
    return this.on_id_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_id_clickedInternal(
    $row: FootPrintManager_billing_contracts_gridComponentRowModel,
  $grid: FootPrintManager_billing_contracts_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  /* On name clicked */
  
  if (await $operations.FootPrintManager.Disable_Billing_ContractEditing.isAuthorized()) {
      await $shell.FootPrintManager.openInfoDialog('Authorization', 'You are not authorized for this operation.');
  } else {
  
      $shell.FootPrintManager.openbilling_contract_editor({ contractId: $row.entity.Id });
  }
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-billing_contracts_grid',
  templateUrl: './FootPrintManager.billing_contracts_grid.component.html'
})
export class FootPrintManager_billing_contracts_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_billing_contracts_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_billing_contracts_gridComponentInParams = { ownerId: null, projectId: null, statusIds: [], endDate: null };


  //#region Variables
  vars: { currentDate?: string, endDate?: string } = { };
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     Id: new GridHeaderModel(new HeaderStyles(null, null), 'Id', 'ID', false, false, null, false),       Name: new GridHeaderModel(new HeaderStyles(null, null), 'Name', 'Name', false, false, null, false),       Project_Owner_Name: new GridHeaderModel(new HeaderStyles(null, null), 'Project_Owner_Name', 'Owner', false, false, null, false),       Project_Name: new GridHeaderModel(new HeaderStyles(null, null), 'Project_Name', 'Project', false, false, null, false),       Account_Name: new GridHeaderModel(new HeaderStyles(null, null), 'Account_Name', 'Account', false, false, null, false),       Status_Name: new GridHeaderModel(new HeaderStyles(null, null), 'Status_Name', 'Status', false, false, null, false),       DaysRemaining: new GridHeaderModel(new HeaderStyles(null, null), 'DaysRemaining', 'Days remaining', false, false, null, false),       StartDate: new GridHeaderModel(new HeaderStyles(null, null), 'StartDate', 'Start date', false, false, null, false),       EndDate: new GridHeaderModel(new HeaderStyles(null, null), 'EndDate', 'End date', false, false, null, false),       ModifiedSysDateTime: new GridHeaderModel(new HeaderStyles(null, null), 'ModifiedSysDateTime', 'Modified date', false, false, null, false),       ModifiedSysUser: new GridHeaderModel(new HeaderStyles(null, null), 'ModifiedSysUser', 'Modified by', false, false, null, false),       AutoRenewalType_Name: new GridHeaderModel(new HeaderStyles(null, null), 'AutoRenewalType_Name', 'Auto renewal', false, false, null, false),       Notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', 'Notes', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_billing_contracts_gridComponentRowModel[] = [];

  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('statusIds') set $inParams_statusIds(value: any) {
    this.inParams['statusIds'] = value;
  }
  get $inParams_statusIds(): any {
    return this.inParams['statusIds'] ;
  }
  @Input('endDate') set $inParams_endDate(value: any) {
    this.inParams['endDate'] = value;
  }
  get $inParams_endDate(): any {
    return this.inParams['endDate'] ;
  }

  topToolbar = {
      new_contract_button: new ToolModel(new ButtonModel('new_contract_button', new ButtonStyles(null, null), false, false, false, 'New Contract', 'icon-ic_fluent_add_20_regular', null)
    , false),
      copy_button: new ToolModel(new ButtonModel('copy_button', new ButtonStyles(null, null), false, false, false, 'Copy', 'icon-ic_fluent_copy_20_regular', null)
    , false),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      activate_button: new ToolModel(new ButtonModel('activate_button', new ButtonStyles(null, null), false, false, false, 'Activate', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      deactivate_button: new ToolModel(new ButtonModel('deactivate_button', new ButtonStyles(null, null), false, false, false, 'Deactivate', 'icon-ic_fluent_prohibited_20_regular', null)
    , false),
      delete_button: new ToolModel(new ButtonModel('delete_button', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Billing contracts';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 25;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      statusIds:  $grid.inParams.statusIds ,
      accountId:  null ,
      fullTextSearch:  $grid.fullTextSearch ,
      endDate:  $grid.vars.endDate ,
    };
    try {
      const data = await this.datasources.Invoices.ds_billing_contracts_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootPrintManager_billing_contracts_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_billing_contracts_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push(row.$initializeExisting(this, entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootPrintManager_billing_contracts_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_refresh_clicked(event = null) {
    return this.on_refresh_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_refresh_clickedInternal(
    $grid: FootPrintManager_billing_contracts_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'billing_contracts_grid.on_refresh_clicked');
  
  
  $grid.refresh();
  }
  on_edit_clicked(event = null) {
    return this.on_edit_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_edit_clickedInternal(
    $grid: FootPrintManager_billing_contracts_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'billing_contracts_grid.on_edit_clicked');
  /* On edit clicked */
  }
  on_new_clicked(event = null) {
    return this.on_new_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_new_clickedInternal(
    $grid: FootPrintManager_billing_contracts_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'billing_contracts_grid.on_new_clicked');
  /* On new clicked */
  }
  on_activate_clicked(event = null) {
    return this.on_activate_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_activate_clickedInternal(
    $grid: FootPrintManager_billing_contracts_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 12/04/2023
  
  /* On activate clicked */
  let selected_rows = $grid.selectedRows;
  let errors: { billingContractName: string, billingContractId: number, errorMessages: string[] }[] = [];
  let activated_contracts = [];
  
  selected_rows = selected_rows.filter(s => s.entity.StatusId === 2);
  
  if (selected_rows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Billing Contract Activate Error', 'No valid billing contract(s) selected!');
      return;
  }
  
  const userConfirm = (await $shell.FootPrintManager.openConfirmationDialog(`Confirm activation`, `You are activating ${selected_rows.length} contract${selected_rows.length > 1 ? 's' : ''}!`, `Activate`, `Cancel`));
  
  // Refresh grid to clear rows
  if (!userConfirm) {
      $grid.refresh();
      return;
  }
  
  for (const row of selected_rows) {
      let current_error: string;
      let success = false;
      current_error = null;
  
      try {
  
          let reason = [];
  
          //Validate billing contract lines
          let reasons = (await $flows.Invoices.is_billing_contract_line_valid_flow({
              billingContractId: row.entity.Id,
              billingContractLineIds: null //We're validating all of the lines
          }));
  
          if ($utils.isDefined(reasons?.reasons)) {
              reason.push(...reasons.reasons);
          };
  
          if (reason.length > 0) {
              throw ({ message: `Billing contract [${row.entity.Id}] cannot be activated: ${reason.join(' ')}` });
          }
  
          let result = (await $flows.Invoices.activate_billing_contract_flow({ contractId: row.entity.Id }));
  
          if (!$utils.isDefined(result)) {
              throw ({ message: `Billing contract [${row.entity.Id}] Error calling the [activate_billing_contract_flow] flow!` });
          }
  
          if (!result.success) {
              throw ({ message: result.messages.length > 0 ? result.messages.join(',') : `Billing contract [${row.entity.Id}] Uncaught exception executing the [activate_billing_contract_flow] flow!` });
  
          }
  
          success = result.success;
  
      } catch (error) {
  
          let target_error = error;
  
          if (typeof target_error === "string") {
              target_error = { message: target_error };
          } else {
              while ($utils.isDefined(target_error.error)) {
                  target_error = target_error.error;
              };
          };
  
          if (!$utils.isDefined(target_error.message)) {
              target_error = { message: "Uncaught exception!" };
          };
  
          current_error = target_error.message;
      };
  
      if (success) {
          activated_contracts.push(row.entity.Id.toString())
      } else {
          errors.push({
              billingContractName: row.entity.Name, billingContractId: row.entity.Id, errorMessages: [current_error]
          });
      };
  };
  
  if ($utils.isDefined(errors)) {
      $shell.FootPrintManager.openErrorDialog(
          `Activate billing contract${selected_rows.length > 1 ? 's' : ''}`,
          activated_contracts.length > 0 ? `Billing contract${selected_rows.length > 1 ? 's' : ''} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: activated_contracts })).formattedValue} were successfully activated.` : `No billing contracts were activated.`,
          null,
          `Errors activating billing contract${selected_rows.length > 1 ? 's' : ''}`,
          errors.map(error => ({ detail: error.errorMessages[0], message: `ID: ${error.billingContractId} Name: ${error.billingContractName}` })))
  }
  else {
      $shell.FootPrintManager.openInfoDialog(`Activate billing contract${selected_rows.length > 1 ? 's' : ''}`, `Billing contract${selected_rows.length > 1 ? 's' : ''} ${selected_rows.length > 1 ? 'were' : 'was'} successfully activated.`);
      $grid.refresh();
  };
  
  }
  on_deactivate_clicked(event = null) {
    return this.on_deactivate_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deactivate_clickedInternal(
    $grid: FootPrintManager_billing_contracts_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 12/04/2023
  
  /* On deactivate clicked */
  const selected_rows = $grid.selectedRows;
  let errors: { billingContractName: string, billingContractId: number, errorMessages: string[] }[] = [];
  let deactivated_contracts = [];
  
  if (selected_rows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Billing Contract Deactivate Error', 'No billing contract selected.');
      return;
  }
  
  const userConfirm = (await $shell.FootPrintManager.openConfirmationDialog(`Confirm deactivation`, `You are deactivating ${selected_rows.length} contract${selected_rows.length > 1 ? 's' : ''}!`, `Deactivate`, `Cancel`));
  
  if (!userConfirm) {
      $grid.refresh();
      return;
  }
  
  for (const row of selected_rows) {
      let current_error: string;
      let success = false;
      current_error = null;
  
      try {
          let result = (await $flows.Invoices.deactivate_billing_contract_flow({ contractId: row.entity.Id }));
  
          if (!$utils.isDefined(result)) {
              throw ({ message: `Billing contract [${row.entity.Id}] Error calling the [deactivate_billing_contract_flow] flow!` });
          }
  
          if (!result.success) {
              throw ({ message: result.messages.length > 0 ? result.messages.join(',') : `Billing contract [${row.entity.Id}] Uncaught exception executing the [deactivate_billing_contract_flow] flow!` });
          }
  
          success = result.success;
  
      } catch (error) {
  
          let target_error = error;
  
          if (typeof target_error === "string") {
              target_error = { message: target_error };
          } else {
              while ($utils.isDefined(target_error.error)) {
                  target_error = target_error.error;
              };
          };
  
          if (!$utils.isDefined(target_error.message)) {
              target_error = { message: "Uncaught exception!" };
          };
  
          current_error = target_error.message;
      };
  
      if (success) {
          deactivated_contracts.push(row.entity.Id.toString())
      } else {
          errors.push({
              billingContractName: row.entity.Name, billingContractId: row.entity.Id, errorMessages: [current_error]
          });
      };
  };
  
  if ($utils.isDefined(errors)) {
      $shell.FootPrintManager.openErrorDialog(`Deactivate billing contract${selected_rows.length > 1 ? 's' : ''}`,
          deactivated_contracts.length > 0 ? `Billing contract${selected_rows.length > 1 ? 's' : ''} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: deactivated_contracts })).formattedValue} were successfully deactivated.` : `No billing contracts were deactivated.`,
          null,
          `Errors deactivating billing contract${selected_rows.length > 1 ? 's' : ''}`,
          errors.map(error => ({ detail: error.errorMessages[0], message: `ID: ${error.billingContractId} Name: ${error.billingContractName}` })))
  }
  else {
      $shell.FootPrintManager.openInfoDialog(`Deactivate billing contract${selected_rows.length > 1 ? 's' : ''}`, `Billing contract${selected_rows.length > 1 ? 's' : ''} ${selected_rows.length > 1 ? 'were' : 'was'} successfully deactivated.`);
      $grid.refresh();
  };
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootPrintManager_billing_contracts_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 12/04/2023
  
  /* On delete clicked */
  const selected_rows = $grid.selectedRows;
  var errors: { billingContractName: string, billingContractId: number, errorMessages: string[] }[] = [];
  var deletedContracts = [];
  
  if (selected_rows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Billing Contract Delete Error', 'No billing contract selected.');
      return;
  } else {
  
      const confirm = (await $shell.FootPrintManager.openConfirmationDialog(`Confirm deletion`, `You are deleting ${selected_rows.length} contract${selected_rows.length > 1 ? 's' : ''}!`, `Delete`, `Cancel`));
  
      if (confirm) {
  
          for (const row of selected_rows) {
              var reasons = (await $flows.Invoices.delete_billing_contract_flow({ billingContractId: row.entity.Id })).reasons;
              if ($utils.isDefined(reasons)) {
                  errors.push({
                      billingContractName: row.entity.Name, billingContractId: row.entity.Id, errorMessages: reasons
                  })
              }
              else {
                  deletedContracts.push(row.entity.Id.toString())
              }
          }
          if ($utils.isDefined(errors)) {
  
              $shell.FootPrintManager.openErrorDialog(`Delete billing contract${selected_rows.length > 1 ? 's' : ''}`,
                  deletedContracts.length > 0 ? `Billing contract${selected_rows.length > 1 ? 's' : ''} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: deletedContracts })).formattedValue} were successfully deleted.` : `No billing contracts were deleted.`,
                  null,
                  `Errors deleting billing contract${selected_rows.length > 1 ? 's' : ''}`,
                  errors.map(error => ({ detail: error.errorMessages[0], message: `ID: ${error.billingContractId} Name: ${error.billingContractName}` })))
          }
          else {
              $shell.FootPrintManager.openInfoDialog(`Delete billing contract${selected_rows.length > 1 ? 's' : ''}`, `Billing contract${selected_rows.length > 1 ? 's' : ''} ${selected_rows.length > 1 ? 'were' : 'was'} successfully deleted.`);
              $grid.refresh();
          };
      } else {
          $grid.refresh();
      };
  }
  
  }
  on_copy_clicked(event = null) {
    return this.on_copy_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_copy_clickedInternal(
    $grid: FootPrintManager_billing_contracts_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 11/27/2023
  
  /* On copy clicked */
  const selected_rows = $grid.selectedRows;
  let errors: { billingContractName: string, billingContractId: number, errorMessages: string[] }[] = [];
  let deactivated_contracts = [];
  
  if (selected_rows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Billing Contract Copy Error', 'No billing contract selected.');
      return;
  }
  
  for (const row of selected_rows) {
      const contract = (await $datasources.Invoices.ds_billing_contracts_editor.get({ contractId: row.entity.Id })).result;
  
      try {
          const copy = (await $shell.FootPrintManager.openbilling_contract_creation_formDialog({ projectId: contract.ProjectId, ownerId: null, copy: true }));
  
          if ($utils.isDefined(copy)) {
              let result = (await $flows.Invoices.copy_billing_contract_flow({ contractId: contract.Id, projectId: copy.projectId, startDate: contract.StartDate, endDate: contract.EndDate }));
  
              if (!$utils.isDefined(result)) {
                  throw ("Error calling the [copy_billing_contract_flow] flow!");
              }
  
              if (!result.success) {
                  throw ({ message: result.messages.length > 0 ? result.messages.join(',') : "Uncaught exception executing the [copy_billing_contract_flow] flow!" })
              }
  
              const contractId = result.contractId;
              $shell.FootPrintManager.openbilling_contract_editor({ contractId: contractId, edit: true });
          };
  
      } catch (error) {
  
          let target_error = error;
  
          if (typeof target_error === "string") {
              target_error = { message: target_error };
          } else {
              while ($utils.isDefined(target_error.error)) {
                  target_error = target_error.error;
              };
          };
  
          if (!$utils.isDefined(target_error.message)) {
              target_error = { message: "Uncaught exception!" };
          };
  
          const errorDescription = `Billing Contract ${contract.Name} - ${target_error.message}`;
          await $shell.FootPrintManager.openErrorDialog('Copy billing contract error', 'An error occurred during copying of the contract', [errorDescription], null, null);
      };
  
      break;
  };
  
  $grid.refresh();
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_billing_contracts_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRowsCount = $grid.selectedRows.length;
  
  // Activation
  $grid.topToolbar.activate_button.control.readOnly = (await checkAllActivatable(selectedRowsCount >= 0));
  
  // Deactivation
  $grid.topToolbar.deactivate_button.control.readOnly = (await checkAllDeactivatable(selectedRowsCount >= 0));
  
  // Copy
  $grid.topToolbar.copy_button.control.readOnly = (await checkAllcopyable(selectedRowsCount != 1));
  
  // Deletion
  $grid.topToolbar.delete_button.control.readOnly = (await checkAllDeletable(selectedRowsCount >= 0));
  
  /**************************************************** 
   * FUNCTIONS
  *****************************************************/
  async function checkAllActivatable(allow: boolean) {
      for (let row of $grid.selectedRows) {
          if (row.entity.StatusId === 2) //Created
          {
              allow = false;
          }
      }
      return allow;
  }
  async function checkAllDeactivatable(allow: boolean) {
      for (let row of $grid.selectedRows) {
          if (row.entity.StatusId === 1) //Active
          {
              allow = false;
          } 
      }
      return allow;
  }
  async function checkAllcopyable(allow: boolean) {
      return allow; //only copy if 1 contract is selected.
  }
  async function checkAllDeletable(allow: boolean) {
      for (let row of $grid.selectedRows) {
          if (row.entity.StatusId === 2 || row.entity.StatusId === 3) //Created/Inactive. Only allow deleting Created or Inactive Contracts.
          {
              allow = false;
          } 
      }
      return allow;
  }
  
  
  
  if ((await $operations.FootPrintManager.Disable_Billing_ContractCreation.isAuthorized())){
      $grid.topToolbar.new_contract_button.hidden = true;
  }
  if ((await $operations.FootPrintManager.Disable_Billing_ContractEditing.isAuthorized())){
      $grid.topToolbar.activate_button.hidden = true;
      $grid.topToolbar.deactivate_button.hidden = true;
      $grid.topToolbar.delete_button.hidden = true;
  }
  if ((await $operations.FootPrintManager.Disable_Billing_Contract_Deletion.isAuthorized())){
      $grid.topToolbar.delete_button.hidden = true;
  }
  if ((await $operations.FootPrintManager.Disable_Billing_ContractCopying.isAuthorized())){
      $grid.topToolbar.copy_button.hidden = true;
  }
  
  $grid.topToolbar.activate_button.hidden = (await $operations.FootPrintManager.Disable_Billing_Contract_Activation.isAuthorized());
  $grid.topToolbar.deactivate_button.hidden = (await $operations.FootPrintManager.Disable_Billing_Contract_Deactivation.isAuthorized());
  }
  on_expiring_toggle(event = null) {
    return this.on_expiring_toggleInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_expiring_toggleInternal(
    $grid: FootPrintManager_billing_contracts_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'billing_contracts_grid.on_expiring_toggle');
  /*
  //O.Arias - 09/15/2023
  
  if ($grid.filters.expiring.control.value === true) {
  
      //$grid.filters.expiring.control.label = 'Showing Expiring Contracts';
  
      $grid.filters.expiring_days.control.readOnly = false;
      $grid.filters.expiring_days.hidden = false;
  
      if ($grid.filters.expiring_days.control.value > 0) {
  
          let today = new Date($utils.date.now());
          today.setHours(0, 0, 0, 0);
          today.setDate(today.getDate() + $grid.filters.expiring_days.control.value);
          $grid.vars.endDate = $utils.date.format(today.toString(), "YYYY-MM-DD")
          $grid.refresh();
  
      };
  
  } else {
  
      //$grid.filters.expiring.control.label = 'Showing All Contracts';
      $grid.filters.expiring_days.control.readOnly = true;
      $grid.filters.expiring_days.hidden = true;
      delete $grid.vars.endDate;
  
  };
  */
  }
  on_expiring_days(event = null) {
    return this.on_expiring_daysInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_expiring_daysInternal(
    $grid: FootPrintManager_billing_contracts_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'billing_contracts_grid.on_expiring_days');
  /*
  //O.Arias 06/30/2023
  
  if ($grid.filters.expiring_days.control.value > 0) {
  
      let today = new Date($utils.date.now());
      today.setHours(0, 0, 0, 0);
  
      today.setDate(today.getDate() + $grid.filters.expiring_days.control.value);
  
      $grid.vars.endDate = $utils.date.format(today.toString(), "YYYY-MM-DD")
      $grid.refresh();
  
  };
  */
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_billing_contracts_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 09/15/2023
  
  //$grid.filters.expiring_days.control.readOnly = true;
  //$grid.filters.expiring_days.hidden = true;
  
  $grid.headers.Account_Name.hidden = true;
  
  $grid.vars.currentDate = $utils.date.now();
  delete $grid.vars.endDate;
  
  if ($utils.isDefined($grid.inParams.endDate)) {
      $grid.vars.endDate = $grid.inParams.endDate;
  };
  
  if ((await $operations.FootPrintManager.Disable_Billing_ContractCreation.isAuthorized())){
      $grid.topToolbar.new_contract_button.hidden = true;
  }
  if ((await $operations.FootPrintManager.Disable_Billing_ContractEditing.isAuthorized())){
      $grid.topToolbar.activate_button.hidden = true;
      $grid.topToolbar.deactivate_button.hidden = true;
      $grid.topToolbar.delete_button.hidden = true;
  }
  if ((await $operations.FootPrintManager.Disable_Billing_Contract_Deletion.isAuthorized())){
      $grid.topToolbar.delete_button.hidden = true;
  }
  if ((await $operations.FootPrintManager.Disable_Billing_ContractCopying.isAuthorized())){
      $grid.topToolbar.copy_button.hidden = true;
  }
  
  $grid.topToolbar.activate_button.hidden = (await $operations.FootPrintManager.Disable_Billing_Contract_Activation.isAuthorized());
  $grid.topToolbar.deactivate_button.hidden = (await $operations.FootPrintManager.Disable_Billing_Contract_Deactivation.isAuthorized());
  
  }
  on_new_contract_clicked(event = null) {
    return this.on_new_contract_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_new_contract_clickedInternal(
    $grid: FootPrintManager_billing_contracts_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  /** New contract button is clicked */
  
  try {
      const newBillingContract = (await $shell.FootPrintManager.openbilling_contract_creation_formDialog (
          {
              projectId: $grid.inParams.projectId,
              ownerId: $grid.inParams.ownerId,
              copy: false
          }
      ));
  
      if (!$utils.isDefined(newBillingContract)) {
          //await $shell.FootPrintManager.openConfirmationDialog('New billing contract', 'New billing contract created', null, null);
          throw ("Error creating new billing contract. ");
      }
  
  } catch (error) {
  
      let target_error = error;
  
      if (typeof target_error === "string") {
          target_error = { message: target_error };
      } else {
          while ($utils.isDefined(target_error.error)) {
              target_error = target_error.error;
          };
      };
  
      if (!$utils.isDefined(target_error.message)) {
          target_error = { message: "Uncaught exception!" };
      };
  
      const errorDescription = `${target_error.message}`;
      await $shell.FootPrintManager.openErrorDialog('New billing contract error', 'An error occurred during creation of a new contract', [errorDescription], null, null);
  };
  }
  on_excel_export_flow(event = null) {
    return this.on_excel_export_flowInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_excel_export_flowInternal(
    $grid: FootPrintManager_billing_contracts_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const wb = $event;
  const clientExportLimit = $settings.FootPrintManager.ExcelExportLimit;;
  const serverLimit = 5000;
  const records = [];
  
  try {
      // Call the api with the same filters(grid inputs)
      // Get the count of the query
      // First time call is the call the decides if we need more calls or not based on the server side limit.
      const { totalCount: requiredCount } = (await $datasources.Invoices.ds_billing_contracts_grid.getList({
          ownerId: $grid.inParams.ownerId,
          projectId: $grid.inParams.projectId,
          statusIds: $grid.inParams.statusIds,
          endDate: $grid.vars.endDate,
          fullTextSearch: $grid.fullTextSearch,
          $top: 0
      }));
  
  
      if (requiredCount > clientExportLimit) {
          await $shell.FootPrintManager.openInfoDialog(`Export limitation of ${clientExportLimit} exceeded`, `Excel will only export the first ${clientExportLimit} records`);
      }
  
      if (serverLimit < clientExportLimit) {
          // Generate a list of promises for concurrent requests
          const fetchPromises = [];
          let currentOffset = 0;
          let currentRemainingCounter = clientExportLimit;
  
          while (currentRemainingCounter > 0) {
              const limit = Math.min(serverLimit, currentRemainingCounter);
              fetchPromises.push(fetchRecords(currentOffset, limit));
              currentOffset += limit;
              currentRemainingCounter -= limit;
          }
  
          // Wait for all promises to resolve
          const additionalResponses = await Promise.all(fetchPromises);
  
          // Combine results from all additional calls
          additionalResponses.forEach((apiResponse) => {
              records.push(...apiResponse.result);
          });
      } else {
          // Make a normal call to get all the required records
          const { result: response } =
              await $datasources.Invoices.ds_billing_contracts_grid.getList({
                  ownerId: $grid.inParams.ownerId,
                  projectId: $grid.inParams.projectId,
                  statusIds: $grid.inParams.statusIds,
                  endDate: $grid.vars.endDate,
                  fullTextSearch: $grid.fullTextSearch,
                  $top: clientExportLimit
              });
          records.push(...response);
      }
  
      const format = `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
      // Compile all data into JSON
      const formatedResults = records.map(record => {
          // Set Date/Time Formats
          let start_date = null;
          let end_date = null;
          let modified_date = null;
          let days_remaining = null;
  
          if ($utils.isDefined(format)) {
  
              if ($utils.isDefined(record.StartDate)) {
                  start_date = $utils.date.format(record.StartDate, format);
              };
  
              if ($utils.isDefined(record.EndDate)) {
                  end_date = $utils.date.format(record.EndDate, format);
              };
  
              if ($utils.isDefined(record.ModifiedSysDateTime)) {
                  modified_date = $utils.date.format(record.ModifiedSysDateTime, format);
              };
          }
  
          if (record.Status.Name === "Active") {
  
              let end = new Date(add_local_time_zone(record.EndDate));
              let today = new Date($utils.date.now());
              let difference = 0;
              today.setHours(0, 0, 0, 0);
  
              //Get the difference in milliseconds
              let time = end.getTime() - today.getTime();
  
              if (time >= 0) {
  
                  //Conver the milliseconds to date
                  difference = Math.floor(time / (1000 * 3600 * 24));
  
              } else {
  
                  difference = 0
  
              };
  
              days_remaining = difference.toString();
  
          };
          return {
              "Id": record.Id?.toString(),
              "Name": record.Name,
              "Owner": record.Project?.Owner?.Name,
              "Project": record.Project?.Name,
              "Account": record.Account?.Name,
              "Status": record.Status?.Name,
              "Days remaining": days_remaining,
              "Start date": start_date,
              "End date": end_date,
              "Modified date": modified_date,
              "Modified by": record.ModifiedSysUser,
              "Auto renewal": record.AutoRenewalType?.Name,
              "Notes": record.Notes
          };
      });
  
  
      // Generate the excel sheet from the JSON
      const ws = $utils.excel.json_to_sheet(formatedResults);
      $utils.excel.book_append_sheet(wb, ws, "Contracts");
  
  } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      throw new Error(errorMessage);
  }
  
  // Helpers
  
  // Function to fetch records with offset and limit
  async function fetchRecords(offset: number, limit: number): Promise<any> {
      return await $datasources.Invoices.ds_billing_contracts_grid.getList({
          ownerId: $grid.inParams.ownerId,
          projectId: $grid.inParams.projectId,
          statusIds: $grid.inParams.statusIds,
          endDate: $grid.vars.endDate,
          fullTextSearch: $grid.fullTextSearch,
          $skip: offset,
          $top: limit
      });
  };
  
  function add_local_time_zone(dateString) {
  
      //This is assuming you are using a date string that follows this: 2023-05-25T00:00:00.000Z
      //When this is supplied, the Date() function assumes it is UTC, but the output is in Local Time
      //This function helps you manipulate the date as intended in midgnight local time
      //new Date(add_local_time_zone("2023-05-25T00:00:00.000Z")) = "Thu May 25 2023 00:00:00 GMT-0400"
  
      const date = new Date(dateString);
      const time = new Date($utils.date.now());
  
      time.setHours(0, 0, 0, 0);
  
      const combinedDateTime = new Date(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          time.getHours(),
          time.getMinutes(),
          time.getSeconds()
      );
  
      return combinedDateTime.toISOString();
  }
  
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_excel_export_flow(wb);

    writeExcelFile(wb, 'FootPrintManager_billing_contracts_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
