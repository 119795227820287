import { Inject, Injectable, Injector } from '@angular/core';


import { Reports_custom_commercial_invoice_reportService } from './Reports.report.index';
import { Reports_custom_inventory_adjustment_by_current_date_reportService } from './Reports.report.index';
import { Reports_custom_material_barcode_reportService } from './Reports.report.index';
import { Reports_custom_packing_list_b2c_reportService } from './Reports.report.index';
import { Reports_custom_packing_list_reportService } from './Reports.report.index';
import { Reports_custom_pick_slip_reportService } from './Reports.report.index';
import { Reports_custom_purchase_order_reportService } from './Reports.report.index';
import { Reports_custom_receiving_reportService } from './Reports.report.index';
import { Reports_custom_sales_order_export_reportService } from './Reports.report.index';
import { Reports_custom_so_reportService } from './Reports.report.index';

@Injectable({ providedIn: 'root' })
export class Reports_ReportService {

  constructor(
    private injector: Injector
  ) {
  }

  public Reports: Reports_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _custom_commercial_invoice_report: Reports_custom_commercial_invoice_reportService;
  public get custom_commercial_invoice_report(): Reports_custom_commercial_invoice_reportService {
    if(!this._custom_commercial_invoice_report) {
      this._custom_commercial_invoice_report = this.injector.get(Reports_custom_commercial_invoice_reportService);
    }
    return this._custom_commercial_invoice_report;
  }
  private _custom_inventory_adjustment_by_current_date_report: Reports_custom_inventory_adjustment_by_current_date_reportService;
  public get custom_inventory_adjustment_by_current_date_report(): Reports_custom_inventory_adjustment_by_current_date_reportService {
    if(!this._custom_inventory_adjustment_by_current_date_report) {
      this._custom_inventory_adjustment_by_current_date_report = this.injector.get(Reports_custom_inventory_adjustment_by_current_date_reportService);
    }
    return this._custom_inventory_adjustment_by_current_date_report;
  }
  private _custom_material_barcode_report: Reports_custom_material_barcode_reportService;
  public get custom_material_barcode_report(): Reports_custom_material_barcode_reportService {
    if(!this._custom_material_barcode_report) {
      this._custom_material_barcode_report = this.injector.get(Reports_custom_material_barcode_reportService);
    }
    return this._custom_material_barcode_report;
  }
  private _custom_packing_list_b2c_report: Reports_custom_packing_list_b2c_reportService;
  public get custom_packing_list_b2c_report(): Reports_custom_packing_list_b2c_reportService {
    if(!this._custom_packing_list_b2c_report) {
      this._custom_packing_list_b2c_report = this.injector.get(Reports_custom_packing_list_b2c_reportService);
    }
    return this._custom_packing_list_b2c_report;
  }
  private _custom_packing_list_report: Reports_custom_packing_list_reportService;
  public get custom_packing_list_report(): Reports_custom_packing_list_reportService {
    if(!this._custom_packing_list_report) {
      this._custom_packing_list_report = this.injector.get(Reports_custom_packing_list_reportService);
    }
    return this._custom_packing_list_report;
  }
  private _custom_pick_slip_report: Reports_custom_pick_slip_reportService;
  public get custom_pick_slip_report(): Reports_custom_pick_slip_reportService {
    if(!this._custom_pick_slip_report) {
      this._custom_pick_slip_report = this.injector.get(Reports_custom_pick_slip_reportService);
    }
    return this._custom_pick_slip_report;
  }
  private _custom_purchase_order_report: Reports_custom_purchase_order_reportService;
  public get custom_purchase_order_report(): Reports_custom_purchase_order_reportService {
    if(!this._custom_purchase_order_report) {
      this._custom_purchase_order_report = this.injector.get(Reports_custom_purchase_order_reportService);
    }
    return this._custom_purchase_order_report;
  }
  private _custom_receiving_report: Reports_custom_receiving_reportService;
  public get custom_receiving_report(): Reports_custom_receiving_reportService {
    if(!this._custom_receiving_report) {
      this._custom_receiving_report = this.injector.get(Reports_custom_receiving_reportService);
    }
    return this._custom_receiving_report;
  }
  private _custom_sales_order_export_report: Reports_custom_sales_order_export_reportService;
  public get custom_sales_order_export_report(): Reports_custom_sales_order_export_reportService {
    if(!this._custom_sales_order_export_report) {
      this._custom_sales_order_export_report = this.injector.get(Reports_custom_sales_order_export_reportService);
    }
    return this._custom_sales_order_export_report;
  }
  private _custom_so_report: Reports_custom_so_reportService;
  public get custom_so_report(): Reports_custom_so_reportService {
    if(!this._custom_so_report) {
      this._custom_so_report = this.injector.get(Reports_custom_so_reportService);
    }
    return this._custom_so_report;
  }
}

