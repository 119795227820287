import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_location_editorService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { locationId: number }): 
  Promise<{ result: { Id?: number, AllocationSequence?: number, ChildFootprint?: number, ChildStackHeight?: number, DimensionUomId?: number, EligibleForAllocation?: boolean, GlobalAllocationSequence?: number, GlobalPickSequence?: number, Height?: number, Length?: number, Name?: string, notes?: string, PickSequence?: number, StatusId?: number, WarehouseId?: number, WeightCapacity?: number, WeightUomId?: number, Width?: number, Status?: { Name?: string }, WeightUom?: { Short_name?: string }, DimensionsUom?: { Short_name?: string } } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.locationId)) {
      missingRequiredInParams.push('\'locationId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_location_editor/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      locationId: number    }) {
        if(isNil(inParams.locationId)) {
          return true; 
        }
      return false;
    }
}
