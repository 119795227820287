import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Locations_warehouse_types_dd_singleComponent } from './Locations.warehouse_types_dd_single.component'
import { Locations_timezones_dd_singleComponent } from './Locations.timezones_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Locations_warehouse_types_dd_singleComponent),
    forwardRef(() => Locations_timezones_dd_singleComponent),
  ],
  selector: 'FootPrintManager-warehouse_creation_form',
  templateUrl: './FootPrintManager.warehouse_creation_form.component.html'
})
export class FootPrintManager_warehouse_creation_formComponent extends BaseComponent implements OnInit, OnDestroy {


  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { confirm?: boolean, warehouseId?: number, reason?: string } = { confirm: null, warehouseId: null, reason: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { nextId?: number } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ confirm?: boolean, warehouseId?: number, reason?: string }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    name: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    type: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    time_zone: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, false, false, 'Confirm', '', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
    name: new FieldModel(new TextBoxModel(this.formGroup.controls['name'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Name', true, false),
    type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['type'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Type', true, false),
    time_zone: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['time_zone'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Time zone', true, false),
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Notes', false, false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true, false),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    name: this.fields.name.control.valueChanges
    ,
    type: this.fields.type.control.valueChanges
    ,
    time_zone: this.fields.time_zone.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Create warehouse';
  
    const $form = this;
    const $utils = this.utils;

    
    (this.fields.type.control as SelectBoxModel).reset(1);
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .name
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .type
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .time_zone
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootPrintManager_warehouse_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.outParams.confirm = false;
  
  
  const nextId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Warehouse' })).nextId;
  $form.vars.nextId = nextId;
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: FootPrintManager_warehouse_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.toolbar.confirm.control.readOnly = true;
  
  // Check required fields
  const allRequiredFieldsHaveValue = $utils.isAllDefined(
      $form.fields.name.control.value,
      $form.fields.type.control.value,
      $form.fields.time_zone.control.value
  );
  
  var errors = '';
  
  if (allRequiredFieldsHaveValue === false) {
      $shell.Locations.openErrorDialog('Save', 'Missing required fields');
      throw new Error('Missing required fields'); // To prevent display mode
  }
  else {
      try {
  
          const warehouse = (await $datasources.Locations.ds_get_warehouse_by_name.get({
              name: $form.fields.name.control.value
          })).result;
          if ($utils.isDefined(warehouse)) {
              throw new Error('Duplicate warehouse found');
          }
  
          const payload = {
              "Id": $form.vars.nextId,
              "EnterpriseId": 1,
              "Name": $form.fields.name.control.value,
              "ServiceUrl": '',
              "Notes": $form.fields.notes.control.value,
              "TypeId": $form.fields.type.control.value,
              "TimeZoneId": $form.fields.time_zone.control.value,
              "FullPalletDefault": false,
              "DefaultMobileReceivingSearchOptionId": 1,
              "DefaultMobilePutAwayConfirmAll": false,
              "DisableMobilePutawayAutoConfirm": false,
              "DisableMobileLpMoveAutoConfirm": false,
              "RequireMobilePutawayTargetCLPScan": false,
              "RestrictMobileInventoryRecountByTheSameUser": false,
              "requiredCalculatorOnCountBacks": false
          };
  
          const create = (await $flows.Utilities.crud_create_flow({ entitySet: 'Warehouses', entity: payload }));
  
          let reason = create.reason;
          if ($utils.isDefined(reason)) {
              addReason(reason);
              $shell.Utilities.openErrorDialog('Unable to create warehouse.', reason);
              $form.toolbar.confirm.control.readOnly = false;
          } else {
              var result = (await $flows.Locations.create_location_flow({
                  warehouseId: $form.vars.nextId,
                  parentId: null,
                  statusId: 1,
                  name: $form.fields.name.control.value,
                  typeId: 1,
                  isLoose: false,
                  skipValidationRules: true,
              }));
  
              if (result.reasons.length) {
                  reason = result.reasons.join('');
                  addReason(reason);
                  $shell.Utilities.openErrorDialog('Unable to create default warehouse location.', reason);
                  $form.toolbar.confirm.control.readOnly = false;
              }
              else {
                  $form.outParams.confirm = true;
                  $form.outParams.warehouseId = $form.vars.nextId;
                  $form.close();
              }
          }
  
      }
      catch (error) {
          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
          addReason(errorMessage);
          $form.toolbar.confirm.control.readOnly = false;
      }
  }
  
  //** FUNCTIONS */
  function addReason(reason: string) {
      if (!$utils.isDefined($form.outParams.reason)) {
          $form.outParams.reason = '';
      }
      $form.outParams.reason += reason + ' ';
  }
  
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: FootPrintManager_warehouse_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.outParams.confirm = false;
  $form.close();
  }
  //#endregion private flows
}
