import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';



interface Iapp_custom_vendor_orders_gridComponentEntity {
  Id?: number, CreatedSysDateTime?: string, FulfillmentDate?: string, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredCarrierId?: number, PreferredWarehouseId?: number, ProjectId?: number, ReadyToExport?: boolean, ReadyToExportBy?: string, ReadyToExportDate?: string, RequestedDeliveryDate?: string, VendorReference?: string, Status?: { Id?: number, Name?: string }, OrderClass?: { LookupCode?: string, OrderClassTypeId?: number }, Project?: { LookupCode?: string, Name?: string, OwnerId?: number, Owner?: { LookupCode?: string, Name?: string } }, PreferredCarrier?: { Id?: number, Name?: string }, PreferredCarrierServiceType?: { Id?: number, Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number }[], Account?: { LookupCode?: string, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }}

interface Iapp_custom_vendor_orders_gridComponentInParams {
  statusIds?: number[], materialIds?: number[]}


class app_custom_vendor_orders_gridComponentRowModel extends GridRowModel {
  grid: app_custom_vendor_orders_gridComponent;
  entity: Iapp_custom_vendor_orders_gridComponentEntity;

  vars: { hasCompletedReceivingTasks?: boolean, hasRemainingReceivingTasks?: boolean, dockAppointmentId?: number } = { };


 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    lookupcode: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    created_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    address_lookup: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    expected_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    value: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    authorized_by: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    completed_by: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    vendor_reference: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    fulfillment_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    total_items: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    ship_from: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: app_ShellService,
private datasources: app_DatasourceService,
private flows: app_FlowService,
private reports: app_ReportService,
private localization: app_LocalizationService,
private operations: app_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: app_custom_vendor_orders_gridComponent, entity: Iapp_custom_vendor_orders_gridComponentEntity) {
    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: app_custom_vendor_orders_gridComponent, entity?: Iapp_custom_vendor_orders_gridComponentEntity) {
    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      statusIds:  $grid.inParams.statusIds ,
      fullTextSearch:  $grid.fullTextSearch ,
      fromDate:  $grid.filters.from_date.control.value ,
      toDate:  $grid.filters.to_date.control.value ,
      take:  null ,
      skip:  null ,
      materialIds:  $grid.inParams.materialIds ,
    };
    const data = await this.datasources.app.custom_ds_vendor_orders_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as Iapp_custom_vendor_orders_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.lookupcode.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.vendor_reference.displayControl as TextModel).text = $row.entity.VendorReference;
    (this.cells.status.displayControl as TextModel).text = $row.entity.Status?.Name;
    (this.cells.notes.displayControl as TextModel).text = $row.entity.Notes;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'lookupcode' : {
        this.on_lookupcode_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_lookupcode_clicked(event = null) {
    return this.on_lookupcode_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lookupcode_clickedInternal(
    $row: app_custom_vendor_orders_gridComponentRowModel,
  $grid: app_custom_vendor_orders_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  
  
  
          $shell.opencustom_vendor_order_editor({ orderId: $row.entity.Id });
      
  
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: app_custom_vendor_orders_gridComponentRowModel,
  $grid: app_custom_vendor_orders_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  // Set Date/Time Formats
  const format = `${$settings.DockAppointments.DateFormat}, ${$settings.DockAppointments.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  if ($utils.isDefined($row.entity.RequestedDeliveryDate)) {
      $row.cells.expected_date.displayControl.text = $utils.date.format($row.entity.RequestedDeliveryDate, format);
  };
  if ($utils.isDefined($row.entity.CreatedSysDateTime)) {
      $row.cells.created_date.displayControl.text = $utils.date.format($row.entity.CreatedSysDateTime, format);
  };
  if ($utils.isDefined($row.entity.FulfillmentDate)) {
      $row.cells.fulfillment_date.displayControl.text = $utils.date.format($row.entity.FulfillmentDate, format);
  };
  
  
  if ($utils.isDefined($row.entity.ShipmentOrderLookups[0]?.ShipmentId) && $row.entity.OrderStatusId == 4) {
  
      const shipment_confirmation = (await $datasources.custom_ds_get_shipment_confirmation_task_by_shipment_id.get({
          shipment_id: $row.entity.ShipmentOrderLookups[0]?.ShipmentId
      })).result;
      if ($utils.isDefined(shipment_confirmation)) {
  
          const employee = shipment_confirmation.Employee;
          var completed_date = shipment_confirmation.CompletedDateTime;
          const format = `${$settings.DockAppointments.DateFormat}, ${$settings.DockAppointments.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
          if ($utils.isDefined(completed_date)) {
              completed_date = $utils.date.format(completed_date, format);
          }
  
          $row.cells.completed_by.displayControl.text = `${employee} ${completed_date}`
  
      }
  
  
  }
  
  // Set authorized fields
  if ($utils.isDefined($row.entity.ReadyToExport) && $row.entity.ReadyToExport) {
  
      const employee = $row.entity.ReadyToExportBy;
      var authorized_date = $row.entity.ReadyToExportDate;
      const format = `${$settings.DockAppointments.DateFormat}, ${$settings.DockAppointments.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
      if ($utils.isDefined(authorized_date)) {
          authorized_date = $utils.date.format(authorized_date, format);
      }
  
      $row.cells.authorized_by.displayControl.text = `${employee} ${authorized_date}`
    
  
  }
  
  
  
  
  
  
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'app-custom_vendor_orders_grid',
  templateUrl: './app.custom_vendor_orders_grid.component.html'
})
export class app_custom_vendor_orders_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: Iapp_custom_vendor_orders_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: Iapp_custom_vendor_orders_gridComponentInParams = { statusIds: [], materialIds: [] };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'lookupcode', 'PO number', false, false, null, false),       created_date: new GridHeaderModel(new HeaderStyles(null, null), 'created_date', 'Date created', false, false, null, false),       address_lookup: new GridHeaderModel(new HeaderStyles(null, null), 'address_lookup', 'Supplier', false, false, null, false),       expected_date: new GridHeaderModel(new HeaderStyles(null, null), 'expected_date', 'Due date', false, false, null, false),       value: new GridHeaderModel(new HeaderStyles(null, null), 'value', 'Value', false, false, null, false),       authorized_by: new GridHeaderModel(new HeaderStyles(null, null), 'authorized_by', 'Authorized by', false, false, null, false),       completed_by: new GridHeaderModel(new HeaderStyles(null, null), 'completed_by', 'Completed by', false, false, null, false),       vendor_reference: new GridHeaderModel(new HeaderStyles(null, null), 'vendor_reference', 'Supplier invoice no.', false, false, null, false),       fulfillment_date: new GridHeaderModel(new HeaderStyles(null, null), 'fulfillment_date', 'Date received', false, false, null, false),       status: new GridHeaderModel(new HeaderStyles(null, null), 'status', 'Status', false, false, null, false),       total_items: new GridHeaderModel(new HeaderStyles(null, null), 'total_items', 'Total items', false, false, null, false),       ship_from: new GridHeaderModel(new HeaderStyles(null, null), 'ship_from', 'Supplier address', false, false, null, false),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'notes', 'Notes', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: app_custom_vendor_orders_gridComponentRowModel[] = [];

  @Input('statusIds') set $inParams_statusIds(value: any) {
    this.inParams['statusIds'] = value;
  }
  get $inParams_statusIds(): any {
    return this.inParams['statusIds'] ;
  }
  @Input('materialIds') set $inParams_materialIds(value: any) {
    this.inParams['materialIds'] = value;
  }
  get $inParams_materialIds(): any {
    return this.inParams['materialIds'] ;
  }

  topToolbar = {
      refresh: new ToolModel(new ButtonModel('refresh', new ButtonStyles(null, null), false, false, false, 'Refresh', 'icon-ic_fluent_arrow_clockwise_20_regular', null)
    , false),
      separator3: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      complete: new ToolModel(new ButtonModel('complete', new ButtonStyles(null, null), false, false, false, 'Complete', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    , false),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, false, false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    , false),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      print: new ToolModel(new ButtonModel('print', new ButtonStyles(null, null), false, false, false, ' ', 'icon-ic_fluent_print_20_regular', null)
    , false),
      attachments: new ToolModel(new ButtonModel('attachments', new ButtonStyles(null, null), false, false, false, ' ', 'icon-ic_fluent_attach_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    from_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    to_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    from_date: new FieldModel(new DateBoxModel(this.formGroup.controls['from_date'] as DatexFormControl, null, false, false, 'l, LT', 'datetime', null)
, new ControlContainerStyles(null, null), 'From date', false, false),
    to_date: new FieldModel(new DateBoxModel(this.formGroup.controls['to_date'] as DatexFormControl, null, false, false, 'l, LT', 'datetime', null)
, new ControlContainerStyles(null, null), 'To date', false, false),
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: app_ShellService,
    private datasources: app_DatasourceService,
    private flows: app_FlowService,
    private reports: app_ReportService,
    private localization: app_LocalizationService,
    private operations: app_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Vendor orders';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 50;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      statusIds:  $grid.inParams.statusIds ,
      fullTextSearch:  $grid.fullTextSearch ,
      fromDate:  $grid.filters.from_date.control.value ,
      toDate:  $grid.filters.to_date.control.value ,
      take:  null ,
      skip:  null ,
      materialIds:  $grid.inParams.materialIds ,
    };
    try {
      const data = await this.datasources.app.custom_ds_vendor_orders_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as Iapp_custom_vendor_orders_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new app_custom_vendor_orders_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push(row.$initializeExisting(this, entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: app_custom_vendor_orders_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: app_custom_vendor_orders_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  const selectedRowsCount = $grid.selectedRows.length;
  
  // One or more rows selected
  if (selectedRowsCount > 0) {
      if (selectedRowsCount === 1) {
          $grid.topToolbar.attachments.control.readOnly = false;
      }
  
  
      $grid.topToolbar.cancel.control.readOnly = true;
      $grid.topToolbar.on_delete.control.readOnly = true;
      $grid.topToolbar.complete.control.readOnly = true;
  
      $grid.topToolbar.print.control.readOnly = false;
  
  
      $grid.topToolbar.complete.control.readOnly = !(await checkAllCompletable());
  
  
      // Cancellation
      $grid.topToolbar.cancel.control.readOnly = !(await checkAllCancellable());
  
  
      // Deletion
      $grid.topToolbar.on_delete.control.readOnly = !(await checkAllDeletable());
  
  
  }
  else {
  
      $grid.topToolbar.cancel.control.readOnly = true;
      $grid.topToolbar.on_delete.control.readOnly = true;
      $grid.topToolbar.complete.control.readOnly = true;
  
      $grid.topToolbar.print.control.readOnly = true;
      $grid.topToolbar.attachments.control.readOnly = true;
  }
  
  
  /**************************************************** 
   * FUNCTIONS
  *****************************************************/
  
  
  
  async function checkAllCompletable() {
      let allow = $grid.selectedRows.length > 0;
  
      for (let row of $grid.selectedRows) {
  
          // Created status
          if (row.entity.OrderStatusId === 1) {
  
              return true;
          }
          else {
              return false
          }
  
      }
  
      return allow;
  }
  
  
  async function checkAllDeletable() {
      let allow = $grid.selectedRows.length > 0;
  
      for (let row of $grid.selectedRows) {
          // Not Created status
          if (row.entity.OrderStatusId !== 1) {
  
              return false;
          }
          else {
              return true
          }
      }
  
      return allow;
  }
  
  async function checkAllCancellable() {
      let allow = $grid.selectedRows.length > 0;
  
      for (let row of $grid.selectedRows) {
          // Created status
          if (row.entity.OrderStatusId === 1) {
  
              return true;
          }
          else {
              return false
          }
      }
  
      return allow;
  }
  
  
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $grid: app_custom_vendor_orders_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Error cancelling orders', 'No orders selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
  
   
              candidates.push(row);
          
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Error cancelling orders';
          const errorMessage = `Order(s) ${failures.map(c => c.entity.LookupCode).join(', ')} cannot be cancelled`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Order(s) ${candidates.map(c => c.entity.LookupCode).join(', ')} - Once cancelled, the order cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some order(s) cannot be cancelled';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Cancel the following order(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const CandidateSuccess = [];
              const CandidateFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      let reasons: string[];
                
                          const flowParams = {
                              orderId: candidate.entity.Id,
                              shipmentId: candidate.entity.ShipmentOrderLookups[0]?.ShipmentId
                          }
                          let result = await $flows.custom_cancel_vendor_order_flow(flowParams);
                          if ($utils.isDefined(result.reasons)) {
                              reasons = result.reasons;
                          }
                      
                    
                      
                      if ($utils.isDefined(reasons)) {
                          CandidateFailures.push(candidate);
                          errorMsgList.push(`Order ${candidate.entity.LookupCode} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons})).formattedValue}`);
                      } else {
                          CandidateSuccess.push(candidate);
                      }
  
  
                  } catch (error) {
                      CandidateFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Order ${candidate.entity.LookupCode} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (CandidateSuccess.length === candidates.length) {
                  const title = 'All order(s) cancelled';
                  const message = `Order(s) ${CandidateSuccess.map(c => c.entity.LookupCode).join(',')} cancelled`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (CandidateFailures.length === candidates.length) {
                      const title = 'All order(s) failed to cancel';
                      const message = `Order(s) ${CandidateFailures.map(c => c.entity.LookupCode).join(',')} could not be cancelled`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some order(s) could not be cancelled';
                      const success = `Order(s) ${CandidateSuccess.map(c => c.entity.LookupCode).join(',')} were cancelled.`;
                      const errors = `The following order(s) could not be cancelled: ${CandidateFailures.map(c => c.entity.LookupCode).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_complete_clicked(event = null) {
    return this.on_complete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_complete_clickedInternal(
    $grid: app_custom_vendor_orders_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Error completing orders', 'No orders selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
       
              candidates.push(row);
          
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Error completing orders';
          const errorMessage = `Order(s) ${failures.map(c => c.entity.LookupCode).join(', ')} cannot be completed`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Order(s) ${candidates.map(c => c.entity.LookupCode).join(',')} - Once completed, the order cannot be changed.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some order(s) cannot be completed';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Complete the following order(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const CandidateSuccess = [];
              const CandidateFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      let reasons: string[];
                     
                          const flowParams = {
                              orderId: candidate.entity.Id,
                              shipmentId: candidate.entity.ShipmentOrderLookups[0]?.ShipmentId
                          }
                          let result = await $flows.custom_complete_vendor_order_flow(flowParams);
                          reasons = result.reasons;
                      
                 
                      if ($utils.isDefined(reasons)) {
                          CandidateFailures.push(candidate);
                          errorMsgList.push(`Order ${candidate.entity.LookupCode} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons})).formattedValue}`);
                      } else {
                          CandidateSuccess.push(candidate);
                      }
  
                  } catch (error) {
                      CandidateFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Order ${candidate.entity.LookupCode} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (CandidateSuccess.length === candidates.length) {
                  const title = 'All order(s) completed';
                  const message = `Order(s) ${CandidateSuccess.map(c => c.entity.LookupCode).join(',')} completed`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (CandidateFailures.length === candidates.length) {
                      const title = 'All order(s) failed to complete';
                      const message = `Order(s) ${CandidateFailures.map(c => c.entity.LookupCode).join(',')} could not be completed`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some order(s) could not be completed';
                      const success = `Order(s) ${CandidateSuccess.map(c => c.entity.LookupCode).join(',')} were completed.`;
                      const errors = `The following order(s) could not be completed: ${CandidateFailures.map(c => c.entity.LookupCode).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: app_custom_vendor_orders_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Error deleting orders', 'No orders selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
  
          candidates.push(row);
  
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Error deleting orders';
          const errorMessage = `Order(s) ${failures.map(c => c.entity.LookupCode).join(', ')} cannot be deleted`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Order(s) ${candidates.map(c => c.entity.LookupCode).join(', ')} - Once deleted, the order cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some order(s) cannot be deleted';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Delete the following order(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const CandidateSuccess = [];
              const CandidateFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      let reasons: string[];
  
                      const flowParams = {
                          orderId: candidate.entity.Id,
                          shipmentId: candidate.entity.ShipmentOrderLookups[0]?.ShipmentId
                      }
                      let result = await $flows.custom_delete_vendor_order_flow(flowParams);
                      reasons = result.reasons;
  
  
                      if ($utils.isDefined(reasons)) {
                          CandidateFailures.push(candidate);
                          errorMsgList.push(`Order ${candidate.entity.LookupCode} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue}`);
                      } else {
                          CandidateSuccess.push(candidate);
                      }
  
                  } catch (error) {
                      CandidateFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Order ${candidate.entity.LookupCode} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (CandidateSuccess.length === candidates.length) {
                  const title = 'All order(s) deleted';
                  const message = `Order(s) ${CandidateSuccess.map(c => c.entity.LookupCode).join(',')} deleted`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (CandidateFailures.length === candidates.length) {
                      const title = 'All order(s) failed to delete';
                      const message = `Order(s) ${CandidateFailures.map(c => c.entity.LookupCode).join(',')} could not be deleted`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some order(s) could not be deleted';
                      const success = `Order(s) ${CandidateSuccess.map(c => c.entity.LookupCode).join(',')} were deleted.`;
                      const errors = `The following order(s) could not be deleted: ${CandidateFailures.map(c => c.entity.LookupCode).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: app_custom_vendor_orders_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  }
  on_print_clicked(event = null) {
    return this.on_print_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_clickedInternal(
    $grid: app_custom_vendor_orders_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  const selected_rows = $grid.selectedRows
  
  if ($utils.isDefined(selected_rows)) {
  
      for (let selected_row of selected_rows) {
  
  
          const authorized = selected_row.entity.ReadyToExport;
  
          if ($utils.isDefined(authorized) && authorized) {
              await $shell.PurchaseOrders.openpurchase_order_reportDialog({ orderId: selected_row.entity.Id });
          }
          else {
              throw new Error('Vendor order should be authorized first.')
          }
  
      }
  
  }
  
  
  
  
  
  }
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: app_custom_vendor_orders_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  const inbound_orders = $grid.rows.map(row => row.entity.Id);
  
  
  let order_lookupCodes: { orderId?: number, formattedLookupCode?: string }[];
  
  if (inbound_orders.length > 0) {
  
  
      const order_tasks = (await $datasources.custom_ds_get_orders_aggregated_shipmentlines.get({ orderIds: inbound_orders })).result;
      const expected_inbound = (await $datasources.custom_ds_get_orders_aggregated_expected.get({ orderIds: inbound_orders })).result;
      const order_addresses = (await $flows.Addresses.get_addresses_single_string_by_orderIds({ orderIds: inbound_orders }));
  
  
  
  
      const receiving_tasks = order_tasks.filter(t => (t.TotalPackagedAmount > 0))
  
  
  
      const receiving_array = receiving_tasks.map(row => row.OrderId);
      const expected_array = expected_inbound.map(row => row.OrderId);
      const address_array = order_addresses.Addresses.map(row => row.OrderId);
      const lookup_array = order_lookupCodes?.map(row => row.orderId);
  
      for (let row of $grid.rows) {
  
          let received_total = 0;
          let expected_total = 0;
          let expected_value = 0;
          let orderIndex = -1;
  
          let orderId = row.entity.Id;
          let order_status_id = row.entity.OrderStatusId;
  
          //Get the lookupCode
          if ($utils.isDefined(lookup_array)) {
              orderIndex = lookup_array.indexOf(orderId)
  
          };
  
          //Get the total received inventory
          orderIndex = receiving_array.indexOf(orderId)
          if (orderIndex >= 0) {
              received_total = receiving_tasks[orderIndex].TotalPackagedAmount;
          };
  
          //Get the total expected inventory
          orderIndex = expected_array.indexOf(orderId)
          if (orderIndex >= 0) {
              expected_total = expected_inbound[orderIndex].Amount;
              expected_value = expected_inbound[orderIndex].Price;
          };
  
  
          row.cells.total_items.displayControl.text = `${received_total} of ${expected_total}`
          row.cells.value.displayControl.text = `${expected_value}`
  
  
  
  
  
          //Set the Ship_From
          orderIndex = address_array.indexOf(orderId)
          if (orderIndex >= 0) {
  
              let address = order_addresses.Addresses[orderIndex]
  
              if ($utils.isDefined(address)) {
  
                  let ship_from = address.Address;
  
                  if (!ship_from.includes('ord-')) {
                      row.cells.ship_from.displayControl.text = ship_from
                      row.cells.address_lookup.displayControl.text = row.entity.Account.LookupCode;
                  }
                  else {
                      row.cells.ship_from.displayControl.text = '';
                      row.cells.address_lookup.displayControl.text = '';
                  };
              };
          };
      };
  
  
  
  };
  }
  on_attachments_clicked(event = null) {
    return this.on_attachments_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_attachments_clickedInternal(
    $grid: app_custom_vendor_orders_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  if ($grid.selectedRows.length === 1) {
      await $shell.FootPrintManager.openorders_attachments_hubDialog({
          orderId: $grid.selectedRows[0].entity.Id, shipmentId: $grid.selectedRows[0].entity.ShipmentOrderLookups[0].ShipmentId});
  }
  }
  on_refresh(event = null) {
    return this.on_refreshInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_refreshInternal(
    $grid: app_custom_vendor_orders_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  $grid.refresh();
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
