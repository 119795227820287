import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';
import { ShipTheory_FlowService } from './ShipTheory.flow.index';
import { PrintNode_FlowService } from './PrintNode.flow.index';
import { EasyPost_FlowService } from './EasyPost.flow.index';

import { Manifesting_get_buyer_addressService } from './Manifesting.flow.index';
import { Manifesting_get_label_contents_explanationService } from './Manifesting.flow.index';
import { Manifesting_get_packinglist_contentService } from './Manifesting.flow.index';
import { Manifesting_get_ship_from_address_flowService } from './Manifesting.flow.index';
import { Manifesting_get_ship_to_address_flowService } from './Manifesting.flow.index';
import { Manifesting_get_third_party_addressService } from './Manifesting.flow.index';

import { $frontendTypes } from './Manifesting.frontend.types'

@Injectable({ providedIn: 'root' })
export class Manifesting_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
    this.ShipTheory = this.injector.get(ShipTheory_FlowService);
    this.PrintNode = this.injector.get(PrintNode_FlowService);
    this.EasyPost = this.injector.get(EasyPost_FlowService);
  }

    public Utilities: Utilities_FlowService;
    public ShipTheory: ShipTheory_FlowService;
    public PrintNode: PrintNode_FlowService;
    public EasyPost: EasyPost_FlowService;
  public Manifesting: Manifesting_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _get_buyer_address: Manifesting_get_buyer_addressService;
  public async get_buyer_address(inParams: { orderId: number, shipmentId: number }): Promise< { address?: { line1?: string, line2?: string, city?: string, state?: string, postalCode?: string, country?: string, email?: string, phone?: string, name?: string, eori?: string, vat?: string } }> {
    if(!this._get_buyer_address) { 
      this._get_buyer_address = this.injector.get(Manifesting_get_buyer_addressService);
    }
    return this._get_buyer_address.run(inParams);
  }
   
   

   
 
  private _get_label_contents_explanation: Manifesting_get_label_contents_explanationService;
  public async get_label_contents_explanation(inParams: { shipping_container_id: number }): Promise< { label_contents_explanation?: string }> {
    if(!this._get_label_contents_explanation) { 
      this._get_label_contents_explanation = this.injector.get(Manifesting_get_label_contents_explanationService);
    }
    return this._get_label_contents_explanation.run(inParams);
  }
   
   

   
 
  private _get_packinglist_content: Manifesting_get_packinglist_contentService;
  public async get_packinglist_content(inParams: { fileType: string, dataType: string, parameters: any }): Promise< { error?: string, contentString?: string }> {
    if(!this._get_packinglist_content) { 
      this._get_packinglist_content = this.injector.get(Manifesting_get_packinglist_contentService);
    }
    return this._get_packinglist_content.run(inParams);
  }
   
   

   
 
  private _get_ship_from_address_flow: Manifesting_get_ship_from_address_flowService;
  public async get_ship_from_address_flow(inParams: { orderId?: number, shipmentId?: number }): Promise< { address?: { name?: string, line1?: string, line2?: string, city?: string, state?: string, postalCode?: string, country?: string, email?: string, phone?: string, eori?: string, vat?: string } }> {
    if(!this._get_ship_from_address_flow) { 
      this._get_ship_from_address_flow = this.injector.get(Manifesting_get_ship_from_address_flowService);
    }
    return this._get_ship_from_address_flow.run(inParams);
  }
   
   

   
 
  private _get_ship_to_address_flow: Manifesting_get_ship_to_address_flowService;
  public async get_ship_to_address_flow(inParams: { orderId?: number, shipmentId?: number }): Promise< { address?: { name?: string, line1?: string, line2?: string, city?: string, state?: string, postalCode?: string, country?: string, email?: string, phone?: string, eori?: string, vat?: string } }> {
    if(!this._get_ship_to_address_flow) { 
      this._get_ship_to_address_flow = this.injector.get(Manifesting_get_ship_to_address_flowService);
    }
    return this._get_ship_to_address_flow.run(inParams);
  }
   
   

   
 
  private _get_third_party_address: Manifesting_get_third_party_addressService;
  public async get_third_party_address(inParams: { orderId?: number, shipmentId?: number }): Promise< { address?: { name?: string, line1?: string, line2?: string, city?: string, state?: string, postalCode?: string, country?: string, email?: string, phone?: string, vat?: string, eori?: string } }> {
    if(!this._get_third_party_address) { 
      this._get_third_party_address = this.injector.get(Manifesting_get_third_party_addressService);
    }
    return this._get_third_party_address.run(inParams);
  }
   
   

   
}
