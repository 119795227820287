import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Materials_materials_by_project_dd_singleComponent } from './Materials.materials_by_project_dd_single.component'
import { FootPrintManager_lots_dd_singleComponent } from './FootPrintManager.lots_dd_single.component'
import { FootPrintManager_vendorlots_dd_singleComponent } from './FootPrintManager.vendorlots_dd_single.component'
import { Materials_material_packagings_dd_singleComponent } from './Materials.material_packagings_dd_single.component'


interface IFootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponentEntity {
  Id?: number, ExpectedInventoryAmount?: number, ExpectedPackagedAmount?: number, OrderId?: number, OrderLineNumber?: number, ProjectId?: number, ShipmentLineId?: number, StatusId?: number, Material?: { Id?: number, ControllerTypeId?: number, LookupCode?: string, PackagingLookups?: { PackagingId?: number }[] }, VendorLot?: { Id?: number, LookupCode?: string }, Lot?: { Id?: number, LookupCode?: string }, ExpectedPackagedPack?: { Id?: number, Name?: string, ShortName?: string }, Status?: { Name?: string }, ActualTargetLicensePlate?: { Id?: number, Archived?: boolean, LookupCode?: string, LicensePlateContents?: { LicensePlateId?: number, LotId?: number, PackagedId?: number, Amount?: number, PackagedAmount?: number }[], Location?: { Name?: string } }}

interface IFootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponentInParams {
  licenseplateId: number, orderId: number, projectId: number, orderStatusId: number}


class FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponent;
  entity: IFootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    material_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    lot_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    vendorlot_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    packaging_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    packaged_amount_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
  });

  override cells = {
    delete: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    status_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    material: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['material_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      ),
    lot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['lot_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      ),
    vendorlot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['vendorlot_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      ),
    packaging: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['packaging_edit'] as DatexFormControl, 
        null, null,
        true,
        false,
        '', null)
      ),
    packaged_amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['packaged_amount_edit'] as DatexFormControl, null, false, false, '', '', null)
      ),
  }

  get $fields_material_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.inParams.projectId;
    
    return expr;
  }
  get $fields_lot_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.material.editControl.value;
    
    return expr;
  }
  get $fields_lot_selector_inParams_vendorLotId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells?.vendorlot?.editControl?.value;
    
    return expr;
  }
  get $fields_vendorlot_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.material?.editControl?.value;
    
    return expr;
  }
  get $fields_vendorlot_selector_inParams_lotId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.lot?.editControl?.value;
    
    return expr;
  }
  get $fields_packaging_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.material.editControl.value;
    
    return expr;
  }


  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
    this.formGroup
      .controls['material_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_material_change();
      });
    this.formGroup
      .controls['lot_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_lot_change();
      });
    this.formGroup
      .controls['vendorlot_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_vendorlot_change();
      });
    this.formGroup
      .controls['packaging_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_packaging_changed();
      });
  }

  async $initializeExisting(grid: FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponent, entity: IFootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponentEntity) {
    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponent, entity?: IFootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponentEntity) {
    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      licenseplateId:  $grid.inParams.licenseplateId ,
      orderId:  $grid.inParams.orderId ,
      fullTextSearch:  null ,
    };
    const data = await this.datasources.AsnOrders.ds_asn_order_licenseplates_receiving_tasks_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.delete.displayControl as TextModel).text = "Delete";
    (this.cells.status_name.displayControl as TextModel).text = $row.entity.Status?.Name;
    (this.cells.material.displayControl as TextModel).text = $row.entity?.Material?.LookupCode;
    (this.cells.material.editControl as SelectBoxModel).reset($row.entity?.Material?.Id);
    (this.cells.lot.displayControl as TextModel).text = $row.entity.Lot?.LookupCode;
    (this.cells.lot.editControl as SelectBoxModel).reset($row.entity?.Lot?.Id);
    (this.cells.vendorlot.displayControl as TextModel).text = $row.entity.VendorLot?.LookupCode;
    (this.cells.vendorlot.editControl as SelectBoxModel).reset($row.entity.VendorLot?.Id);
    (this.cells.packaging.displayControl as TextModel).text = $row.entity?.ExpectedPackagedPack?.ShortName;
    (this.cells.packaging.editControl as SelectBoxModel).reset($row.entity?.ExpectedPackagedPack?.Id);
    (this.cells.packaged_amount.displayControl as TextModel).text = $row.entity.ExpectedPackagedAmount?.toString();
    (this.cells.packaged_amount.editControl as NumberBoxModel).reset($row.entity?.ExpectedPackagedAmount);

    await this.on_row_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'delete' : {
        this.on_row_delete();
        break;
      }
    }
  }

  //#region private flows
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponentRowModel,
  $grid: FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($row.cells.packaged_amount.editControl.value <= 0) {
      $shell.FootPrintManager.openErrorDialog('Save', 'Order line quantity must be greater than 0.');
      throw new Error('Order line quantity must be greater than 0.'); // to prevent displayMode 
  }
  
  
  try {
      // Serial Numbers
      let material = (await $datasources.Materials.ds_get_material_by_materialId.get({materialId: $row.cells.material.editControl.value})).result;
      let adjustedPackagedAmount = $row.cells.packaged_amount.editControl.value - $row.entity.ExpectedPackagedAmount;
  
      if (material[0].ControllerTypeId === 3 || material[0].ControllerTypeId === 4 || !material[0].IsFixedWeight || !material[0].IsFixedVolume || !material[0].IsFixedLength) {
          if (adjustedPackagedAmount > 0) { // Create SerialNumbers
              let dialogResults = await $shell.FootPrintManager.openserialnumbers_creation_gridDialog({ materialId: material[0].Id, packagingId: $row.cells.packaging.editControl.value, packagedAmount: adjustedPackagedAmount });
  
              let serialnumbersResult = dialogResults.serialNumbers;
              let confirmResult = dialogResults.confirm;
  
              if (confirmResult === true && $utils.isDefined(serialnumbersResult) && serialnumbersResult.length === adjustedPackagedAmount) {
                  var serialNumbersToCreate = serialnumbersResult;
                   // Add material, lot, packaging and licenseplate as serial creation flow in the ASN library requires it when creating new serial numbers
                  var serialNumbersWithAdditionalData = serialNumbersToCreate.map(obj => ({
                      ...obj,
                      MaterialId: material[0].Id,
                      LotId: $row.cells.lot.editControl.value,
                      PackagingId: $row.cells.packaging.editControl.value,
                      LicensePlateId: $grid.inParams.licenseplateId
                  }));
              }
              else {
                  throw Error('No serial numbers were provided.');
              }
          }
          else if (adjustedPackagedAmount < 0) {
              let dialogResults = await $shell.FootPrintManager.openserialnumbers_selection_gridDialog({
                  lotId: $row.cells.lot.editControl.value,
                  licenseplateId: $grid.inParams.licenseplateId,
                  packagingId: $row.cells.packaging.editControl.value,
                  packagedAmountRequest: adjustedPackagedAmount * -1
              });
  
              let serialnumberIds = dialogResults.SerialNumberIds;
              let confirmResult = dialogResults.confirm;
  
              if (confirmResult === true && $utils.isDefined(serialnumberIds)) {
                  var serialNumbersToDelete = serialnumberIds;
              }
              else {
                  throw Error('No serial numbers were provided.');
              }
          }
          else {
              //throw new Error('Error creating serial numbers.')
          }
      }
  
      await $flows.AsnOrders.set_asn_receiving_task_flow({
          licenseplateId: $grid.inParams.licenseplateId,
          lotId: $row.cells.lot.editControl.value,
          packagingId: $row.cells.packaging.editControl.value,
          orderId: $grid.inParams.orderId,
          materialId: $row.cells.material.editControl.value,
          vendorlotId: $row.cells.vendorlot.editControl.value,
          packagedAmount: $row.cells.packaged_amount.editControl.value,
          existingTaskId: $row.entity.Id,
          serialNumberIdsToDelete: serialNumbersToDelete,
          serialNumbersToCreate: serialNumbersWithAdditionalData
      });
  
      // Update Grid
      if ($row.cells.packaged_amount.editControl.value > 0) {
          $row.refresh();
      }
      else {
          $grid.rows.splice($grid.rows.indexOf($row), 1);
      }
  }
  catch (ex) {
      let message = $utils.isDefined(ex.error?.error?.message) ? ex.error.error.message : ex.message;
      $shell.FootPrintManager.openErrorDialog('Error', message);
      throw Error(message); // To prevent display mode
  }
  
  $grid.events.receiving_tasks_changed.emit();
  }
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponentRowModel,
  $grid: FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($row.cells.packaged_amount.editControl.value <= 0) {
      $shell.FootPrintManager.openErrorDialog('Save', 'Order line quantity must be greater than 0.');
      throw new Error('Order line quantity must be greater than 0.'); // to prevent displayMode 
  }
  
  
  try {
      // Serial Numbers
      let material = (await $datasources.Materials.ds_get_material_by_materialId.get({materialId: $row.cells.material.editControl.value})).result;
      let adjustedPackagedAmount = $row.cells.packaged_amount.editControl.value;
  
      if (material[0].ControllerTypeId === 3 || material[0].ControllerTypeId === 4 || !material[0].IsFixedWeight || !material[0].IsFixedVolume || !material[0].IsFixedLength) {
          if (adjustedPackagedAmount > 0) { // Create SerialNumbers
              let dialogResults = await $shell.FootPrintManager.openserialnumbers_creation_gridDialog({ materialId: material[0].Id, packagingId: $row.cells.packaging.editControl.value, packagedAmount: adjustedPackagedAmount, lotId: $row.cells.lot.editControl.value, licensePlateId: $grid.inParams.licenseplateId });
  
              let serialnumbersResult = dialogResults.serialNumbers;
              let confirmResult = dialogResults.confirm;
  
              if (confirmResult === true && $utils.isDefined(serialnumbersResult) && serialnumbersResult.length === adjustedPackagedAmount) {
                  var serialNumbersToCreate = serialnumbersResult;
                   // Add material, lot, packaging and licenseplate as serial creation flow in the ASN library requires it when creating new serial numbers
                  var serialNumbersWithAdditionalData = serialNumbersToCreate.map(obj => ({
                      ...obj,
                      MaterialId: material[0].Id,
                      LotId: $row.cells.lot.editControl.value,
                      PackagingId: $row.cells.packaging.editControl.value,
                      LicensePlateId: $grid.inParams.licenseplateId
                  }));
              }
              else {
                  throw Error('No serial numbers were provided.');
              }
          }
      }
  
      $row.entity.Id = (await $flows.AsnOrders.set_asn_receiving_task_flow({
          licenseplateId: $grid.inParams.licenseplateId,
          lotId: $row.cells.lot.editControl.value,
          packagingId: $row.cells.packaging.editControl.value,
          orderId: $grid.inParams.orderId,
          materialId: $row.cells.material.editControl.value,
          vendorlotId: $row.cells.vendorlot.editControl.value,
          packagedAmount: $row.cells.packaged_amount.editControl.value,
          existingTaskId: null,
          serialNumbersToCreate: serialNumbersWithAdditionalData
      })).taskId;
  
  
  
      await $row.refresh();
  
  }
  catch (ex) {
      let message = $utils.isDefined(ex.error?.error?.message) ? ex.error.error.message : ex.message;
      $shell.FootPrintManager.openErrorDialog('Error', message);
      throw Error(message); // To prevent display mode
  }
  
  $grid.events.receiving_tasks_changed.emit();
  }
  on_row_delete(event = null) {
    return this.on_row_deleteInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_deleteInternal(
    $row: FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponentRowModel,
  $grid: FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($row.cells.delete.displayControl.text != null) {
      let confirmDelete = await $shell.FootPrintManager.openConfirmationDialog('Confirm: Delete Content', 'Are you sure you want to delete this content?', 'Confirm', 'Cancel');
  
      if ($utils.isDefined(confirmDelete) && confirmDelete) {
          var serialNumbers = (await $flows.AsnOrders.get_serials_to_delete_with_children_flow({ licenseplateId: $grid.inParams.licenseplateId }))
          if ($utils.isDefined(serialNumbers)) {
              var parentSerials = serialNumbers.parentSerialNumbers
              if ($utils.isDefined(serialNumbers.fourthChildSerials)) {
                  let result = (await $flows.AsnOrders.delete_children_serial_numbers_flow({ serialnumberIds: serialNumbers.fourthChildSerials })).reasons
                  if ($utils.isDefined(result)) {
                      $shell.FootPrintManager.openErrorDialog(`Error deleting children serial numbers ${serialNumbers.fourthChildSerials}`, result.join(' ,'))
                  }
              }
              if ($utils.isDefined(serialNumbers.thirdChildSerials)) {
                  let result = (await $flows.AsnOrders.delete_children_serial_numbers_flow({ serialnumberIds: serialNumbers.thirdChildSerials })).reasons
                  if ($utils.isDefined(result)) {
                      $shell.FootPrintManager.openErrorDialog(`Error deleting children serial numbers ${serialNumbers.thirdChildSerials}`, result.join(' ,'))
                  }
              }
              if ($utils.isDefined(serialNumbers.secondChildSerials)) {
                  let result = (await $flows.AsnOrders.delete_children_serial_numbers_flow({ serialnumberIds: serialNumbers.secondChildSerials })).reasons
                  if ($utils.isDefined(result)) {
                      $shell.FootPrintManager.openErrorDialog(`Error deleting children serial numbers ${serialNumbers.secondChildSerials}`, result.join(' ,'))
                  }
              }
              if ($utils.isDefined(serialNumbers.firstChildSerials)) {
                  let result = (await $flows.AsnOrders.delete_children_serial_numbers_flow({ serialnumberIds: serialNumbers.firstChildSerials })).reasons
                  if ($utils.isDefined(result)) {
                      $shell.FootPrintManager.openErrorDialog(`Error deleting children serial numbers ${serialNumbers.firstChildSerials}`, result.join(' ,'))
                  }
              }
          }
  
          await $flows.AsnOrders.set_asn_receiving_task_flow({
              licenseplateId: $grid.inParams.licenseplateId,
              lotId: $row.entity.Lot.Id,
              packagingId: $row.entity.ExpectedPackagedPack.Id,
              orderId: $grid.inParams.orderId,
              materialId: $row.entity.Material.Id,
              vendorlotId: $row.entity.VendorLot.Id,
              packagedAmount: 0,
              existingTaskId: $row.entity.Id,
              serialNumberIdsToDelete: parentSerials
          });
  
          $grid.rows.splice($grid.rows.indexOf($row), 1);
          $grid.refresh();
      }
  }
  
  $grid.events.receiving_tasks_changed.emit();
  }
  on_material_change(event = null) {
    return this.on_material_changeInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_changeInternal(
    $row: FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponentRowModel,
  $grid: FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  var materialId = $row.cells.material.editControl.value;
  
  
  if ($row.cells.material.editControl.isChanged) {
      // Clear dependent fields
      $row.cells.lot.editControl.value = null;
      $row.cells.vendorlot.editControl.value = null;
      $row.cells.packaging.editControl.value = null;
      unlockCell($row.cells.lot);
      unlockCell($row.cells.vendorlot);
      unlockCell($row.cells.packaging);
  
      if ($utils.isDefined($row.cells.material.editControl.value)) {
          // Get material
          let material = (await $datasources.Materials.ds_get_material_by_materialId.get({ materialId: materialId })).result[0];
          if (!$utils.isDefined(material)) { return; }
  
          // Automatically set Lot if Non-lotted material
          if ((material.ControllerTypeId === 1 || material.ControllerTypeId === 3)) {
              let globalLot = (await $datasources.Lots.ds_get_lots_by_materialId.get({ materialId: material.Id })).result.filter(l => l.TypeId === 1)[0];
              if ($utils.isDefined(globalLot)) {
                  $row.cells.vendorlot.editControl.value = globalLot.VendorLotId;
                  lockCell($row.cells.vendorlot);
                  $row.cells.lot.editControl.value = globalLot.Id;
                  lockCell($row.cells.lot);
              }
          }
  
          // Default the packaging
          const defaultPackaging = (await $flows.Materials.get_material_default_packaging({ materialId: materialId }));
          var packagingId = defaultPackaging.packagingId;
  
          if ($utils.isDefined(packagingId)) {
  
              $row.cells.packaging.editControl.value = packagingId;
              //lockCell($row.cells.packaging);
  
  
              // Default the decimal formatting
              const decimalFormat = (await $flows.Materials.get_material_packaging_decimal_precision_formatted({
                  materialId: materialId,
                  packagingId: packagingId
              }));
  
              if ($utils.isDefined(decimalFormat)) {
                  $row.cells.packaged_amount.editControl.format = decimalFormat.format;
  
              }
  
          }
  
      } else {
          lockCell($row.cells.lot);
          lockCell($row.cells.vendorlot);
          lockCell($row.cells.packaging);
      }
  }
  
  /************************************************* 
   * FUNCTIONS
  *************************************************/
  function lockCell(cell: any) {
      cell.editControl.readOnly = true;
      cell.editControl.styles.setStyle('background', 'transparent');
  }
  
  function unlockCell(cell: any) {
      cell.editControl.readOnly = false;
      cell.editControl.styles.resetStyle();
  }
  }
  on_lot_change(event = null) {
    return this.on_lot_changeInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lot_changeInternal(
    $row: FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponentRowModel,
  $grid: FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($row.cells.lot.editControl.isChanged) {
      if ($utils.isDefined($row.cells.lot.editControl.value)) {
          let vendorlot = (await $datasources.Lots.ds_find_vendorlots.get({lotId: $row.cells.lot.editControl.value})).result[0];
          if ($utils.isDefined(vendorlot)) {
              $row.cells.vendorlot.editControl.value = vendorlot.Id;
          }
      }
  }
  }
  on_vendorlot_change(event = null) {
    return this.on_vendorlot_changeInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_vendorlot_changeInternal(
    $row: FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponentRowModel,
  $grid: FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($row.cells.vendorlot.editControl.isChanged) {
      $row.cells.lot.editControl.value = null;
  }
  }
  on_row_loaded(event = null) {
    return this.on_row_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_loadedInternal(
    $row: FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponentRowModel,
  $grid: FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let orderStatusId: number = $grid.inParams.orderStatusId;
  if (!$grid.canEdit) {
      $row.cells.delete.displayControl.text = null;
  }
  else if ($row.entity.StatusId === 2) {
      $row.cells.delete.displayControl.text = "Cancel";
  } 
  else if (orderStatusId !== 1){ 
      $row.cells.lot.editControl.readOnly = true; 
      $row.cells.vendorlot.editControl.readOnly = true; 
      $row.cells.material.editControl.readOnly = true; 
      $row.cells.packaging.editControl.readOnly = true; 
      $row.cells.packaged_amount.editControl.readOnly = true; 
  }
  }
  on_packaging_changed(event = null) {
    return this.on_packaging_changedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_packaging_changedInternal(
    $row: FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponentRowModel,
  $grid: FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  var materialId = $row.cells.material.editControl.value;
  var packagingId = $row.cells.packaging.editControl.value;
  
  
  // Default the decimal formatting
  const decimalFormat = (await $flows.Materials.get_material_packaging_decimal_precision_formatted({
      materialId: materialId,
      packagingId: packagingId
  }));
  
  if ($utils.isDefined(decimalFormat)) {
      $row.cells.packaged_amount.editControl.format = decimalFormat.format;
  
  }
  
  
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Materials_materials_by_project_dd_singleComponent),
    forwardRef(() => FootPrintManager_lots_dd_singleComponent),
    forwardRef(() => FootPrintManager_vendorlots_dd_singleComponent),
    forwardRef(() => Materials_material_packagings_dd_singleComponent),
  ],
  selector: 'FootPrintManager-asn_order_licenseplates_receiving_tasks_grid',
  templateUrl: './FootPrintManager.asn_order_licenseplates_receiving_tasks_grid.component.html'
})
export class FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponentInParams = { licenseplateId: null, orderId: null, projectId: null, orderStatusId: null };


  //#region Variables
  vars: { shipments?: { id?: number, warehouseId?: number }[] } = { };
  //#endregion
  //#region Events
  @Output()
  receiving_tasks_changed = new EventEmitter<void>();
  
  events = {
    receiving_tasks_changed: this.receiving_tasks_changed,
  }
  //#endregion

  headers = {
     delete: new GridHeaderModel(new HeaderStyles(null, null), 'delete', ' ', false, false, null, false),       status_name: new GridHeaderModel(new HeaderStyles(null, null), 'status_name', 'Status name', false, false, null, false),       material: new GridHeaderModel(new HeaderStyles(null, null), 'material', 'Material', true, false, null, false),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'lot', 'Lot', true, false, null, false),       vendorlot: new GridHeaderModel(new HeaderStyles(null, null), 'vendorlot', 'Vendor lot', true, false, null, false),       packaging: new GridHeaderModel(new HeaderStyles(null, null), 'packaging', 'Packaging', true, false, null, false),       packaged_amount: new GridHeaderModel(new HeaderStyles(null, null), 'packaged_amount', 'Packaged amount', true, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponentRowModel[] = [];

  @Input('licenseplateId') set $inParams_licenseplateId(value: any) {
    this.inParams['licenseplateId'] = value;
  }
  get $inParams_licenseplateId(): any {
    return this.inParams['licenseplateId'] ;
  }
  @Input('orderId') set $inParams_orderId(value: any) {
    this.inParams['orderId'] = value;
  }
  get $inParams_orderId(): any {
    return this.inParams['orderId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('orderStatusId') set $inParams_orderStatusId(value: any) {
    this.inParams['orderStatusId'] = value;
  }
  get $inParams_orderStatusId(): any {
    return this.inParams['orderStatusId'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, false, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Receiving tasks';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.licenseplateId)) {
        this.$missingRequiredInParams.push('licenseplateId');
      }
      if(isNil(this.inParams.orderId)) {
        this.$missingRequiredInParams.push('orderId');
      }
      if(isNil(this.inParams.projectId)) {
        this.$missingRequiredInParams.push('projectId');
      }
      if(isNil(this.inParams.orderStatusId)) {
        this.$missingRequiredInParams.push('orderStatusId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 10;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      licenseplateId:  $grid.inParams.licenseplateId ,
      orderId:  $grid.inParams.orderId ,
      fullTextSearch:  null ,
    };
    try {
      const data = await this.datasources.AsnOrders.ds_asn_order_licenseplates_receiving_tasks_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push(row.$initializeExisting(this, entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  async addRow(entity?: IFootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponentEntity) {
    const row = new FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations,
      this.logger);
    await row.$initializeNew(this, entity);
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  common_process_rows(event = null) {
    return this.common_process_rowsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async common_process_rowsInternal(
    $grid: FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'asn_order_licenseplates_receiving_tasks_grid.common_process_rows');
  // DISABLED (PENDING CHANGE TO USE AsnOrders.set_asn_receiving_task_flow INSTEAD)
  for (let record of /*$grid.vars.rowsToProcess*/[]) {
  
      switch (record.action) {
          case "Add": {
              let taskId = await addNewTask(
                  $grid.inParams.orderId,
                  null,
                  record.row.cells.material.editControl.value,
                  record.row.cells.packaging.editControl.value,
                  record.row.cells.vendorlot.editControl.value,
                  record.row.cells.lot.editControl.value,
                  record.row.cells.packaged_amount.editControl.value,
                  $grid.inParams.licenseplateId
              );
  
              record.row.entity.Id = taskId;
              record.row.refresh();
              break;
          }
          case "Edit": {
              let taskId = await addNewTask(
                  $grid.inParams.orderId,
                  record.row.entity.Id,
                  record.row.cells.material.editControl.value,
                  record.row.cells.packaging.editControl.value,
                  record.row.cells.vendorlot.editControl.value,
                  record.row.cells.lot.editControl.value,
                  record.row.cells.packaged_amount.editControl.value,
                  $grid.inParams.licenseplateId
              );
  
              await updateShipmentLinePackagedAmount(record.row.entity.ShipmentLineId, -record.row.entity.ExpectedPackagedAmount);
              await updateOrderLinePackagedAmount(record.row.entity.OrderId, record.row.entity.OrderLineNumber, -record.row.entity.ExpectedPackagedAmount);
  
              record.row.refresh();
              break;
          }
          case "Delete": {
              // Delete Receiving Task
              await $flows.Utilities.crud_delete_flow({ id: record.row.entity.Id, entitySet: "Tasks" });
  
              // Delete SerialNumbers and SerialNumberCreation Tasks
              let childTasks = (await $datasources.AsnOrders.ds_get_tasks_by_chainHead.get({chainHead: record.row.entity.Id})).result;
              for (let task of childTasks) {
                  $flows.Utilities.crud_delete_flow({id: task.Id, entitySet: "Tasks"});
                  $flows.Utilities.crud_delete_flow({id: task.SerialNumberId, entitySet: "SerialNumbers"});
              }
  
              // Update Lines
              await updateShipmentLinePackagedAmount(record.row.entity.ShipmentLineId, -record.row.entity.ExpectedPackagedAmount);
              await updateOrderLinePackagedAmount(record.row.entity.OrderId, record.row.entity.OrderLineNumber, -record.row.entity.ExpectedPackagedAmount);            
  
              // Remove from grid
              $grid.rows.splice($grid.rows.indexOf(record.row), 1);
              break;
          }
      }
  }
  
  //$grid.vars.rowsToProcess = [];
  
  
  /********************************************************************************
  *********************************************************************************
   * FUNCTIONS
  ********************************************************************************
  *******************************************************************************/
  async function getMaterialPackaging(materialId: number, packagingId: number) {
      return (await $datasources.Materials.ds_get_material_packaging_by_materialId_and_packagingId.get({
          materialId: materialId,
          packagingId: packagingId
      })).result[0];
  }
  
  
  function validateFields(materialId: number, vendorLotId: number, lotId: number, packagingId: number, packagedAmount: number) {
      // Check has all required fields
      if (!$utils.isAllDefined(
          materialId,
          vendorLotId,
          lotId,
          packagingId,
          packagedAmount
      )) {
          throw new Error('Please populate all required fields.');
      }
  
      // Validate values
      if (packagedAmount <= 0) {
          throw new Error('Please provide a positive value for the Packaged Amount field.');
      }
  }
  
  
  async function updateShipmentLinePackagedAmount(shipmentLineId: number, adjustPackagedAmount: number) {
      let shipmentLine = (await $datasources.AsnOrders.ds_get_shipmentline_by_shipmentlineId.get({ shipmentLineId: shipmentLineId })).result;
  
      if ($utils.isDefined(shipmentLine)) {
          if (shipmentLine.ExpectedPackagedAmount + adjustPackagedAmount > 0) {
              await $flows.Utilities.crud_update_flow({
                  entitySet: "ShipmentLines",
                  id: shipmentLineId,
                  entity: {
                      "ExpectedPackagedAmount": shipmentLine.ExpectedPackagedAmount + adjustPackagedAmount,
                      "ExpectedAmount": (shipmentLine.ExpectedPackagedAmount + adjustPackagedAmount) * (shipmentLine.ExpectedAmount / shipmentLine.ExpectedPackagedAmount)
                  }
              });
          }
          else {
              await $flows.Utilities.crud_delete_flow({ id: shipmentLineId, entitySet: "ShipmentLines" });
          }
      }
  }
  
  
  async function updateOrderLinePackagedAmount(orderId: number, orderLineNumber: number, adjustPackagedAmount: number) {
      let orderLine = (await $datasources.AsnOrders.ds_get_orderlines_by_orderId_linenumber.get({ orderId: orderId, linenumber: orderLineNumber })).result;
  
      // Update or Delete OrderLine
      if ($utils.isDefined(orderLine)) {
          if (orderLine.PackagedAmount + adjustPackagedAmount > 0) {
              await $flows.Utilities.crud_update_dual_pk_flow({
                  entitySet: "OrderLines",
                  pk1String: "OrderId",
                  pk1: orderLine.OrderId,
                  pk2String: "LineNumber",
                  pk2: orderLine.LineNumber,
                  entity: {
                      "PackagedAmount": orderLine.PackagedAmount + adjustPackagedAmount,
                      "Amount": (orderLine.PackagedAmount + adjustPackagedAmount) * (orderLine.Amount / orderLine.PackagedAmount)
                  }
              });
          }
          else {
              await $flows.Utilities.crud_delete_dual_pk_flow({
                  entitySet: "OrderLines",
                  pk1String: "OrderId",
                  pk1: orderLine.OrderId,
                  pk2String: "LineNumber",
                  pk2: orderLine.LineNumber
              });
          }
      }
  }
  
  
  async function addNewTask(orderId: number, receivingTaskId: number, materialId: number, packagingId: number, vendorLotId: number, lotId: number, packagedAmount: number, licenseplateId: number) {
      validateFields(
          materialId,
          vendorLotId,
          lotId,
          packagingId,
          packagedAmount
      );
  
      let matchingOrderLine = (await $datasources.AsnOrders.ds_get_orderlines_by_content.get({
          orderId: orderId,
          materialId: materialId,
          packagingId: packagingId,
          vendorLotId: vendorLotId,
          lotId: lotId
      })).result[0];
  
      // Get Data
      var licenseplate = (await $datasources.Locations.ds_get_licenseplate_by_licenseplateId.get({ licenseplateId: licenseplateId })).result;
      var order = (await $datasources.AsnOrders.ds_get_order_by_orderId.get({ orderId: orderId })).result;
      let material = (await $datasources.Materials.ds_get_material_by_materialId.get({ materialId: materialId })).result[0];
      let packaging = await getMaterialPackaging(materialId, packagingId);
      var orderLineNumber: number;
      var shipmentLineId: number;
  
      // OrderLine
      if ($utils.isDefined(matchingOrderLine)) { // OrderLine Exists
          orderLineNumber = matchingOrderLine.LineNumber;
  
          await updateOrderLinePackagedAmount(orderId, orderLineNumber, packagedAmount)
  
          // Update ShipmentLine
          let matchingShipmentLine = (await $datasources.AsnOrders.ds_get_shipmentlines_by_orderline.get({ orderId: matchingOrderLine.OrderId, orderLineNumber: matchingOrderLine.LineNumber })).result[0];
  
          if ($utils.isDefined(matchingShipmentLine)) {
              shipmentLineId = matchingShipmentLine.Id
              await updateShipmentLinePackagedAmount(shipmentLineId, packagedAmount);
          }
          else {
              shipmentLineId = await createShipmentLine(orderId, orderLineNumber, licenseplate.ShipmentId, packagedAmount, packaging.BasePackagingQuantity, packaging.PackagingId);
          }
      }
      else { // OrderLine does not exist
          // Create OrderLine
          orderLineNumber = (await createOrderLine(
              orderId,
              materialId,
              vendorLotId,
              lotId,
              licenseplate.ShipmentId,
              packagedAmount,
              packaging.BasePackagingQuantity,
              packagingId
          ));
  
          // Create ShipmentLine
          shipmentLineId = await createShipmentLine(orderId, orderLineNumber, licenseplate.ShipmentId, packagedAmount, packaging.BasePackagingQuantity, packaging.PackagingId);
      }
  
      // Create/Update Task
      if (!$utils.isDefined(receivingTaskId)) {
          receivingTaskId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Task' })).nextId;
      }
  
  
      let properties = {
          "ActualTargetLicensePlateId": licenseplate.Id,
          "ActualTargetLocationId": licenseplate.Location.Id,
          "ChainHead": 0,
          "ExpectedInventoryAmount": packagedAmount * packaging.BasePackagingQuantity,
          "ExpectedInventoryAmountPackId": packaging.BasePackagingId,
          "ExpectedPackagedAmount": packagedAmount,
          "ExpectedPackagedPackId": packagingId,
          "ExpectedTargetLicensePlateId": licenseplate.Id,
          "ExpectedTargetLocationId": licenseplate.Location.Id,
          "GrossVolume": packaging.ShippingVolume * packagedAmount,
          "GrossWeight": packaging.ShippingWeight * packagedAmount,
          "Id": receivingTaskId,
          "LotId": lotId,
          "MaterialId": materialId,
          "NetVolume": packaging.Volume * packagedAmount,
          "NetWeight": packaging.Weight * packagedAmount,
          "OperationCodeId": 2,
          "OrderId": $grid.inParams.orderId,
          "OrderLineNumber": orderLineNumber,
          "ProjectId": order.ProjectId,
          "ShipmentId": licenseplate.ShipmentId,
          "ShipmentLineId": shipmentLineId,
          "StartDateTime": new Date(),
          "StatusId": 4,
          "VendorLotId": vendorLotId,
          "VolumeUomId": packaging.VolumeUomId,
          "WarehouseId": licenseplate.Warehouse.Id,
          "WeightUomId": packaging.WeightUomId,
      }
  
      await $flows.Utilities.crud_create_flow({ entitySet: "Tasks", entity: properties });
  
      // Serial Numbers
      if (material.ControllerTypeId === 3 || material.ControllerTypeId === 4 || !material.IsFixedWeight) {
          let dialogResults = await $shell.FootPrintManager.openserialnumbers_creation_gridDialog({ materialId, packagingId, packagedAmount });
          let serialnumbersResult = dialogResults.serialNumbers;
          let confirmResult = dialogResults.confirm;
  
          if (confirmResult === true && $utils.isDefined(serialnumbersResult) && serialnumbersResult.length === packagedAmount) {
              for (let serialnumber of serialnumbersResult) {
                  let serialnumberId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'SerialNumber' })).nextId;
                  let taskId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Task' })).nextId;
  
  
                  // Create SerialNumber
                  let properties: any = {
                      "Archived": false,
                      "DimensionUomId": serialnumber.DimensionUOM,
                      "GrossVolume": serialnumber.GrossVolumeValue,
                      "GrossWeight": serialnumber.GrossWeightValue,
                      "Height": serialnumber.Height,
                      "Id": serialnumberId,
                      "Length": serialnumber.Length,
                      "LicensePlateId": licenseplateId,
                      "LookupCode": serialnumber.LookupCode,
                      "LotId": lotId,
                      "NetVolume": serialnumber.NetVolumeValue,
                      "NetWeight": serialnumber.NetWeightValue,
                      "PackagingId": packaging.PackagingId,
                      "StatusId": 1,
                      "VolumeUomId": serialnumber.VolumeUOM,
                      "WeightUomId": serialnumber.WeightUOM,
                      "Width": serialnumber.Width
                  }
  
                  await $flows.Utilities.crud_create_flow({ entitySet: "SerialNumbers", entity: properties });
  
                  // Create Task
                  properties = {
                      "ActualInventoryAmount": packaging.BasePackagingQuantity,
                      "ActualInventoryAmountPackId": packaging.BasePackagingId,
                      "ActualPackagedAmount": 1,
                      "ActualPackagedPackId": packaging.PackagingId,
                      "ActualSourceLicensePlateId": licenseplateId,
                      "ActualTargetLicensePlateId": licenseplateId,
                      "ActualTargetLocationId": licenseplate.Location.Id,
                      "AuxiliaryExternalIdentifier": receivingTaskId,
                      "ChainHead": receivingTaskId,
                      "CompletedDateTime": new Date(),
                      "ExpectedInventoryAmount": packaging.BasePackagingQuantity,
                      "ExpectedInventoryAmountPackId": packaging.BasePackagingId,
                      "ExpectedPackagedAmount": 1,
                      "ExpectedPackagedPackId": packaging.PackagingId,
                      "GrossVolume": serialnumber.GrossVolumeValue,
                      "GrossWeight": serialnumber.GrossWeightValue,
                      "Id": taskId,
                      "LotId": lotId,
                      "MaterialId": materialId,
                      "NetVolume": serialnumber.NetVolumeValue,
                      "NetWeight": serialnumber.NetWeightValue,
                      "OperationCodeId": 17,
                      "OrderId": orderId,
                      "OrderLineNumber": orderLineNumber,
                      "SerialNumberId": serialnumberId,
                      "ShipmentId": licenseplate.ShipmentId,
                      "ShipmentLineId": shipmentLineId,
                      "StartDateTime": new Date(),
                      "StatusId": 2,
                      "VolumeUomId": serialnumber.VolumeUOM,
                      "WarehouseId": licenseplate.Warehouse.Id,
                      "WeightUomId": serialnumber.WeightUOM,
                  }
  
                  await $flows.Utilities.crud_create_flow({ entitySet: "Tasks", entity: properties });
              }
          }
          else {
              throw new Error('Error creating serial numbers.')
          }
      }
  
      return receivingTaskId;
  }
  
  
  async function createShipmentLine(orderId: number, orderLineNumber: number, shipmentId: number, packagedAmount: number, basePackagingQuantity: number, packagingId: number) {
      let shipmentLineId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'ShipmentLine' })).nextId;
      let shipmentLineNumber = (await $datasources.AsnOrders.ds_get_shipmentlines_by_orderId_bottom1.get({ orderId: orderId })).result?.LineNumber ?? 0 + 1;
  
      let properties = {
          "ActualAmount": 0,
          "ActualPackagedAmount": 0,
          "ActualPackagedId": packagingId,
          "ExpectedAmount": packagedAmount * basePackagingQuantity,
          "ExpectedPackagedAmount": packagedAmount,
          "ExpectedPackagedId": packagingId,
          "Id": shipmentLineId,
          "LineNumber": shipmentLineNumber,
          "NetVolume": 0,
          "OrderId": orderId,
          "OrderLineNumber": orderLineNumber,
          "ShipmentId": shipmentId,
          "StatusId": 2
      }
  
      await $flows.Utilities.crud_create_flow({ entitySet: "ShipmentLines", entity: properties });
  
      return shipmentLineId
  }
  
  async function createOrderLine(orderId: number, materialId: number, vendorLotId: number, lotId: number, shipmentId: number, packagedAmount: number, basePackagingQuantity: number, packagingId: number) {
      let orderLineNumber = ((await $datasources.AsnOrders.ds_get_orderlines_by_orderId_bottom1.get({ orderId: orderId })).result?.LineNumber ?? 0) + 1;
  
      let properties = {
          "Amount": packagedAmount * basePackagingQuantity,
          "Cost": 0,
          "HasChildren": false,
          "LineNumber": orderLineNumber,
          "LotId": lotId,
          "MaterialId": materialId,
          "OrderId": orderId,
          "PackagedAmount": packagedAmount,
          "PackagedId": packagingId,
          "Price": 0,
          "ShipmentId": shipmentId,
          "StatusId": 16,
          "TotalCost": 0,
          "TotalPrice": 0,
          "VendorLotId": vendorLotId
      }
  
      await $flows.Utilities.crud_create_flow({ entitySet: "OrderLines", entity: properties });
  
      return orderLineNumber;
  }
  
  // Serial Numbers
  async function createSerialNumbersOnAsnLicensePlate(
      orderId: number,
      materialId: number,
      lotId: number,
      packagingId: number,
      packagedAmount: number,
      licenseplateId: number
  ) {
  
  }
  }
  apply_operations(event = null) {
    return this.apply_operationsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async apply_operationsInternal(
    $grid: FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let results = (await $datasources.AsnOrders.ds_get_shipments_by_orderId.get({ orderId: $grid.inParams.orderId })).result;
  
  if ($utils.isDefined(results)) {
  
      var shipmentStatus = results[0].Shipment?.StatusId;
      if ($utils.isDefined(shipmentStatus)) {
  
          // Check for completed or cancelled
          if (shipmentStatus !== 8 && shipmentStatus !== 16) {
  
              if (shipmentStatus == 1) {
                  $grid.canEdit = true;
                  $grid.canAdd = true;
  
              }
  
              else if (await $operations.FootPrintManager.Disable_AsnOrders_LicenseplateEditing.isAuthorized()) {
                  $grid.canEdit = false;
                  $grid.canAdd = false;
  
              }
              else {
                  $grid.canEdit = true;
                  $grid.canAdd = true;
              }
  
          }
          else {
              $grid.canEdit = false;
              $grid.canAdd = false;
          }
  
  
      }
  
  }
  
  
  
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let orderStatusId: number = $grid.inParams.orderStatusId;
  let results = (await $datasources.AsnOrders.ds_get_shipments_by_orderId.get({ orderId: $grid.inParams.orderId })).result;
  
  $grid.vars.shipments = results.map(r =>
      ({ id: r.ShipmentId, warehouseId: r.Shipment.ActualWarehouseId ?? r.Shipment.ExpectedWarehouseId })
  );
  
  if(orderStatusId !== 1){
      $grid.headers.delete.hidden = true;
  }
  
  // Call common flow to apply the role based permissions
  await $grid.apply_operations();
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
