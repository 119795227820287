import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';



interface Iapp_custom_outbound_material_scanned_gridComponentEntity {
  Id?: number, Notes?: string, Material?: { LookupCode?: string }}

interface Iapp_custom_outbound_material_scanned_gridComponentInParams {
  orderId: number}


class app_custom_outbound_material_scanned_gridComponentRowModel extends GridRowModel {
  grid: app_custom_outbound_material_scanned_gridComponent;
  entity: Iapp_custom_outbound_material_scanned_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    barcode_notes_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    Id: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    material: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    barcode_notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['barcode_notes_edit'] as DatexFormControl, null, false, false, '', null)
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: app_ShellService,
private datasources: app_DatasourceService,
private flows: app_FlowService,
private reports: app_ReportService,
private localization: app_LocalizationService,
private operations: app_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: app_custom_outbound_material_scanned_gridComponent, entity: Iapp_custom_outbound_material_scanned_gridComponentEntity) {
    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: app_custom_outbound_material_scanned_gridComponent, entity?: Iapp_custom_outbound_material_scanned_gridComponentEntity) {
    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      orderId:  $grid.inParams.orderId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    const data = await this.datasources.app.custom_ds_outbound_material_scanned_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as Iapp_custom_outbound_material_scanned_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.Id.displayControl as TextModel).text = $row.entity.Id?.toString();
    (this.cells.material.displayControl as TextModel).text = $row.entity.Material?.LookupCode;
    (this.cells.barcode_notes.displayControl as TextModel).text = $row.entity.Notes;
    (this.cells.barcode_notes.editControl as TextBoxModel).reset($row.entity.Notes);

  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: app_custom_outbound_material_scanned_gridComponentRowModel,
  $grid: app_custom_outbound_material_scanned_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  let payload: any = {};
  
  if ($row.cells.barcode_notes.editControl.isChanged) {
      payload.Notes = $row.cells.barcode_notes.editControl.value;
  }
  
  
  try {
      
      await $flows.Utilities.crud_update_flow({ entitySet: 'Tasks', id: $row.entity.Id, entity: payload});
  
      await $row.refresh();
    } catch (error) {
        $shell.showErrorDetails('Save', 'Error on save.', error); 
        throw error; // to prevent displayMode 
    }
  
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'app-custom_outbound_material_scanned_grid',
  templateUrl: './app.custom_outbound_material_scanned_grid.component.html'
})
export class app_custom_outbound_material_scanned_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: Iapp_custom_outbound_material_scanned_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: Iapp_custom_outbound_material_scanned_gridComponentInParams = { orderId: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     Id: new GridHeaderModel(new HeaderStyles(null, null), 'Id', 'Id', false, false, null, false),       material: new GridHeaderModel(new HeaderStyles(null, null), 'material', 'Material', false, false, null, false),       barcode_notes: new GridHeaderModel(new HeaderStyles(null, null), 'barcode_notes', 'Barcode', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: app_custom_outbound_material_scanned_gridComponentRowModel[] = [];

  @Input('orderId') set $inParams_orderId(value: any) {
    this.inParams['orderId'] = value;
  }
  get $inParams_orderId(): any {
    return this.inParams['orderId'] ;
  }

  topToolbar = {
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(null, null), true, false, false, 'Delete', 'ms-Icon ms-Icon--Delete', null)
    , false)
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: app_ShellService,
    private datasources: app_DatasourceService,
    private flows: app_FlowService,
    private reports: app_ReportService,
    private localization: app_LocalizationService,
    private operations: app_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Scanned barcodes';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.orderId)) {
        this.$missingRequiredInParams.push('orderId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 100;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      orderId:  $grid.inParams.orderId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    try {
      const data = await this.datasources.app.custom_ds_outbound_material_scanned_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as Iapp_custom_outbound_material_scanned_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new app_custom_outbound_material_scanned_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push(row.$initializeExisting(this, entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: app_custom_outbound_material_scanned_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_delete(event = null) {
    return this.on_deleteInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deleteInternal(
    $grid: app_custom_outbound_material_scanned_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  const hasSelectedRows = selectedRows.length > 0;
  
  if (hasSelectedRows) {
      const userConfirmed = await $shell.openConfirmationDialog($grid.title, 'Are you sure you want to delete the selected barcode scan(s)?', 'Delete');
      if (userConfirmed) {
          for (const row of selectedRows) {
              const Id = row.entity.Id;
              await $flows.Utilities.crud_delete_flow({  entitySet:'Tasks', id: Id});
          }
          await $grid.refresh();
      }
  }
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: app_custom_outbound_material_scanned_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $grid.topToolbar.on_delete.control.readOnly = true;
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: app_custom_outbound_material_scanned_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  const SelectedRowsCount = $grid.selectedRows.length;
  
  if (SelectedRowsCount >= 1) {
      $grid.topToolbar.on_delete.control.readOnly = false;
  }
  else {
      $grid.topToolbar.on_delete.control.readOnly = true;
  }
  
  }
  on_export_flow(event = null) {
    return this.on_export_flowInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_export_flowInternal(
    $grid: app_custom_outbound_material_scanned_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  // Initialize
  var wb = $event;
  var transactions = [];
  
  
  try {
  
  
      var response = (await $datasources.custom_ds_outbound_material_scanned_grid.get({
          orderId: $grid.inParams.orderId,
          fullTextSearch: $grid.fullTextSearch
      })).result;
  
  
  
  
      // Compile all data into JSON
      for (let record of response) {
  
  
          transactions.push({
  
              "Material": record.Material?.LookupCode,
              "Barcode": record.Notes
          });
      }
  
      var ws = $utils.excel.json_to_sheet(transactions);
      $utils.excel.book_append_sheet(wb, ws, "Barcodes");
  
  
  
  } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      throw new Error(errorMessage);
  }
  
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_export_flow(wb);

    writeExcelFile(wb, 'app_custom_outbound_material_scanned_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
