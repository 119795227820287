import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { app_custom_vendor_orders_gridComponent } from './app.custom_vendor_orders_grid.component';
import { Materials_materials_by_project_dd_multiComponent } from './Materials.materials_by_project_dd_multi.component'
import { PurchaseOrders_orderstatuses_dd_multiComponent } from './PurchaseOrders.orderstatuses_dd_multi.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => app_custom_vendor_orders_gridComponent),
    forwardRef(() => Materials_materials_by_project_dd_multiComponent),
    forwardRef(() => PurchaseOrders_orderstatuses_dd_multiComponent),
  ],
  selector: 'app-custom_vendor_orders_hub',
  templateUrl: './app.custom_vendor_orders_hub.component.html'
})
export class app_custom_vendor_orders_hubComponent extends BaseComponent implements OnInit, OnDestroy {


  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { currentDate?: string, calendar_viewDate?: string, calendar_dockDoorIds?: number[] } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
    materials: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
      create_vendor_order: new ToolModel(new ButtonModel('create_vendor_order', new ButtonStyles(null, null), false, false, false, 'New vendor order', 'icon-ic_fluent_add_20_regular', null)
    , false)
  };

  actionbar = {
  };

 filters = {
    materials: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['materials'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Materials', false, false),
    status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['status'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Status', false, false),
  };


  filtersets = {
  newGroup1: new FieldsetModel('Filters', false, true, false, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      inbound_orders: new TabItemModel(
        this.rootTabGroup, 
        'Orders', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    cacheValueFor_$tabs_inbound_orders_custom_vendor_orders_grid_inParams_statusIds: number[];
    get $tabs_inbound_orders_custom_vendor_orders_grid_inParams_statusIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.status.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_inbound_orders_custom_vendor_orders_grid_inParams_statusIds, expr)) {
        this.cacheValueFor_$tabs_inbound_orders_custom_vendor_orders_grid_inParams_statusIds = expr;
      }
      return this.cacheValueFor_$tabs_inbound_orders_custom_vendor_orders_grid_inParams_statusIds;
    }
  
    cacheValueFor_$tabs_inbound_orders_custom_vendor_orders_grid_inParams_materialIds: number[];
    get $tabs_inbound_orders_custom_vendor_orders_grid_inParams_materialIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.materials.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_inbound_orders_custom_vendor_orders_grid_inParams_materialIds, expr)) {
        this.cacheValueFor_$tabs_inbound_orders_custom_vendor_orders_grid_inParams_materialIds = expr;
      }
      return this.cacheValueFor_$tabs_inbound_orders_custom_vendor_orders_grid_inParams_materialIds;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_inbound_orders', { read: app_custom_vendor_orders_gridComponent }) $tabs_inbound_orders: app_custom_vendor_orders_gridComponent;
    //#endregion tabs children

  //#region filters inParams
  get $fields_materials_selector_inParams_projectId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 20;
    
    return expr;
  }



  cacheValueFor_$fields_status_selector_inParams_statusIds: number[];
  get $fields_status_selector_inParams_statusIds(): number[] {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = [1,4,8];
    
    if(!isEqual(this.cacheValueFor_$fields_status_selector_inParams_statusIds, expr)) {
      this.cacheValueFor_$fields_status_selector_inParams_statusIds = expr;
    }
    return this.cacheValueFor_$fields_status_selector_inParams_statusIds;
  }



  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Vendor orders hub`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Manage non inventory controlled vendor orders`;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: app_ShellService,
  private datasources: app_DatasourceService,
  private flows: app_FlowService,
  private reports: app_ReportService,
  private localization: app_LocalizationService,
  private operations: app_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.inbound_orders,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Vendor orders hub';
    
    const $hub = this;
    const $utils = this.utils;

    (this.filters.status.control as SelectBoxModel).reset([1]);

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_inbound_orders') {
      if (!isNil(this.$tabs_inbound_orders) && !this.tabs.inbound_orders.hidden) {
        this.$tabs_inbound_orders.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_create_vendor_order_clicked(event = null) {
    return this.on_create_vendor_order_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_vendor_order_clickedInternal(
    $hub: app_custom_vendor_orders_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  const dialogResult = (await $shell.opencustom_vendor_order_creation_form());
  
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: app_custom_vendor_orders_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $hub.vars.currentDate = $utils.date.now();
  
  
  
  
  }
  //#endregion private flows
}
