import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { Locations_locations_library_homeComponent } from './Locations.locations_library_home.component';
import { Locations_licenseplate_statuses_dd_singleComponent } from './Locations.licenseplate_statuses_dd_single.component';
import { Locations_location_statuses_dd_singleComponent } from './Locations.location_statuses_dd_single.component';
import { Locations_location_type_dd_singleComponent } from './Locations.location_type_dd_single.component';
import { Locations_locations_dd_singleComponent } from './Locations.locations_dd_single.component';
import { Locations_measurement_units_dd_singleComponent } from './Locations.measurement_units_dd_single.component';
import { Locations_mixing_rule_dd_singleComponent } from './Locations.mixing_rule_dd_single.component';
import { Locations_projects_dd_singleComponent } from './Locations.projects_dd_single.component';
import { Locations_storage_categories_dd_singleComponent } from './Locations.storage_categories_dd_single.component';
import { Locations_timezones_dd_singleComponent } from './Locations.timezones_dd_single.component';
import { Locations_warehouse_types_dd_singleComponent } from './Locations.warehouse_types_dd_single.component';
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component';
import { Locations_yn_dd_singleComponent } from './Locations.yn_dd_single.component';
import { Locations_zones_dd_singleComponent } from './Locations.zones_dd_single.component';
import { Locations_licenseplate_statuses_dd_multiComponent } from './Locations.licenseplate_statuses_dd_multi.component';
import { Locations_location_statuses_dd_multiComponent } from './Locations.location_statuses_dd_multi.component';
import { Locations_location_type_dd_multiComponent } from './Locations.location_type_dd_multi.component';
import { Locations_locations_dd_multiComponent } from './Locations.locations_dd_multi.component';
import { Locations_measurement_units_dd_multiComponent } from './Locations.measurement_units_dd_multi.component';
import { Locations_mixing_rule_dd_multiComponent } from './Locations.mixing_rule_dd_multi.component';
import { Locations_projects_dd_multiComponent } from './Locations.projects_dd_multi.component';
import { Locations_storage_categories_dd_multiComponent } from './Locations.storage_categories_dd_multi.component';
import { Locations_timezones_dd_multiComponent } from './Locations.timezones_dd_multi.component';
import { Locations_warehouse_types_dd_multiComponent } from './Locations.warehouse_types_dd_multi.component';
import { Locations_warehouses_dd_multiComponent } from './Locations.warehouses_dd_multi.component';
import { Locations_yn_dd_multiComponent } from './Locations.yn_dd_multi.component';
import { Locations_zones_dd_multiComponent } from './Locations.zones_dd_multi.component';
import { Locations_locations_by_count_widgetComponent } from './Locations.locations_by_count_widget.component';
import { Locations_locations_empty_by_count_widgetComponent } from './Locations.locations_empty_by_count_widget.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class Locations_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public Locations: Locations_ShellService = this;

  public openlocations_library_home(replaceCurrentView?: boolean) {
    this.logger.log('Locations', 'locations_library_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'Locations_locations_library_home',
        component: Locations_locations_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openlocations_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Locations', 'locations_library_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Locations_locations_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Locations_locations_library_home') {
      this.logger.log('Locations', 'locations_library_home');
      const title = 'Home';
      const component = Locations_locations_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_licenseplate_statuses_dd_single') {
      const title = 'Licenseplate Statuses Dropdown.';
      const component = Locations_licenseplate_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_location_statuses_dd_single') {
      const title = 'Location Statuses Dropdown.';
      const component = Locations_location_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_location_type_dd_single') {
      const title = 'Location Type Dropdown';
      const component = Locations_location_type_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_locations_dd_single') {
      const title = 'Locations dropdown';
      const component = Locations_locations_dd_singleComponent;
      const inParams:{ warehouseId: number, typeId: number, isLoose?: boolean, eligibleForAllocation?: boolean, parentId?: number } = { warehouseId: null, typeId: null, isLoose: null, eligibleForAllocation: null, parentId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('isLoose'))) {
        const paramValueString = params.get('isLoose');
        inParams.isLoose = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('eligibleForAllocation'))) {
        const paramValueString = params.get('eligibleForAllocation');
        inParams.eligibleForAllocation = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('parentId'))) {
        const paramValueString = params.get('parentId');
        inParams.parentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_measurement_units_dd_single') {
      const title = 'Measurement Units Dropdown';
      const component = Locations_measurement_units_dd_singleComponent;
      const inParams:{ typeId?: number } = { typeId: null };
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_mixing_rule_dd_single') {
      this.logger.log('Locations', 'mixing_rule_dd_single');
      const title = 'Mixing Rule Dropdown';
      const component = Locations_mixing_rule_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_projects_dd_single') {
      const title = 'Projects dropdown';
      const component = Locations_projects_dd_singleComponent;
      const inParams:{ statusId?: number, ownerId?: number } = { statusId: null, ownerId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_storage_categories_dd_single') {
      const title = 'storage_categories_dd';
      const component = Locations_storage_categories_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_timezones_dd_single') {
      const title = 'Time Zones Dropdown';
      const component = Locations_timezones_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_warehouse_types_dd_single') {
      const title = 'Warehouse Types Dropdown';
      const component = Locations_warehouse_types_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_warehouses_dd_single') {
      const title = 'Warehouses Dropdown';
      const component = Locations_warehouses_dd_singleComponent;
      const inParams:{ warehouseIds?: number[] } = { warehouseIds: [] };
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_yn_dd_single') {
      const title = 'Yes No Dropdown';
      const component = Locations_yn_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_zones_dd_single') {
      const title = 'Zones Dropdown';
      const component = Locations_zones_dd_singleComponent;
      const inParams:{ warehouseId: number } = { warehouseId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_licenseplate_statuses_dd_multi') {
      const title = 'Licenseplate Statuses Dropdown.';
      const component = Locations_licenseplate_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_location_statuses_dd_multi') {
      const title = 'Location Statuses Dropdown.';
      const component = Locations_location_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_location_type_dd_multi') {
      const title = 'Location Type Dropdown';
      const component = Locations_location_type_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_locations_dd_multi') {
      const title = 'Locations dropdown';
      const component = Locations_locations_dd_multiComponent;
      const inParams:{ warehouseId: number, typeId: number, isLoose?: boolean, eligibleForAllocation?: boolean, parentId?: number } = { warehouseId: null, typeId: null, isLoose: null, eligibleForAllocation: null, parentId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('isLoose'))) {
        const paramValueString = params.get('isLoose');
        inParams.isLoose = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('eligibleForAllocation'))) {
        const paramValueString = params.get('eligibleForAllocation');
        inParams.eligibleForAllocation = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('parentId'))) {
        const paramValueString = params.get('parentId');
        inParams.parentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_measurement_units_dd_multi') {
      const title = 'Measurement Units Dropdown';
      const component = Locations_measurement_units_dd_multiComponent;
      const inParams:{ typeId?: number } = { typeId: null };
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_mixing_rule_dd_multi') {
      this.logger.log('Locations', 'mixing_rule_dd_multi');
      const title = 'Mixing Rule Dropdown';
      const component = Locations_mixing_rule_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_projects_dd_multi') {
      const title = 'Projects dropdown';
      const component = Locations_projects_dd_multiComponent;
      const inParams:{ statusId?: number, ownerId?: number } = { statusId: null, ownerId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_storage_categories_dd_multi') {
      const title = 'storage_categories_dd';
      const component = Locations_storage_categories_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_timezones_dd_multi') {
      const title = 'Time Zones Dropdown';
      const component = Locations_timezones_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_warehouse_types_dd_multi') {
      const title = 'Warehouse Types Dropdown';
      const component = Locations_warehouse_types_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_warehouses_dd_multi') {
      const title = 'Warehouses Dropdown';
      const component = Locations_warehouses_dd_multiComponent;
      const inParams:{ warehouseIds?: number[] } = { warehouseIds: [] };
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_yn_dd_multi') {
      const title = 'Yes No Dropdown';
      const component = Locations_yn_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_zones_dd_multi') {
      const title = 'Zones Dropdown';
      const component = Locations_zones_dd_multiComponent;
      const inParams:{ warehouseId: number } = { warehouseId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_locations_by_count_widget') {
      const title = 'Total locations';
      const component = Locations_locations_by_count_widgetComponent;
      const inParams:{ warehouseId?: number } = { warehouseId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Locations_locations_empty_by_count_widget') {
      const title = 'Empty locations';
      const component = Locations_locations_empty_by_count_widgetComponent;
      const inParams:{ warehouseId?: number } = { warehouseId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
