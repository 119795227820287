import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootPrintManager_warehouse_transfer_outbound_order_lines_gridComponent } from './FootPrintManager.warehouse_transfer_outbound_order_lines_grid.component';
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'

type EntityType = { 
    id?: number, lookupcode?: string, ownerId?: number, projectId?: number, sourceWarehouseId?: number, targetWarehouseId?: number, outboundOrderId?: number, inboundOrderId?: number, outboundShipmentId?: number, inboundShipmentId?: number, statusId?: number, notes?: string, inboundOrderLookupcode?: string, outboundOrderLookupcode?: string, outboundOrderStatusId?: number, inboundOrderStatusId?: number, statusName?: string}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_warehouse_transfer_outbound_order_lines_gridComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Locations_warehouses_dd_singleComponent),
  ],
  selector: 'FootPrintManager-warehouse_transfer_editor',
  templateUrl: './FootPrintManager.warehouse_transfer_editor.component.html'
})
export class FootPrintManager_warehouse_transfer_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { warehouseTransferId: number } = { warehouseTransferId: null };
  //#region Inputs
  @Input('warehouseTransferId') set $inParams_warehouseTransferId(v: number) {
    this.inParams.warehouseTransferId = v;
  }
  get $inParams_warehouseTransferId(): number {
    return this.inParams.warehouseTransferId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { cachedSourceWarehouseId?: number, cachedTargetWarehouseId?: number } = { };
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    owner: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    source_warehouse: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    target_warehouse: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      process: new ToolModel(new ButtonModel('process', new ButtonStyles(null, null), false, false, false, 'Process', 'icon-ic_fluent_arrow_clockwise_20_regular', null)
    , false),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, false, false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    , false),
      delete_transfer: new ToolModel(new ButtonModel('delete_transfer', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      print: new ToolModel(new ButtonModel('print', new ButtonStyles(null, null), false, false, false, ' ', 'icon-ic_fluent_print_20_regular', null)
    , false),
      attachments: new ToolModel(new ButtonModel('attachments', new ButtonStyles(null, null), false, false, false, ' ', 'icon-ic_fluent_attach_20_regular', null)
    , false)
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), 'Transfer code', false, false),
    status: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Status', false, false),
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Owner', true, false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Project', true, false),
    source_warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['source_warehouse'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Source warehouse', true, false),
    target_warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['target_warehouse'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Target warehouse', true, false),
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Notes', false, false),
    outbound_order: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, false, false, 'N/A', 'ms-Icon ms-Icon--DecreaseIndentMirrored', null)
, new ControlContainerStyles(null, null), 'Outbound order', false, false),
    inbound_order: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, false, false, 'N/A', 'ms-Icon ms-Icon--DecreaseIndent', null)
, new ControlContainerStyles(null, null), 'Inbound order', false, false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Transfer information', false, false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      transfer_lines: new TabItemModel(
        this.rootTabGroup, 
        'Inventory to transfer', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_transfer_lines_warehouse_transfer_outbound_order_lines_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.outboundOrderId;
      
      return expr;
    }
  
    get $tabs_transfer_lines_warehouse_transfer_outbound_order_lines_grid_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.outboundShipmentId;
      
      return expr;
    }
  
    get $tabs_transfer_lines_warehouse_transfer_outbound_order_lines_grid_inParams_projectId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.projectId;
      
      return expr;
    }
  
    get $tabs_transfer_lines_warehouse_transfer_outbound_order_lines_grid_inParams_orderStatusId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.outboundOrderStatusId;
      
      return expr;
    }
  
    get $tabs_transfer_lines_warehouse_transfer_outbound_order_lines_grid_inParams_warehouseId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.sourceWarehouseId;
      
      return expr;
    }
  
    get $tabs_transfer_lines_warehouse_transfer_outbound_order_lines_grid_inParams_warehouseTransferId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.id;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_transfer_lines', { read: FootPrintManager_warehouse_transfer_outbound_order_lines_gridComponent }) $tabs_transfer_lines: FootPrintManager_warehouse_transfer_outbound_order_lines_gridComponent;
    //#endregion tabs children

  //#region fields inParams
  get $fields_owner_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.project.control.value;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.owner.control.value;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    source_warehouse: this.fields.source_warehouse.control.valueChanges
    ,
    target_warehouse: this.fields.target_warehouse.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.transfer_lines,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.warehouseTransferId)) {
        this.$missingRequiredInParams.push('warehouseTransferId');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'warehouse_transfer_editor';
    
    await this.on_init();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      warehouseTransferId:  $editor.inParams.warehouseTransferId 
    };

    const data = await this.datasources.WarehouseTransfers.ds_warehouse_transfer_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.lookupcode.control as TextBoxModel).reset($editor.entity.lookupcode);
    (this.fields.owner.control as SelectBoxModel).reset($editor.entity.ownerId);
    (this.fields.project.control as SelectBoxModel).reset($editor.entity.projectId);
    (this.fields.source_warehouse.control as SelectBoxModel).reset($editor.entity.sourceWarehouseId);
    (this.fields.target_warehouse.control as SelectBoxModel).reset($editor.entity.targetWarehouseId);
    (this.fields.notes.control as TextBoxModel).reset($editor.entity.notes);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_transfer_lines') {
        if (!isNil(this.$tabs_transfer_lines) && !this.tabs.transfer_lines.hidden && !this.tabs.transfer_lines.removed) {
          this.$tabs_transfer_lines.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_changed();
      });
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_changed();
      });
    this.$formGroupFieldValidationObservables
      .source_warehouse
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_source_warehouse_changed();
      });
    this.$formGroupFieldValidationObservables
      .target_warehouse
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_target_warehouse_changed();
      });
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_notes_changed();
      });
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_warehouse_transfer_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'warehouse_transfer_editor.on_init');
  
  }
  on_project_changed(event = null) {
    return this.on_project_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changedInternal(
    $editor: FootPrintManager_warehouse_transfer_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'warehouse_transfer_editor.on_project_changed');
  // If project has value
  if ($utils.isDefined($editor.fields.project.control.value)) {
      if (!$utils.isDefined($editor.fields.owner.control.value)) {
          let project = (await $datasources.Owners.ds_get_project_by_projectId.get({ projectId: $editor.fields.project.control.value })).result[0];
          $editor.fields.owner.control.value = project.OwnerId;
      }
  
      // Update warehouse transfer
      await $flows.Utilities.crud_update_flow({
          entitySet: 'WarehouseTransfers',
          id: $editor.entity.id,
          entity: {
              ProjectId: $editor.fields.project.control.value
          }
      });
  
      // Update outbound order
      await $flows.Utilities.crud_update_flow({
          entitySet: 'Orders',
          id: $editor.entity.outboundOrderId,
          entity: {
              ProjectId: $editor.fields.project.control.value
          }
      });
  
      // Update inbound order
      await $flows.Utilities.crud_update_flow({
          entitySet: 'Orders',
          id: $editor.entity.inboundOrderId,
          entity: {
              ProjectId: $editor.fields.project.control.value
          }
      });
  }
  
  
  
  }
  on_owner_changed(event = null) {
    return this.on_owner_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changedInternal(
    $editor: FootPrintManager_warehouse_transfer_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'warehouse_transfer_editor.on_owner_changed');
  $editor.fields.project.control.value = null;
  }
  on_source_warehouse_changed(event = null) {
    return this.on_source_warehouse_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_source_warehouse_changedInternal(
    $editor: FootPrintManager_warehouse_transfer_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'warehouse_transfer_editor.on_source_warehouse_changed');
  $editor.toolbar.delete_transfer.control.readOnly = false;
  $editor.toolbar.process.control.readOnly = false;
  
  
  if ($utils.isDefined($editor.fields.source_warehouse.control.value)) {
      let payload: any = {
          SourceWarehouseId: $editor.fields.source_warehouse.control.value
      };
  
      if ($editor.fields.source_warehouse.control.value === ($editor.fields.target_warehouse.control.value ?? $editor.vars.cachedTargetWarehouseId)) {
          $editor.fields.target_warehouse.control.value = $editor.vars.cachedSourceWarehouseId;
  
          payload.TargetWarehouseId = $editor.fields.target_warehouse.control.value;
  
          if ($utils.isDefined($editor.entity?.id)) {
              // Update inbound shipment
              await $flows.Utilities.crud_update_flow({
                  entitySet: 'Shipments',
                  id: $editor.entity.inboundShipmentId,
                  entity: {
                      ExpectedWarehouseId: $editor.fields.target_warehouse.control.value
                  }
              });
          }
  
          $editor.vars.cachedTargetWarehouseId = $editor.fields.target_warehouse.control.value;
      }
  
      if ($utils.isDefined($editor.entity?.id)) {
          // Update warehouse transfer
          await $flows.Utilities.crud_update_flow({
              entitySet: 'WarehouseTransfers',
              id: $editor.entity.id,
              entity: payload
          });
  
          // Update outbound shipment
          await $flows.Utilities.crud_update_flow({
              entitySet: 'Shipments',
              id: $editor.entity.outboundShipmentId,
              entity: {
                  ExpectedWarehouseId: $editor.fields.source_warehouse.control.value
              }
          });
      }
  
      $editor.vars.cachedSourceWarehouseId = $editor.fields.source_warehouse.control.value;
  }
  else {
      // Lock down fields
      $editor.toolbar.delete_transfer.control.readOnly = true;
      $editor.toolbar.process.control.readOnly = true;
  
  }
  }
  on_target_warehouse_changed(event = null) {
    return this.on_target_warehouse_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_target_warehouse_changedInternal(
    $editor: FootPrintManager_warehouse_transfer_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'warehouse_transfer_editor.on_target_warehouse_changed');
  $editor.toolbar.delete_transfer.control.readOnly = false;
  $editor.toolbar.process.control.readOnly = false;
  
  
  if ($utils.isDefined($editor.fields.target_warehouse.control.value)) {
      let payload: any = {
          TargetWarehouseId: $editor.fields.target_warehouse.control.value
      };
  
      if (($editor.fields.source_warehouse.control.value ?? $editor.vars.cachedSourceWarehouseId) === $editor.fields.target_warehouse.control.value) {
          $editor.fields.source_warehouse.control.value = $editor.vars.cachedTargetWarehouseId;
  
          payload.SourceWarehouseId = $editor.fields.source_warehouse.control.value;
  
          if ($utils.isDefined($editor.entity?.id)) {
              // Update outbound shipment
              await $flows.Utilities.crud_update_flow({
                  entitySet: 'Shipments',
                  id: $editor.entity.outboundShipmentId,
                  entity: {
                      ExpectedWarehouseId: $editor.fields.source_warehouse.control.value
                  }
              });
          }
  
          $editor.vars.cachedSourceWarehouseId = $editor.fields.source_warehouse.control.value;
      }
  
      if ($utils.isDefined($editor.entity?.id)) {
          // Update warehouse transfer
          await $flows.Utilities.crud_update_flow({
              entitySet: 'WarehouseTransfers',
              id: $editor.entity.id,
              entity: payload
          });
  
          // Update inbound shipment
          await $flows.Utilities.crud_update_flow({
              entitySet: 'Shipments',
              id: $editor.entity.inboundShipmentId,
              entity: {
                  ExpectedWarehouseId: $editor.fields.target_warehouse.control.value
              }
          });
      }
  
      $editor.vars.cachedTargetWarehouseId = $editor.fields.target_warehouse.control.value;
  }
  else {
      // Lock down fields
      $editor.toolbar.delete_transfer.control.readOnly = true;
      $editor.toolbar.process.control.readOnly = true;
  
  }
  }
  on_notes_changed(event = null) {
    return this.on_notes_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_notes_changedInternal(
    $editor: FootPrintManager_warehouse_transfer_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'warehouse_transfer_editor.on_notes_changed');
  if ($utils.isDefined($editor.entity?.id)) {
      await $flows.Utilities.crud_update_flow({
          entitySet: 'WarehouseTransfers',
          id: $editor.entity.id,
          entity: {
              Notes: $editor.fields.notes.control.value
          }
      });
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_warehouse_transfer_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'warehouse_transfer_editor.on_data_loaded');
  if ($utils.isDefined($editor.inParams.warehouseTransferId) && $editor.inParams.warehouseTransferId > 0 && !$utils.isDefined($editor.entity?.id)) {
      await $shell.FootPrintManager.openErrorDialog('Error opening warehouse transfer', `Warehouse transfer with ID '${$editor.inParams.warehouseTransferId}' does not exist.`);
      $editor.close();
  }
  
  $editor.toolbar.process.control.icon = 'icon-ic_fluent_arrow_clockwise_dashes_20_regular';
  $editor.toolbar.process.control.readOnly = false;
  $editor.toolbar.delete_transfer.control.readOnly = false;
  $editor.toolbar.cancel.control.readOnly = false;
  
  $editor.vars.cachedSourceWarehouseId = $editor.fields.source_warehouse.control.value;
  $editor.vars.cachedTargetWarehouseId = $editor.fields.target_warehouse.control.value;
  
  setFieldAccessibility();
  
  $editor.fields.lookupcode.hidden = false;
  $editor.fields.status.hidden = false;
  $editor.fields.outbound_order.hidden = false;
  $editor.fields.inbound_order.hidden = false;
  
  $editor.fields.outbound_order.control.label = $editor.entity.outboundOrderLookupcode;
  $editor.fields.inbound_order.control.label = $editor.entity.inboundOrderLookupcode;
  
  $editor.title = `Warehouse Transfer: ${$editor.entity.lookupcode}`;
  
  // Set button visibility
  $editor.toolbar.process.hidden = false;
  $editor.toolbar.delete_transfer.hidden = !(await $flows.WarehouseTransfers.is_warehouse_transfer_deletable({
      warehouseTransferId: $editor.entity.id,
      optimalInputs: {
          statusId: $editor.entity.statusId
      }
  })).isValid;
  
  // Get status
  let statusDetails = (await $flows.WarehouseTransfers.get_warehouse_transfer_status_flow({
      warehouse_transfer_id: $editor.entity.id
  }));
  
  $editor.fields.status.control.text = statusDetails.state_description;
  $editor.toolbar.cancel.control.readOnly = !statusDetails.eligibilities.is_transfer_cancellable;
  
  // Set process button
  switch (statusDetails.state) {
      case 1: { // Created 
          $editor.toolbar.process.control.label = 'Release outbound order'
  
          if (!statusDetails.eligibilities.is_outbound_processable) { $editor.toolbar.process.hidden = true; }
          break;
      }
      case 2: { // Outbound processed 
          $editor.toolbar.process.control.label = 'Release outbound order'
  
          if (!statusDetails.eligibilities.is_outbound_processable) { $editor.toolbar.process.hidden = true; }
          break;
      }
      case 3: { // Outbound wave created 
          $editor.toolbar.process.control.label = 'Release outbound order'
  
          if (!statusDetails.eligibilities.is_outbound_processable) { $editor.toolbar.process.hidden = true; }
          break;
      }
      case 4: { // Outbound wave processed 
          $editor.toolbar.process.control.label = 'Release outbound order'
  
          if (!statusDetails.eligibilities.is_outbound_processable) { $editor.toolbar.process.hidden = true; }
          break;
      }
      case 5: { // Outbound ready to pick 
          $editor.toolbar.process.control.label = 'Auto-pick outbound order'
  
          if (!statusDetails.eligibilities.is_outbound_pickable) { $editor.toolbar.process.hidden = true; }
          break;
      }
      case 6: { // Outbound picking in progress 
          $editor.toolbar.process.control.label = 'Auto-pick outbound order'
  
          if (!statusDetails.eligibilities.is_outbound_pickable) { $editor.toolbar.process.hidden = true; }
          break;
      }
      case 7: { // Outbound ready to load 
          $editor.toolbar.process.control.label = 'Auto-load outbound order'
  
          if (!statusDetails.eligibilities.is_outbound_loadable) { $editor.toolbar.process.hidden = true; }
          break;
      }
      case 8: { // Outbound loading in progress 
          $editor.toolbar.process.control.label = 'Auto-load outbound order'
  
          if (!statusDetails.eligibilities.is_outbound_loadable) { $editor.toolbar.process.hidden = true; }
          break;
      }
      case 9: { // Outbound ready to complete 
          $editor.toolbar.process.control.label = 'Complete outbound order'
  
          if (!statusDetails.eligibilities.is_outbound_completable) { $editor.toolbar.process.hidden = true; }
          break;
      }
      case 10: { // Waiting for inbound 
          $editor.toolbar.process.control.label = 'Release inbound order'
  
          if (!statusDetails.eligibilities.is_inbound_processable) { $editor.toolbar.process.hidden = true; }
          break;
      }
      case 11: { // Inbound ready to receive 
          $editor.toolbar.process.control.label = 'Auto-receive inbound order'
  
          if (!statusDetails.eligibilities.is_inbound_receivable) { $editor.toolbar.process.hidden = true; }
          break;
      }
      case 12: { // Inbound receiving in progress 
          $editor.toolbar.process.control.label = 'Auto-receive inbound order'
  
          if (!statusDetails.eligibilities.is_inbound_receivable) { $editor.toolbar.process.hidden = true; }
          break;
      }
      case 13: { // Ready to complete 
          $editor.toolbar.process.control.label = 'Complete warehouse transfer'
  
          if (!statusDetails.eligibilities.is_inbound_completable) { $editor.toolbar.process.hidden = true; }
          break;
      }
      case 14: { // Completed'
          $editor.toolbar.process.hidden = true;
          $editor.toolbar.cancel.hidden = true;
          break;
      }
      default: {
          $editor.toolbar.process.hidden = true;
          $editor.toolbar.cancel.hidden = true;
          break;
      }
  }
  
  
  /******************************************************
   * FUNCTIONS
  *******************************************************/
  function setFieldAccessibility() {
      switch ($editor.entity.statusId) {
          case 1: { // Created
              $editor.fields.owner.control.readOnly = false;
              $editor.fields.project.control.readOnly = false;
              $editor.fields.source_warehouse.control.readOnly = false;
              $editor.fields.target_warehouse.control.readOnly = false;
              $editor.fields.notes.control.readOnly = false;
              $editor.fields.outbound_order.control.readOnly = false;
              $editor.fields.inbound_order.control.readOnly = false;
              break;
          }
          case 2: { // Outbound released
              $editor.fields.owner.control.readOnly = true;
              $editor.fields.project.control.readOnly = true;
              $editor.fields.source_warehouse.control.readOnly = true;
              $editor.fields.target_warehouse.control.readOnly = false;
              $editor.fields.notes.control.readOnly = false;
              $editor.fields.outbound_order.control.readOnly = false;
              $editor.fields.inbound_order.control.readOnly = false;
              break;
          }
          case 3: { // Completed
              $editor.fields.owner.control.readOnly = true;
              $editor.fields.project.control.readOnly = true;
              $editor.fields.source_warehouse.control.readOnly = true;
              $editor.fields.target_warehouse.control.readOnly = true;
              $editor.fields.notes.control.readOnly = true;
              $editor.fields.outbound_order.control.readOnly = false;
              $editor.fields.inbound_order.control.readOnly = false;
              break;
          }
          case 4: { // Cancelled
              $editor.fields.owner.control.readOnly = true;
              $editor.fields.project.control.readOnly = true;
              $editor.fields.source_warehouse.control.readOnly = true;
              $editor.fields.target_warehouse.control.readOnly = true;
              $editor.fields.notes.control.readOnly = true;
              $editor.fields.outbound_order.control.readOnly = false;
              $editor.fields.inbound_order.control.readOnly = false;
              break;
          }
          case 5: { // Inbound released
              $editor.fields.owner.control.readOnly = true;
              $editor.fields.project.control.readOnly = true;
              $editor.fields.source_warehouse.control.readOnly = true;
              $editor.fields.target_warehouse.control.readOnly = true;
              $editor.fields.notes.control.readOnly = false;
              $editor.fields.outbound_order.control.readOnly = false;
              $editor.fields.inbound_order.control.readOnly = false;
              break;
          }
          case 6: { // Partially completed
              $editor.fields.owner.control.readOnly = true;
              $editor.fields.project.control.readOnly = true;
              $editor.fields.source_warehouse.control.readOnly = true;
              $editor.fields.target_warehouse.control.readOnly = true;
              $editor.fields.notes.control.readOnly = false;
              $editor.fields.outbound_order.control.readOnly = false;
              $editor.fields.inbound_order.control.readOnly = false;
              break;
          }
          case 7: { // Outbound completed
              $editor.fields.owner.control.readOnly = true;
              $editor.fields.project.control.readOnly = true;
              $editor.fields.source_warehouse.control.readOnly = true;
              $editor.fields.target_warehouse.control.readOnly = true;
              $editor.fields.notes.control.readOnly = false;
              $editor.fields.outbound_order.control.readOnly = false;
              $editor.fields.inbound_order.control.readOnly = false;
              break;
          }
          default: {
              $editor.fields.owner.control.readOnly = true;
              $editor.fields.project.control.readOnly = true;
              $editor.fields.source_warehouse.control.readOnly = true;
              $editor.fields.target_warehouse.control.readOnly = true;
              $editor.fields.notes.control.readOnly = true;
              break;
          }
      }
  }
  }
  on_delete_transfer_clicked(event = null) {
    return this.on_delete_transfer_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_transfer_clickedInternal(
    $editor: FootPrintManager_warehouse_transfer_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'warehouse_transfer_editor.on_delete_transfer_clicked');
  $editor.toolbar.delete_transfer.control.readOnly = true;
  $editor.toolbar.process.control.readOnly = true;
  
  if (await $shell.FootPrintManager.openConfirmationDialog('Delete warehouse transfer', `Are you sure you want to delete warehouse transfer ${$editor.entity.lookupcode}?`, 'Confirm', 'Cancel')) {
      try {
          let result = (await $flows.WarehouseTransfers.delete_warehouse_transfer_flow({ warehouseTransferId: $editor.entity.id }));
  
          if ($utils.isDefined(result.reasons)) {
              throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
          }
          else {
              $shell.FootPrintManager.openInfoDialog('Successfully deleted warehouse transfer', `Warehouse transfer ${$editor.entity.lookupcode} has been deleted.`);
              $editor.close();
          }
      }
      catch (error) {
          let targetError = error;
  
          while ($utils.isDefined(targetError.error)) {
              targetError = targetError.error;
          }
  
          await $shell.FootPrintManager.openErrorDialog('Error deleting warehouse transfer', targetError.message);
      }
  }
  
  $editor.toolbar.delete_transfer.control.readOnly = false;
  $editor.toolbar.process.control.readOnly = false;
  }
  on_outbound_order_clicked(event = null) {
    return this.on_outbound_order_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_outbound_order_clickedInternal(
    $editor: FootPrintManager_warehouse_transfer_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'warehouse_transfer_editor.on_outbound_order_clicked');
  await $shell.FootPrintManager.openwarehouse_transfer_outbound_order_editor({ orderId: $editor.entity.outboundOrderId, warehouseTransferId: $editor.entity.id });
  }
  on_inbound_order_clicked(event = null) {
    return this.on_inbound_order_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_inbound_order_clickedInternal(
    $editor: FootPrintManager_warehouse_transfer_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'warehouse_transfer_editor.on_inbound_order_clicked');
  $shell.FootPrintManager.openwarehouse_transfer_inbound_order_editor({ orderId: $editor.entity.inboundOrderId, warehouseTransferId: $editor.entity.id });
  }
  on_process_clicked(event = null) {
    return this.on_process_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_process_clickedInternal(
    $editor: FootPrintManager_warehouse_transfer_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'warehouse_transfer_editor.on_process_clicked');
  $editor.toolbar.delete_transfer.control.readOnly = true;
  $editor.toolbar.process.control.readOnly = true;
  
  $editor.fields.inbound_order.control.readOnly = true;
  $editor.fields.lookupcode.control.readOnly = true;
  $editor.fields.notes.control.readOnly = true;
  $editor.fields.outbound_order.control.readOnly = true;
  $editor.fields.owner.control.readOnly = true;
  $editor.fields.project.control.readOnly = true;
  $editor.fields.source_warehouse.control.readOnly = true;
  $editor.fields.target_warehouse.control.readOnly = true;
  
  try {
      // Get status
      let statusDetails = (await $flows.WarehouseTransfers.get_warehouse_transfer_status_flow({
          warehouse_transfer_id: $editor.entity.id
      }));
  
      let endStatus =
          statusDetails.state < 5 ? 5 :
              statusDetails.state < 7 ? 7 :
                  statusDetails.state < 9 ? 9 :
                      statusDetails.state < 10 ? 10 :
                          statusDetails.state < 11 ? 11 :
                              statusDetails.state < 13 ? 13 :
                                  14;
  
      await processTransfer(statusDetails.state, endStatus);
  
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog('Error processing transfer', targetError.message);
  }
  
  
  await $editor.refresh();
  
  
  
  /*******************************************************
   * FUNCTIONS
  ********************************************************/
  async function processTransfer(currentStatus: number, endStatus: number) {
      let isConfirmed: boolean;
  
      while (currentStatus < endStatus) {
          switch (currentStatus) {
              case 1: { // Created 
                  isConfirmed = await getConfirmation(isConfirmed);
                  if (isConfirmed) {
                      $editor.toolbar.process.control.label = 'Processing outbound order...';
                      $editor.toolbar.process.control.icon = 'icon datex-default-spinner';
  
                      let result = (await $flows.WarehouseTransfers.process_source_outbound_order_flow({ order_id: $editor.entity.outboundOrderId }));
  
                      if ($utils.isDefined(result.reasons)) {
                          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
                      }
                      else {
                          currentStatus = 2
                      }
                  }
                  else {
                      endStatus = 0;
                  }
  
                  break;
              }
              case 2: { // Outbound processed 
  
  
                  isConfirmed = await getConfirmation(isConfirmed);
                  if (isConfirmed) {
                      $editor.toolbar.process.control.label = 'Creating wave for outbound order...';
                      $editor.toolbar.process.control.icon = 'icon datex-default-spinner';
  
                      let result = (await $flows.WarehouseTransfers.create_wave_source_outbound_order_flow({ order_id: $editor.entity.outboundOrderId }));
  
                      if ($utils.isDefined(result.reasons)) {
                          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
                      }
                      else {
                          currentStatus = 3
                      }
                  }
                  else {
                      endStatus = 0;
                  }
  
                  break;
              }
              case 3: { // Outbound wave created 
  
  
                  isConfirmed = await getConfirmation(isConfirmed);
                  if (isConfirmed) {
                      $editor.toolbar.process.control.label = 'Processing wave for outbound order...';
                      $editor.toolbar.process.control.icon = 'icon datex-default-spinner';
  
                      let result = (await $flows.WarehouseTransfers.process_wave_source_outbound_order_flow({ order_id: $editor.entity.outboundOrderId }));
  
                      if ($utils.isDefined(result.reasons)) {
                          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
                      }
                      else {
                          currentStatus = 4
                      }
                  }
                  else {
                      endStatus = 0;
                  }
  
                  break;
              }
              case 4: { // Outbound wave processed 
  
                  isConfirmed = await getConfirmation(isConfirmed);
                  if (isConfirmed) {
                      $editor.toolbar.process.control.label = 'Releasing wave for outbound order...';
                      $editor.toolbar.process.control.icon = 'icon datex-default-spinner';
  
                      let result = (await $flows.WarehouseTransfers.release_wave_source_outbound_order_flow({ order_id: $editor.entity.outboundOrderId }));
  
                      if ($utils.isDefined(result.reasons)) {
                          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
                      }
                      else {
                          currentStatus = 5
                      }
                  }
                  else {
                      endStatus = 0;
                  }
  
                  break;
              }
              case 5: { // Outbound ready to pick 
  
                  let options = (await $shell.FootPrintManager.openauto_pick_options_formDialog({ warehouse_id: $editor.entity.sourceWarehouseId }));
  
                  if (options?.confirm) {
                      $editor.toolbar.process.control.label = 'Auto-picking outbound order...';
                      $editor.toolbar.process.control.icon = 'icon datex-default-spinner';
  
                      let autoPickResult = (await $flows.WarehouseTransfers.auto_pick_source_outbound_order_flow({ order_id: $editor.entity.outboundOrderId }));
  
                      if ($utils.isDefined(autoPickResult.reasons)) {
                          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: autoPickResult.reasons })).formattedValue);
                      }
                      else {
                          currentStatus = 7
                      }
  
                      // Autoload
                      if (options?.auto_load) {
                      $editor.toolbar.process.control.label = 'Auto-loading outbound order...';
                      $editor.toolbar.process.control.icon = 'icon datex-default-spinner';
  
                      let autoLoadResult = (await $flows.WarehouseTransfers.auto_load_source_outbound_order_flow({ order_id: $editor.entity.outboundOrderId }));
  
                      if ($utils.isDefined(autoLoadResult.reasons)) {
                          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: autoLoadResult.reasons })).formattedValue);
                      }
                      else {
                          currentStatus = 9
                      }
                  }
  
                  }
                  else {
                      endStatus = 0;
                  }
  
                  break;
              }
              case 6: { // Outbound picking in progress 
  
                  isConfirmed = await getConfirmation(isConfirmed);
                  if (isConfirmed) {
                      $editor.toolbar.process.control.label = 'Auto-picking outbound order...';
                      $editor.toolbar.process.control.icon = 'icon datex-default-spinner';
  
                      let result = (await $flows.WarehouseTransfers.auto_pick_source_outbound_order_flow({ order_id: $editor.entity.outboundOrderId }));
  
                      if ($utils.isDefined(result.reasons)) {
                          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
                      }
                      else {
                          currentStatus = 7
                      }
                  }
                  else {
                      endStatus = 0;
                  }
  
                  break;
              }
              case 7: { // Outbound ready to load 
  
                  isConfirmed = await getConfirmation(isConfirmed);
                  if (isConfirmed) {
                      $editor.toolbar.process.control.label = 'Auto-loading outbound order...';
                      $editor.toolbar.process.control.icon = 'icon datex-default-spinner';
  
                      let result = (await $flows.WarehouseTransfers.auto_load_source_outbound_order_flow({ order_id: $editor.entity.outboundOrderId }));
  
                      if ($utils.isDefined(result.reasons)) {
                          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
                      }
                      else {
                          currentStatus = 9
                      }
                  }
                  else {
                      endStatus = 0;
                  }
  
                  break;
              }
              case 8: { // Outbound loading in progress 
  
                  isConfirmed = await getConfirmation(isConfirmed);
                  if (isConfirmed) {
                      $editor.toolbar.process.control.label = 'Auto-loading outbound order...';
                      $editor.toolbar.process.control.icon = 'icon datex-default-spinner';
  
                      let result = (await $flows.WarehouseTransfers.auto_load_source_outbound_order_flow({ order_id: $editor.entity.outboundOrderId }));
  
                      if ($utils.isDefined(result.reasons)) {
                          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
                      }
                      else {
                          currentStatus = 9
                      }
                  }
                  else {
                      endStatus = 0;
                  }
  
                  break;
              }
              case 9: { // Outbound ready to complete 
  
                  isConfirmed = await getConfirmation(isConfirmed);
                  if (isConfirmed) {
                      $editor.toolbar.process.control.label = 'Completing outbound order...';
                      $editor.toolbar.process.control.icon = 'icon datex-default-spinner';
  
                      let result = (await $flows.WarehouseTransfers.complete_source_outbound_order_flow({ order_id: $editor.entity.outboundOrderId }));
  
                      if ($utils.isDefined(result.reasons)) {
                          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
                      }
                      else {
                          currentStatus = 10
                      }
                  }
                  else {
                      endStatus = 0;
                  }
  
                  break;
              }
              case 10: { // Waiting for inbound 
  
                  isConfirmed = await getConfirmation(isConfirmed);
                  if (isConfirmed) {
                      $editor.toolbar.process.control.label = 'Processing inbound order...';
                      $editor.toolbar.process.control.icon = 'icon datex-default-spinner';
  
                      let result = (await $flows.WarehouseTransfers.process_target_inbound_order_flow({ order_id: $editor.entity.inboundOrderId }));
  
                      if ($utils.isDefined(result.reasons)) {
                          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
                      }
                      else {
                          currentStatus = 11
                      }
                  }
                  else {
                      endStatus = 0;
                  }
  
                  break;
              }
              case 11: { // Inbound ready to receive 
  
                  isConfirmed = await getConfirmation(isConfirmed);
                  if (isConfirmed) {
                      $editor.toolbar.process.control.label = 'Auto-receiving inbound order...';
                      $editor.toolbar.process.control.icon = 'icon datex-default-spinner';
  
                      let result = (await $flows.WarehouseTransfers.auto_receive_target_inbound_order_flow({ order_id: $editor.entity.inboundOrderId }));
  
                      if ($utils.isDefined(result.reasons)) {
                          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
                      }
                      else {
                          currentStatus = 13
                      }
                  }
                  else {
                      endStatus = 0;
                  }
  
                  break;
              }
              case 12: { // Inbound receiving in progress 
  
                  isConfirmed = await getConfirmation(isConfirmed);
                  if (isConfirmed) {
                      $editor.toolbar.process.control.label = 'Auto-receiving inbound order...';
                      $editor.toolbar.process.control.icon = 'icon datex-default-spinner';
  
                      let result = (await $flows.WarehouseTransfers.auto_receive_target_inbound_order_flow({ order_id: $editor.entity.inboundOrderId }));
  
                      if ($utils.isDefined(result.reasons)) {
                          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
                      }
                      else {
                          currentStatus = 13
                      }
                  }
                  else {
                      endStatus = 0;
                  }
  
                  break;
              }
              case 13: { // Ready to complete 
  
                  isConfirmed = await getConfirmation(isConfirmed);
                  if (isConfirmed) {
                      $editor.toolbar.process.control.label = 'Completing inbound order...';
                      $editor.toolbar.process.control.icon = 'icon datex-default-spinner';
  
                      let result = (await $flows.WarehouseTransfers.complete_target_inbound_order_flow({ order_id: $editor.entity.inboundOrderId }));
  
                      if ($utils.isDefined(result.reasons)) {
                          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
                      }
                      else {
                          currentStatus = 14
                      }
                  }
                  else {
                      endStatus = 0;
                  }
  
                  break;
              }
              case 14: { // Completed'
  
                  break;
              }
          }
      }
  }
  
  async function getConfirmation(isAlreadyConfirmed: boolean) {
      let isConfirmed = isAlreadyConfirmed;
  
      if (!$utils.isDefined(isConfirmed)) {
          isConfirmed = (await $shell.FootPrintManager.openConfirmationDialog(`Confirm ${$editor.toolbar.process.control.label.toLowerCase()}`, `Are you sure you want to ${$editor.toolbar.process.control.label.toLowerCase()}?`));
      }
  
      return isConfirmed;
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: FootPrintManager_warehouse_transfer_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'warehouse_transfer_editor.on_cancel_clicked');
  $editor.toolbar.delete_transfer.control.readOnly = true;
  $editor.toolbar.process.control.readOnly = true;
  $editor.toolbar.cancel.control.readOnly = true;
  
  if (await $shell.FootPrintManager.openConfirmationDialog('Cancel warehouse transfer', `Are you sure you want to cancel warehouse transfer ${$editor.entity.lookupcode}?`, 'Confirm', 'Cancel')) {
      try {
          let result = (await $flows.WarehouseTransfers.cancel_warehouse_transfer_flow({ warehouseTransferId: $editor.entity.id }));
  
          if ($utils.isDefined(result.reasons)) {
              throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
          }
          else {
              $shell.FootPrintManager.openInfoDialog('Successfully cancelled warehouse transfer', `Warehouse transfer ${$editor.entity.lookupcode} has been cancelled.`);
              await $editor.refresh();
          }
      }
      catch (error) {
          let targetError = error;
  
          while ($utils.isDefined(targetError.error)) {
              targetError = targetError.error;
          }
  
          await $shell.FootPrintManager.openErrorDialog('Error deleting warehouse transfer', targetError.message);
      }
  }
  
  $editor.toolbar.delete_transfer.control.readOnly = false;
  $editor.toolbar.process.control.readOnly = false;
  $editor.toolbar.cancel.control.readOnly = false;
  }
  on_print_clicked(event = null) {
    return this.on_print_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_clickedInternal(
    $editor: FootPrintManager_warehouse_transfer_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'warehouse_transfer_editor.on_print_clicked');
  await $shell.FootPrintManager.openInfoDialog('Not yet implemented', 'The print functionality for this screen has not yet been implemented.');
  }
  on_attachments_clicked(event = null) {
    return this.on_attachments_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_attachments_clickedInternal(
    $editor: FootPrintManager_warehouse_transfer_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'warehouse_transfer_editor.on_attachments_clicked');
  await $shell.FootPrintManager.openentity_attachments_gridDialog({ entityType: 'WarehouseTransfer', entityKeys: [{ name: 'Id', value: $editor.entity.id }]});
  }
  //#endregion private flows
  
}
