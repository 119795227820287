import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { SalesOrders_outbound_processing_strategies_dd_singleComponent } from './SalesOrders.outbound_processing_strategies_dd_single.component'
import { SalesOrders_outbound_processing_strategies_hardcoded_dd_singleComponent } from './SalesOrders.outbound_processing_strategies_hardcoded_dd_single.component'
import { Cartonization_cartonization_strategies_dd_singleComponent } from './Cartonization.cartonization_strategies_dd_single.component'
import { SalesOrders_wave_priorities_dd_singleComponent } from './SalesOrders.wave_priorities_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => SalesOrders_outbound_processing_strategies_dd_singleComponent),
    forwardRef(() => SalesOrders_outbound_processing_strategies_hardcoded_dd_singleComponent),
    forwardRef(() => Cartonization_cartonization_strategies_dd_singleComponent),
    forwardRef(() => SalesOrders_wave_priorities_dd_singleComponent),
  ],
  selector: 'FootPrintManager-sales_orders_processing_options_form',
  templateUrl: './FootPrintManager.sales_orders_processing_options_form.component.html'
})
export class FootPrintManager_sales_orders_processing_options_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { order_ids: number[], default_waving: boolean, expand_order_line_kits?: boolean, is_wave_created?: boolean } = { order_ids: [], default_waving: null, expand_order_line_kits: null, is_wave_created: null };
  //#region Inputs
  @Input('order_ids') set $inParams_order_ids(v: number[]) {
    this.inParams.order_ids = v;
  }
  get $inParams_order_ids(): number[] {
    return this.inParams.order_ids;
  }
  @Input('default_waving') set $inParams_default_waving(v: boolean) {
    this.inParams.default_waving = v;
  }
  get $inParams_default_waving(): boolean {
    return this.inParams.default_waving;
  }
  @Input('expand_order_line_kits') set $inParams_expand_order_line_kits(v: boolean) {
    this.inParams.expand_order_line_kits = v;
  }
  get $inParams_expand_order_line_kits(): boolean {
    return this.inParams.expand_order_line_kits;
  }
  @Input('is_wave_created') set $inParams_is_wave_created(v: boolean) {
    this.inParams.is_wave_created = v;
  }
  get $inParams_is_wave_created(): boolean {
    return this.inParams.is_wave_created;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { confirm?: boolean, waveOptions?: { isCreateWave?: boolean, isProcessWave?: boolean, isReleaseWave?: boolean, priority?: number, description?: string, abortIfErrors?: boolean, groupOnWave?: boolean, isCreateManualAllocationTasks?: boolean }, processingStrategyWorkflowId?: string, expand_order_line_kits_request?: boolean } = { confirm: null, waveOptions: { isCreateWave: null, isProcessWave: null, isReleaseWave: null, priority: null, description: null, abortIfErrors: null, groupOnWave: null, isCreateManualAllocationTasks: null }, processingStrategyWorkflowId: null, expand_order_line_kits_request: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ confirm?: boolean, waveOptions?: { isCreateWave?: boolean, isProcessWave?: boolean, isReleaseWave?: boolean, priority?: number, description?: string, abortIfErrors?: boolean, groupOnWave?: boolean, isCreateManualAllocationTasks?: boolean }, processingStrategyWorkflowId?: string, expand_order_line_kits_request?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    processing_strategy: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    hardcoded_processing_strategy: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    cartonization_strategy: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    expand_kit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    is_create_wave: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    is_process_wave: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    is_create_manual_allocation_tasks: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    is_release_wave: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    description: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    priority: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    group_by_wave: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    abort_if_errors: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, false, false, 'Confirm', '', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
    processing_strategy: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['processing_strategy'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Processing strategy', true, false),
    hardcoded_processing_strategy: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['hardcoded_processing_strategy'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Processing strategy', true, false),
    cartonization_strategy: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['cartonization_strategy'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Cartonization strategy', false, false),
    expand_kit: new FieldModel(new CheckBoxModel(this.formGroup.controls['expand_kit'] as DatexFormControl, null, false, false, 'Expand order line kits?', null)
, new ControlContainerStyles(null, null), '', false, false),
    is_create_wave: new FieldModel(new CheckBoxModel(this.formGroup.controls['is_create_wave'] as DatexFormControl, null, false, false, 'Create wave', null)
, new ControlContainerStyles(null, null), '', false, false),
    is_process_wave: new FieldModel(new CheckBoxModel(this.formGroup.controls['is_process_wave'] as DatexFormControl, null, false, false, 'Process wave', null)
, new ControlContainerStyles(null, null), '', false, false),
    is_create_manual_allocation_tasks: new FieldModel(new CheckBoxModel(this.formGroup.controls['is_create_manual_allocation_tasks'] as DatexFormControl, null, false, false, 'Create manual allocation tasks', null)
, new ControlContainerStyles(null, null), '', false, false),
    is_release_wave: new FieldModel(new CheckBoxModel(this.formGroup.controls['is_release_wave'] as DatexFormControl, null, false, false, 'Release wave', null)
, new ControlContainerStyles(null, null), '', false, false),
    description: new FieldModel(new TextBoxModel(this.formGroup.controls['description'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Description', false, false),
    priority: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['priority'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Priority', false, false),
    group_by_wave: new FieldModel(new CheckBoxModel(this.formGroup.controls['group_by_wave'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Group on single wave', true, false),
    abort_if_errors: new FieldModel(new CheckBoxModel(this.formGroup.controls['abort_if_errors'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Abort wave release if any orders error', false, false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true, false),
  wave_options: new FieldsetModel('Wave options', false, false, true, false),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    processing_strategy: this.fields.processing_strategy.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    hardcoded_processing_strategy: this.fields.hardcoded_processing_strategy.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    cartonization_strategy: this.fields.cartonization_strategy.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    expand_kit: this.fields.expand_kit.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    is_create_wave: this.fields.is_create_wave.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    is_process_wave: this.fields.is_process_wave.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    is_create_manual_allocation_tasks: this.fields.is_create_manual_allocation_tasks.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    is_release_wave: this.fields.is_release_wave.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    description: this.fields.description.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    priority: this.fields.priority.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    group_by_wave: this.fields.group_by_wave.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    abort_if_errors: this.fields.abort_if_errors.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
  }
  

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.order_ids)) {
        this.$missingRequiredInParams.push('order_ids');
      }
      if(isNil(this.inParams.default_waving)) {
        this.$missingRequiredInParams.push('default_waving');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'Processing options';
  
    const $form = this;
    const $utils = this.utils;

    (this.fields.hardcoded_processing_strategy.control as SelectBoxModel).reset('304DF5F5-EFE3-4944-9304-9D0D7D675001');
    (this.fields.cartonization_strategy.control as SelectBoxModel).reset('Default');
    (this.fields.is_create_wave.control as CheckBoxModel).reset(false);
    
    (this.fields.group_by_wave.control as CheckBoxModel).reset(false);
    (this.fields.abort_if_errors.control as CheckBoxModel).reset(false);

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .processing_strategy
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .hardcoded_processing_strategy
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .cartonization_strategy
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .expand_kit
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .is_create_wave
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_is_create_wave_changed();
      });
    this.$formGroupFieldValidationObservables
      .is_process_wave
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_is_process_wave_changed();
      });
    this.$formGroupFieldValidationObservables
      .is_create_manual_allocation_tasks
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_is_process_wave_changed();
      });
    this.$formGroupFieldValidationObservables
      .is_release_wave
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_is_release_wave_changed();
      });
    this.$formGroupFieldValidationObservables
      .description
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .priority
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .group_by_wave
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_group_by_wave_changed();
      });
    this.$formGroupFieldValidationObservables
      .abort_if_errors
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: FootPrintManager_sales_orders_processing_options_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.outParams.confirm = false;
  $form.close();
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: FootPrintManager_sales_orders_processing_options_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  // Check required fields
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $form.fields.group_by_wave.control.value
  );
  
  var errors = '';
  
  if (!allRequiredFieldHaveValue) {
      $shell.FootPrintManager.openErrorDialog('Processing Options Error', 'Please enter in data in all required fields.');
      return;
  }
  
  else {
      try {
  
  
          // Set expand kit request
          if($utils.isDefined($form.fields.expand_kit.control.value) && $form.fields.expand_kit.control.value === true){
              $form.outParams.expand_order_line_kits_request = true;
          }
  
          let processingStrategyWorkflowLookup = $form.fields.processing_strategy.hidden ? $form.fields.hardcoded_processing_strategy.control.value : $form.fields.processing_strategy.control.value;
          if (processingStrategyWorkflowLookup == null || processingStrategyWorkflowLookup === '_') {
              processingStrategyWorkflowLookup = '304DF5F5-EFE3-4944-9304-9D0D7D675001';
          }
  
  
          $form.outParams.waveOptions.isCreateWave = $form.fields.is_create_wave.control.value;
          $form.outParams.waveOptions.isProcessWave = $form.fields.is_process_wave.control.value;
          $form.outParams.waveOptions.isReleaseWave = $form.fields.is_release_wave.control.value;
          $form.outParams.waveOptions.isCreateManualAllocationTasks = $form.fields.is_create_manual_allocation_tasks.control.value ?? false;
          $form.outParams.processingStrategyWorkflowId = processingStrategyWorkflowLookup;
  
  
          if ($utils.isDefined($form.fields.group_by_wave.control.value)) {
              if ($form.fields.group_by_wave.control.value)
                  $form.outParams.waveOptions.groupOnWave = true;
          }
  
          if ($utils.isDefined($form.fields.description.control.value)) {
  
              $form.outParams.waveOptions.description = $form.fields.description.control.value;
          }
  
          if ($utils.isDefined($form.fields.priority.control.value)) {
  
              $form.outParams.waveOptions.priority = $form.fields.priority.control.value;
          }
  
          $form.outParams.waveOptions.abortIfErrors = $form.fields.abort_if_errors.control.value ?? false;
  
  
          $form.outParams.confirm = true;
          $form.close();
  
      }
      catch (error) {
          $shell.FootPrintManager.openErrorDialog('Save', 'Error on save');
          throw error; // To prevent display mode
      }
  }
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootPrintManager_sales_orders_processing_options_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.outParams.confirm = false;
  $form.toolbar.confirm.control.readOnly = true;
  $form.outParams.waveOptions.isCreateWave = false;
  $form.outParams.waveOptions.isProcessWave = false;
  $form.outParams.waveOptions.isReleaseWave = false;
  
  // Handle expand kits input
  if ($utils.isDefined($form.inParams.expand_order_line_kits) && $form.inParams.expand_order_line_kits === true) {
      $form.fields.expand_kit.hidden = false;
      $form.fields.expand_kit.control.value = true;
  }
  else {
      $form.fields.expand_kit.hidden = true;
  
  }
  
  var defaultWave = false
  // Set wave option behavior
  
  if ($form.inParams.default_waving) {
      defaultWave = true;
  }
  
  // Set title field
  
  var title = 'Sales Order Processing Options'
  
  $form.title = title;
  
  $form.fields.abort_if_errors.hidden = true;
  
  
  // Show/hide fields
  $form.fields.is_create_wave.control.value = defaultWave;
  $form.fields.is_process_wave.control.value = defaultWave;
  $form.fields.is_release_wave.control.value = defaultWave;
  
  $form.fields.is_process_wave.hidden = !defaultWave;
  $form.fields.is_release_wave.hidden = !defaultWave;
  $form.fields.description.hidden = !defaultWave;
  $form.fields.priority.hidden = !defaultWave;
  $form.fields.group_by_wave.hidden = !defaultWave;
  $form.fields.is_create_manual_allocation_tasks.hidden = false;
  
  //hide wave options if reprocessing new lines
  
  if ($form.inParams.is_wave_created) {
      $form.fieldsets.wave_options.hidden = true
      $form.outParams.waveOptions.isCreateWave = false;
      $form.outParams.waveOptions.isProcessWave = false;
      $form.outParams.waveOptions.isReleaseWave = false;
  }
  
  
  // TODO: Remove once Cartonization fully implemented
  if (!(await $flows.Utilities.get_username_flow({})).domain.includes('datexcorp.com')) {
      $form.fields.cartonization_strategy.hidden = true;
  }
  
  const minVersionCheck = (await $flows.Utilities.check_footprint_version({ minimumVersion: '24.10.1' }));
  if (minVersionCheck.meetsMinimumVersion) {
      $form.fields.hardcoded_processing_strategy.hidden = true;
      get_processing_strategy();
  } else {
      $form.fields.processing_strategy.hidden = true;
      if ($utils.isDefined($form.fields.hardcoded_processing_strategy.control.value)) {
          $form.toolbar.confirm.control.readOnly = false;
      }
  }
  
  
  /**********************************************************
   * FUNCTIONS
  ***********************************************************/
  async function get_processing_strategy() {
      $form.fields.processing_strategy.control.styles.setStyle('opacity', 0.5);
      let processingStrategyLookup: string;
      if ($form.inParams.order_ids.length === 1) {
          // Get default processing strategy
          const order = (await $datasources.SalesOrders.ds_sales_order_editor.get({ orderId: $form.inParams.order_ids[0] })).result;
          const strategies = (await $datasources.SalesOrders.ds_get_default_processing_strategy.get({ project_id: order.ProjectId, order_class_id: order.OrderClassId, account_id: order.AccountId })).result;
  
          const processingStrategyId = strategies[0]?.ProcessingStrategyWorkflowId;
          processingStrategyLookup = (await $datasources.SalesOrders.ds_get_processing_strategy_workflows.getList({})).result
              .find(w => w.Id === processingStrategyId)?.LookupCode;
      }
  
      if (!$utils.isDefined(processingStrategyLookup)) {
          processingStrategyLookup = (await $datasources.SalesOrders.ds_get_processing_strategy_workflows.getList({ full_text_search: 'fill or stop', $top: 1 })).result[0]?.LookupCode;
      }
  
      $form.fields.processing_strategy.control.value = processingStrategyLookup;
      $form.fields.processing_strategy.control.styles.clearStyle();
      $form.toolbar.confirm.control.readOnly = !$utils.isDefined($form.fields.processing_strategy.control.value);
  }
  }
  on_group_by_wave_changed(event = null) {
    return this.on_group_by_wave_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_group_by_wave_changedInternal(
    $form: FootPrintManager_sales_orders_processing_options_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($form.fields.group_by_wave.control.value) && $form.fields.group_by_wave.control.value) {
      $form.fields.abort_if_errors.hidden = false;
  }
  else {
      $form.fields.abort_if_errors.hidden = true;
      $form.fields.abort_if_errors.control.value = false;
  }
  }
  on_is_create_wave_changed(event = null) {
    return this.on_is_create_wave_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_is_create_wave_changedInternal(
    $form: FootPrintManager_sales_orders_processing_options_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const isMultiple = $form.inParams.order_ids.length > 1;
  
  if ($form.fields.is_create_wave.control.value) {
      $form.fields.is_process_wave.hidden = false;
      $form.fields.is_release_wave.hidden = false;
      
      $form.fields.description.hidden = !isMultiple;
      $form.fields.priority.hidden = !isMultiple;
      $form.fields.group_by_wave.hidden = !isMultiple;
      $form.fields.abort_if_errors.hidden = !$form.fields.group_by_wave.control.value;
  
      $form.fields.is_process_wave.control.value = true;
      $form.fields.is_release_wave.control.value = true;
      $form.fields.is_create_manual_allocation_tasks.hidden = false
  }
  else {
      $form.fields.is_process_wave.hidden = true;
      $form.fields.is_release_wave.hidden = true;
      $form.fields.description.hidden = true;
      $form.fields.priority.hidden = true;
      $form.fields.group_by_wave.hidden = true;
      $form.fields.abort_if_errors.hidden = true;
  
      $form.fields.is_process_wave.control.value = false;
      $form.fields.is_release_wave.control.value = false;
      $form.fields.is_create_manual_allocation_tasks.hidden = true
  }
  }
  on_is_process_wave_changed(event = null) {
    return this.on_is_process_wave_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_is_process_wave_changedInternal(
    $form: FootPrintManager_sales_orders_processing_options_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if (!$form.fields.is_process_wave.control.value) {
      $form.fields.is_release_wave.control.value = false;
      $form.fields.is_create_manual_allocation_tasks.hidden = true;
      $form.fields.is_create_manual_allocation_tasks.control.value = false;
  }
  else
  {
      $form.fields.is_create_manual_allocation_tasks.hidden = false;
  
  
  }
  
  
  
  
  
  
  }
  on_is_release_wave_changed(event = null) {
    return this.on_is_release_wave_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_is_release_wave_changedInternal(
    $form: FootPrintManager_sales_orders_processing_options_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($form.fields.is_release_wave.control.value) {
      $form.fields.is_process_wave.control.value = true;
      $form.fields.is_create_manual_allocation_tasks.hidden = false;
  }
  }
  //#endregion private flows
  //#region validation flows
  
  validate_form(fieldErrors: { processing_strategy: Pick<string[], 'push'>,hardcoded_processing_strategy: Pick<string[], 'push'>,cartonization_strategy: Pick<string[], 'push'>,expand_kit: Pick<string[], 'push'>,is_create_wave: Pick<string[], 'push'>,is_process_wave: Pick<string[], 'push'>,is_create_manual_allocation_tasks: Pick<string[], 'push'>,is_release_wave: Pick<string[], 'push'>,description: Pick<string[], 'push'>,priority: Pick<string[], 'push'>,group_by_wave: Pick<string[], 'push'>,abort_if_errors: Pick<string[], 'push'>, } = null) {
    const emptyResult = { processing_strategy:[],hardcoded_processing_strategy:[],cartonization_strategy:[],expand_kit:[],is_create_wave:[],is_process_wave:[],is_create_manual_allocation_tasks:[],is_release_wave:[],description:[],priority:[],group_by_wave:[],abort_if_errors:[], };
    if (!this.initialized){
      return Promise.resolve(emptyResult);
    }
    return this.validate_formInternal(
      this,
      { fieldErrors: fieldErrors ?? emptyResult },
      this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization
      );
  }
  async validate_formInternal(
    $form: FootPrintManager_sales_orders_processing_options_formComponent,
    $validation:{
      fieldErrors: { processing_strategy: Pick<string[], 'push'>,hardcoded_processing_strategy: Pick<string[], 'push'>,cartonization_strategy: Pick<string[], 'push'>,expand_kit: Pick<string[], 'push'>,is_create_wave: Pick<string[], 'push'>,is_process_wave: Pick<string[], 'push'>,is_create_manual_allocation_tasks: Pick<string[], 'push'>,is_release_wave: Pick<string[], 'push'>,description: Pick<string[], 'push'>,priority: Pick<string[], 'push'>,group_by_wave: Pick<string[], 'push'>,abort_if_errors: Pick<string[], 'push'>, }
    },
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
  ) {
    let canConfirm = true;
  for (let key in $form.fields) {
      if ($form.fields[key].required && !$form.fields[key].hidden && !$utils.isDefined($form.fields[key].control.value)) {
          canConfirm = false;
      }
  }
  
  
  $form.toolbar.confirm.control.readOnly = !canConfirm;
    return $validation.fieldErrors as { processing_strategy:[],hardcoded_processing_strategy:[],cartonization_strategy:[],expand_kit:[],is_create_wave:[],is_process_wave:[],is_create_manual_allocation_tasks:[],is_release_wave:[],description:[],priority:[],group_by_wave:[],abort_if_errors:[], };
  }
  //#endregion validation flows
}
