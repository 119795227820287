import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootPrintManager_inventory_location_gridComponent } from './FootPrintManager.inventory_location_grid.component';
import { FootPrintManager_licenseplate_listComponent } from './FootPrintManager.licenseplate_list.component';
import { FootPrintManager_inventory_tasks_gridComponent } from './FootPrintManager.inventory_tasks_grid.component';
import { FootPrintManager_serialnumbers_gridComponent } from './FootPrintManager.serialnumbers_grid.component';
import { FootPrintManager_lots_udf_gridComponent } from './FootPrintManager.lots_udf_grid.component';
import { Inventory_total_available_amount_widgetComponent } from './Inventory.total_available_amount_widget.component';
import { Inventory_total_gross_weight_widgetComponent } from './Inventory.total_gross_weight_widget.component';
import { Inventory_total_packaged_amount_widgetComponent } from './Inventory.total_packaged_amount_widget.component';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_inventory_location_gridComponent),
    forwardRef(() => FootPrintManager_licenseplate_listComponent),
    forwardRef(() => FootPrintManager_inventory_tasks_gridComponent),
    forwardRef(() => FootPrintManager_serialnumbers_gridComponent),
    forwardRef(() => FootPrintManager_lots_udf_gridComponent),
    forwardRef(() => Inventory_total_available_amount_widgetComponent),
    forwardRef(() => Inventory_total_gross_weight_widgetComponent),
    forwardRef(() => Inventory_total_packaged_amount_widgetComponent),
  ],
  selector: 'FootPrintManager-single_lot_hub',
  templateUrl: './FootPrintManager.single_lot_hub.component.html'
})
export class FootPrintManager_single_lot_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { lotId: number } = { lotId: null };
  //#region Inputs
  @Input('lotId') set $inParams_lotId(v: number) {
    this.inParams.lotId = v;
  }
  get $inParams_lotId(): number {
    return this.inParams.lotId;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { hubTitle?: string, hubDescription?: string } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
  });
  
  toolbar = {
      edit: new ToolModel(new ButtonModel('edit', new ButtonStyles(null, null), false, false, false, 'Edit lot', 'icon-ic_fluent_edit_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      change_status: new ToolModel(new ButtonModel('change_status', new ButtonStyles(null, null), false, false, false, 'Change status', 'icon-ic_fluent_arrow_sync_circle_20_regular', null)
    , false),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      create_inventory: new ToolModel(new ButtonModel('create_inventory', new ButtonStyles(null, null), false, false, false, 'Create inventory', 'icon-ic_fluent_add_20_regular', null)
    , false)
  };

  actionbar = {
  };

 filters = {
    material: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Material', false, false),
    project: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Project', false, false),
    manufactured_on: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Manufactured on', false, false),
    expires_on: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Expires on', false, false),
    status: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Status', false, false),
  };


  filtersets = {
  newGroup1: new FieldsetModel('Label of newGroup1', true, false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      inventory: new TabItemModel(
        this.rootTabGroup, 
        'Inventory', 
        false,
        ),
      licenseplates: new TabItemModel(
        this.rootTabGroup, 
        'License plates', 
        false,
        ),
      tasks: new TabItemModel(
        this.rootTabGroup, 
        'Activity', 
        false,
        ),
      serialnumbers: new TabItemModel(
        this.rootTabGroup, 
        'Serial numbers', 
        false,
        ),
      udfs: new TabItemModel(
        this.rootTabGroup, 
        'Custom fields', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_inventory_inventory_location_grid_inParams_lotId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.lotId;
      
      return expr;
    }
  
    get $tabs_licenseplates_licenseplate_list_inParams_lotId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.lotId;
      
      return expr;
    }
  
    get $tabs_tasks_inventory_tasks_grid_inParams_lotId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.lotId;
      
      return expr;
    }
  
    get $tabs_serialnumbers_serialnumbers_grid_inParams_lotId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.lotId;
      
      return expr;
    }
  
    get $tabs_serialnumbers_serialnumbers_grid_inParams_archived(): boolean {
      const $hub = this;
      const $utils = this.utils;
      const expr = false;
      
      return expr;
    }
  
    get $tabs_udfs_lots_udf_grid_inParams_lotId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.lotId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_inventory', { read: FootPrintManager_inventory_location_gridComponent }) $tabs_inventory: FootPrintManager_inventory_location_gridComponent;
      @ViewChild('$tabs_licenseplates', { read: FootPrintManager_licenseplate_listComponent }) $tabs_licenseplates: FootPrintManager_licenseplate_listComponent;
      @ViewChild('$tabs_tasks', { read: FootPrintManager_inventory_tasks_gridComponent }) $tabs_tasks: FootPrintManager_inventory_tasks_gridComponent;
      @ViewChild('$tabs_serialnumbers', { read: FootPrintManager_serialnumbers_gridComponent }) $tabs_serialnumbers: FootPrintManager_serialnumbers_gridComponent;
      @ViewChild('$tabs_udfs', { read: FootPrintManager_lots_udf_gridComponent }) $tabs_udfs: FootPrintManager_lots_udf_gridComponent;
    //#endregion tabs children
    widgets = {
      total_available_amount_widget: new WidgetModel(false),
      total_gross_weight_widget: new WidgetModel(false),
      total_packaged_amount_widget: new WidgetModel(false),
    };
  
    //#region widgets inParams
    get $widgets_total_available_amount_widget_inParams_lotId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.lotId;
      
      return expr;
    }
  
    get $widgets_total_gross_weight_widget_inParams_lotId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.lotId;
      
      return expr;
    }
  
    get $widgets_total_packaged_amount_widget_inParams_lotId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.lotId;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_total_available_amount_widget', { read:  Inventory_total_available_amount_widgetComponent }) $widgets_total_available_amount_widget: Inventory_total_available_amount_widgetComponent;
      @ViewChild('$widgets_total_gross_weight_widget', { read:  Inventory_total_gross_weight_widgetComponent }) $widgets_total_gross_weight_widget: Inventory_total_gross_weight_widgetComponent;
      @ViewChild('$widgets_total_packaged_amount_widget', { read:  Inventory_total_packaged_amount_widgetComponent }) $widgets_total_packaged_amount_widget: Inventory_total_packaged_amount_widgetComponent;
    //#endregion widgets children

  //#region filters inParams
  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return $hub.vars.hubTitle;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return $hub.vars.hubDescription;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.inventory,
      this.tabs.licenseplates,
      this.tabs.tasks,
      this.tabs.serialnumbers,
      this.tabs.udfs,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.lotId)) {
        this.$missingRequiredInParams.push('lotId');
      }
  }



  initialized = false;

  async $init() {
    this.title = 'Single Lot Hub';
    
    const $hub = this;
    const $utils = this.utils;


    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_total_available_amount_widget') {
      if (!isNil(this.$widgets_total_available_amount_widget) && !this.widgets.total_available_amount_widget.hidden) {
        this.$widgets_total_available_amount_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_total_gross_weight_widget') {
      if (!isNil(this.$widgets_total_gross_weight_widget) && !this.widgets.total_gross_weight_widget.hidden) {
        this.$widgets_total_gross_weight_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_total_packaged_amount_widget') {
      if (!isNil(this.$widgets_total_packaged_amount_widget) && !this.widgets.total_packaged_amount_widget.hidden) {
        this.$widgets_total_packaged_amount_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
    if (childToSkip !== '$tabs_inventory') {
      if (!isNil(this.$tabs_inventory) && !this.tabs.inventory.hidden) {
        this.$tabs_inventory.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_licenseplates') {
      if (!isNil(this.$tabs_licenseplates) && !this.tabs.licenseplates.hidden) {
        this.$tabs_licenseplates.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_tasks') {
      if (!isNil(this.$tabs_tasks) && !this.tabs.tasks.hidden) {
        this.$tabs_tasks.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_serialnumbers') {
      if (!isNil(this.$tabs_serialnumbers) && !this.tabs.serialnumbers.hidden) {
        this.$tabs_serialnumbers.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_udfs') {
      if (!isNil(this.$tabs_udfs) && !this.tabs.udfs.hidden) {
        this.$tabs_udfs.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_create_inventory_clicked(event = null) {
    return this.on_create_inventory_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_inventory_clickedInternal(
    $hub: FootPrintManager_single_lot_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const dialogResult = await $shell.FootPrintManager.openinventory_creation_formDialog({
      lotId: $hub.inParams.lotId
  });
  const userConfirmed = dialogResult.confirm;
  
  if (userConfirmed) {
      $hub.refresh()
  }
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_single_lot_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  const lot = (await $datasources.Lots.ds_get_lot_by_lotId.get({ lotId: $hub.inParams.lotId })).result;
  
  if ($utils.isDefined(lot)) {
  
      var expirationDate;
      var manufacturedDate;
      var receivedDate;
      var status_name = lot[0].Status.Name;
      var vendor_lot = lot[0].VendorLot?.LookupCode;
      var material_id = lot[0].MaterialId;
  
      const material = (await $datasources.Materials.ds_get_material_by_materialId.get({ materialId: material_id })).result;
      if ($utils.isDefined(material)) {
          var material_lookup = material[0].LookupCode;
          $hub.filters.material.control.text = material_lookup;
  
          var project_id = material[0].ProjectId;
          const project = (await $datasources.Owners.ds_get_project_by_projectId.get({ projectId: project_id })).result;
  
          if ($utils.isDefined(project)) {
              var project_lookup = project[0].LookupCode;
              $hub.filters.project.control.text = project_lookup;
          }
  
      }
  
  
      // Get last lot status change task to get reason codes
      let task = (await $datasources.Lots.ds_get_first_task_for_lot.get({ lotId: $hub.inParams.lotId, operationCodeId: 35 })).result;
      if ($utils.isDefined(task)) {
  
          const task_id = task.Id;
  
          const reasons = (await $datasources.Lots.ds_get_lot_status_change_reason_codes.get({ task_id: task_id })).result;
          var reason_codes;
  
          if ($utils.isDefined(reasons)) {
  
              let i = 0;
              for (let reason of reasons) {
                  i += 1;
                  let lookupcode = reason.ReasonCode?.LookupCode;
                  if ($utils.isDefined(lookupcode)) {
                      if (i != 1) {
                          lookupcode = ', ' + lookupcode;
                      }
                      if ($utils.isDefined(reason_codes)) {
                          reason_codes = reason_codes + lookupcode;
                      }
                      else {
                          reason_codes = lookupcode;
                      }
                  }
              }
  
  
          }
      }
  
      // Apply date format
      if ($utils.isDefined(lot[0].VendorLot?.ExpirationDate) && $utils.isDefined($settings.FootPrintManager.DateFormat)) {
          var dateFormat = $settings.FootPrintManager.DateFormat;
          expirationDate = `${$utils.date.format(lot[0].VendorLot?.ExpirationDate, dateFormat)}`;
          $hub.filters.expires_on.control.text = expirationDate;
      }
  
      if ($utils.isDefined(lot[0].VendorLot?.ManufactureDate) && $utils.isDefined($settings.FootPrintManager.DateFormat)) {
          var dateFormat = $settings.FootPrintManager.DateFormat;
          manufacturedDate = `${$utils.date.format(lot[0].VendorLot?.ManufactureDate, dateFormat)}`;
          $hub.filters.manufactured_on.control.text = manufacturedDate;
      }
  
      if (($utils.isDefined(lot[0].LastReceiveDate)) && $utils.isDefined($settings.FootPrintManager.DateFormat)) {
          var dateFormat = $settings.FootPrintManager.DateFormat;
          receivedDate = `${$utils.date.format(lot[0].LastReceiveDate, dateFormat)}`;
      }
  
      // Apply field data
  
      $hub.title = `Lot ${lot[0].LookupCode}`;
  
      const title = `${lot[0].LookupCode}`;
      $hub.vars.hubTitle = title;
  
  
      const description = `Vendor lot ${vendor_lot} `;
      $hub.vars.hubDescription = description;
  
  
      if (status_name == 'Active') {
          $hub.filters.status.styles.setCreationClass();
      }
      else {
          $hub.filters.status.styles.setImportantClass();
      }
      if ($utils.isDefined(reason_codes)) {
          status_name = `${status_name} - ${reason_codes}`
      }
  
      $hub.filters.status.control.text = status_name;
  
  
  }
  
  
  // Hide serialnumbers tab if no serialnumbers exists
  const serialnumber = (await $datasources.SerialNumbers.ds_get_serialnumber_by_various_top1.get({ lotId: $hub.inParams.lotId })).result;
  if ($utils.isDefined(serialnumber)) {
  
      $hub.tabs.serialnumbers.hidden = false;
  }
  else {
      $hub.tabs.serialnumbers.hidden = true;
  }
  
  $hub.toolbar.create_inventory.hidden = (await $operations.FootPrintManager.Disable_Inventory_Creation.isAuthorized());
  }
  on_edit_clicked(event = null) {
    return this.on_edit_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_edit_clickedInternal(
    $hub: FootPrintManager_single_lot_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const dialogResult = (await $shell.FootPrintManager.openlot_editorDialog({lotId: $hub.inParams.lotId},'flyout'));
  const userConfirmed = dialogResult.confirm;
  
  if (userConfirmed) {
  
      await $hub.on_init();
      await $hub.refresh();
  }
  
  }
  on_status_change(event = null) {
    return this.on_status_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_status_changeInternal(
    $hub: FootPrintManager_single_lot_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  
  
  const dialogResult = (await $shell.FootPrintManager.openlot_status_change_editorDialog({lotId: $hub.inParams.lotId},'modal'));
  const userConfirmed = dialogResult.confirm;
  
  if (userConfirmed) {
  
      await $hub.on_init();
      await $hub.refresh();
  }
  
  }
  //#endregion private flows
}
