import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Manifesting_ShellService } from './Manifesting.shell.service';
import { Manifesting_OperationService } from './Manifesting.operation.service';
import { Manifesting_DatasourceService } from './Manifesting.datasource.index';
import { Manifesting_FlowService } from './Manifesting.flow.index';
import { Manifesting_ReportService } from './Manifesting.report.index';
import { Manifesting_LocalizationService } from './Manifesting.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Manifesting.frontend.types'
import { $frontendTypes as $types} from './Manifesting.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';



interface IManifesting_shiptheory_manifesting_shipments_gridComponentEntity {
  Id?: number, CarrierId?: number, CarrierServiceTypeId?: number, ExpectedWarehouseId?: number, LookupCode?: string, TrackingIdentifier?: string, ExpectedWarehouse?: { Name?: string }, Carrier?: { Name?: string }, CarrierServiceType?: { Name?: string }, Status?: { Name?: string }, OrderLookups?: { OrderId?: number, Order?: { BillingAddresId?: number, IntegrationHubId?: string, IntegrationHubSourceId?: string, IntegrationHubSourcePlatform?: string, OwnerReference?: string, ProjectId?: number, Project?: { LookupCode?: string, OwnerId?: number, Owner?: { LookupCode?: string } }, Addresses?: { Id?: number, City?: string, Country?: string, Line1?: string, PostalCode?: string, State?: string }[] } }[], ShippingContainers?: { Id?: number, NmfcNumber?: string, NmfcSubNumber?: string }[], warehouseAddress?: { WarehouseId?: number, ContactId?: number, Warehouse?: { Id?: number, Name?: string, Notes?: string }, Contact?: { FirstName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, ReferenceCode?: string, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[], carrierAccountNumber?: { OwnerId?: number, CarrierId?: number, AccountNumber?: string, Description?: string, Instructions?: string, Notes?: string, ReferenceNumber?: string }[], orderAccountAddress?: { Id?: number, AddressId?: number, FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { Id?: number, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } }[], shipmentShippingContainerCount?: { TotalShippingContainerCount?: number, ShipmentId?: number }[]}

interface IManifesting_shiptheory_manifesting_shipments_gridComponentInParams {
  shipmentIds: number[], printerId?: number, length?: number, width?: number, height?: number, weight?: number, utilizeShipmentContainer?: boolean, labelSize?: number, contentsExplanation?: string, containerCount?: number, printerIdPackinglist?: number}


class Manifesting_shiptheory_manifesting_shipments_gridComponentRowModel extends GridRowModel {
  grid: Manifesting_shiptheory_manifesting_shipments_gridComponent;
  entity: IManifesting_shiptheory_manifesting_shipments_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    LookupCode: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    reference: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    Carrier_Name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    CarrierServiceType_Name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    shipping_container_count: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    warehouse_address: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    shipping_address: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    tracking: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    printjob: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    label: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Manifesting_ShellService,
private datasources: Manifesting_DatasourceService,
private flows: Manifesting_FlowService,
private reports: Manifesting_ReportService,
private localization: Manifesting_LocalizationService,
private operations: Manifesting_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: Manifesting_shiptheory_manifesting_shipments_gridComponent, entity: IManifesting_shiptheory_manifesting_shipments_gridComponentEntity) {
    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: Manifesting_shiptheory_manifesting_shipments_gridComponent, entity?: IManifesting_shiptheory_manifesting_shipments_gridComponentEntity) {
    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      shipmentIds:  $grid.inParams.shipmentIds ,
    };
    const data = await this.datasources.Manifesting.ds_manifesting_shipments_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IManifesting_shiptheory_manifesting_shipments_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.LookupCode.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.reference.displayControl as TextModel).text = $row.entity.OrderLookups[0]?.Order?.OwnerReference;
    (this.cells.Carrier_Name.displayControl as TextModel).text = $row.entity.Carrier?.Name;
    (this.cells.CarrierServiceType_Name.displayControl as TextModel).text = $row.entity.CarrierServiceType?.Name;
    (this.cells.tracking.displayControl as TextModel).text = $row.entity.TrackingIdentifier;
    (this.cells.printjob.displayControl as TextModel).text = $row.entity.OrderLookups[0]?.Order?.IntegrationHubSourcePlatform;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'printjob' : {
        this.on_printjob_clicked();
        break;
      }
      case 'label' : {
        this.on_label_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: Manifesting_shiptheory_manifesting_shipments_gridComponentRowModel,
  $grid: Manifesting_shiptheory_manifesting_shipments_gridComponent, 
    $shell: Manifesting_ShellService,
    $datasources: Manifesting_DatasourceService,
    $flows: Manifesting_FlowService,
    $reports: Manifesting_ReportService,
    $settings: SettingsValuesService,
    $operations: Manifesting_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manifesting_LocalizationService,
    $event: any
  ) {
  
  // Set label field
  if ($utils.isDefined($row.entity.ShippingContainers[0]?.NmfcNumber)) {
      $row.cells.label.displayControl.text = 'Label'
  }
  
  
  
  // Set shipment from and to addresses
  $row.cells.warehouse_address.displayControl.text = (
      ($utils.isDefined($row.entity.warehouseAddress[0]?.Contact?.Address?.Line1))
          ?
          $row.entity.warehouseAddress[0]?.Contact?.Address?.Line1 + ', ' +
          $row.entity.warehouseAddress[0]?.Contact?.Address?.City + ', ' +
          $row.entity.warehouseAddress[0]?.Contact?.Address?.State + ' ' +
          $row.entity.warehouseAddress[0]?.Contact?.Address?.PostalCode + ' ' +
          $row.entity.warehouseAddress[0]?.Contact?.Address?.Country
          :
          (
              '-'
          )
  
  
  );
  
  
  
  
  $row.cells.shipping_address.displayControl.text = (
      ($utils.isAllDefined($row.entity.OrderLookups[0]?.Order?.Addresses[0]?.Id, $row.entity.OrderLookups[0]?.Order?.Addresses[0]?.Line1))
          ?
          $row.entity.OrderLookups[0]?.Order?.Addresses[0]?.Line1 + ', ' +
          $row.entity.OrderLookups[0]?.Order?.Addresses[0]?.City + ', ' +
          $row.entity.OrderLookups[0]?.Order?.Addresses[0]?.State + ' ' +
          $row.entity.OrderLookups[0]?.Order?.Addresses[0]?.PostalCode + ' ' +
          $row.entity.OrderLookups[0]?.Order?.Addresses[0]?.Country
          :
          (
              ($utils.isAllDefined($row.entity.orderAccountAddress[0]?.Id, $row.entity.orderAccountAddress[0]?.Address?.Line1))
                  ?
                  $row.entity.orderAccountAddress[0]?.Address?.Line1 + ', ' +
                  $row.entity.orderAccountAddress[0]?.Address?.City + ', ' +
                  $row.entity.orderAccountAddress[0]?.Address?.State + ' ' +
                  $row.entity.orderAccountAddress[0]?.Address?.PostalCode + ' ' +
                  $row.entity.orderAccountAddress[0]?.Address?.Country
                  :
                  (
                      '-'
                  )
          )
  );
  
  
  // Set shipment shipping container count
  $row.cells.shipping_container_count.displayControl.text = (
      $utils.isDefined($row.entity.shipmentShippingContainerCount[0]?.TotalShippingContainerCount) ?
          $row.entity.shipmentShippingContainerCount[0]?.TotalShippingContainerCount?.toString() : '0'
  );
  
  
  // EasyPost - Set manifest shipment status
  const manifestingStatus = (await $flows.ShipTheory.is_shipment_manifestable_flow({ shipmentId: $row.entity.Id }))
  if ($utils.isDefined(manifestingStatus)) {
      var manifestStatus = manifestingStatus.status;
      $row.cells.status.displayControl.text = manifestStatus;
  
  } else {
      $row.cells.status.displayControl.text = 'Not Ready'
  }
  
  
  
  if (manifestStatus === 'Ready') {
  
      if ($row.cells.shipping_address.displayControl.text !== '-' && $row.cells.warehouse_address.displayControl.text !== '-') {
  
          var manifestReady = true;
      }
  }
  
  // Initialize manifest ready shipments button
  const printerId = $grid.inParams.printerId;
  const containerLength = $grid.inParams.length;
  const containerWidth = $grid.inParams.width;
  const containerHeight = $grid.inParams.height;
  const containerWeight = $grid.inParams.weight;
  
  if ($grid.inParams.utilizeShipmentContainer === true) {
      var utilizeShipmentContainer = true;
  }
  console.log(manifestReady);
  console.log(utilizeShipmentContainer);
  
  if (utilizeShipmentContainer === true && manifestReady === true) {
  
      $grid.topToolbar.manifest_ready_shipments.control.readOnly = false;
  
  } else if ($utils.isAllDefined(containerLength, containerWidth, containerHeight, containerWeight) && manifestReady === true) {
  
      $grid.topToolbar.manifest_ready_shipments.control.readOnly = false;
  
  }
  else {
  
      $grid.topToolbar.manifest_ready_shipments.control.readOnly = true;
  
  }
  
  // Initialize print packing list button
  
  $grid.topToolbar.print_packing_lists.control.readOnly = false;
  
  
  
  }
  on_label_clicked(event = null) {
    return this.on_label_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_label_clickedInternal(
    $row: Manifesting_shiptheory_manifesting_shipments_gridComponentRowModel,
  $grid: Manifesting_shiptheory_manifesting_shipments_gridComponent, 
    $shell: Manifesting_ShellService,
    $datasources: Manifesting_DatasourceService,
    $flows: Manifesting_FlowService,
    $reports: Manifesting_ReportService,
    $settings: SettingsValuesService,
    $operations: Manifesting_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manifesting_LocalizationService,
    $event: any
  ) {
  
  
  var labelUrl;
  
  labelUrl = $row.entity.ShippingContainers[0]?.NmfcNumber;
  
  if ($utils.isDefined(labelUrl)) {
  
      $shell.Manifesting.openshipping_label_content_base64_pdfDialog({ label_input: labelUrl }, 'flyout')
  }
  
  
  
  
  
  
  
  }
  on_printjob_clicked(event = null) {
    return this.on_printjob_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_printjob_clickedInternal(
    $row: Manifesting_shiptheory_manifesting_shipments_gridComponentRowModel,
  $grid: Manifesting_shiptheory_manifesting_shipments_gridComponent, 
    $shell: Manifesting_ShellService,
    $datasources: Manifesting_DatasourceService,
    $flows: Manifesting_FlowService,
    $reports: Manifesting_ReportService,
    $settings: SettingsValuesService,
    $operations: Manifesting_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manifesting_LocalizationService,
    $event: any
  ) {
  var printJobId = parseInt($row.entity.OrderLookups[0]?.Order?.IntegrationHubSourcePlatform);
  
  
  $shell.PrintNode.openprintjob_gridDialog({printJobId:printJobId},'modal')
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Manifesting-shiptheory_manifesting_shipments_grid',
  templateUrl: './Manifesting.shiptheory_manifesting_shipments_grid.component.html'
})
export class Manifesting_shiptheory_manifesting_shipments_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IManifesting_shiptheory_manifesting_shipments_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);


  inParams: IManifesting_shiptheory_manifesting_shipments_gridComponentInParams = { shipmentIds: [], printerId: null, length: null, width: null, height: null, weight: null, utilizeShipmentContainer: null, labelSize: null, contentsExplanation: null, containerCount: null, printerIdPackinglist: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     status: new GridHeaderModel(new HeaderStyles(null, null), 'status', 'Status', false, false, null, false),       LookupCode: new GridHeaderModel(new HeaderStyles(null, null), 'LookupCode', 'Shipment', false, false, null, false),       reference: new GridHeaderModel(new HeaderStyles(null, null), 'reference', 'Ref', false, false, null, false),       Carrier_Name: new GridHeaderModel(new HeaderStyles(null, null), 'Carrier_Name', 'Carrier', false, false, null, false),       CarrierServiceType_Name: new GridHeaderModel(new HeaderStyles(null, null), 'CarrierServiceType_Name', 'Service', false, false, null, false),       shipping_container_count: new GridHeaderModel(new HeaderStyles(null, null), 'shipping_container_count', 'Package count', false, false, null, false),       warehouse_address: new GridHeaderModel(new HeaderStyles(null, null), 'warehouse_address', 'From', false, false, null, false),       shipping_address: new GridHeaderModel(new HeaderStyles(null, null), 'shipping_address', 'To', false, false, null, false),       tracking: new GridHeaderModel(new HeaderStyles(null, null), 'tracking', 'Tracking', false, false, null, false),       printjob: new GridHeaderModel(new HeaderStyles(null, null), 'printjob', 'Print job', false, false, null, false),       label: new GridHeaderModel(new HeaderStyles(null, null), 'label', 'Label', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Manifesting_shiptheory_manifesting_shipments_gridComponentRowModel[] = [];

  @Input('shipmentIds') set $inParams_shipmentIds(value: any) {
    this.inParams['shipmentIds'] = value;
  }
  get $inParams_shipmentIds(): any {
    return this.inParams['shipmentIds'] ;
  }
  @Input('printerId') set $inParams_printerId(value: any) {
    this.inParams['printerId'] = value;
  }
  get $inParams_printerId(): any {
    return this.inParams['printerId'] ;
  }
  @Input('length') set $inParams_length(value: any) {
    this.inParams['length'] = value;
  }
  get $inParams_length(): any {
    return this.inParams['length'] ;
  }
  @Input('width') set $inParams_width(value: any) {
    this.inParams['width'] = value;
  }
  get $inParams_width(): any {
    return this.inParams['width'] ;
  }
  @Input('height') set $inParams_height(value: any) {
    this.inParams['height'] = value;
  }
  get $inParams_height(): any {
    return this.inParams['height'] ;
  }
  @Input('weight') set $inParams_weight(value: any) {
    this.inParams['weight'] = value;
  }
  get $inParams_weight(): any {
    return this.inParams['weight'] ;
  }
  @Input('utilizeShipmentContainer') set $inParams_utilizeShipmentContainer(value: any) {
    this.inParams['utilizeShipmentContainer'] = value;
  }
  get $inParams_utilizeShipmentContainer(): any {
    return this.inParams['utilizeShipmentContainer'] ;
  }
  @Input('labelSize') set $inParams_labelSize(value: any) {
    this.inParams['labelSize'] = value;
  }
  get $inParams_labelSize(): any {
    return this.inParams['labelSize'] ;
  }
  @Input('contentsExplanation') set $inParams_contentsExplanation(value: any) {
    this.inParams['contentsExplanation'] = value;
  }
  get $inParams_contentsExplanation(): any {
    return this.inParams['contentsExplanation'] ;
  }
  @Input('containerCount') set $inParams_containerCount(value: any) {
    this.inParams['containerCount'] = value;
  }
  get $inParams_containerCount(): any {
    return this.inParams['containerCount'] ;
  }
  @Input('printerIdPackinglist') set $inParams_printerIdPackinglist(value: any) {
    this.inParams['printerIdPackinglist'] = value;
  }
  get $inParams_printerIdPackinglist(): any {
    return this.inParams['printerIdPackinglist'] ;
  }

  topToolbar = {
      manifest_ready_shipments: new ToolModel(new ButtonModel('manifest_ready_shipments', new ButtonStyles(null, null), false, false, false, 'Manifest ready shipments', 'ms-Icon ms-Icon--AutomateFlow', null)
    , false),
      print_packing_lists: new ToolModel(new ButtonModel('print_packing_lists', new ButtonStyles(null, null), false, false, false, 'Print packing lists', 'ms-Icon ms-Icon--AddToShoppingList', null)
    , false),
      manifestcount: new ToolModel(new ButtonModel('manifestcount', new ButtonStyles(null, null), false, false, false, 'Manifest count', '', null)
    , false)
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Manifesting_ShellService,
    private datasources: Manifesting_DatasourceService,
    private flows: Manifesting_FlowService,
    private reports: Manifesting_ReportService,
    private localization: Manifesting_LocalizationService,
    private operations: Manifesting_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'ShipTheory shipments manifesting grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.shipmentIds)) {
        this.$missingRequiredInParams.push('shipmentIds');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 50;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      shipmentIds:  $grid.inParams.shipmentIds ,
    };
    try {
      const data = await this.datasources.Manifesting.ds_manifesting_shipments_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IManifesting_shiptheory_manifesting_shipments_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Manifesting_shiptheory_manifesting_shipments_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push(row.$initializeExisting(this, entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: Manifesting_shiptheory_manifesting_shipments_gridComponent,
  
    $shell: Manifesting_ShellService,
    $datasources: Manifesting_DatasourceService,
    $flows: Manifesting_FlowService,
    $reports: Manifesting_ReportService,
    $settings: SettingsValuesService,
    $operations: Manifesting_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manifesting_LocalizationService,
    $event: any
  ) {
  // Default buttons to read only
  $grid.topToolbar.manifest_ready_shipments.control.readOnly = true;
  $grid.topToolbar.print_packing_lists.control.readOnly = true;
  
  if ($utils.isDefined($grid.inParams.printerId)
      || $utils.isDefined($grid.inParams.length)
      || $utils.isDefined($grid.inParams.width)
      || $utils.isDefined($grid.inParams.height)
      || ($utils.isDefined($grid.inParams.utilizeShipmentContainer) && $grid.inParams.utilizeShipmentContainer == true)
  ) {
      $grid.topToolbar.manifest_ready_shipments.hidden = false;
      $grid.topToolbar.print_packing_lists.hidden = true;
  }
  else if ($utils.isDefined($grid.inParams.printerIdPackinglist)) {
      $grid.topToolbar.manifest_ready_shipments.hidden = true;
      $grid.topToolbar.print_packing_lists.hidden = false;
  }
  else {
      $grid.topToolbar.manifest_ready_shipments.hidden = false;
      $grid.topToolbar.print_packing_lists.hidden = true;
  }
  
  
  $grid.topToolbar.manifestcount.hidden = true;
  }
  on_manifest_ready_shipments_button_clicked(event = null) {
    return this.on_manifest_ready_shipments_button_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_manifest_ready_shipments_button_clickedInternal(
    $grid: Manifesting_shiptheory_manifesting_shipments_gridComponent,
  
    $shell: Manifesting_ShellService,
    $datasources: Manifesting_DatasourceService,
    $flows: Manifesting_FlowService,
    $reports: Manifesting_ReportService,
    $settings: SettingsValuesService,
    $operations: Manifesting_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manifesting_LocalizationService,
    $event: any
  ) {
  
  
  var rowCount = $grid.rows.length;
  var rows = $grid.rows
  
  
  var printerId = $grid.inParams.printerId;
  var utilizeShipmentContainer = $grid.inParams.utilizeShipmentContainer;
  var containerCount = $utils.isDefined($grid.inParams.containerCount) ? $grid.inParams.containerCount : 1;
  var weightInput = $grid.inParams.weight;
  var printerIdPackinglist = $grid.inParams.printerIdPackinglist;
  
  
  
  
  const candidates = [];
  const failures = [];
  const errors = [];
  for (const row of rows) {
  
      if (row.cells.status.displayControl.text === 'Ready') {
          candidates.push(row);
  
      } else {
          failures.push(row);
          errors.push(`Order ${row.entity.LookupCode} - Not Ready`);
      }
  
  
  }
  
  
  // no candidate
  if (candidates.length === 0) {
      const title = 'Manifesting errors';
      const errorMessage = `Order(s) ${failures.map(c => c.entity.LookupCode).join(',')} cannot be manifested`;
      const errorList = errors;
      await $shell.Manifesting.openErrorDialog(title, errorMessage, errorList);
      return;
  } else {
      const confirmCandidates = `Order(s) ${candidates.map(c => c.entity.LookupCode).join(',')} - Once manifested, tracking number, rate will be saved against the shipment and print request will be submitted.`
  
      let confirm = false;
      if (failures.length >= 1) {
          const title = 'Some order(s) cannot be manifested';
          const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
          confirm = await $shell.Manifesting.openConfirmationDialog(title, message);
      } else {
          const title = 'Manifest the following order(s)';
          const message = confirmCandidates;
          confirm = await $shell.Manifesting.openConfirmationDialog(title, message, 'Proceed');
      }
  
      if (confirm) {
          const processSuccess = [];
          const processFailures = [];
          const errorMsgList = [];
          const errorMsgListDetails = [];
  
          $grid.topToolbar.manifest_ready_shipments.hidden = true;
          $grid.topToolbar.manifestcount.hidden = false;
          $grid.topToolbar.manifestcount.control.icon = "icon datex-default-spinner";
          $grid.topToolbar.manifestcount.control.label = 'Preparing Manifest Data..';
          var totalManifestCount = candidates.length;
          var manifestCount = 0;
  
          for (const candidate of candidates) {
              try {
  
                  var orderLookup = candidate.entity.LookupCode;
                  manifestCount = manifestCount + 1;
                  $grid.topToolbar.manifestcount.control.label = `Manifesting order ${orderLookup}  ${manifestCount} of ${totalManifestCount} `
  
  
                  // Gather Shipment Data
                  const shipmentId = candidate.entity.Id;
                  const shippingContainerId = candidate.entity.ShippingContainers[0]?.Id;
  
                  var projectLookupcode = candidate.entity.OrderLookups[0]?.Order?.Project.LookupCode.substring(0, 3);
  
  
  
                  const shipment = (await $datasources.Manifesting.ds_get_shipment_details_for_manifesting_by_shipmentId.get({ shipmentId: shipmentId })).result[0];
                  if ($utils.isDefined(shipment)) {
                      var carrierId = shipment.CarrierId;
                      var carrier = shipment.Carrier.Name;
                      var carrierServiceName = shipment.CarrierServiceType?.Name;
                      var warehouseId = shipment.ExpectedWarehouseId;
                      var orderId = shipment.OrderLookups[0]?.OrderId;
                      var ownerId = shipment.OrderLookups[0]?.Order?.Project?.OwnerId;
  
                      var shipToAddress = (await $flows.Manifesting.get_ship_to_address_flow({ orderId: orderId, shipmentId: shipment.Id })).address;
  
                  }
  
                  var owner = candidate.entity.OrderLookups[0]?.Order?.Project?.Owner?.LookupCode;
  
                  var referenceNumber = candidate.entity.OrderLookups[0]?.Order?.OwnerReference ??  null;
  
  
                  const shipFromAddress = (await $flows.Manifesting.get_ship_from_address_flow({ orderId: orderId, shipmentId: shipment.Id })).address;
  
                  var parcel: any[] = [];
                  if (utilizeShipmentContainer === true) {
  
                      const shippingcontainers = (await $datasources.Manifesting.ds_get_shippingcontainers_by_shipmentId.get({ shipmentId: shipmentId })).result;
                      if ($utils.isDefined(shippingcontainers)) {
  
                          var containerWeight = $utils.isDefined(shippingcontainers[0].Weight) ? shippingcontainers[0].Weight : 1;
                          weightInput = containerWeight;
                      }
                  }
  
                  // Define products for shipping
                  let itemsContent = await $flows.ShipTheory.get_items_content({
  
                      shipmentId: shipmentId
  
                  });
                  const items = itemsContent.content;
  
  
  
  
                  $grid.topToolbar.manifestcount.control.label = `Calling ShipTheory for order ${orderLookup}  ${manifestCount} of ${totalManifestCount} `
  
  
                  const result = await $flows.ShipTheory.create_shipment_manifest({
                      referenceNumber: referenceNumber,
                      carrier: carrier,
                      service: carrierServiceName,
                      containerCount: containerCount,
                      weight: weightInput,
                      value: 1, // TO DO CALCULATE VALUE FROM ORDER LINE PRICE
                      fromAddress: {
                          "company": shipFromAddress.name,
                          "address_line_1": shipFromAddress.line1,
                          "city": shipFromAddress.city,
                          "postcode": shipFromAddress.postalCode,
                          "telephone": shipFromAddress.phone,
                          "country": shipFromAddress.country
                      },
                      toAddress: {
                          "company": shipToAddress.name,
                          "address_line_1": shipToAddress.line1,
                          "city": shipToAddress.city,
                          "postcode": shipToAddress.postalCode,
                          "telephone": shipToAddress.phone.replace(/\+/g, ""), // remove the + symbol if found
                          "country": shipToAddress.country,
                          "email": shipToAddress.email
                      },
  
                      products: items
                  });
  
                  const reason = result?.reason;
                  if ($utils.isDefined(reason)) {
                      processFailures.push(candidate);
  
                      const errorDescription = `Order ${candidate.entity.LookupCode} - ${reason}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription });
                      break;
  
                  }
  
  
                  // Update tracking data on shipment
                  const tracking = result.tracking;
  
                  // Get postage label data
                  if ($utils.isDefined(result.label)) {
                      var label = result.label;
                  }
  
  
                  let payloadShipment: any = {};
                  let payloadShippingContainer: any = {};
  
  
                  if ($utils.isDefined(tracking)) {
                      payloadShipment.TrackingIdentifier = tracking.toString();
                  }
  
                  if ($utils.isDefined(label)) {
                      payloadShippingContainer.NmfcNumber = label;
                  }
  
  
                  await $flows.Utilities.crud_update_flow({ entitySet: 'Shipments', id: shipmentId, entity: payloadShipment });
                  await $flows.Utilities.crud_update_flow({ entitySet: 'ShippingContainers', id: shippingContainerId, entity: payloadShippingContainer });
  
  
  
  
  
  
                  // PrintNode Printing
                  if ($utils.isAllDefined(label, printerId)) {
  
                      $grid.topToolbar.manifestcount.control.label = `Printing label using PrintNode for ${orderLookup}  ${manifestCount} of ${totalManifestCount} `
  
  
  
                      const flowPrintParams = {
                          contentType: "pdf_base64",
                          content: label,
                          printerId: printerId
                      }
  
                      const print = await $flows.PrintNode.print_request_flow(flowPrintParams);
                      const printJobId = print.printJobId;
  
                      let payloadOrder: any = {};
                      if ($utils.isDefined(printJobId)) {
                          payloadOrder.IntegrationHubSourcePlatform = printJobId.toString();
                      }
                      await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: orderId, entity: payloadOrder });
  
  
  
                  }
  
                  // Packing List
                  if ($utils.isDefined(printerIdPackinglist)) {
                      // Get Packing List content
                      let packinglistContent = await $flows.Manifesting.get_packinglist_content({
                          fileType: "pdf",
                          dataType: "base64",
                          parameters: {
                              orderId: candidate.entity.OrderLookups[0]?.OrderId
                          }
                      });
  
                      if (!$utils.isDefined(packinglistContent?.error)) {
                          // Print 
                          $grid.topToolbar.manifestcount.control.label = `Printing packing list using PrintNode for ${orderLookup}  ${manifestCount} of ${totalManifestCount} `
                          const flowPrintParams = {
                              contentType: "pdf_base64",
                              content: packinglistContent.contentString,
                              printerId: printerIdPackinglist
                          }
  
                          const print = await $flows.PrintNode.print_request_flow(flowPrintParams);
                      }
                      else {
                          console.log(`Failed to print packing list: ${packinglistContent.error}`);
                      }
                  }
  
  
                  processSuccess.push(candidate);
  
              } catch (error) {
                  processFailures.push(candidate);
                  const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                  const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                  const errorDescription = `Order ${candidate.entity.LookupCode} - ${errorMessage}`;
                  errorMsgList.push(errorDescription);
                  errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
  
              }
          }
          // all succeeded
          if (processSuccess.length === candidates.length) {
              const title = 'All order(s) manifested';
              const message = `Order(s) ${processSuccess.map(c => c.entity.LookupCode).join(',')} manifested`;
              await $shell.Manifesting.openInfoDialog(title, message);
              $grid.topToolbar.manifestcount.hidden = true;
              $grid.topToolbar.manifest_ready_shipments.hidden = false;
              await $grid.refresh();
          } else {
              // all failures
              if (processFailures.length === candidates.length) {
                  const title = 'All order(s) failed to manifest';
                  const message = `Order(s) ${processFailures.map(c => c.entity.LookupCode).join(',')} could not be manifested`;
                  $grid.topToolbar.manifestcount.hidden = true;
                  $grid.topToolbar.manifest_ready_shipments.hidden = false;
                  await $shell.Manifesting.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
              } else {
                  const title = 'Some order(s) could not be manifested';
                  const manifestedOrders = `Order(s) ${processSuccess.map(c => c.entity.LookupCode).join(',')} were manifested.`;
                  const errorOrders = `The following order(s) could not be manifested: ${processFailures.map(c => c.entity.LookupCode).join(',')}`;
                  const message = `${manifestedOrders} \r\n\r\n${errorOrders}`;
                  $grid.topToolbar.manifestcount.hidden = true;
                  $grid.topToolbar.manifest_ready_shipments.hidden = false;
                  await $shell.Manifesting.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  await $grid.refresh();
              }
          }
      }
  }
  
  }
  print_packing_lists(event = null) {
    return this.print_packing_listsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async print_packing_listsInternal(
    $grid: Manifesting_shiptheory_manifesting_shipments_gridComponent,
  
    $shell: Manifesting_ShellService,
    $datasources: Manifesting_DatasourceService,
    $flows: Manifesting_FlowService,
    $reports: Manifesting_ReportService,
    $settings: SettingsValuesService,
    $operations: Manifesting_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manifesting_LocalizationService,
    $event: any
  ) {
  for (let candidate of $grid.rows) {
      // Packing List
      if ($utils.isDefined($grid.inParams.printerIdPackinglist)) {
          // Get Packing List content
          let packinglistContent = await $flows.Manifesting.get_packinglist_content({
              fileType: "pdf",
              dataType: "base64",
              parameters: {
                  orderId: candidate.entity.OrderLookups[0]?.OrderId
              }
          });
  
          if (!$utils.isDefined(packinglistContent?.error)) {
              // Print 
              const flowPrintParams = {
                  contentType: "pdf_base64",
                  content: packinglistContent.contentString,
                  printerId: $grid.inParams.printerIdPackinglist
              }
  
              const print = await $flows.PrintNode.print_request_flow(flowPrintParams);
          }
          else {
              console.log(`Failed to print packing list: ${packinglistContent.error}`);
          }
      }
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
