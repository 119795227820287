import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { app_custom_vendor_order_lines_gridComponent } from './app.custom_vendor_order_lines_grid.component';
import { Invoices_currencies_dd_singleComponent } from './Invoices.currencies_dd_single.component'

type EntityType = { 
    Id?: number, AccountId?: number, BillingAddresId?: number, CurrencyId?: number, FulfillmentDate?: string, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredCarrierId?: number, PreferredCarrierServiceTypeId?: number, PreferredWarehouseId?: number, ProjectId?: number, ReadyToExport?: boolean, ReadyToExportBy?: string, ReadyToExportDate?: string, RequestedDeliveryDate?: string, VendorReference?: string, Project?: { OwnerId?: number }, OrderClass?: { Name?: string }, Status?: { Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { Id?: number, CarrierId?: number, ContainerIdentifier?: string, ConveyanceId?: number, ConveyenceReference?: string, Notes?: string, PaymentTermId?: number, PickupDate?: string, SealId?: string, TrailerId?: string } }[], OrderLines?: { LineNumber?: number }[], Account?: { Id?: number, LookupCode?: string, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => app_custom_vendor_order_lines_gridComponent),
    forwardRef(() => Invoices_currencies_dd_singleComponent),
  ],
  selector: 'app-custom_vendor_order_editor',
  templateUrl: './app.custom_vendor_order_editor.component.html'
})
export class app_custom_vendor_order_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { orderId: number } = { orderId: null };
  //#region Inputs
  @Input('orderId') set $inParams_orderId(v: number) {
    this.inParams.orderId = v;
  }
  get $inParams_orderId(): number {
    return this.inParams.orderId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { shipmentId?: number, billingAddressId?: number } = { };
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    vendor_reference: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    expected_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    fulfillment_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    authorized: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    completed: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    currency: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      on_complete: new ToolModel(new ButtonModel('on_complete', new ButtonStyles(null, null), false, false, false, 'Complete', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), true, false, false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    , false),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      attachments: new ToolModel(new ButtonModel('attachments', new ButtonStyles(null, null), false, false, false, ' ', 'icon-ic_fluent_attach_20_regular', null)
    , false),
      on_print: new ToolModel(new SplitButtonModel<{ purchase_order_report: ButtonModel }>(
        'on_print',
        new ButtonStyles(null, null),
        false,
        false,
        ' ',
        'icon-ic_fluent_print_20_regular',
        null,
        [
          new ButtonModel('purchase_order_report', new ButtonStyles(null, null), false, false, false, 'Purchase order report', '')
        ])
    , false)
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'PO number', true, false),
    vendor_reference: new FieldModel(new TextBoxModel(this.formGroup.controls['vendor_reference'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Supplier invoice no.', false, false),
    expected_date: new FieldModel(new DateBoxModel(this.formGroup.controls['expected_date'] as DatexFormControl, null, false, false, 'l, LT', 'datetime', null)
, new ControlContainerStyles(null, null), 'Due date', false, false),
    fulfillment_date: new FieldModel(new DateBoxModel(this.formGroup.controls['fulfillment_date'] as DatexFormControl, null, false, false, '', 'date', null)
, new ControlContainerStyles(null, null), 'Date received', false, false),
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Notes', false, false),
    authorized: new FieldModel(new CheckBoxModel(this.formGroup.controls['authorized'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Authorized', false, false),
    authorized_by: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), '', false, false),
    completed: new FieldModel(new CheckBoxModel(this.formGroup.controls['completed'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Completed', false, false),
    completed_by: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), '', false, false),
    ship_from_selection: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, false, false, 'Supplier', 'icon-ic_fluent_video_person_20_regular', null)
, new ControlContainerStyles(null, null), '', false, false),
    ship_from_clear: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, false, false, 'Clear', 'icon-ic_fluent_prohibited_20_regular', null)
, new ControlContainerStyles(null, null), '', false, false),
    ship_from: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), '', false, false),
    currency: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['currency'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Currency', false, false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Order', false, true, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      purchase_order_lines: new TabItemModel(
        this.rootTabGroup, 
        'Lines', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_purchase_order_lines_custom_vendor_order_lines_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_purchase_order_lines_custom_vendor_order_lines_grid_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
      
      return expr;
    }
  
    get $tabs_purchase_order_lines_custom_vendor_order_lines_grid_inParams_projectId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ProjectId;
      
      return expr;
    }
  
    get $tabs_purchase_order_lines_custom_vendor_order_lines_grid_inParams_orderStatusId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.OrderStatusId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_purchase_order_lines', { read: app_custom_vendor_order_lines_gridComponent }) $tabs_purchase_order_lines: app_custom_vendor_order_lines_gridComponent;
    //#endregion tabs children

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    vendor_reference: this.fields.vendor_reference.control.valueChanges
    ,
    expected_date: this.fields.expected_date.control.valueChanges
    ,
    fulfillment_date: this.fields.fulfillment_date.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
    authorized: this.fields.authorized.control.valueChanges
    ,
    completed: this.fields.completed.control.valueChanges
    ,
    currency: this.fields.currency.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: app_ShellService,
    private datasources: app_DatasourceService,
    private flows: app_FlowService,
    private reports: app_ReportService,
    private localization: app_LocalizationService,
    private operations: app_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.purchase_order_lines,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.orderId)) {
        this.$missingRequiredInParams.push('orderId');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Edit Vendor Order';
    
    await this.on_init();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      orderId:  $editor.inParams.orderId 
    };

    const data = await this.datasources.app.custom_ds_vendor_order_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.lookupcode.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.vendor_reference.control as TextBoxModel).reset($editor.entity.VendorReference);
    (this.fields.expected_date.control as DateBoxModel).reset($editor.entity.RequestedDeliveryDate);
    (this.fields.fulfillment_date.control as DateBoxModel).reset($editor.entity.FulfillmentDate);
    (this.fields.notes.control as TextBoxModel).reset($editor.entity.Notes);
    (this.fields.authorized.control as CheckBoxModel).reset($editor.entity.ReadyToExport);
    (this.fields.currency.control as SelectBoxModel).reset($editor.entity.CurrencyId);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_purchase_order_lines') {
        if (!isNil(this.$tabs_purchase_order_lines) && !this.tabs.purchase_order_lines.hidden && !this.tabs.purchase_order_lines.removed) {
          this.$tabs_purchase_order_lines.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .vendor_reference
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .expected_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .fulfillment_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .authorized
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_authorized();
      });
    this.$formGroupFieldValidationObservables
      .completed
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .currency
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
  }

  //#region private flows
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $editor: app_custom_vendor_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  
  const confirmation = await $shell.FootPrintManager.openConfirmationDialog('Delete Vendor Order', `Are you sure you want to delete order  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
  if (confirmation) {
      try {
  
          const result = await $flows.custom_delete_vendor_order_flow({ orderId: $editor.entity.Id, shipmentId: $editor.entity.ShipmentOrderLookups[0]?.ShipmentId });
          const reasons = result.reasons;
          var order = $editor.entity.LookupCode;
  
          if ($utils.isDefined(reasons)) {
              await $shell.FootPrintManager.openErrorDialog('Vendor order cannot be deleted.', `Order ${reasons.join(', ').replace(/, ([^,]*)$/, ', and $1')}`);
              return;
          }
          else {
  
  
              let title = `Vendor order ${order} deleted`;
  
              $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { timeOut: 5000, closeButton: true, positionClass: EToasterPosition.bottomRight });
  
  
              await $editor.close();
  
          }
  
      } catch (error) {
          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
          const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
          const errorDescription = `Order ${order} - ${errorMessage}`;
          await $shell.FootPrintManager.openErrorDialog('Order delete error', 'An error occurred during the deletion of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
      }
  }
  
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: app_custom_vendor_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  
  const confirmation = await $shell.FootPrintManager.openConfirmationDialog('Cancel Vendor Order', `Are you sure you want to cancel order  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
  if (confirmation) {
      try {
  
          const result = await $flows.custom_cancel_vendor_order_flow({ orderId: $editor.entity.Id, shipmentId: $editor.entity.ShipmentOrderLookups[0]?.ShipmentId });
          const reasons = result.reasons;
  
          if ($utils.isDefined(reasons)) {
              await $shell.FootPrintManager.openErrorDialog('Vendor order cannot be cancelled.', `Order ${reasons.join(', ').replace(/, ([^,]*)$/, ', and $1')}`);
              return;
          }
          else {
  
  
              let title = `Vendor order ${$editor.entity.LookupCode} cancelled`;
  
              $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { timeOut: 5000, closeButton: true, positionClass: EToasterPosition.bottomRight });
  
  
              await $editor.refresh();
              await $editor.tabs.purchase_order_lines.activate();
          }
  
      } catch (error) {
          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
          const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
          const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
          await $shell.FootPrintManager.openErrorDialog('Order cancel error', 'An error occurred during the cancellation of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
      }
  }
  
  }
  on_complete_clicked(event = null) {
    return this.on_complete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_complete_clickedInternal(
    $editor: app_custom_vendor_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  
  const confirmation = await $shell.FootPrintManager.openConfirmationDialog('Complete Vendor Order', `Are you sure you want to complete order  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
  if (confirmation) {
      try {
  
          const result = await $flows.custom_complete_vendor_order_flow({ orderId: $editor.entity.Id, shipmentId: $editor.entity.ShipmentOrderLookups[0]?.ShipmentId });
          const reasons = result.reasons;
  
          if ($utils.isDefined(reasons)) {
              await $shell.FootPrintManager.openErrorDialog('Vendor order cannot be completed.', `Order ${reasons.join(', ').replace(/, ([^,]*)$/, ', and $1')}`);
              return;
          }
          else {
  
  
              let title = `Vendor order ${$editor.entity.LookupCode} completed`;
  
              $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { timeOut: 5000, closeButton: true, positionClass: EToasterPosition.bottomRight });
  
  
              await $editor.refresh();
              await $editor.tabs.purchase_order_lines.activate();
          }
  
      } catch (error) {
          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
          const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
          const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
          await $shell.FootPrintManager.openErrorDialog('Order complete error', 'An error occurred during the completion of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
      }
  }
  
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: app_custom_vendor_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  // Shipment created if not found in the on_init flow
  
  // Set editor title
  $editor.title = `Vendor Order ${$editor.entity.LookupCode}`;
  
  var shipFrom = '';
  
  const orderAddress = (await $flows.Addresses.get_address_single_string_by_orderId({ orderId: $editor.inParams.orderId }));
  if ($utils.isDefined(orderAddress)) {
      shipFrom = orderAddress.address;
  
  }
  // Only display ship from if it is not an auto generated account
  if ($utils.isDefined(shipFrom)) {
      if (!shipFrom.startsWith('ord-')) {
  
          $editor.fields.ship_from.hidden = false;
          $editor.fields.ship_from.control.text = shipFrom;
          $editor.fields.ship_from_clear.hidden = false;
  
      } else {
          $editor.fields.ship_from.hidden = true;
          $editor.fields.ship_from.control.text = '';
          $editor.fields.ship_from_clear.hidden = true;
      }
  }
  else {
      $editor.fields.ship_from.hidden = true;
      $editor.fields.ship_from.control.text = '';
      $editor.fields.ship_from_clear.hidden = true;
  }
  
  // Set authorized fields
  if ($utils.isDefined($editor.entity.ReadyToExport) && $editor.entity.ReadyToExport) {
  
      const employee = $editor.entity.ReadyToExportBy;
      var authorized_date = $editor.entity.ReadyToExportDate;
      const format = `${$settings.DockAppointments.DateFormat}, ${$settings.DockAppointments.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
      if ($utils.isDefined(authorized_date)) {
          authorized_date = $utils.date.format(authorized_date, format);
      }
  
      $editor.fields.authorized_by.control.text = `${employee} ${authorized_date}`
  
      $editor.fields.authorized.control.readOnly = true;
  
  }
  else {
    
      $editor.fields.authorized_by.control.text = ''
      $editor.fields.authorized.control.readOnly = false;
  }
  
  
  
  
  // Fetch shipments
  const shipments = (await $datasources.PurchaseOrders.ds_get_shipments_by_orderId.get({ orderId: $editor.entity.Id })).result;
  
  if (shipments.length > 1) {
      // close
      await $shell.PurchaseOrders.openErrorDialog('Invalid order', 'This order has more than one shipment. Editor will close');
      $editor.close();
      return;
  }
  
  else {
  
  
  
      if (shipments.length === 1) {
          $editor.vars.shipmentId = shipments[0].ShipmentId;
      }
  
  
      // created status
      if ($editor.entity.OrderStatusId === 1) {
          $editor.toolbar.cancel.hidden = false;
          $editor.toolbar.cancel.control.readOnly = false;
          $editor.toolbar.on_delete.hidden = false;
          $editor.toolbar.on_delete.control.readOnly = false;
          $editor.toolbar.on_complete.hidden = false;
          $editor.toolbar.on_complete.control.readOnly = false;
  
          $editor.fields.completed.control.readOnly = true;
  
  
      } else if ($editor.entity.OrderStatusId === 8) {
          // cancelled status
  
  
          $editor.toolbar.cancel.hidden = true;
          $editor.toolbar.cancel.control.readOnly = true;
  
          $editor.toolbar.on_delete.hidden = true;
          $editor.toolbar.on_delete.control.readOnly = true;
  
          $editor.toolbar.on_complete.hidden = true;
          $editor.toolbar.on_complete.control.readOnly = true;
  
          $editor.fields.completed.control.readOnly = true;
  
  
  
      } else if ($editor.entity.OrderStatusId === 4) {
          // completed status
  
  
          $editor.toolbar.cancel.hidden = true;
          $editor.toolbar.cancel.control.readOnly = true;
  
          $editor.toolbar.on_delete.hidden = true;
          $editor.toolbar.on_delete.control.readOnly = true;
  
          $editor.fields.completed.control.readOnly = true;
          $editor.fields.completed.control.value = true;
  
  
          $editor.toolbar.on_complete.hidden = true;
          $editor.toolbar.on_complete.control.readOnly = true;
  
  
          const shipment_confirmation = (await $datasources.custom_ds_get_shipment_confirmation_task_by_shipment_id.get({ shipment_id: $editor.entity.ShipmentOrderLookups[0]?.ShipmentId })).result;
          if ($utils.isDefined(shipment_confirmation)) {
  
              const employee = shipment_confirmation.Employee;
              var completed_date = shipment_confirmation.CompletedDateTime;
              const format = `${$settings.DockAppointments.DateFormat}, ${$settings.DockAppointments.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
              if ($utils.isDefined(completed_date)) {
                  completed_date = $utils.date.format(completed_date, format);
              }
  
              $editor.fields.completed_by.control.text = `${employee} ${completed_date}`
  
          }
  
  
  
  
  
      }
      else {
  
          $editor.toolbar.cancel.hidden = true;
          $editor.fields.completed.control.readOnly = true;
          $editor.toolbar.on_delete.hidden = true;
  
          $editor.toolbar.on_complete.hidden = true;
          $editor.toolbar.on_complete.control.readOnly = true;
  
  
          $editor.fields.lookupcode.control.readOnly = true;
  
          $editor.fields.vendor_reference.control.readOnly = true;
  
          $editor.fields.expected_date.control.readOnly = true;
  
  
      }
  }
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: app_custom_vendor_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  // Set Date/Time Formats
  $editor.fields.expected_date.control.format = `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  $editor.fields.fulfillment_date.control.format = `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  
  
  // Create shipment if none found
  const shipments = (await $datasources.PurchaseOrders.ds_get_shipments_by_orderId.get({ orderId: $editor.inParams.orderId })).result;
  if (!$utils.isDefined(shipments)) {
  
      const nextShipmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Shipment' })).nextId;
  
      const order = (await $datasources.PurchaseOrders.ds_get_order_by_orderId.get({ orderId: $editor.inParams.orderId })).result;
  
      if ($utils.isDefined(order)) {
  
          // create shipment order lookup
          const payload = {
              "OrderId": $editor.inParams.orderId,
              "Shipment": {
                  "Id": nextShipmentId,
                  "LookupCode": order.LookupCode,
                  "ExpectedWarehouseId": 1,
                  "ExpectedDate": order.RequestedDeliveryDate,
                  "Cartonized": false,
                  "ConfirmSeal": false,
                  "StatusId": 1,
                  "Transhipment": false,
                  "TypeId": 1
              }
          }
  
          await $flows.Utilities.crud_create_flow({ entitySet: 'ShipmentOrderLookup', entity: payload });
          $editor.vars.shipmentId = nextShipmentId;
      }
  }
  
  // Set warehouse on order if blank and shipment contains expected warehouse
  const orders = (await $datasources.PurchaseOrders.ds_get_order_by_orderId.get({ orderId: $editor.inParams.orderId })).result;
  if (!$utils.isDefined(orders.PreferredWarehouseId) && $utils.isDefined(shipments[0]?.Shipment?.ExpectedWarehouseId)) {
  
      let payload: any = {};
  
      payload.PreferredWarehouseId = shipments[0]?.Shipment?.ExpectedWarehouseId;
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.inParams.orderId, entity: payload });
  }
  
  
  }
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $editor: app_custom_vendor_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  const allRequiredFieldHaveValues = $utils.isAllDefined(
      $editor.fields.lookupcode.control.value
  );
  
  if (allRequiredFieldHaveValues === false) {
      return;
  }
  
  
  var shipmentId = $editor.vars.shipmentId;
  
  
  let payload: any = {};
  
  if ($editor.fields.lookupcode.control.isChanged) {
      payload.LookupCode = $editor.fields.lookupcode.control.value;
  }
  
  
  if ($editor.fields.vendor_reference.control.isChanged) {
      payload.VendorReference = $editor.fields.vendor_reference.control.value;
  }
  
  
  if ($editor.fields.expected_date.control.isChanged) {
      payload.RequestedDeliveryDate = $editor.fields.expected_date.control.value;
  }
  if ($editor.fields.fulfillment_date.control.isChanged) {
      payload.FulfillmentDate = $editor.fields.fulfillment_date.control.value;
  }
  
  
  if ($editor.fields.notes.control.isChanged) {
      payload.Notes = $editor.fields.notes.control.value;
  }
  
  if ($editor.fields.currency.control.isChanged) {
      payload.CurrencyId = $editor.fields.currency.control.value;
  }
  
  
  
  await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
  
  // Create the shipment if it does not exist only if the order has a preferred warehouse of if the preferred warehouse is selected in the editor
  if (!$utils.isDefined(shipmentId)) {
  
      const nextShipmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Shipment' })).nextId;
  
      const order = (await $datasources.PurchaseOrders.ds_get_order_by_orderId.get({ orderId: $editor.inParams.orderId })).result;
  
  
  
      if ($utils.isDefined(order)) {
  
          // create shipment order lookup
          const payload = {
              "OrderId": $editor.inParams.orderId,
              "Shipment": {
                  "Id": nextShipmentId,
                  "LookupCode": order.LookupCode,
                  "ExpectedWarehouseId": 1,
                  "ExpectedDate": order.RequestedDeliveryDate,
                  "Cartonized": false,
                  "ConfirmSeal": false,
                  "StatusId": 1,
                  "Transhipment": false,
                  "TypeId": 1,
                  "Notes": $editor.fields.notes.control.value
              }
          }
          await $flows.Utilities.crud_create_flow({ entitySet: 'ShipmentOrderLookup', entity: payload });
  
          shipmentId = nextShipmentId;
          $editor.vars.shipmentId = nextShipmentId;
  
      }
  
  
  }
  
  if ($utils.isDefined(shipmentId)) {
  
      payload = {};
  
      if ($editor.fields.lookupcode.control.isChanged) {
          payload.LookupCode = $editor.fields.lookupcode.control.value;
      }
  
      if ($editor.fields.expected_date.control.isChanged) {
          payload.ExpectedDate = $editor.fields.expected_date.control.value;
      }
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'Shipments', id: shipmentId, entity: payload });
  }
  
  
  
  await $editor.refresh();
  }
  on_ship_from_clicked(event = null) {
    return this.on_ship_from_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_ship_from_clickedInternal(
    $editor: app_custom_vendor_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  const dialogResult = await $shell.FootPrintManager.openownersaccounts_gridDialog({
      ownerId: $editor.entity.Project.OwnerId,
      typeIds: [1, 3, 4, 5], // Billing, Operations, Residential, Commerical
      accountTypeId: 1, // Vendor
      allowSelection: true
  });
  
  const userConfirmed = dialogResult.confirm;
  const accountId = dialogResult.accountId;
  const addressId = dialogResult.addressId;
  
  if (userConfirmed && $utils.isAllDefined(accountId, addressId)) {
      let payload: any = {};
  
      payload.AccountId = accountId;
      payload.BillingAddresId = addressId;
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
  }
  // Always refresh as the user might have made some changes to an existing address
  await $editor.refresh();
  
  }
  on_ship_from_cleared(event = null) {
    return this.on_ship_from_clearedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_ship_from_clearedInternal(
    $editor: app_custom_vendor_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  let payload: any = {};
  
  payload.AccountId = null;
  payload.BillingAddresId = null;
  
  await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
  
  
  await $editor.refresh();
  
  }
  on_attachments_clicked(event = null) {
    return this.on_attachments_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_attachments_clickedInternal(
    $editor: app_custom_vendor_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  await $shell.FootPrintManager.openorders_attachments_hubDialog({ orderId: $editor.entity.Id, shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId});
  }
  on_purchase_order_report(event = null) {
    return this.on_purchase_order_reportInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_purchase_order_reportInternal(
    $editor: app_custom_vendor_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  const authorized = $editor.entity.ReadyToExport;
  
  if ($utils.isDefined(authorized) && authorized) {
      await $shell.PurchaseOrders.openpurchase_order_reportDialog({ orderId: $editor.inParams.orderId });
  }
  else {
      throw new Error('Vendor order should be authorized first.')
  }
  
  
  
  }
  on_authorized(event = null) {
    return this.on_authorizedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_authorizedInternal(
    $editor: app_custom_vendor_order_editorComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  let payload: any = {};
  
  if ($editor.fields.authorized.control.isChanged) {
  
      if (!$editor.fields.authorized.control.value) {
          payload.ReadyToExport = false;
          payload.ReadyToExportBy = null;
          payload.ReadyToExportDate = null
  
  
          const result = (await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload }));
          if ($utils.isDefined(result)) {
              const reason = result.reason;
              if ($utils.isDefined(reason)) {
                  throw new Error(reason)
              }
          }
          else {
              $editor.fields.authorized_by.control.text = '';
          }
      }
      else {
          await $flows.custom_authorize_vendor_order_flow({ orderId: $editor.inParams.orderId })
      }
  
      await $editor.refresh();
  }
  
  
  
  
  }
  //#endregion private flows
  
}
