import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { LaborManagement_operation_codes_dd_singleComponent } from './LaborManagement.operation_codes_dd_single.component'


interface IFootPrintManager_work_class_operation_codes_gridComponentEntity {
  Id?: number, Priority?: number, Name?: string}

interface IFootPrintManager_work_class_operation_codes_gridComponentInParams {
  workClassId: number, operationCodes?: any}

interface IFootPrintManager_work_class_operation_codes_gridComponentOutParams {
  operationCodes?: { id?: number, priority?: number, name?: string }[]}

class FootPrintManager_work_class_operation_codes_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_work_class_operation_codes_gridComponent;
  entity: IFootPrintManager_work_class_operation_codes_gridComponentEntity;

  vars: { isSelected?: boolean } = { };


 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    operation_code_name_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
  });

  override cells = {
    priority: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    operation_code_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['operation_code_name_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false,
        false,
        '', null)
      ),
  }

  cacheValueFor_$fields_operation_code_name_selector_inParams_excludeIds: number[];
  get $fields_operation_code_name_selector_inParams_excludeIds(): number[] {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.outParams.operationCodes.map(oc => oc.id);
    
    if(!isEqual(this.cacheValueFor_$fields_operation_code_name_selector_inParams_excludeIds, expr)) {
      this.cacheValueFor_$fields_operation_code_name_selector_inParams_excludeIds = expr;
    }
    return this.cacheValueFor_$fields_operation_code_name_selector_inParams_excludeIds;
  }


  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_work_class_operation_codes_gridComponent, entity: IFootPrintManager_work_class_operation_codes_gridComponentEntity) {
    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_work_class_operation_codes_gridComponent, entity?: IFootPrintManager_work_class_operation_codes_gridComponentEntity) {
    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_row_init();
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      operationCodes:  $grid.inParams.operationCodes ,
    };
    const data = await this.datasources.LaborManagement.ds_work_class_operation_codes_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootPrintManager_work_class_operation_codes_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.priority.displayControl as TextModel).text = $row.entity?.Priority?.toString();
    (this.cells.operation_code_name.displayControl as TextModel).text = $row.entity?.Name;
    (this.cells.operation_code_name.editControl as SelectBoxModel).reset($row.entity?.Id);

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_row();
      this.isNew = false;
    } else {
      await this.on_save_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_save_row(event = null) {
    return this.on_save_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_rowInternal(
    $row: FootPrintManager_work_class_operation_codes_gridComponentRowModel,
  $grid: FootPrintManager_work_class_operation_codes_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'work_class_operation_codes_grid.on_save_row');
  $row.cells.operation_code_name.displayControl.text = $row.cells.operation_code_name.editControl.displayText;
  
  $grid.update_outputs();
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_work_class_operation_codes_gridComponentRowModel,
  $grid: FootPrintManager_work_class_operation_codes_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'work_class_operation_codes_grid.on_row_data_loaded');
  $row.vars.isSelected = false;
  }
  on_row_init(event = null) {
    return this.on_row_initInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_initInternal(
    $row: FootPrintManager_work_class_operation_codes_gridComponentRowModel,
  $grid: FootPrintManager_work_class_operation_codes_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'work_class_operation_codes_grid.on_row_init');
  let maxPriority = (Math.max(...$grid.rows.map(row => Number(row.cells.priority.displayControl.text))));
  
  if (!isFinite(maxPriority)) { maxPriority = 0; }
  
  $row.cells.priority.displayControl.text = (maxPriority + 1).toString();
  $row.entity.Priority = maxPriority + 1;
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => LaborManagement_operation_codes_dd_singleComponent),
  ],
  selector: 'FootPrintManager-work_class_operation_codes_grid',
  templateUrl: './FootPrintManager.work_class_operation_codes_grid.component.html'
})
export class FootPrintManager_work_class_operation_codes_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_work_class_operation_codes_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);


  inParams: IFootPrintManager_work_class_operation_codes_gridComponentInParams = { workClassId: null, operationCodes: null };

  outParams: IFootPrintManager_work_class_operation_codes_gridComponentOutParams = { operationCodes: [] };

  //#region Variables
  vars: { suppressFlows?: boolean } = { };
  //#endregion
  //#region Events
  @Output()
  operationCodesInitialized = new EventEmitter<void>();
  @Output()
  outParamsChange = new EventEmitter<{ operationCodes?: { id?: number, priority?: number, name?: string }[] }>();
  
  events = {
    operationCodesInitialized: this.operationCodesInitialized,
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  headers = {
     priority: new GridHeaderModel(new HeaderStyles(null, null), 'priority', 'Priority', false, false, null, false),       operation_code_name: new GridHeaderModel(new HeaderStyles(null, null), 'operation_code_name', 'Operation', true, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_work_class_operation_codes_gridComponentRowModel[] = [];

  @Input('workClassId') set $inParams_workClassId(value: any) {
    this.inParams['workClassId'] = value;
  }
  get $inParams_workClassId(): any {
    return this.inParams['workClassId'] ;
  }
  @Input('operationCodes') set $inParams_operationCodes(value: any) {
    this.inParams['operationCodes'] = value;
  }
  get $inParams_operationCodes(): any {
    return this.inParams['operationCodes'] ;
  }

  topToolbar = {
      move_up: new ToolModel(new ButtonModel('move_up', new ButtonStyles(null, null), false, false, false, 'Raise priority', 'icon-ic_fluent_arrow_up_20_regular', null)
    , false),
      move_down: new ToolModel(new ButtonModel('move_down', new ButtonStyles(null, null), false, false, false, 'Lower priority', 'icon-ic_fluent_arrow_down_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      remove: new ToolModel(new ButtonModel('remove', new ButtonStyles(['destructive'], null), false, false, false, 'Remove', 'icon-ic_fluent_delete_20_regular', null)
    , false)
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, false, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Operation codes';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.workClassId)) {
        this.$missingRequiredInParams.push('workClassId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 50;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      operationCodes:  $grid.inParams.operationCodes ,
    };
    try {
      const data = await this.datasources.LaborManagement.ds_work_class_operation_codes_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootPrintManager_work_class_operation_codes_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_work_class_operation_codes_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push(row.$initializeExisting(this, entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootPrintManager_work_class_operation_codes_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  async addRow(entity?: IFootPrintManager_work_class_operation_codes_gridComponentEntity) {
    const row = new FootPrintManager_work_class_operation_codes_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations,
      this.logger);
    await row.$initializeNew(this, entity);
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_work_class_operation_codes_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'work_class_operation_codes_grid.on_init');
  $grid.vars.suppressFlows = false;
  
  $grid.events.operationCodesInitialized.emit();
  }
  on_remove_clicked(event = null) {
    return this.on_remove_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_remove_clickedInternal(
    $grid: FootPrintManager_work_class_operation_codes_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'work_class_operation_codes_grid.on_remove_clicked');
  for (let i = $grid.rows.length - 1; i >= 0; i--) {
      if ($grid.rows[i].selected) {
          $grid.rows.splice(i, 1);
      }
  }
  
  for (let i = 0; i < $grid.rows.length; i++) {
      $grid.rows[i].cells.priority.displayControl.text = (i + 1).toString();
  }
  
  $grid.update_outputs();
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: FootPrintManager_work_class_operation_codes_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'work_class_operation_codes_grid.on_data_loaded');
  $grid.update_outputs();
  
  $grid.rows.sort((a, b) => Number(a.cells.priority.displayControl.text) - Number(b.cells.priority.displayControl.text));
  }
  update_outputs(event = null) {
    return this.update_outputsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async update_outputsInternal(
    $grid: FootPrintManager_work_class_operation_codes_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'work_class_operation_codes_grid.update_outputs');
  $grid.outParams.operationCodes = $grid.rows.map(row => ({ id: row.cells.operation_code_name.editControl.value, priority: Number(row.entity.Priority), name: row.cells.operation_code_name.displayControl.text }));
  $grid.events.outParamsChange.emit();
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_work_class_operation_codes_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'work_class_operation_codes_grid.on_row_selected');
  // REMOVED (TO REVIEW): BROKEN BY GAMMA UPDATE
  // if (!$grid.vars.suppressFlows) {
  //     $grid.vars.suppressFlows = true;
  
  //     for (let row of $grid.rows) {
  //         if (row.selected) {
  //             if (row.vars.isSelected) {
  //                 row.selected = false;
  //                 row.vars.isSelected = false;
  //             }
  //             else {
  //                 row.vars.isSelected = true;
  //             }
  //         }
  //         else {
  //             row.vars.isSelected = false;
  //         }
  //     }
  
  //     $grid.vars.suppressFlows = false;
  // }
  
  if ($grid.selectedRows.length === 1) {
      $grid.topToolbar.move_down.control.readOnly = false;
      $grid.topToolbar.move_up.control.readOnly = false;
      $grid.topToolbar.remove.control.readOnly = false;
  }
  else {
      $grid.topToolbar.move_down.control.readOnly = true;
      $grid.topToolbar.move_up.control.readOnly = true;
      $grid.topToolbar.remove.control.readOnly = true;
  }
  
  }
  on_move_up_clicked(event = null) {
    return this.on_move_up_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_move_up_clickedInternal(
    $grid: FootPrintManager_work_class_operation_codes_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'work_class_operation_codes_grid.on_move_up_clicked');
  if (!$utils.isDefined($grid.selectedRows.find(row => Number(row.cells.priority.displayControl.text) === 1))) {
      for (let row of $grid.selectedRows) {
          let oldPriority = Number(row.cells.priority.displayControl.text)
          let newPriority = oldPriority - 1;
  
          let rowWithOldPriority = $grid.rows.find(r => Number(r.cells.priority.displayControl.text) === oldPriority && r.cells.operation_code_name.editControl.value !== row.cells.operation_code_name.editControl.value);
  
          let rowWithNewPriority = $grid.rows.find(r => Number(r.cells.priority.displayControl.text) === newPriority && r.cells.operation_code_name.editControl.value !== row.cells.operation_code_name.editControl.value);
  
          row.cells.priority.displayControl.text = newPriority.toString();
          row.entity.Priority = newPriority;
  
          // if ($utils.isDefined(rowWithOldPriority)) {
          //     // Update to new priority
          //     row.cells.priority.displayControl.text = newPriority.toString();
          // }
          // else if ($utils.isDefined(rowWithNewPriority)) {
          //     // Swap
          //     row.cells.priority.displayControl.text = newPriority.toString();
          //     //rowWithNewPriority.cells.priority.displayControl.text = oldPriority.toString();
          // }
      }
  
      $grid.rows.sort((a, b) => Number(a.cells.priority.displayControl.text) - Number(b.cells.priority.displayControl.text));
  
      $grid.update_outputs();
  }
  
  
  
  }
  on_move_down_clicked(event = null) {
    return this.on_move_down_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_move_down_clickedInternal(
    $grid: FootPrintManager_work_class_operation_codes_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'work_class_operation_codes_grid.on_move_down_clicked');
  for (let row of $grid.selectedRows) {
      let oldPriority = Number(row.cells.priority.displayControl.text)
      let newPriority = oldPriority + 1;
  
      let rowWithOldPriority = $grid.rows.find(r => Number(r.cells.priority.displayControl.text) === oldPriority && r.cells.operation_code_name.editControl.value !== row.cells.operation_code_name.editControl.value);
      let rowWithNewPriority = $grid.rows.find(r => Number(r.cells.priority.displayControl.text) === newPriority && r.cells.operation_code_name.editControl.value !== row.cells.operation_code_name.editControl.value);
  
      row.cells.priority.displayControl.text = newPriority.toString();
      row.entity.Priority = newPriority;
  }
  
  $grid.rows.sort((a, b) => Number(a.cells.priority.displayControl.text) - Number(b.cells.priority.displayControl.text));
  
  $grid.update_outputs();
  
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
