import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootPrintManager_shipping_container_tasks_gridComponent } from './FootPrintManager.shipping_container_tasks_grid.component';
import { PackVerification_containertypes_dd_singleComponent } from './PackVerification.containertypes_dd_single.component'
import { PackVerification_measurementunits_dd_singleComponent } from './PackVerification.measurementunits_dd_single.component'

type EntityType = { 
    Id?: number, Height?: number, Length?: number, LookupCode?: string, TrackingNumber?: string, TrailerNumber?: string, Volume?: number, Weight?: number, Width?: number, LicensePlates?: { Id?: number, Archived?: boolean, LocationId?: number, WarehouseId?: number }[], Shipment?: { Id?: number, ActualWarehouseId?: number, LookupCode?: string, TrailerId?: string, OrderLookups?: { CreatedSysDateTime?: string, Order?: { Id?: number, LookupCode?: string, Project?: { Id?: number, LookupCode?: string, Owner?: { Id?: number, LookupCode?: string } }, Account?: { Id?: number, LookupCode?: string }, PreferredCarrier?: { Id?: number, Name?: string }, PreferredCarrierServiceType?: { Id?: number, Name?: string } } }[], Carrier?: { Id?: number, Name?: string }, CarrierServiceType?: { Id?: number, Name?: string } }, ContainerType?: { Id?: number, Name?: string }, DimensionUom?: { Id?: number, Name?: string }, WeightUom?: { Id?: number, Name?: string }, VolumeUom?: { Id?: number, Name?: string }}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_shipping_container_tasks_gridComponent),
    forwardRef(() => PackVerification_containertypes_dd_singleComponent),
    forwardRef(() => PackVerification_measurementunits_dd_singleComponent),
  ],
  selector: 'FootPrintManager-shipping_container_editor',
  templateUrl: './FootPrintManager.shipping_container_editor.component.html'
})
export class FootPrintManager_shipping_container_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { shippingContainerId: number } = { shippingContainerId: null };
  //#region Inputs
  @Input('shippingContainerId') set $inParams_shippingContainerId(v: number) {
    this.inParams.shippingContainerId = v;
  }
  get $inParams_shippingContainerId(): number {
    return this.inParams.shippingContainerId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { currentContainerId?: number, currentDimensionUomId?: number, currentWeightUomId?: number, currentVolumeUomId?: number, initial_values?: { is_pack_verified: boolean } } = { };
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    container_code: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    container_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    weight: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    weight_uom: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    length: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    width: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    height: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    dimensions_uom: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    volume: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    volume_uom: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    trailer_number: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    tracking_number: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    is_pack_verified: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, false, false, 'Confirm', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, false, false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      open_pack_verification: new ToolModel(new ButtonModel('open_pack_verification', new ButtonStyles(null, null), false, false, false, 'Pack verification', 'icon-ic_fluent_barcode_scanner_20_regular', null)
    , false),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      print: new ToolModel(new SplitButtonModel<{ packing_slip: ButtonModel, shipping_label: ButtonModel }>(
        'print',
        new ButtonStyles(null, null),
        false,
        false,
        ' ',
        'icon-ic_fluent_print_20_regular',
        null,
        [
          new ButtonModel('packing_slip', new ButtonStyles(null, null), false, false, false, 'Packing slip', ''),
          new ButtonModel('shipping_label', new ButtonStyles(null, null), false, false, false, 'Shipping label', '')
        ])
    , false),
      separator3: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      surveys: new ToolModel(new ButtonModel('surveys', new ButtonStyles(null, null), false, false, false, 'Surveys', 'icon-ic_fluent_shifts_availability_20_regular', null)
    , false)
  };

  fields = {
    container_code: new FieldModel(new TextBoxModel(this.formGroup.controls['container_code'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Container code', true, false),
    container_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['container_type'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Container type', false, false),
    weight: new FieldModel(new NumberBoxModel(this.formGroup.controls['weight'] as DatexFormControl, null, false, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Weight', false, false),
    weight_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['weight_uom'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'UOM', false, false),
    length: new FieldModel(new NumberBoxModel(this.formGroup.controls['length'] as DatexFormControl, null, false, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Length', false, false),
    width: new FieldModel(new NumberBoxModel(this.formGroup.controls['width'] as DatexFormControl, null, false, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Width', false, false),
    height: new FieldModel(new NumberBoxModel(this.formGroup.controls['height'] as DatexFormControl, null, false, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Height', false, false),
    dimensions_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['dimensions_uom'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'UOM', false, false),
    volume: new FieldModel(new NumberBoxModel(this.formGroup.controls['volume'] as DatexFormControl, null, false, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Volume', false, false),
    volume_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['volume_uom'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'UOM', false, false),
    carrier: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Carrier', false, false),
    carrier_service_type: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Carier service type', false, false),
    trailer_number: new FieldModel(new TextBoxModel(this.formGroup.controls['trailer_number'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Trailer number', false, false),
    tracking_number: new FieldModel(new TextBoxModel(this.formGroup.controls['tracking_number'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Tracking number', false, false),
    is_pack_verified: new FieldModel(new CheckBoxModel(this.formGroup.controls['is_pack_verified'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), 'Pack verified', false, false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('General information', true, false, true, false),
  newGroup2: new FieldsetModel('Container details', false, true, false, false),
  newGroup3: new FieldsetModel('Shipping details', false, true, false, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      tasks: new TabItemModel(
        this.rootTabGroup, 
        'Tasks', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_tasks_shipping_container_tasks_grid_inParams_shippingContainerId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.shippingContainerId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_tasks', { read: FootPrintManager_shipping_container_tasks_gridComponent }) $tabs_tasks: FootPrintManager_shipping_container_tasks_gridComponent;
    //#endregion tabs children

  //#region fields inParams
  get $fields_weight_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 3;
    
    return expr;
  }

  get $fields_dimensions_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_volume_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 2;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    container_code: this.fields.container_code.control.valueChanges
    ,
    container_type: this.fields.container_type.control.valueChanges
    ,
    weight: this.fields.weight.control.valueChanges
    ,
    weight_uom: this.fields.weight_uom.control.valueChanges
    ,
    length: this.fields.length.control.valueChanges
    ,
    width: this.fields.width.control.valueChanges
    ,
    height: this.fields.height.control.valueChanges
    ,
    dimensions_uom: this.fields.dimensions_uom.control.valueChanges
    ,
    volume: this.fields.volume.control.valueChanges
    ,
    volume_uom: this.fields.volume_uom.control.valueChanges
    ,
    trailer_number: this.fields.trailer_number.control.valueChanges
    ,
    tracking_number: this.fields.tracking_number.control.valueChanges
    ,
    is_pack_verified: this.fields.is_pack_verified.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.tasks,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.shippingContainerId)) {
        this.$missingRequiredInParams.push('shippingContainerId');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Container';
    

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      shippingContainerId:  $editor.inParams.shippingContainerId 
    };

    const data = await this.datasources.PackVerification.ds_get_shippingcontainer_by_shippingContainerId.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.container_code.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.container_type.control as SelectBoxModel).reset($editor.entity.ContainerType?.Id);
    (this.fields.weight.control as NumberBoxModel).reset($editor.entity.Weight);
    (this.fields.weight_uom.control as SelectBoxModel).reset($editor.entity.WeightUom?.Id);
    (this.fields.length.control as NumberBoxModel).reset($editor.entity.Length);
    (this.fields.width.control as NumberBoxModel).reset($editor.entity.Width);
    (this.fields.height.control as NumberBoxModel).reset($editor.entity.Height);
    (this.fields.dimensions_uom.control as SelectBoxModel).reset($editor.entity.DimensionUom?.Id);
    (this.fields.volume.control as NumberBoxModel).reset($editor.entity.Volume);
    (this.fields.volume_uom.control as SelectBoxModel).reset($editor.entity.VolumeUom?.Id);
    (this.fields.carrier.control as TextModel).text = $editor.entity?.Shipment?.Carrier?.Name ?? $editor.entity?.Shipment?.OrderLookups[0]?.Order?.PreferredCarrier?.Name;
    (this.fields.carrier_service_type.control as TextModel).text = $editor.entity?.Shipment?.CarrierServiceType?.Name ?? $editor.entity?.Shipment?.OrderLookups[0]?.Order?.PreferredCarrierServiceType?.Name;
    (this.fields.trailer_number.control as TextBoxModel).reset($editor.entity.TrailerNumber);
    (this.fields.tracking_number.control as TextBoxModel).reset($editor.entity.TrackingNumber);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_tasks') {
        if (!isNil(this.$tabs_tasks) && !this.tabs.tasks.hidden && !this.tabs.tasks.removed) {
          this.$tabs_tasks.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .container_code
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .container_type
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_container_type_changed();
      });
    this.$formGroupFieldValidationObservables
      .weight
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .weight_uom
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_weight_uom_changed();
      });
    this.$formGroupFieldValidationObservables
      .length
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .width
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .height
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .dimensions_uom
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_dimensions_uom_changed();
      });
    this.$formGroupFieldValidationObservables
      .volume
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .volume_uom
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_volume_uom_changed();
      });
    this.$formGroupFieldValidationObservables
      .trailer_number
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .tracking_number
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .is_pack_verified
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_shipping_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'shipping_container_editor.on_data_loaded');
  $editor.vars.currentContainerId = $editor.fields.container_type.control.value;
  $editor.vars.currentDimensionUomId = $editor.fields.dimensions_uom.control.value;
  $editor.vars.currentWeightUomId = $editor.fields.weight_uom.control.value;
  $editor.vars.currentVolumeUomId = $editor.fields.volume_uom.control.value;
  
  $editor.title = `Container ${$editor.entity.LookupCode}`;
  
  // Check if pack verified
  $editor.fields.is_pack_verified.control.readOnly = true;
  let hasReleasedTasks = false;
  let isArchived = false;
  if ($utils.isDefined($editor.entity.LicensePlates)) {
      let promises: Promise<Awaited<ReturnType<typeof $datasources.PackVerification.ds_get_picking_tasks_by_licenseplateId.get>>>[] = [];
      for (const lp of $editor.entity.LicensePlates) {
          promises.push($datasources.PackVerification.ds_get_picking_tasks_by_licenseplateId.get({ licenseplateId: lp.Id }));
      }
  
      const results = await Promise.all(promises);
  
      for (let result of results) {
          if (result.result.some(r => [1,4].includes(r.StatusId))) {
              hasReleasedTasks = true;
          }
      }
  
      isArchived = !$editor.entity.LicensePlates?.some(lp => !lp.Archived);
  }
  
  const verificationTasks = (await $datasources.PackVerification.ds_get_start_pack_verification_tasks_by_shippingContainerId.getList({shippingContainerId: $editor.entity.Id })).result;
  const isPackVerified = verificationTasks?.some(t => t.Status?.Id == 2 && t.Undone !== true);
  
  // Check if eligible for pack verification
  const canPackVerify = $utils.isDefined($editor.entity.LicensePlates) &&
      !hasReleasedTasks &&
      true //!isArchived
      
  
  $editor.fields.is_pack_verified.control.value = isPackVerified;
  $editor.fields.is_pack_verified.control.readOnly = !isPackVerified;
  $editor.toolbar.open_pack_verification.hidden = !canPackVerify;
  
  
  // Cache initial values
  $editor.vars.initial_values = {
      is_pack_verified: isPackVerified
  };
  
  }
  on_container_type_changed(event = null) {
    return this.on_container_type_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_container_type_changedInternal(
    $editor: FootPrintManager_shipping_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'shipping_container_editor.on_container_type_changed');
  try {
      let length: number = $editor.fields.length.control.value ?? 0;
      let width: number = $editor.fields.width.control.value ?? 0;
      let height: number = $editor.fields.height.control.value ?? 0;
      let dimensionUomId: number = $editor.fields.dimensions_uom.control.value;
      let volume: number = $editor.fields.volume.control.value ?? 0;
      let volumeUomId: number = $editor.fields.volume_uom.control.value;
      let weight: number = $editor.fields.weight.control.value ?? 0;
      let weightUomId: number = $editor.fields.weight_uom.control.value;
  
      let oldContainerWeight = 0;
      if ($utils.isDefined($editor.vars.currentContainerId)) {
          oldContainerWeight = (await $datasources.PackVerification.ds_get_container_type_by_containerTypeId.get({ containerTypeId: $editor.vars.currentContainerId })).result?.Weight ?? 0;
      }
  
      // Get shipping container type
      if ($utils.isDefined($editor.fields.container_type.control.value)) {
          let containerType = (await $datasources.PackVerification.ds_get_container_type_by_containerTypeId.get({ containerTypeId: $editor.fields.container_type.control.value })).result;
  
          if (!$utils.isDefined(containerType)) {
              throw new Error(`Container type with ID '${$editor.fields.container_type.control.value}' was not found.`);
          }
  
          // Update dimensions
          length = containerType.Length;
          width = containerType.Width;
          height = containerType.Height;
          dimensionUomId = containerType.DimensionUomId;
          volume = length * width * height;
          volumeUomId = containerType.VolumeUomId;
  
          // Convert volume if dimension and volume UOM aren't the same measurement
          let toUom = volumeUomId;
          let fromUom: number;
  
          switch (dimensionUomId) {
              case 2: { // Meter
                  fromUom = 6;
                  break;
              }
              case 3: { // Centimeter
                  fromUom = 7;
                  break;
              }
              case 4: { // Foot
                  fromUom = 8;
                  break;
              }
              case 5: { // Inch
                  fromUom = 11;
                  break;
              }
          }
  
          if (fromUom !== toUom) {
              volume = (await $flows.Utilities.convert_volume_flow({ inputVolume: volume, fromUom: fromUom, toUom: toUom })).convertedVolume;
          }
  
          // Update weight
          weight -= oldContainerWeight; // Remove old container's weight
          if ($utils.isDefined(weightUomId)) {
              let weightConvertResult = (await $flows.Utilities.convert_weight_flow({ inputWeight: weight, fromUom: weightUomId, toUom: containerType.WeightUomId }));
  
              if ($utils.isDefined(weightConvertResult.reasons)) {
                  throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: weightConvertResult.reasons })).formattedValue);
              }
  
              weight = weightConvertResult.convertedWeight;
          }
  
          weightUomId = containerType.WeightUomId;
          weight += containerType.Weight;
  
          if (weight < 0) { weight = 0; }
  
      }
      else {
          weight -= oldContainerWeight;
      }
  
      $editor.fields.length.control.value = length;
      $editor.fields.width.control.value = width;
      $editor.fields.height.control.value = height;
      $editor.fields.dimensions_uom.control.value = dimensionUomId;
      $editor.fields.volume.control.value = volume;
      $editor.fields.volume_uom.control.value = volumeUomId;
      $editor.fields.weight.control.value = weight;
      $editor.fields.weight_uom.control.value = weightUomId;
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog('Error updating shipping container', targetError.message);
  }
  
  $editor.vars.currentContainerId = $editor.fields.container_type.control.value;
  $editor.vars.currentDimensionUomId = $editor.fields.dimensions_uom.control.value;
  $editor.vars.currentWeightUomId = $editor.fields.weight_uom.control.value;
  $editor.vars.currentVolumeUomId = $editor.fields.volume_uom.control.value;
  }
  on_dimensions_uom_changed(event = null) {
    return this.on_dimensions_uom_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_dimensions_uom_changedInternal(
    $editor: FootPrintManager_shipping_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'shipping_container_editor.on_dimensions_uom_changed');
  if ($utils.isDefined($editor.fields.dimensions_uom.control.value)) {
      // Convert the existing dimension values to the new UOM
      $editor.fields.length.control.value = (await $flows.Utilities.convert_distance_flow({ inputDistance: $editor.fields.length.control.value, fromUom: $editor.vars.currentDimensionUomId, toUom: $editor.fields.dimensions_uom.control.value})).convertedDistance;
      $editor.fields.width.control.value = (await $flows.Utilities.convert_distance_flow({ inputDistance: $editor.fields.width.control.value, fromUom: $editor.vars.currentDimensionUomId, toUom: $editor.fields.dimensions_uom.control.value})).convertedDistance;
      $editor.fields.height.control.value = (await $flows.Utilities.convert_distance_flow({ inputDistance: $editor.fields.height.control.value, fromUom: $editor.vars.currentDimensionUomId, toUom: $editor.fields.dimensions_uom.control.value})).convertedDistance;
  
      $editor.vars.currentDimensionUomId = $editor.fields.dimensions_uom.control.value;
  }
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $editor: FootPrintManager_shipping_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'shipping_container_editor.on_confirm_clicked');
  if ($editor.fields.is_pack_verified.control.value === false && $editor.vars.initial_values.is_pack_verified === true) {
      const undoResult = (await $flows.PackVerification.undo_pack_verification_for_shipping_container_flow({ shipping_container_id: $editor.entity.Id }));
  
      if ($utils.isDefined(undoResult.reasons)) {
          $shell.FootPrintManager.openToaster(`Error undoing pack verification`, undoResult.reasons.join(', '), EToasterType.Error, { positionClass: EToasterPosition.bottomRight, closeButton: true, disableTimeOut: true });
          return; 
      }
  }
  
  // Update typeId and dimensions
  await $flows.Utilities.crud_update_flow({
      entitySet: 'ShippingContainers',
      id: $editor.entity.Id,
      entity: {
          ContainerTypeId: $editor.fields.container_type.control.value,
          Length: $editor.fields.length.control.value,
          Width: $editor.fields.width.control.value,
          Height: $editor.fields.height.control.value,
          DimensionUomId: $editor.fields.dimensions_uom.control.value,
          Weight: $editor.fields.weight.control.value,
          WeightUomId: $editor.fields.weight_uom.control.value,
          TrailerNumber: $editor.fields.trailer_number.control.value,
          TrackingNumber: $editor.fields.tracking_number.control.value,
          Volume: $editor.fields.volume.control.value,
          VolumeUomId: $editor.fields.volume_uom.control.value
      }
  });
  
  $editor.close();
  }
  on_weight_uom_changed(event = null) {
    return this.on_weight_uom_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_weight_uom_changedInternal(
    $editor: FootPrintManager_shipping_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'shipping_container_editor.on_weight_uom_changed');
  if ($utils.isDefined($editor.fields.weight_uom.control.value)) {
      if ($utils.isDefined($editor.vars.currentWeightUomId)) {
          // Convert the existing weight values to the new UOM
          $editor.fields.weight.control.value = (await $flows.Utilities.convert_weight_flow({ inputWeight: $editor.fields.weight.control.value, fromUom: $editor.vars.currentWeightUomId, toUom: $editor.fields.weight_uom.control.value })).convertedWeight;
      }
      $editor.vars.currentWeightUomId = $editor.fields.weight_uom.control.value;
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: FootPrintManager_shipping_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'shipping_container_editor.on_cancel_clicked');
  $editor.close();
  }
  on_volume_uom_changed(event = null) {
    return this.on_volume_uom_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_volume_uom_changedInternal(
    $editor: FootPrintManager_shipping_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'shipping_container_editor.on_volume_uom_changed');
  if ($utils.isDefined($editor.fields.volume_uom.control.value)) {
      if ($utils.isDefined($editor.vars.currentVolumeUomId)) {
          // Convert the existing volume value to the new UOM
          $editor.fields.volume.control.value = (await $flows.Utilities.convert_volume_flow({ inputVolume: $editor.fields.volume.control.value, fromUom: $editor.vars.currentVolumeUomId, toUom: $editor.fields.volume_uom.control.value })).convertedVolume;
      }
      $editor.vars.currentVolumeUomId = $editor.fields.volume_uom.control.value;
  }
  }
  on_surveys_clicked(event = null) {
    return this.on_surveys_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_surveys_clickedInternal(
    $editor: FootPrintManager_shipping_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'shipping_container_editor.on_surveys_clicked');
  
  await $shell.FootPrintManager.opensubmitted_surveys_gridDialog({
      entities: [{ type: 'ShippingContainer', ids: [$editor.entity.Id] }],
      operationContextTypes: null,
      warehouseIds: null
  }, 'modal');
  
  
  }
  on_print_packing_slip_clicked(event = null) {
    return this.on_print_packing_slip_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_packing_slip_clickedInternal(
    $editor: FootPrintManager_shipping_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'shipping_container_editor.on_print_packing_slip_clicked');
  
  $reports.ShippingContainers.packing_slip_report.print({ shippingContainerId: $editor.entity.Id });
  }
  on_print_shipping_label_clicked(event = null) {
    return this.on_print_shipping_label_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_shipping_label_clickedInternal(
    $editor: FootPrintManager_shipping_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'shipping_container_editor.on_print_shipping_label_clicked');
  const reportData = (await $flows.ShippingContainers.get_shipping_label_report_flow({ shipping_container_id: $editor.entity.Id })).reportData;
  
  await $shell.FootPrintManager.openshipping_label_contentDialog({ url: await $utils.blob.toBase64(reportData, true)}, 'modal', EModalSize.Xlarge);
  }
  open_pack_verification_clicked(event = null) {
    return this.open_pack_verification_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async open_pack_verification_clickedInternal(
    $editor: FootPrintManager_shipping_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'shipping_container_editor.open_pack_verification_clicked');
  if ($editor.vars.initial_values.is_pack_verified) {
      await $shell.FootPrintManager.openpack_verification_wizardDialog({
          shippingContainerId: $editor.entity.Id,
          isBlind: false
      });
  } else {
      await $shell.FootPrintManager.openselect_pack_verification_location_formDialog({ shipping_container_id: $editor.entity.Id, warehouse_id: $editor.entity.LicensePlates[0]?.WarehouseId });
  }
  
  await $editor.refresh();
  }
  //#endregion private flows
  
}
