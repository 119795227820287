import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Invoices_billingcodes_dd_singleComponent } from './Invoices.billingcodes_dd_single.component'
import { Invoices_billingcodes_dd_multiComponent } from './Invoices.billingcodes_dd_multi.component'
import { Invoices_operation_codes_dd_multiComponent } from './Invoices.operation_codes_dd_multi.component'


import { FootPrintManager_billing_records_by_invoiceline_gridComponent } from './FootPrintManager.billing_records_by_invoiceline_grid.component';

interface IFootPrintManager_invoice_lines_gridComponentEntity {
  Id?: number, BillingCodeId?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, Description?: string, InvoiceId?: number, LineNumber?: number, LineTotal?: number, Notes?: string, Quantity?: number, UnitPrice?: number, BillingCode?: { Name?: string }, BillingRecords?: { BillingTaskId?: number, BillingTask?: { Id?: number, OperationCode?: { Id?: number, Name?: string } } }[]}

interface IFootPrintManager_invoice_lines_gridComponentInParams {
  invoiceId: number, invoiceTypeId?: number, open_to_line?: number}


class FootPrintManager_invoice_lines_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_invoice_lines_gridComponent;
  entity: IFootPrintManager_invoice_lines_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    billing_code_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    amount_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    unit_price_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    notes_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    description_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    linenumber: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    billing_code: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['billing_code_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      ),
    amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['amount_edit'] as DatexFormControl, null, false, false, '', '', null)
      ),
    unit_price: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['unit_price_edit'] as DatexFormControl, null, false, false, '0.00', '', null)
      ),
    line_total: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    billing_record_count: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['notes_edit'] as DatexFormControl, null, false, false, '', null)
      ),
    description: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['description_edit'] as DatexFormControl, null, false, false, '', null)
      ),
    operation_code: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }


  //#region rowExpand inParams


  get $rowExpand_FootPrintManager_billing_records_by_invoiceline_grid_inParams_invoiceLineId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.entity.Id;
    
    return expr;
  }
  //#endregion rowExpand inParams

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_invoice_lines_gridComponent, entity: IFootPrintManager_invoice_lines_gridComponentEntity) {
    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_invoice_lines_gridComponent, entity?: IFootPrintManager_invoice_lines_gridComponentEntity) {
    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_init_new_row();
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      invoiceId:  $grid.inParams.invoiceId ,
      fullTextSearch:  $grid.fullTextSearch ,
      operationCodeIds:  $grid.filters.operation_code.control.value ,
      billingCodeIds:  $grid.filters.billing_code.control.value ,
    };
    const data = await this.datasources.Invoices.ds_invoice_lines_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootPrintManager_invoice_lines_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.linenumber.displayControl as TextModel).text = $row.entity.LineNumber?.toString();
    (this.cells.billing_code.displayControl as TextModel).text = $row.entity.BillingCode?.Name;
    (this.cells.billing_code.editControl as SelectBoxModel).reset($row.entity.BillingCodeId);
    (this.cells.amount.displayControl as TextModel).text = $row.entity.Quantity?.toString();
    (this.cells.amount.editControl as NumberBoxModel).reset($row.entity.Quantity);
    (this.cells.unit_price.displayControl as TextModel).text = $row.entity.UnitPrice?.toString();
    (this.cells.unit_price.editControl as NumberBoxModel).reset($row.entity.UnitPrice);
    (this.cells.line_total.displayControl as TextModel).text = $row.entity.LineTotal?.toString();
    (this.cells.billing_record_count.displayControl as TextModel).text = $utils.isDefined($row.entity.BillingRecords) ? $row.entity.BillingRecords.length.toString() : '0';
    (this.cells.notes.displayControl as TextModel).text = $row.entity.Notes;
    (this.cells.notes.editControl as TextBoxModel).reset($row.entity.Notes);
    (this.cells.description.displayControl as TextModel).text = $row.entity.Description;
    (this.cells.description.editControl as TextBoxModel).reset($row.entity.Description);
    (this.cells.operation_code.displayControl as TextModel).text = [...new Set($row.entity.BillingRecords?.map(br => br.BillingTask?.OperationCode?.Name))].join(', ');

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_init_new_row(event = null) {
    return this.on_init_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_new_rowInternal(
    $row: FootPrintManager_invoice_lines_gridComponentRowModel,
  $grid: FootPrintManager_invoice_lines_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  $row.entity = {
      InvoiceId: $grid.inParams.invoiceId
  };
  
  
  
  }
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: FootPrintManager_invoice_lines_gridComponentRowModel,
  $grid: FootPrintManager_invoice_lines_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const allRequiredFieldHaveValue =
      $utils.isDefined($row.cells.amount.editControl.value) &&
      $utils.isDefined($row.cells.unit_price.editControl.value) &&
      $utils.isDefined($row.cells.billing_code.editControl.value);
  
  if (allRequiredFieldHaveValue === false) {
      $shell.FootPrintManager.openErrorDialog('Save', 'Missing Required fields');
      throw new Error('Missing Required fields'); // to prevent displayMode 
  }
  
  let typeId = $grid.inParams.invoiceTypeId;
  let minimumCharge: number = 0;
  
  if (typeId === 1 || typeId === 3) {
      //Invoice (1) - Debit Memo (3)
  
      if ($row.cells.amount.editControl.value <= 0) {
          $shell.FootPrintManager.openErrorDialog('Save', 'Invoice line amount must be greater than 0');
          throw new Error('Order line quantity must be greater than 0'); // to prevent displayMode 
      }
  
      if ($row.cells.unit_price.editControl.value < 0) {
          $shell.FootPrintManager.openErrorDialog('Save', 'Invoice line rate must be greater than 0');
          throw new Error('Invoice line rate must be greater than or equal to 0'); // to prevent displayMode 
      }
  
  } else if (typeId === 2) {
  
      if (Number($row.cells.amount.editControl.value) * Number($row.cells.unit_price.editControl.value) > 0) {
          $shell.FootPrintManager.openErrorDialog('Save', 'Credit memo line total must be negative');
          throw new Error('Credit memo line total must be negative'); // to prevent displayMode 
      }
  
      minimumCharge = -2147483647; //To force the minimum calculation and return the negative value we're looking for
  }
  
  try {
  
      const flowLinesParams = {
          invoiceId: $grid.inParams.invoiceId,
          billingCodeId: $row.cells.billing_code.editControl.value,
          quantity: $row.cells.amount.editControl.value,
          unitPrice: $row.cells.unit_price.editControl.value,
          minimumCharge: minimumCharge,
          notes: $row.cells.notes.editControl.value,
          description: $row.cells.description.editControl.value
  
      }
      const linesResult = await $flows.Invoices.create_invoice_line_flow(flowLinesParams);
      const invoiceLineId = linesResult.invoiceLineId;
      const errors = linesResult.reason;
      if ($utils.isDefined(errors)) {
  
          $shell.FootPrintManager.showErrorDetails('Save', 'Error on save', errors);
          throw errors; // to prevent displayMode 
      }
      else {
  
          $row.entity.Id = invoiceLineId;
          await $row.refresh();
      }
  } catch (error) {
      $shell.FootPrintManager.showErrorDetails('Save', 'Error on save', error);
      throw error; // to prevent displayMode 
  }
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_invoice_lines_gridComponentRowModel,
  $grid: FootPrintManager_invoice_lines_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintManager_invoice_lines_gridComponentRowModel,
  $grid: FootPrintManager_invoice_lines_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const allRequiredFieldHaveValue =
      $utils.isDefined($row.cells.amount.editControl.value) &&
      $utils.isDefined($row.cells.unit_price.editControl.value) &&
      $utils.isDefined($row.cells.billing_code.editControl.value);
  
  if (allRequiredFieldHaveValue === false) {
      $shell.FootPrintManager.openErrorDialog('Save', 'Missing Required fields');
      throw new Error('Missing Required fields'); // to prevent displayMode 
  }
  
  const payload: any = {};
  
  let typeId = $grid.inParams.invoiceTypeId;
  let minimumCharge: number = 0;
  
  if (typeId === 1 || typeId === 3) {
      //Invoice (1) - Debit Memo (3)
  
      if ($row.cells.amount.editControl.value <= 0) {
          $shell.FootPrintManager.openErrorDialog('Save', 'Invoice line amount must be greater than 0.');
          throw new Error('Order line quantity must be greater than 0.'); // to prevent displayMode 
      }
  
      if ($row.cells.unit_price.editControl.value < 0) {
          $shell.FootPrintManager.openErrorDialog('Save', 'Invoice line rate must be greater than 0.');
          throw new Error('Invoice line rate must be greater than or equal to 0.'); // to prevent displayMode 
      }
  
  } else if (typeId === 2) {
      //Credit Memo (2) - Bug FootPrint Cloud 190807: Credit Memo - Invoice Lines do not allow negative values
  
      if (Number($row.cells.amount.editControl.value) * Number($row.cells.unit_price.editControl.value) > 0) {
          $shell.FootPrintManager.openErrorDialog('Save', 'Credit memo line total must be negative!');
          throw new Error('Credit memo line total must be negative!'); // to prevent displayMode 
      }
  
      payload.MinimumCharge = -2147483647; //To force the minimum calculation and return the negative value we're looking for
  }
  
  if ($row.cells.amount.editControl.isChanged) {
      payload.Quantity = $row.cells.amount.editControl.value;
  }
  
  if ($row.cells.unit_price.editControl.isChanged) {
      payload.UnitPrice = $row.cells.unit_price.editControl.value;
  }
  if ($row.cells.billing_code.editControl.isChanged) {
      payload.BillingCodeId = $row.cells.billing_code.editControl.value;
  }
  
  if ($row.cells.notes.editControl.isChanged) {
      payload.Notes = $row.cells.notes.editControl.value;
  }
  
  if ($row.cells.description.editControl.isChanged) {
      payload.Description = $row.cells.description.editControl.value;
  }
  
  try {
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'InvoiceLines', id: $row.entity.Id, entity: payload });
  
      await $row.refresh();
  } catch (error) {
      $shell.FootPrintManager.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  }
  
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Invoices_billingcodes_dd_singleComponent),
    forwardRef(() => Invoices_billingcodes_dd_multiComponent),
    forwardRef(() => Invoices_operation_codes_dd_multiComponent),
    forwardRef(() => FootPrintManager_billing_records_by_invoiceline_gridComponent)
  ],
  selector: 'FootPrintManager-invoice_lines_grid',
  templateUrl: './FootPrintManager.invoice_lines_grid.component.html'
})
export class FootPrintManager_invoice_lines_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_invoice_lines_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_invoice_lines_gridComponentInParams = { invoiceId: null, invoiceTypeId: null, open_to_line: null };


  //#region Variables
  vars: { is_initialized?: boolean } = { };
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     linenumber: new GridHeaderModel(new HeaderStyles(null, null), 'linenumber', '#', false, false, null, false),       billing_code: new GridHeaderModel(new HeaderStyles(null, null), 'billing_code', 'Billing code', false, false, null, false),       amount: new GridHeaderModel(new HeaderStyles(null, null), 'amount', 'Amount', true, false, null, false),       unit_price: new GridHeaderModel(new HeaderStyles(null, null), 'unit_price', 'Rate', true, false, null, false),       line_total: new GridHeaderModel(new HeaderStyles(null, null), 'line_total', 'Total', false, false, null, false),       billing_record_count: new GridHeaderModel(new HeaderStyles(null, null), 'billing_record_count', 'Billing records', false, false, null, false),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'notes', 'Notes', false, false, null, false),       description: new GridHeaderModel(new HeaderStyles(null, null), 'description', 'Description', false, false, null, false),       operation_code: new GridHeaderModel(new HeaderStyles(null, null), 'operation_code', 'Operation codes', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_invoice_lines_gridComponentRowModel[] = [];

  @Input('invoiceId') set $inParams_invoiceId(value: any) {
    this.inParams['invoiceId'] = value;
  }
  get $inParams_invoiceId(): any {
    return this.inParams['invoiceId'] ;
  }
  @Input('invoiceTypeId') set $inParams_invoiceTypeId(value: any) {
    this.inParams['invoiceTypeId'] = value;
  }
  get $inParams_invoiceTypeId(): any {
    return this.inParams['invoiceTypeId'] ;
  }
  @Input('open_to_line') set $inParams_open_to_line(value: any) {
    this.inParams['open_to_line'] = value;
  }
  get $inParams_open_to_line(): any {
    return this.inParams['open_to_line'] ;
  }

  topToolbar = {
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false)
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, false, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };

  formGroup: FormGroup = new FormGroup({
    billing_code: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    operation_code: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    billing_code: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['billing_code'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Billing code', false, false),
    operation_code: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['operation_code'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Operation code', false, false),
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Invoice lines';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.invoiceId)) {
        this.$missingRequiredInParams.push('invoiceId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 25;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      invoiceId:  $grid.inParams.invoiceId ,
      fullTextSearch:  $grid.fullTextSearch ,
      operationCodeIds:  $grid.filters.operation_code.control.value ,
      billingCodeIds:  $grid.filters.billing_code.control.value ,
    };
    try {
      const data = await this.datasources.Invoices.ds_invoice_lines_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootPrintManager_invoice_lines_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_invoice_lines_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push(row.$initializeExisting(this, entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootPrintManager_invoice_lines_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  async addRow(entity?: IFootPrintManager_invoice_lines_gridComponentEntity) {
    const row = new FootPrintManager_invoice_lines_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations,
      this.logger);
    await row.$initializeNew(this, entity);
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_invoice_lines_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $grid.vars.is_initialized = false;
  
   // Disable Delete button
  $grid.topToolbar.on_delete.control.readOnly = true;
  
  
  const invoice = (await $datasources.Invoices.ds_get_invoice_by_invoiceId.get({invoiceId: $grid.inParams.invoiceId})).result;
  
   const statusId = invoice.StatusId;
  
  // Created
  if (statusId === 1) {
  
      $grid.bottomToolbar.addLine.control.readOnly = false;
      $grid.canEdit = true;
      $grid.headers.amount.required = true;
      $grid.headers.unit_price.required = true;
      $grid.headers.billing_code.required = true;
  
  
  }
  
  else {
  
      $grid.bottomToolbar.addLine.control.readOnly = true;
      $grid.canEdit = false;
      $grid.headers.amount.required = false;
      $grid.headers.unit_price.required = false;
      $grid.headers.billing_code.required = false;
      
  
  }
  
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_invoice_lines_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const invoice = (await $datasources.Invoices.ds_get_invoice_by_invoiceId.get({ invoiceId: $grid.inParams.invoiceId })).result;
  
  const statusId = invoice.StatusId;
  
  const selectedRowsCount = $grid.selectedRows.length;
  
  // One or more rows selected and order status is created
  if (selectedRowsCount > 0 && statusId === 1) {
      $grid.topToolbar.on_delete.control.readOnly = false;
  
  }
  else {
      $grid.topToolbar.on_delete.control.readOnly = true;
  
  }
  
  
  if ($utils.isDefined($grid.inParams.open_to_line)) {
      const matchingRow = $grid.rows.find(row => row.entity.LineNumber === $grid.inParams.open_to_line);
  
      if ($utils.isDefined(matchingRow)) {
          matchingRow.selected = true;
          $grid.inParams.open_to_line = null;
  
          scrollIntoView();
      }
  }
  
  
  
  
  async function scrollIntoView() {
      await new Promise(result => setTimeout(result, 200))
      // Scroll into view
      const element = document.getElementsByClassName('selected-row')[0];
      if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
  }
  }
  on_delete(event = null) {
    return this.on_deleteInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deleteInternal(
    $grid: FootPrintManager_invoice_lines_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Invoice Line Delete Error', 'No order lines selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              invoiceLineId: row.entity.Id
          };
          const result = await $flows.Invoices.is_invoice_line_deletable(flowParams);
          const reason = result.reason;
          if ($utils.isDefined(reason)) {
              failures.push(row);
              errors.push(`Invoice Line ${row.entity.LineNumber.toString()} - ${reason}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Delete invoice line errors';
          const errorMessage = `Invoice line(s) ${failures.map(c => c.entity.LineNumber.toString()).join(',')} cannot be deleted`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Invoice line(s) ${candidates.map(c => c.entity.LineNumber.toString()).join(',')} - Once deleted, the invoice line(s) cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some invoice line(s) cannot be deleted';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Delete the following invoice line(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const processSuccess = [];
              const processFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      const flowParams = {
                          invoiceLineId: candidate.entity.Id
                      }
                      const result = await $flows.Invoices.delete_invoice_line_flow(flowParams);
  
                      processSuccess.push(candidate);
                  } catch (error) {
                      processFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Invoice line ${candidate.entity.LineNumber.toString()} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (processSuccess.length === candidates.length) {
                  const title = 'All invoice line(s) deleted';
                  const message = `Invoice line(s) ${processSuccess.map(c => c.entity.LineNumber.toString()).join(',')} deleted`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (processFailures.length === candidates.length) {
                      const title = 'All invoice line(s) failed to delete';
                      const message = `Invoice line(s) ${processFailures.map(c => c.entity.LineNumber.toString()).join(',')} could not be deleted`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some invoice lines could not be deleted';
                      const success = `Invoice line(s) ${processSuccess.map(c => c.entity.LineNumber.toString()).join(',')} were deleted.`;
                      const errors = `The following invoice line(s) could not be deleted: ${processFailures.map(c => c.entity.LineNumber.toString()).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: FootPrintManager_invoice_lines_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await handleOpenToLine();
  
  $grid.vars.is_initialized = true;
  
  
  
  
  /**********************************************
   * FUNCTIONS
  ***********************************************/
  async function handleOpenToLine() {
      if (!$grid.vars.is_initialized && $utils.isDefined($grid.inParams.open_to_line)) {
          const result = (await $datasources.Invoices.ds_get_invoice_line_position.get({ invoice_id: $grid.inParams.invoiceId, invoice_line: $grid.inParams.open_to_line })).result[0];
  
          if (!$utils.isDefined(result) || result.MaxLineNumber < $grid.inParams.open_to_line) {
              // Invalid line number
              return;
          }
  
          $grid.pageSkip = Math.floor((result.LinePosition - 1) / $grid.pageSize) * $grid.pageSize;
          $grid.refresh();
      }
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
