import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';



interface IFootPrintManager_entity_user_defined_field_values_gridComponentEntity {
  Id?: number, UdfId?: number, EntityId?: string, UdfValue?: string, UdfName?: string, UdfType?: string}

interface IFootPrintManager_entity_user_defined_field_values_gridComponentInParams {
  entityType: string, entityKeys: { name?: string, value?: any }[]}


class FootPrintManager_entity_user_defined_field_values_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_entity_user_defined_field_values_gridComponent;
  entity: IFootPrintManager_entity_user_defined_field_values_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    udf_value_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    udf_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    udf_value: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['udf_value_edit'] as DatexFormControl, null, false, false, '', null)
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_entity_user_defined_field_values_gridComponent, entity: IFootPrintManager_entity_user_defined_field_values_gridComponentEntity) {
    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id,this.entity.UdfId,this.entity.EntityId,this.entity.UdfValue,this.entity.UdfName,this.entity.UdfType].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_entity_user_defined_field_values_gridComponent, entity?: IFootPrintManager_entity_user_defined_field_values_gridComponentEntity) {
    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id,this.entity.UdfId,this.entity.EntityId,this.entity.UdfValue,this.entity.UdfName,this.entity.UdfType].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = {};
    set($resultKey, 'Id',this.entity.Id);
    set($resultKey, 'UdfId',this.entity.UdfId);
    set($resultKey, 'EntityId',this.entity.EntityId);
    set($resultKey, 'UdfValue',this.entity.UdfValue);
    set($resultKey, 'UdfName',this.entity.UdfName);
    set($resultKey, 'UdfType',this.entity.UdfType);
    const inParams = {
      $keys:[$resultKey],
      EntityType:  $grid.inParams.entityType ,
      EntityKeys:  $grid.inParams.entityKeys as {name: string, value: object}[] ,
    };
    const data = await this.datasources.FootPrintManager.ds_entity_user_defined_field_values_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootPrintManager_entity_user_defined_field_values_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.udf_name.displayControl as TextModel).text = $row.entity.UdfName;
    (this.cells.udf_value.displayControl as TextModel).text = $row.entity.UdfValue;
    (this.cells.udf_value.editControl as TextBoxModel).reset($row.entity.UdfValue);

  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintManager_entity_user_defined_field_values_gridComponentRowModel,
  $grid: FootPrintManager_entity_user_defined_field_values_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  if ($row.cells.udf_value.editControl.isChanged) {
      let value: any = $row.cells.udf_value.editControl.value;
      // Convert to type
      if ($utils.isDefinedTrimmed(value)) {
          value = convertDataType($row.entity.UdfType, value);
      } else {
          value = null;
      }
  
      let result = await $flows.Utilities.crud_batch_request_flow({
          entities: [{
              method: 'PATCH',
              set: $grid.inParams.entityType + 's',
              keys: $grid.inParams.entityKeys,
              properties: { [$row.entity.UdfName]: value }
          }]
      });
  
      if ($utils.isDefined(result.reasons)) {
          await $shell.FootPrintManager.openErrorDialog(`Error updating ${$row.entity.UdfName}`, 'Errors : ' + (await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
      }
      else {
          $row.cells.udf_value.displayControl.text = $row.cells.udf_value.editControl.value;
          $row.entity.UdfValue = $row.cells.udf_value.editControl.value;
      }
  }
  
  
  
  function convertDataType(type: string, value: any) {
      if (type === 'Edm.Decimal') {
          value = Number(value);
      }
      else if (type === 'Edm.Boolean') {
          value = convertToBoolean(value);
      }
  
      return value;
  }
  
  
  function convertToBoolean(value: string | number | null): boolean | null {
      if (!$utils.isDefinedTrimmed(value)) {
          return null;
      }
      if (isNaN(Number(value)) && typeof value === 'string') {
          value = value.toLowerCase();
          if (value === 'true') {
              return true;
          } else if (value === 'false') {
              return false;
          }
      } else if (!isNaN(Number(value))) {
          if (value === 1) {
              return true;
          } else if (value === 0) {
              return false;
          }
      }
      throw new Error('Invalid input');
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-entity_user_defined_field_values_grid',
  templateUrl: './FootPrintManager.entity_user_defined_field_values_grid.component.html'
})
export class FootPrintManager_entity_user_defined_field_values_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_entity_user_defined_field_values_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['default','fit-content-table']);


  inParams: IFootPrintManager_entity_user_defined_field_values_gridComponentInParams = { entityType: null, entityKeys: [] };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     udf_name: new GridHeaderModel(new HeaderStyles(null, null), 'udf_name', 'Field', false, false, null, false),       udf_value: new GridHeaderModel(new HeaderStyles(null, null), 'udf_value', 'Value', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_entity_user_defined_field_values_gridComponentRowModel[] = [];

  @Input('entityType') set $inParams_entityType(value: any) {
    this.inParams['entityType'] = value;
  }
  get $inParams_entityType(): any {
    return this.inParams['entityType'] ;
  }
  @Input('entityKeys') set $inParams_entityKeys(value: any) {
    this.inParams['entityKeys'] = value;
  }
  get $inParams_entityKeys(): any {
    return this.inParams['entityKeys'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Custom fields';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.entityType)) {
        this.$missingRequiredInParams.push('entityType');
      }
      if(isNil(this.inParams.entityKeys)) {
        this.$missingRequiredInParams.push('entityKeys');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 100;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      EntityType:  $grid.inParams.entityType ,
      EntityKeys:  $grid.inParams.entityKeys as {name: string, value: object}[] ,
    };
    try {
      const data = await this.datasources.FootPrintManager.ds_entity_user_defined_field_values_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootPrintManager_entity_user_defined_field_values_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_entity_user_defined_field_values_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push(row.$initializeExisting(this, entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootPrintManager_entity_user_defined_field_values_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }



 
  close() {
    this.$finish.emit();
  }
}
