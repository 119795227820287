<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="on_complete" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_complete_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="on_delete" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_delete_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="separator1" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <div class="tool-separator"></div>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="attachments" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_attachments_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="on_print" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button 
                      data-cy="splitbutton"
                      class="datex-button splitbutton" 
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matMenuTriggerFor]="dropdownMenuOne"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip">
                <div class="button-label">
                  <div *ngIf="tool.control.icon"
                        class="button-icon">
                    <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                  <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                  <div class="button-icon splitbutton-drop-icon">
                    <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                  </div>
                </div>
              </button>
              <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                <div data-cy="splitbutton-options">
                  <div *ngIf="!tool.control.buttons.purchase_order_report.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.purchase_order_report.readOnly || tool.control.buttons.purchase_order_report.disabled"
                            [ngStyle]="tool.control.buttons.purchase_order_report.styles.style"
                            [ngClass]="tool.control.buttons.purchase_order_report.styles.classes"
                            [matTooltip]="tool.control.buttons.purchase_order_report.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_purchase_order_report($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.purchase_order_report.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.purchase_order_report.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.purchase_order_report.label}}</div>
                      </div>
                    </button>
                  </div>
                </div>
              </mat-menu>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden && !fieldsets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-lookupcode" *ngIf="!fields.lookupcode.hidden && !fields.lookupcode.removed" 
                            class="field-container standard {{fields.lookupcode.invalid ? 'invalid' : ''}} {{fields.lookupcode.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.lookupcode.styles.style"
                            [ngClass]="fields.lookupcode.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.lookupcode.label + (fields.lookupcode.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.lookupcode.label}}<span *ngIf="fields.lookupcode.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="lookupcode"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.lookupcode.control.readOnly || fields.lookupcode.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.lookupcode.control.placeholder}}"
                                [ngStyle]="fields.lookupcode.control.styles.style"
                                [ngClass]="fields.lookupcode.control.styles.classes"
                                [matTooltip]="fields.lookupcode.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.lookupcode.invalid">
                          <ng-container *ngFor="let error of fields.lookupcode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-vendor_reference" *ngIf="!fields.vendor_reference.hidden && !fields.vendor_reference.removed" 
                            class="field-container standard {{fields.vendor_reference.invalid ? 'invalid' : ''}} {{fields.vendor_reference.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.vendor_reference.styles.style"
                            [ngClass]="fields.vendor_reference.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.vendor_reference.label + (fields.vendor_reference.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.vendor_reference.label}}<span *ngIf="fields.vendor_reference.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="vendor_reference"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.vendor_reference.control.readOnly || fields.vendor_reference.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.vendor_reference.control.placeholder}}"
                                [ngStyle]="fields.vendor_reference.control.styles.style"
                                [ngClass]="fields.vendor_reference.control.styles.classes"
                                [matTooltip]="fields.vendor_reference.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.vendor_reference.invalid">
                          <ng-container *ngFor="let error of fields.vendor_reference.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-expected_date" *ngIf="!fields.expected_date.hidden && !fields.expected_date.removed" 
                            class="field-container standard {{fields.expected_date.invalid ? 'invalid' : ''}} {{fields.expected_date.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.expected_date.styles.style"
                            [ngClass]="fields.expected_date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.expected_date.label + (fields.expected_date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.expected_date.label}}<span *ngIf="fields.expected_date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="expected_date"
                            [format]="fields.expected_date.control.format"
                            [mode]="fields.expected_date.control.mode"
                            [ngStyle]="fields.expected_date.control.styles.style"
                            [ngClass]="fields.expected_date.control.styles.classes"
                            [tooltip]="fields.expected_date.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.expected_date.invalid">
                          <ng-container *ngFor="let error of fields.expected_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-fulfillment_date" *ngIf="!fields.fulfillment_date.hidden && !fields.fulfillment_date.removed" 
                            class="field-container standard {{fields.fulfillment_date.invalid ? 'invalid' : ''}} {{fields.fulfillment_date.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.fulfillment_date.styles.style"
                            [ngClass]="fields.fulfillment_date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.fulfillment_date.label + (fields.fulfillment_date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.fulfillment_date.label}}<span *ngIf="fields.fulfillment_date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="fulfillment_date"
                            [format]="fields.fulfillment_date.control.format"
                            [mode]="fields.fulfillment_date.control.mode"
                            [ngStyle]="fields.fulfillment_date.control.styles.style"
                            [ngClass]="fields.fulfillment_date.control.styles.classes"
                            [tooltip]="fields.fulfillment_date.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.fulfillment_date.invalid">
                          <ng-container *ngFor="let error of fields.fulfillment_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-notes" *ngIf="!fields.notes.hidden && !fields.notes.removed" 
                            class="field-container double {{fields.notes.invalid ? 'invalid' : ''}} {{fields.notes.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.notes.styles.style"
                            [ngClass]="fields.notes.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.notes.label + (fields.notes.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.notes.label}}<span *ngIf="fields.notes.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.notes.control.readOnly || fields.notes.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.notes.control.placeholder}}"
                                [ngStyle]="fields.notes.control.styles.style"
                                [ngClass]="fields.notes.control.styles.classes"
                                [matTooltip]="fields.notes.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.notes.invalid">
                          <ng-container *ngFor="let error of fields.notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-authorized" *ngIf="!fields.authorized.hidden && !fields.authorized.removed" 
                            class="field-container standard {{fields.authorized.invalid ? 'invalid' : ''}} {{fields.authorized.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.authorized.styles.style"
                            [ngClass]="fields.authorized.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.authorized.label + (fields.authorized.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.authorized.label}}<span *ngIf="fields.authorized.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="authorized"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.authorized.control.styles.style"
                                      [ngClass]="fields.authorized.control.styles.classes"
                                      [matTooltip]="fields.authorized.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.authorized.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.authorized.invalid">
                          <ng-container *ngFor="let error of fields.authorized.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-authorized_by" *ngIf="!fields.authorized_by.hidden && !fields.authorized_by.removed" 
                            class="field-container double {{fields.authorized_by.invalid ? 'invalid' : ''}} {{fields.authorized_by.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.authorized_by.styles.style"
                            [ngClass]="fields.authorized_by.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.authorized_by.label + (fields.authorized_by.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.authorized_by.label}}<span *ngIf="fields.authorized_by.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.authorized_by.control.styles.style"
                              [ngClass]="fields.authorized_by.control.styles.classes"
                              [matTooltip]="fields.authorized_by.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.authorized_by.control.text }}</div>
                        <ng-container *ngIf="fields.authorized_by.invalid">
                          <ng-container *ngFor="let error of fields.authorized_by.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-completed" *ngIf="!fields.completed.hidden && !fields.completed.removed" 
                            class="field-container standard {{fields.completed.invalid ? 'invalid' : ''}} {{fields.completed.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.completed.styles.style"
                            [ngClass]="fields.completed.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.completed.label + (fields.completed.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.completed.label}}<span *ngIf="fields.completed.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="completed"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.completed.control.styles.style"
                                      [ngClass]="fields.completed.control.styles.classes"
                                      [matTooltip]="fields.completed.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.completed.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.completed.invalid">
                          <ng-container *ngFor="let error of fields.completed.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-completed_by" *ngIf="!fields.completed_by.hidden && !fields.completed_by.removed" 
                            class="field-container double {{fields.completed_by.invalid ? 'invalid' : ''}} {{fields.completed_by.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.completed_by.styles.style"
                            [ngClass]="fields.completed_by.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.completed_by.label + (fields.completed_by.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.completed_by.label}}<span *ngIf="fields.completed_by.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.completed_by.control.styles.style"
                              [ngClass]="fields.completed_by.control.styles.classes"
                              [matTooltip]="fields.completed_by.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.completed_by.control.text }}</div>
                        <ng-container *ngIf="fields.completed_by.invalid">
                          <ng-container *ngFor="let error of fields.completed_by.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-ship_from_selection" *ngIf="!fields.ship_from_selection.hidden && !fields.ship_from_selection.removed" 
                            class="field-container standard {{fields.ship_from_selection.invalid ? 'invalid' : ''}} {{fields.ship_from_selection.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.ship_from_selection.styles.style"
                            [ngClass]="fields.ship_from_selection.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.ship_from_selection.label + (fields.ship_from_selection.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.ship_from_selection.label}}<span *ngIf="fields.ship_from_selection.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.ship_from_selection.control.readOnly || fields.ship_from_selection.control.disabled"
                                [ngStyle]="fields.ship_from_selection.control.styles.style"
                                [ngClass]="fields.ship_from_selection.control.styles.classes"
                                [matTooltip]="fields.ship_from_selection.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_ship_from_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.ship_from_selection.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.ship_from_selection.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.ship_from_selection.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.ship_from_selection.invalid">
                          <ng-container *ngFor="let error of fields.ship_from_selection.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-ship_from_clear" *ngIf="!fields.ship_from_clear.hidden && !fields.ship_from_clear.removed" 
                            class="field-container standard {{fields.ship_from_clear.invalid ? 'invalid' : ''}} {{fields.ship_from_clear.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.ship_from_clear.styles.style"
                            [ngClass]="fields.ship_from_clear.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.ship_from_clear.label + (fields.ship_from_clear.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.ship_from_clear.label}}<span *ngIf="fields.ship_from_clear.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.ship_from_clear.control.readOnly || fields.ship_from_clear.control.disabled"
                                [ngStyle]="fields.ship_from_clear.control.styles.style"
                                [ngClass]="fields.ship_from_clear.control.styles.classes"
                                [matTooltip]="fields.ship_from_clear.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_ship_from_cleared($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.ship_from_clear.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.ship_from_clear.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.ship_from_clear.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.ship_from_clear.invalid">
                          <ng-container *ngFor="let error of fields.ship_from_clear.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-ship_from" *ngIf="!fields.ship_from.hidden && !fields.ship_from.removed" 
                            class="field-container double {{fields.ship_from.invalid ? 'invalid' : ''}} {{fields.ship_from.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.ship_from.styles.style"
                            [ngClass]="fields.ship_from.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.ship_from.label + (fields.ship_from.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.ship_from.label}}<span *ngIf="fields.ship_from.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.ship_from.control.styles.style"
                              [ngClass]="fields.ship_from.control.styles.classes"
                              [matTooltip]="fields.ship_from.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.ship_from.control.text }}</div>
                        <ng-container *ngIf="fields.ship_from.invalid">
                          <ng-container *ngFor="let error of fields.ship_from.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-currency" *ngIf="!fields.currency.hidden && !fields.currency.removed" 
                            class="field-container standard {{fields.currency.invalid ? 'invalid' : ''}} {{fields.currency.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.currency.styles.style"
                            [ngClass]="fields.currency.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.currency.label + (fields.currency.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.currency.label}}<span *ngIf="fields.currency.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Invoices-currencies_dd_single 
                            data-cy="selector"
                            [type]="fields.currency.control.type"
                            formControlName="currency"
                            (displayTextChange)="fields.currency.control.displayText=$event"
                            [placeholder]="fields.currency.control.placeholder"
                            [styles]="fields.currency.control.styles"
                            [tooltip]="fields.currency.control.tooltip"
                        >
                        </Invoices-currencies_dd_single>
                        <ng-container *ngIf="fields.currency.invalid">
                          <ng-container *ngFor="let error of fields.currency.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.purchase_order_lines.hasTabToShow" class="tab" data-cy="tab-purchase_order_lines">
              <h2 [className]="tabs.purchase_order_lines.active? 'active': ''" (click)="tabs.purchase_order_lines.activate()">{{tabs.purchase_order_lines.title}}</h2>
            </div>
          </div>
        
              <app-custom_vendor_order_lines_grid *ngIf="tabs.purchase_order_lines.active"
              #$tabs_purchase_order_lines
              [orderId]="$tabs_purchase_order_lines_custom_vendor_order_lines_grid_inParams_orderId"
              [shipmentId]="$tabs_purchase_order_lines_custom_vendor_order_lines_grid_inParams_shipmentId"
              [projectId]="$tabs_purchase_order_lines_custom_vendor_order_lines_grid_inParams_projectId"
              [orderStatusId]="$tabs_purchase_order_lines_custom_vendor_order_lines_grid_inParams_orderStatusId"
              ($refreshEvent)="refresh(false, false, '$tabs_purchase_order_lines')">
              </app-custom_vendor_order_lines_grid>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>