import { ElementRef, Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, forwardRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles
} from './models/control';
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { BaseComponent } from './components/base.component';


import { WarehouseScene3D, IVisMaterial, IVisDockdoor, IVisObject, WarehouseVisualizationService } from './visualization.service';
import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-dock_door_visualization',
  templateUrl: './FootPrintManager.dock_door_visualization.component.html'
})
export class FootPrintManager_dock_door_visualizationComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('rendererCanvas', { static: true })
  public rendererCanvas: ElementRef<HTMLCanvasElement>;

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  private scene: WarehouseScene3D = null;
  dockdoors: IVisDockdoor[] = [];

  inParams: { warehouse_id: number } = { warehouse_id: null };
  //#region Inputs
  @Input('warehouse_id') set $inParams_warehouse_id(v: number) {
    this.inParams.warehouse_id = v;
  }
  get $inParams_warehouse_id(): number {
    return this.inParams.warehouse_id;
  }
  //#endregion Inputs

  //#endregion filters inParams

  toolbar = {
    refresh: new ToolModel(new ButtonModel('refresh', new ButtonStyles(null, null), false, false, false, 'Refresh', 'ms-Icon ms-Icon--Refresh', null)
  , false),
    separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
  , false),
    color_guide: new ToolModel(new ButtonModel('color_guide', new ButtonStyles(['link'], null), false, false, false, 'Green (Open) Red (Active) Yellow (Incoming) Black (Disabled)', '', null)
  , false)
  };

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    private visService: WarehouseVisualizationService
  ) {
    super();
    this.title = 'Dock door view';
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearVisualizationModel();
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.warehouse_id)) {
        this.$missingRequiredInParams.push('warehouse_id');
      }
  }

  initialized = false;
  get $hasModelLoaded() : boolean { return !isNil(this.scene); }

  async $init() {
    this.title = 'Dock door view';

    await this.on_init();

    if (this.$hasModelLoaded) {
      await this.$dataLoad();
    }
    this.initialized = true;
  }

  protected override $initEmpty() {
    this.clearVisualizationModel();
  }

  get activeDockdoorMaterial(): IVisMaterial {
    return { color: {
      r: this.normalizeValue(83),
      g: this.normalizeValue(181),
      b: this.normalizeValue(96)
    },
    opacity: 1 };
  }

  get inactiveDockdoorMaterial(): IVisMaterial {
    return { color: {
      r: this.normalizeValue(82),
      g: this.normalizeValue(82),
      b: this.normalizeValue(82)
    },
    opacity: 0.5 };
  }

  normalizeValue(value: number): number {
    if (value < 0 || value > 255) {
      throw new Error("Input value must be between 0 and 255.");
    }
    return value / 255;
  }

  clearVisualizationModel() {
    if (!isNil(this.scene)) {
      this.scene.dispose();
    }
    this.scene = null;
    this.dockdoors = [];
  }

  async setVisualizationModel(model: string) : Promise<void> {
    if (!isNil(model)) {
      if (!isNil(this.scene)) {
        this.scene.dispose();
      }
      const scene = await this.visService.createScene3D(model, this.rendererCanvas);
      this.scene = scene;

      this.dockdoors = [];
      const dockdoors = scene.getObjectsByType(undefined, 'dockdoor');
      dockdoors.forEach(dd => {
        this.dockdoors.push({ 
          Id: dd?.userData?.properties?.Id,
          Name: dd?.userData?.properties?.Name 
      } as IVisDockdoor);
      });
    } else {
      this.clearVisualizationModel();
    }
  }

  async $dataLoad() {
    await this.$dataLoaded();
  }

  async $dataLoaded() {
   
    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    return result;
  }

  setMaterial(obj: IVisObject, material: IVisMaterial) {
    this.visService.setObjectMaterialByIdAndType(
      this.scene,
      obj.Id, this.getObjectType(obj),
      material.color.r, material.color.g, material.color.b, material.opacity);
  }

  private getObjectType(obj: IVisObject): string {
    if ('ChildFootprint' in obj) {
      return 'location';
    } else if ('Width' in obj) {
      return 'zone';
    } else return 'dockdoor';
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $visualization: FootPrintManager_dock_door_visualizationComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Set visualization model
  
  // code example: 
  
  if ($utils.isDefined($visualization.inParams.warehouse_id)) {
      const model = (await $datasources.FootPrintManager.ds_get_warehouse_viz_model.get({ warehouse_id: $visualization.inParams.warehouse_id })).result?.Model;
      await $visualization.setVisualizationModel(model);
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $visualization: FootPrintManager_dock_door_visualizationComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Apply visualization data
  if ($utils.isDefined($visualization.inParams.warehouse_id)) {
      // load doockdoors
      const dds = await $datasources.DockAppointments.ds_get_dock_doors_by_warehouse_id.get({ warehouseId: $visualization.inParams.warehouse_id });
      for (const res_dd of dds.result) {
          // get vizualization object
          const viz_dd = $visualization.dockdoors.find(item => item.Id === res_dd.Id);
          if (viz_dd) {
              // Apply data to the vizualization object
              let door_status = res_dd.StatusId;
  
              // First check the status of the door, set to Black if not active
              if (door_status !== 1) {
                  $visualization.setMaterial(viz_dd, {
                      color: { r: 0, g: 0, b: 0 },
                      opacity: 0.5
                  });
              }
  
              else {
                  // Get a list of active appointments for the given dock door
                  const activeAppointments = (await $datasources.DockAppointments.ds_get_upcoming_dock_appointments_by_dock_door.get({
                      locationId: res_dd.Id
                  })).result;
                  if ($utils.isDefined(activeAppointments)) {
  
                      // Get the current date time at the warehouse
                      const warehouseId = activeAppointments[0].WarehouseId;
                      const currentDateTimeUTC: Date = new Date();
                      const currentDateTimeUTCString: string = currentDateTimeUTC.toISOString();
  
                      const currentWarehouseDate = (await $datasources.DockAppointments.ds_get_current_date_from_utc.get({
                          utcDate: currentDateTimeUTCString,
                          warehouseId: warehouseId
                      })).result;
  
                      const warehouseDateConverted = currentWarehouseDate?.convertedDate;
  
  
                      // Loop thru the current appointments to check for a appointment that is actively assigned to the dock door
                      var currentAppointment = null;
                      for (let activeAppointment of activeAppointments) {
  
                          const dockAppointmentStatusId = activeAppointment.StatusId;
                          // Find the first appointment for the dock door that is in a door assigned or in process status
                          if ([2, 3].includes(dockAppointmentStatusId)) {
                              currentAppointment = activeAppointment.Id;
                              // Set to red
                              $visualization.setMaterial(viz_dd, {
                                  color: { r: 255, g: 0, b: 0 },
                                  opacity: 0.5
                              });
                              break;
  
                          }
                      }
  
                      // If a active appointment by door assigned or in process status is not found loop thru again to find the current appointment based on scheduled arrival/departure times.
                      if (!$utils.isDefined(currentAppointment)) {
                          for (let activeAppointment of activeAppointments) {
  
                              const scheduledArrival = activeAppointment.convertedScheduledArrival?.convertedDate;
                              const scheduledDeparture = activeAppointment.convertedScheduledDeparture?.convertedDate;
  
                              // Check if the appointment is window the loading/unloading window
                              if ($utils.isAllDefined(scheduledArrival, scheduledDeparture, warehouseDateConverted)) {
                                  if (scheduledArrival <= warehouseDateConverted && scheduledDeparture >= warehouseDateConverted) {
                                      currentAppointment = activeAppointment.Id;
                                      // Set to yellow
                                      $visualization.setMaterial(viz_dd, {
                                          color: { r: 255, g: 255, b: 0 },
                                          opacity: 0.5
                                      });
                                      break;
  
                                  }
                                  // Set to green by default when the appointment is either in the past or in the future
                                  else {
                                      $visualization.setMaterial(viz_dd, {
                                          color: { r: 0, g: 255, b: 0 },
                                          opacity: 0.5
                                      });
                                  }
                              }
                              // Set to green by default when the appointment is either in the past or in the future
                              else {
                                  $visualization.setMaterial(viz_dd, {
                                      color: { r: 0, g: 255, b: 0 },
                                      opacity: 0.5
                                  });
                              }
                          }
  
                      }
  
                  }
                  // No active appointments set to Green
                  else {
                      $visualization.setMaterial(viz_dd, {
                          color: { r: 0, g: 255, b: 0 },
                          opacity: 0.5
                      });
                  }
  
              }
          }
      }
  
  
  }
  }
  //#endregion private flows

  close() {
    this.$finish.emit();
  }
}
