import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { BarTender_ShellService } from './BarTender.shell.service';
import { BarTender_OperationService } from './BarTender.operation.service';
import { BarTender_DatasourceService } from './BarTender.datasource.index';
import { BarTender_FlowService } from './BarTender.flow.index';
import { BarTender_ReportService } from './BarTender.report.index';
import { BarTender_LocalizationService } from './BarTender.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './BarTender.frontend.types'
import { $frontendTypes as $types} from './BarTender.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { BarTender_azure_blob_storage_navigation_gridComponent } from './BarTender.azure_blob_storage_navigation_grid.component';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => BarTender_azure_blob_storage_navigation_gridComponent),
  ],
  selector: 'BarTender-bartender_hub',
  templateUrl: './BarTender.bartender_hub.component.html'
})
export class BarTender_bartender_hubComponent extends BaseComponent implements OnInit, OnDestroy {


  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  formGroup: FormGroup = new FormGroup({
  });
  
  toolbar = {
      manage: new ToolModel(new SplitButtonModel<{ manage_configurations: ButtonModel }>(
        'manage',
        new ButtonStyles(null, null),
        false,
        false,
        'Manage',
        'icon-ic_fluent_settings_20_regular',
        null,
        [
          new ButtonModel('manage_configurations', new ButtonStyles(null, null), false, false, false, 'Configurations', '')
        ])
    , false)
  };

  actionbar = {
  };

 filters = {
  };


  filtersets = {
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      file_explorer: new TabItemModel(
        this.rootTabGroup, 
        'File explorer', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_file_explorer', { read: BarTender_azure_blob_storage_navigation_gridComponent }) $tabs_file_explorer: BarTender_azure_blob_storage_navigation_gridComponent;
    //#endregion tabs children


  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `BarTender hub`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `View and manage BarTender settings and data.`;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: BarTender_ShellService,
  private datasources: BarTender_DatasourceService,
  private flows: BarTender_FlowService,
  private reports: BarTender_ReportService,
  private localization: BarTender_LocalizationService,
  private operations: BarTender_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.file_explorer,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'BarTender hub';
    
    const $hub = this;
    const $utils = this.utils;



    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_file_explorer') {
      if (!isNil(this.$tabs_file_explorer) && !this.tabs.file_explorer.hidden) {
        this.$tabs_file_explorer.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_manage_configurations_clicked(event = null) {
    return this.on_manage_configurations_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_manage_configurations_clickedInternal(
    $hub: BarTender_bartender_hubComponent,
  
    $shell: BarTender_ShellService,
    $datasources: BarTender_DatasourceService,
    $flows: BarTender_FlowService,
    $reports: BarTender_ReportService,
    $settings: SettingsValuesService,
    $operations: BarTender_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: BarTender_LocalizationService,
    $event: any
  ) {
    this.logger.log('BarTender', 'bartender_hub.on_manage_configurations_clicked');
  const result = await $shell.BarTender.openconfigurations_formDialog('flyout', EModalSize.Large);
  
  if (result.is_confirmed) { $hub.refresh(); }
  }
  //#endregion private flows
}
