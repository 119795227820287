<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"


>
    <ng-container topToolbar>
    <div class="toolContainer">
      <button mat-button
              class="datex-button"
              (click)="$exportExcel()">
        <div class="button-label">
          <div class="button-icon">
            <i class="icon icon-ic_fluent_document_arrow_down_20_regular"></i>
          </div>
          <div class="button-text">Export</div>
        </div>
      </button>
    </div>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.lookupcode.displayControl.styles.classes"
          [matTooltip]="row.cells.lookupcode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="description">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.description.displayControl.styles.style"
          [ngClass]="row.cells.description.displayControl.styles.classes"
          [matTooltip]="row.cells.description.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.description.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['description_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.description.editControl.readOnly || row.cells.description.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.description.editControl.placeholder}}"
            [ngStyle]="row.cells.description.editControl.styles.style"
            [ngClass]="row.cells.description.editControl.styles.classes"
            [matTooltip]="row.cells.description.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="upc_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.upc_code.displayControl.styles.style"
          [ngClass]="row.cells.upc_code.displayControl.styles.classes"
          [matTooltip]="row.cells.upc_code.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.upc_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['upc_code_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.upc_code.editControl.readOnly || row.cells.upc_code.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.upc_code.editControl.placeholder}}"
            [ngStyle]="row.cells.upc_code.editControl.styles.style"
            [ngClass]="row.cells.upc_code.editControl.styles.classes"
            [matTooltip]="row.cells.upc_code.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="available">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.available.displayControl.styles.style"
          [ngClass]="row.cells.available.displayControl.styles.classes"
          [matTooltip]="row.cells.available.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.available.displayControl.text | formatText : row.cells.available.displayControl.formatType : row.cells.available.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="allocation_quantity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.allocation_quantity.displayControl.styles.style"
          [ngClass]="row.cells.allocation_quantity.displayControl.styles.classes"
          [matTooltip]="row.cells.allocation_quantity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.allocation_quantity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['allocation_quantity_edit']"
            matInput
            numberBox
            [format]="row.cells.allocation_quantity.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.allocation_quantity.editControl.readOnly || row.cells.allocation_quantity.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.allocation_quantity.editControl.placeholder}}"
            [ngStyle]="row.cells.allocation_quantity.editControl.styles.style"
            [ngClass]="row.cells.allocation_quantity.editControl.styles.classes"
            [matTooltip]="row.cells.allocation_quantity.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="commodity_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.commodity_code.displayControl.styles.style"
          [ngClass]="row.cells.commodity_code.displayControl.styles.classes"
          [matTooltip]="row.cells.commodity_code.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.commodity_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['commodity_code_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.commodity_code.editControl.readOnly || row.cells.commodity_code.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.commodity_code.editControl.placeholder}}"
            [ngStyle]="row.cells.commodity_code.editControl.styles.style"
            [ngClass]="row.cells.commodity_code.editControl.styles.classes"
            [matTooltip]="row.cells.commodity_code.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="country_of_origin">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.country_of_origin.displayControl.styles.style"
          [ngClass]="row.cells.country_of_origin.displayControl.styles.classes"
          [matTooltip]="row.cells.country_of_origin.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.country_of_origin.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['country_of_origin_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.country_of_origin.editControl.readOnly || row.cells.country_of_origin.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.country_of_origin.editControl.placeholder}}"
            [ngStyle]="row.cells.country_of_origin.editControl.styles.style"
            [ngClass]="row.cells.country_of_origin.editControl.styles.classes"
            [matTooltip]="row.cells.country_of_origin.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="duty_rate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.duty_rate.displayControl.styles.style"
          [ngClass]="row.cells.duty_rate.displayControl.styles.classes"
          [matTooltip]="row.cells.duty_rate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.duty_rate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['duty_rate_edit']"
            matInput
            numberBox
            [format]="row.cells.duty_rate.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.duty_rate.editControl.readOnly || row.cells.duty_rate.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.duty_rate.editControl.placeholder}}"
            [ngStyle]="row.cells.duty_rate.editControl.styles.style"
            [ngClass]="row.cells.duty_rate.editControl.styles.classes"
            [matTooltip]="row.cells.duty_rate.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="require_outbound_scanning">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['require_outbound_scanning_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.require_outbound_scanning.displayControl.styles.style"
                  [ngClass]="row.cells.require_outbound_scanning.displayControl.styles.classes"
                  [matTooltip]="row.cells.require_outbound_scanning.displayControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.require_outbound_scanning.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['require_outbound_scanning_edit']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.require_outbound_scanning.editControl.styles.style"
                  [ngClass]="row.cells.require_outbound_scanning.editControl.styles.classes"
                  [matTooltip]="row.cells.require_outbound_scanning.editControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.require_outbound_scanning.editControl.label}}</mat-checkbox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="special_instructions">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.special_instructions.displayControl.styles.style"
          [ngClass]="row.cells.special_instructions.displayControl.styles.classes"
          [matTooltip]="row.cells.special_instructions.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.special_instructions.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['special_instructions_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.special_instructions.editControl.readOnly || row.cells.special_instructions.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.special_instructions.editControl.placeholder}}"
            [ngStyle]="row.cells.special_instructions.editControl.styles.style"
            [ngClass]="row.cells.special_instructions.editControl.styles.classes"
            [matTooltip]="row.cells.special_instructions.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
