import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootPrintManager_inventory_location_gridComponent } from './FootPrintManager.inventory_location_grid.component';
import { FootPrintManager_licenseplate_listComponent } from './FootPrintManager.licenseplate_list.component';
import { FootPrintManager_inventory_tasks_gridComponent } from './FootPrintManager.inventory_tasks_grid.component';
import { FootPrintManager_serialnumbers_gridComponent } from './FootPrintManager.serialnumbers_grid.component';
import { FootPrintManager_locations_replenishments_gridComponent } from './FootPrintManager.locations_replenishments_grid.component';
import { FootPrintManager_primary_picks_gridComponent } from './FootPrintManager.primary_picks_grid.component';
import { Inventory_total_available_amount_location_widgetComponent } from './Inventory.total_available_amount_location_widget.component';
import { Inventory_total_gross_weight_widgetComponent } from './Inventory.total_gross_weight_widget.component';
import { Inventory_total_packaged_amount_widgetComponent } from './Inventory.total_packaged_amount_widget.component';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_inventory_location_gridComponent),
    forwardRef(() => FootPrintManager_licenseplate_listComponent),
    forwardRef(() => FootPrintManager_inventory_tasks_gridComponent),
    forwardRef(() => FootPrintManager_serialnumbers_gridComponent),
    forwardRef(() => FootPrintManager_locations_replenishments_gridComponent),
    forwardRef(() => FootPrintManager_primary_picks_gridComponent),
    forwardRef(() => Inventory_total_available_amount_location_widgetComponent),
    forwardRef(() => Inventory_total_gross_weight_widgetComponent),
    forwardRef(() => Inventory_total_packaged_amount_widgetComponent),
  ],
  selector: 'FootPrintManager-single_location_hub',
  templateUrl: './FootPrintManager.single_location_hub.component.html'
})
export class FootPrintManager_single_location_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { locationId: number } = { locationId: null };
  //#region Inputs
  @Input('locationId') set $inParams_locationId(v: number) {
    this.inParams.locationId = v;
  }
  get $inParams_locationId(): number {
    return this.inParams.locationId;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { hubTitle?: string, hubDescription?: string, zonePath?: string } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
    Loose_Flag: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    Allocation_eligible: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });
  
  toolbar = {
      edit: new ToolModel(new ButtonModel('edit', new ButtonStyles(null, null), false, false, false, 'Edit', 'icon-ic_fluent_edit_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      create_inventory: new ToolModel(new ButtonModel('create_inventory', new ButtonStyles(null, null), false, false, false, 'Create inventory', 'icon-ic_fluent_add_20_regular', null)
    , false),
      location_project_assignments: new ToolModel(new ButtonModel('location_project_assignments', new ButtonStyles(null, null), false, false, false, 'Project assignments', 'icon-ic_fluent_text_bullet_list_20_regular', null)
    , false),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      surveys: new ToolModel(new ButtonModel('surveys', new ButtonStyles(null, null), false, false, false, 'Surveys', 'icon-ic_fluent_shifts_availability_20_regular', null)
    , false)
  };

  actionbar = {
      custom_fields: new ToolModel(new ButtonModel('custom_fields', new ButtonStyles(null, null), false, false, false, 'Custom fields', 'icon-ic_fluent_edit_20_regular', null)
    , false)
  };

 filters = {
    status: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Status', false, false),
    Loose_Flag: new FieldModel(new CheckBoxModel(this.formGroup.controls['Loose_Flag'] as DatexFormControl, null, true, false, 'Is loose', null)
, new ControlContainerStyles(null, null), '', false, false),
    Allocation_eligible: new FieldModel(new CheckBoxModel(this.formGroup.controls['Allocation_eligible'] as DatexFormControl, null, true, false, 'Eligible for allocation', null)
, new ControlContainerStyles(null, null), '', false, false),
  };


  filtersets = {
  newGroup1: new FieldsetModel('', true, false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      inventory: new TabItemModel(
        this.rootTabGroup, 
        'Inventory', 
        false,
        ),
      licenseplates_cards: new TabItemModel(
        this.rootTabGroup, 
        'License plates', 
        false,
        ),
      tasks: new TabItemModel(
        this.rootTabGroup, 
        'Activity', 
        false,
        ),
      serialnumbers: new TabItemModel(
        this.rootTabGroup, 
        'Serial numbers', 
        false,
        ),
      replenishments: new TabItemModel(
        this.rootTabGroup, 
        'Replenishments', 
        false,
        ),
      primary_picks: new TabItemModel(
        this.rootTabGroup, 
        'Primary picks', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_inventory_inventory_location_grid_inParams_locationId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = !$utils.isDefinedTrimmed($hub.vars.zonePath) ? $hub.inParams.locationId : null;
      
      return expr;
    }
  
    get $tabs_inventory_inventory_location_grid_inParams_zonePath(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.zonePath;
      
      return expr;
    }
  
    get $tabs_licenseplates_cards_licenseplate_list_inParams_locationId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.locationId;
      
      return expr;
    }
  
    get $tabs_tasks_inventory_tasks_grid_inParams_locationId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.locationId;
      
      return expr;
    }
  
    get $tabs_serialnumbers_serialnumbers_grid_inParams_locationId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.locationId;
      
      return expr;
    }
  
    get $tabs_serialnumbers_serialnumbers_grid_inParams_archived(): boolean {
      const $hub = this;
      const $utils = this.utils;
      const expr = false;
      
      return expr;
    }
  
    cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_locationId: number[];
    get $tabs_replenishments_locations_replenishments_grid_inParams_locationId(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = [$hub.inParams.locationId];
      
      if(!isEqual(this.cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_locationId, expr)) {
        this.cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_locationId = expr;
      }
      return this.cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_locationId;
    }
  
    get $tabs_primary_picks_primary_picks_grid_inParams_location_id(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.locationId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_inventory', { read: FootPrintManager_inventory_location_gridComponent }) $tabs_inventory: FootPrintManager_inventory_location_gridComponent;
      @ViewChild('$tabs_licenseplates_cards', { read: FootPrintManager_licenseplate_listComponent }) $tabs_licenseplates_cards: FootPrintManager_licenseplate_listComponent;
      @ViewChild('$tabs_tasks', { read: FootPrintManager_inventory_tasks_gridComponent }) $tabs_tasks: FootPrintManager_inventory_tasks_gridComponent;
      @ViewChild('$tabs_serialnumbers', { read: FootPrintManager_serialnumbers_gridComponent }) $tabs_serialnumbers: FootPrintManager_serialnumbers_gridComponent;
      @ViewChild('$tabs_replenishments', { read: FootPrintManager_locations_replenishments_gridComponent }) $tabs_replenishments: FootPrintManager_locations_replenishments_gridComponent;
      @ViewChild('$tabs_primary_picks', { read: FootPrintManager_primary_picks_gridComponent }) $tabs_primary_picks: FootPrintManager_primary_picks_gridComponent;
    //#endregion tabs children
    widgets = {
      total_available_amount_widget: new WidgetModel(false),
      total_gross_weight_widget: new WidgetModel(false),
      total_packaged_amount_widget: new WidgetModel(false),
    };
  
    //#region widgets inParams
    get $widgets_total_available_amount_widget_inParams_locationId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.locationId;
      
      return expr;
    }
  
    get $widgets_total_gross_weight_widget_inParams_locationId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.locationId;
      
      return expr;
    }
  
    get $widgets_total_packaged_amount_widget_inParams_locationId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.locationId;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_total_available_amount_widget', { read:  Inventory_total_available_amount_location_widgetComponent }) $widgets_total_available_amount_widget: Inventory_total_available_amount_location_widgetComponent;
      @ViewChild('$widgets_total_gross_weight_widget', { read:  Inventory_total_gross_weight_widgetComponent }) $widgets_total_gross_weight_widget: Inventory_total_gross_weight_widgetComponent;
      @ViewChild('$widgets_total_packaged_amount_widget', { read:  Inventory_total_packaged_amount_widgetComponent }) $widgets_total_packaged_amount_widget: Inventory_total_packaged_amount_widgetComponent;
    //#endregion widgets children

  //#region filters inParams
  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return $hub.vars.hubTitle;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return $hub.vars.hubDescription;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.inventory,
      this.tabs.licenseplates_cards,
      this.tabs.tasks,
      this.tabs.serialnumbers,
      this.tabs.replenishments,
      this.tabs.primary_picks,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.locationId)) {
        this.$missingRequiredInParams.push('locationId');
      }
  }



  initialized = false;

  async $init() {
    this.title = 'Single Location Hub';
    
    const $hub = this;
    const $utils = this.utils;


    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_total_available_amount_widget') {
      if (!isNil(this.$widgets_total_available_amount_widget) && !this.widgets.total_available_amount_widget.hidden) {
        this.$widgets_total_available_amount_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_total_gross_weight_widget') {
      if (!isNil(this.$widgets_total_gross_weight_widget) && !this.widgets.total_gross_weight_widget.hidden) {
        this.$widgets_total_gross_weight_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_total_packaged_amount_widget') {
      if (!isNil(this.$widgets_total_packaged_amount_widget) && !this.widgets.total_packaged_amount_widget.hidden) {
        this.$widgets_total_packaged_amount_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
    if (childToSkip !== '$tabs_inventory') {
      if (!isNil(this.$tabs_inventory) && !this.tabs.inventory.hidden) {
        this.$tabs_inventory.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_licenseplates_cards') {
      if (!isNil(this.$tabs_licenseplates_cards) && !this.tabs.licenseplates_cards.hidden) {
        this.$tabs_licenseplates_cards.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_tasks') {
      if (!isNil(this.$tabs_tasks) && !this.tabs.tasks.hidden) {
        this.$tabs_tasks.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_serialnumbers') {
      if (!isNil(this.$tabs_serialnumbers) && !this.tabs.serialnumbers.hidden) {
        this.$tabs_serialnumbers.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_replenishments') {
      if (!isNil(this.$tabs_replenishments) && !this.tabs.replenishments.hidden) {
        this.$tabs_replenishments.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_primary_picks') {
      if (!isNil(this.$tabs_primary_picks) && !this.tabs.primary_picks.hidden) {
        this.$tabs_primary_picks.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_create_inventory_clicked(event = null) {
    return this.on_create_inventory_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_inventory_clickedInternal(
    $hub: FootPrintManager_single_location_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const locations = (await $datasources.Locations.ds_get_location_by_locationId.get({ locationId: $hub.inParams.locationId })).result;
  if ($utils.isDefined(locations)) {
  
      var typeId = locations[0].TypeId;
      var warehouseId = locations[0].WarehouseId;
  
      if ($utils.isAllDefined(typeId,warehouseId)) {
  
          // If the location is of type inventory pass in the location Id into the creation form, else pass in only the warehouse.
          if (typeId === 3) {
              const dialogResult = await $shell.FootPrintManager.openinventory_creation_formDialog({
                  locationId: $hub.inParams.locationId
              });
              const userConfirmed = dialogResult.confirm;
  
              if (userConfirmed) {
                  $hub.refresh()
              }
  
  
          }
          else {
              const dialogResult = await $shell.FootPrintManager.openinventory_creation_formDialog({
                  warehouseId: warehouseId
              });
              const userConfirmed = dialogResult.confirm;
  
              if (userConfirmed) {
                  $hub.refresh()
              }
  
  
          }
  
  
      }
      else {
          throw new Error ('Unable to determine location warehouse or location type.')
      }
  
  }
  else {
      throw new Error ('Unable to extract location properties.')
  }
  
  
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_single_location_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const hubLocation = (await $datasources.Locations.ds_get_location_by_locationId.get({ locationId: $hub.inParams.locationId })).result[0];
  let title = `${hubLocation.Type.Name}: ${hubLocation.Name}`;
  var description = `Warehouse: ${hubLocation.Warehouse?.Name}`;
  
  $hub.filters.status.control.text = hubLocation.Status.Name;
  
  if ($utils.isDefined(location)) {
      if (hubLocation.TypeId == 3) { // Inventory location
          $hub.filters.Loose_Flag.hidden = false;
          $hub.filters.Loose_Flag.control.value = hubLocation?.IsLoose ?? false;
  
          $hub.filters.Allocation_eligible.control.value = hubLocation?.EligibleForAllocation ?? false;
          $hub.filters.Allocation_eligible.hidden = false;
      } else if (hubLocation.TypeId === 1) { // Zone location
          $hub.vars.zonePath = hubLocation.Path;
  
          $hub.filters.Allocation_eligible.hidden = true;
          $hub.filters.Loose_Flag.hidden = true;
      }
  }
  else {
      $hub.filters.Loose_Flag.control.value = false;
      $hub.filters.Loose_Flag.hidden = true;
      $hub.filters.Allocation_eligible.control.value = false;
      $hub.filters.Allocation_eligible.hidden = true;
  }
  
  $hub.title = title;
  $hub.vars.hubDescription = description;
  $hub.vars.hubTitle = title;
  
  // Hide serialnumbers tab if no serialnumbers exists
  const serialnumber = (await $datasources.SerialNumbers.ds_get_serialnumber_by_various_top1.get({ locationId: $hub.inParams.locationId })).result;
  if ($utils.isDefined(serialnumber)) {
  
      $hub.tabs.serialnumbers.hidden = false;
  }
  else {
      $hub.tabs.serialnumbers.hidden = true;
  }
  
  
  $hub.toolbar.create_inventory.hidden = (await $operations.FootPrintManager.Disable_Inventory_Creation.isAuthorized());
  }
  on_edit_clicked(event = null) {
    return this.on_edit_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_edit_clickedInternal(
    $hub: FootPrintManager_single_location_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  const dialogResult = await $shell.FootPrintManager.openlocation_editorDialog({ locationId: $hub.inParams.locationId }, 'flyout');
  const userConfirmed = dialogResult.confirm;
  
  if (userConfirmed) {
      await $hub.on_init();
      await $hub.refresh();
  }
  
  }
  on_project_assignments_clicked(event = null) {
    return this.on_project_assignments_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_assignments_clickedInternal(
    $hub: FootPrintManager_single_location_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  $shell.FootPrintManager.openlocation_projects_gridDialog({locationId: $hub.inParams.locationId},'flyout');
  }
  on_custom_fields_clicked(event = null) {
    return this.on_custom_fields_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_custom_fields_clickedInternal(
    $hub: FootPrintManager_single_location_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $shell.FootPrintManager.openentity_user_defined_field_values_gridDialog({ entityType: 'LocationContainer', entityKeys: [{name: 'Id', value: $hub.inParams.locationId}]}, 'flyout', EModalSize.Large);
  }
  on_surveys_clicked(event = null) {
    return this.on_surveys_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_surveys_clickedInternal(
    $hub: FootPrintManager_single_location_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  await $shell.FootPrintManager.opensubmitted_surveys_gridDialog({
      entities: [{ type: 'Location', ids: [$hub.inParams.locationId] }],
      operationContextTypes: null,
      warehouseIds: null
  }, 'modal');
  
  
  }
  //#endregion private flows
}
