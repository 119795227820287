import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_ds_so_report_detailsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId?: number }): 
  Promise<{ result: { materialID?: number, packagedAmount?: number, lineNumber?: number, notes?: string, establishmentIdentifier?: string, marks?: string, lotId?: number, totalCost?: number, price?: number, packaging?: string, OrderId?: number, PackagedId?: number, MaterialId?: number, materialDetails?: { Id?: number, ControllerTypeId?: number, Description?: string, IsFixedLength?: boolean, IsFixedVolume?: boolean, IsFixedWeight?: boolean, LookupCode?: string, ProjectId?: number }, lotDetails?: { Id?: number, LastReceiveDate?: string, LookupCode?: string, MaterialId?: number, Notes?: string, VendorLotId?: number, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string }, Status?: { Id?: number, Name?: string }, Material?: { LookupCode?: string, MixingRuleId?: number, ProjectId?: number, Project?: { LookupCode?: string, OwnerId?: number }, StorageCategoryRule?: { Id?: number, Name?: string } } } }[] }> 
  {
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_so_report_details/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { materialID?: number, packagedAmount?: number, lineNumber?: number, notes?: string, establishmentIdentifier?: string, marks?: string, lotId?: number, totalCost?: number, price?: number, packaging?: string, OrderId?: number, PackagedId?: number, MaterialId?: number, materialDetails?: { Id?: number, ControllerTypeId?: number, Description?: string, IsFixedLength?: boolean, IsFixedVolume?: boolean, IsFixedWeight?: boolean, LookupCode?: string, ProjectId?: number }, lotDetails?: { Id?: number, LastReceiveDate?: string, LookupCode?: string, MaterialId?: number, Notes?: string, VendorLotId?: number, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string }, Status?: { Id?: number, Name?: string }, Material?: { LookupCode?: string, MixingRuleId?: number, ProjectId?: number, Project?: { LookupCode?: string, OwnerId?: number }, StorageCategoryRule?: { Id?: number, Name?: string } } } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_so_report_details/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId?: number, $keys: { OrderId?: number, PackagedId?: number, MaterialId?: number }[] }): 
  Promise<{ result: { materialID?: number, packagedAmount?: number, lineNumber?: number, notes?: string, establishmentIdentifier?: string, marks?: string, lotId?: number, totalCost?: number, price?: number, packaging?: string, OrderId?: number, PackagedId?: number, MaterialId?: number, materialDetails?: { Id?: number, ControllerTypeId?: number, Description?: string, IsFixedLength?: boolean, IsFixedVolume?: boolean, IsFixedWeight?: boolean, LookupCode?: string, ProjectId?: number }, lotDetails?: { Id?: number, LastReceiveDate?: string, LookupCode?: string, MaterialId?: number, Notes?: string, VendorLotId?: number, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string }, Status?: { Id?: number, Name?: string }, Material?: { LookupCode?: string, MixingRuleId?: number, ProjectId?: number, Project?: { LookupCode?: string, OwnerId?: number }, StorageCategoryRule?: { Id?: number, Name?: string } } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_so_report_details/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      orderId?: number    }) {
      return false;
    }
}
