import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_ds_packing_list_report_zagg_headerService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId?: number }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, LookupCode?: string, Notes?: string, OwnerReference?: string, RequestedDeliveryDate?: string, VendorReference?: string, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { Id?: number, ItemCount?: number, LookupCode?: string, TrackingIdentifier?: string, Carrier?: { Name?: string }, CarrierServiceType?: { Name?: string } } }[], Project?: { Name?: string, Owner?: { Name?: string } }, shipToAddress?: { AttentionOf?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, PrimaryTelephone?: string, OrderId?: number, accountName?: string, accountLookupcode?: string }, shipByAddress?: { Id?: number, Project?: { Id?: number, Name?: string, Owner?: { Id?: number, Name?: string, OwnersContactsLookup?: { ContactId?: number, Contact?: { Id?: number, FirstName?: string, LastName?: string, PrimaryTelephone?: string, TypeId?: number, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } }, soldByAddress?: { Id?: number, Project?: { Id?: number, Name?: string, Owner?: { Id?: number, Name?: string, OwnersContactsLookup?: { ContactId?: number, Contact?: { Id?: number, FirstName?: string, LastName?: string, PrimaryTelephone?: string, TypeId?: number, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } } } }> 
  {
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_packing_list_report_zagg_header/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      orderId?: number    }) {
      return false;
    }
}
