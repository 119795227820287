import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootPrintManager_invoices_gridComponent } from './FootPrintManager.invoices_grid.component';
import { Invoices_invoice_total_units_with_filters_widgetComponent } from './Invoices.invoice_total_units_with_filters_widget.component';
import { Invoices_invoice_total_billed_with_filters_widgetComponent } from './Invoices.invoice_total_billed_with_filters_widget.component';
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Invoices_invoice_statuses_dd_multiComponent } from './Invoices.invoice_statuses_dd_multi.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_invoices_gridComponent),
    forwardRef(() => Invoices_invoice_total_units_with_filters_widgetComponent),
    forwardRef(() => Invoices_invoice_total_billed_with_filters_widgetComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Invoices_invoice_statuses_dd_multiComponent),
  ],
  selector: 'FootPrintManager-invoices_hub',
  templateUrl: './FootPrintManager.invoices_hub.component.html'
})
export class FootPrintManager_invoices_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { ownerId?: number, projectId?: number } = { ownerId: null, projectId: null };
  //#region Inputs
  @Input('ownerId') set $inParams_ownerId(v: number) {
    this.inParams.ownerId = v;
  }
  get $inParams_ownerId(): number {
    return this.inParams.ownerId;
  }
  @Input('projectId') set $inParams_projectId(v: number) {
    this.inParams.projectId = v;
  }
  get $inParams_projectId(): number {
    return this.inParams.projectId;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { createdFrom?: string, createdTo?: string } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
    owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
      create_invoice: new ToolModel(new ButtonModel('create_invoice', new ButtonStyles(null, null), false, false, false, 'New invoice', 'icon-ic_fluent_add_20_regular', null)
    , false)
  };

  actionbar = {
  };

 filters = {
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Owner', false, false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Project', false, false),
    status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['status'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Status', false, false),
  };


  filtersets = {
  newGroup1: new FieldsetModel('Filters', false, true, false, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      invoices: new TabItemModel(
        this.rootTabGroup, 
        'Invoices', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_invoices_invoices_grid_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $tabs_invoices_invoices_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_invoices_invoices_grid_inParams_statusIds: number[];
    get $tabs_invoices_invoices_grid_inParams_statusIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.status.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_invoices_invoices_grid_inParams_statusIds, expr)) {
        this.cacheValueFor_$tabs_invoices_invoices_grid_inParams_statusIds = expr;
      }
      return this.cacheValueFor_$tabs_invoices_invoices_grid_inParams_statusIds;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_invoices', { read: FootPrintManager_invoices_gridComponent }) $tabs_invoices: FootPrintManager_invoices_gridComponent;
    //#endregion tabs children
    widgets = {
      invoice_total_units_with_filters_widget: new WidgetModel(false),
      invoice_total_billed_with_filters_widget: new WidgetModel(false),
    };
  
    //#region widgets inParams
    get $widgets_invoice_total_units_with_filters_widget_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $widgets_invoice_total_units_with_filters_widget_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$widgets_invoice_total_units_with_filters_widget_inParams_statusIds: number[];
    get $widgets_invoice_total_units_with_filters_widget_inParams_statusIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.status.control.value;
      
      if(!isEqual(this.cacheValueFor_$widgets_invoice_total_units_with_filters_widget_inParams_statusIds, expr)) {
        this.cacheValueFor_$widgets_invoice_total_units_with_filters_widget_inParams_statusIds = expr;
      }
      return this.cacheValueFor_$widgets_invoice_total_units_with_filters_widget_inParams_statusIds;
    }
  
    get $widgets_invoice_total_units_with_filters_widget_inParams_createdFrom(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.createdFrom;
      
      return expr;
    }
  
    get $widgets_invoice_total_units_with_filters_widget_inParams_createdTo(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.createdTo;
      
      return expr;
    }
  
    get $widgets_invoice_total_billed_with_filters_widget_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $widgets_invoice_total_billed_with_filters_widget_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$widgets_invoice_total_billed_with_filters_widget_inParams_statusIds: number[];
    get $widgets_invoice_total_billed_with_filters_widget_inParams_statusIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.status.control.value;
      
      if(!isEqual(this.cacheValueFor_$widgets_invoice_total_billed_with_filters_widget_inParams_statusIds, expr)) {
        this.cacheValueFor_$widgets_invoice_total_billed_with_filters_widget_inParams_statusIds = expr;
      }
      return this.cacheValueFor_$widgets_invoice_total_billed_with_filters_widget_inParams_statusIds;
    }
  
    get $widgets_invoice_total_billed_with_filters_widget_inParams_createdFrom(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.createdFrom;
      
      return expr;
    }
  
    get $widgets_invoice_total_billed_with_filters_widget_inParams_createdTo(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.createdTo;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_invoice_total_units_with_filters_widget', { read:  Invoices_invoice_total_units_with_filters_widgetComponent }) $widgets_invoice_total_units_with_filters_widget: Invoices_invoice_total_units_with_filters_widgetComponent;
      @ViewChild('$widgets_invoice_total_billed_with_filters_widget', { read:  Invoices_invoice_total_billed_with_filters_widgetComponent }) $widgets_invoice_total_billed_with_filters_widget: Invoices_invoice_total_billed_with_filters_widgetComponent;
    //#endregion widgets children

  //#region filters inParams
  get $fields_owner_selector_inParams_statusId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 1;
    
    return expr;
  }



  get $fields_owner_selector_inParams_projectId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.project.control.value;
    
    return expr;
  }



  get $fields_project_selector_inParams_statusId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 1;
    
    return expr;
  }



  get $fields_project_selector_inParams_ownerId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.owner.control.value;
    
    return expr;
  }



  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Invoices hub`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Your landing page to view invoices and their related actions.`;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.invoices,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Invoices Hub';
    
    const $hub = this;
    const $utils = this.utils;

    (this.filters.status.control as SelectBoxModel).reset([1]);

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['owner']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_change();
      });
    this.formGroup
      .controls['project']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_change();
      });
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_invoice_total_units_with_filters_widget') {
      if (!isNil(this.$widgets_invoice_total_units_with_filters_widget) && !this.widgets.invoice_total_units_with_filters_widget.hidden) {
        this.$widgets_invoice_total_units_with_filters_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_invoice_total_billed_with_filters_widget') {
      if (!isNil(this.$widgets_invoice_total_billed_with_filters_widget) && !this.widgets.invoice_total_billed_with_filters_widget.hidden) {
        this.$widgets_invoice_total_billed_with_filters_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
    if (childToSkip !== '$tabs_invoices') {
      if (!isNil(this.$tabs_invoices) && !this.tabs.invoices.hidden) {
        this.$tabs_invoices.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_create_invoice_clicked(event = null) {
    return this.on_create_invoice_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_invoice_clickedInternal(
    $hub: FootPrintManager_invoices_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  
  
  
  const dialogResult = (await $shell.FootPrintManager.openinvoice_creation_form({
      ownerId: $hub.filters.owner.control.value,
      projectId: $hub.filters.project.control.value
      }));
  
  
  
  }
  on_owner_change(event = null) {
    return this.on_owner_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changeInternal(
    $hub: FootPrintManager_invoices_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $hub.filters.project.control.value = null;
  }
  on_project_change(event = null) {
    return this.on_project_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changeInternal(
    $hub: FootPrintManager_invoices_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const projectId = $hub.filters.project.control.value
  
  
  // Set Owner from Project
  if ($utils.isDefined(projectId) && !$utils.isDefined($hub.filters.owner.control.value)) {
      const project = (await $datasources.Owners.ds_get_project_by_projectId.get({
          projectId: projectId
      })).result;
     
      if ($utils.isDefined(project)) {
  
          $hub.filters.owner.control.value = project[0].OwnerId;
  
      }
  
  }
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_invoices_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if (await $operations.FootPrintManager.Disable_Navigation_Billing_Invoices.isAuthorized()) {
      
      $hub.close();
      throw new Error ('This screen currently requires authorization, please see your system admin for access.')
    
  }
  
  
  
  $hub.filters.owner.control.value = $hub.inParams.ownerId
  $hub.filters.project.control.value = $hub.inParams.projectId
  
  if ((await $operations.FootPrintManager.Disable_Billing_InvoiceCreation.isAuthorized())) {
      $hub.toolbar.create_invoice.hidden = true;
  }
  }
  on_invoice_grid_filters_updated(event = null) {
    return this.on_invoice_grid_filters_updatedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_invoice_grid_filters_updatedInternal(
    $hub: FootPrintManager_invoices_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Set Grid Variables to updated filter values
  $hub.vars.createdFrom = $event.createdFrom;
  $hub.vars.createdTo = $event.createdTo;
  
  }
  //#endregion private flows
}
