import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { WarehouseTransfers_outbound_bill_of_lading_reportComponent } from './WarehouseTransfers.outbound_bill_of_lading_report.component';
import { WarehouseTransfers_outbound_pick_slip_reportComponent } from './WarehouseTransfers.outbound_pick_slip_report.component';
import { WarehouseTransfers_warehousetransfer_statuses_dd_singleComponent } from './WarehouseTransfers.warehousetransfer_statuses_dd_single.component';
import { WarehouseTransfers_warehousetransfer_types_dd_singleComponent } from './WarehouseTransfers.warehousetransfer_types_dd_single.component';
import { WarehouseTransfers_warehousetransfer_statuses_dd_multiComponent } from './WarehouseTransfers.warehousetransfer_statuses_dd_multi.component';
import { WarehouseTransfers_warehousetransfer_types_dd_multiComponent } from './WarehouseTransfers.warehousetransfer_types_dd_multi.component';
import { WarehouseTransfers_orderline_total_amount_widgetComponent } from './WarehouseTransfers.orderline_total_amount_widget.component';
import { WarehouseTransfers_orderline_total_gross_received_widgetComponent } from './WarehouseTransfers.orderline_total_gross_received_widget.component';
import { WarehouseTransfers_orderline_total_received_widgetComponent } from './WarehouseTransfers.orderline_total_received_widget.component';
import { WarehouseTransfers_outbound_order_total_picked_gross_widgetComponent } from './WarehouseTransfers.outbound_order_total_picked_gross_widget.component';
import { WarehouseTransfers_outbound_order_total_picked_units_widgetComponent } from './WarehouseTransfers.outbound_order_total_picked_units_widget.component';
import { WarehouseTransfers_outbound_order_total_units_widgetComponent } from './WarehouseTransfers.outbound_order_total_units_widget.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class WarehouseTransfers_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public WarehouseTransfers: WarehouseTransfers_ShellService = this;

  public openoutbound_bill_of_lading_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    this.logger.log('WarehouseTransfers', 'outbound_bill_of_lading_report');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Bill of lading',
        referenceName: 'WarehouseTransfers_outbound_bill_of_lading_report',
        component: WarehouseTransfers_outbound_bill_of_lading_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openoutbound_bill_of_lading_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('WarehouseTransfers', 'outbound_bill_of_lading_report');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      WarehouseTransfers_outbound_bill_of_lading_reportComponent,
      'Bill of lading',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openoutbound_pick_slip_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    this.logger.log('WarehouseTransfers', 'outbound_pick_slip_report');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Pick Slip',
        referenceName: 'WarehouseTransfers_outbound_pick_slip_report',
        component: WarehouseTransfers_outbound_pick_slip_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openoutbound_pick_slip_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('WarehouseTransfers', 'outbound_pick_slip_report');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      WarehouseTransfers_outbound_pick_slip_reportComponent,
      'Pick Slip',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'WarehouseTransfers_outbound_bill_of_lading_report') {
      this.logger.log('WarehouseTransfers', 'outbound_bill_of_lading_report');
      const title = 'Bill of lading';
      const component = WarehouseTransfers_outbound_bill_of_lading_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'WarehouseTransfers_outbound_pick_slip_report') {
      this.logger.log('WarehouseTransfers', 'outbound_pick_slip_report');
      const title = 'Pick Slip';
      const component = WarehouseTransfers_outbound_pick_slip_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'WarehouseTransfers_warehousetransfer_statuses_dd_single') {
      this.logger.log('WarehouseTransfers', 'warehousetransfer_statuses_dd_single');
      const title = 'warehousetransfer_statuses_dd';
      const component = WarehouseTransfers_warehousetransfer_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'WarehouseTransfers_warehousetransfer_types_dd_single') {
      this.logger.log('WarehouseTransfers', 'warehousetransfer_types_dd_single');
      const title = 'warehousetransfer_types_dd';
      const component = WarehouseTransfers_warehousetransfer_types_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'WarehouseTransfers_warehousetransfer_statuses_dd_multi') {
      this.logger.log('WarehouseTransfers', 'warehousetransfer_statuses_dd_multi');
      const title = 'warehousetransfer_statuses_dd';
      const component = WarehouseTransfers_warehousetransfer_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'WarehouseTransfers_warehousetransfer_types_dd_multi') {
      this.logger.log('WarehouseTransfers', 'warehousetransfer_types_dd_multi');
      const title = 'warehousetransfer_types_dd';
      const component = WarehouseTransfers_warehousetransfer_types_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'WarehouseTransfers_orderline_total_amount_widget') {
      this.logger.log('WarehouseTransfers', 'orderline_total_amount_widget');
      const title = 'Total amount';
      const component = WarehouseTransfers_orderline_total_amount_widgetComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'WarehouseTransfers_orderline_total_gross_received_widget') {
      this.logger.log('WarehouseTransfers', 'orderline_total_gross_received_widget');
      const title = 'Total gross recv';
      const component = WarehouseTransfers_orderline_total_gross_received_widgetComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'WarehouseTransfers_orderline_total_received_widget') {
      this.logger.log('WarehouseTransfers', 'orderline_total_received_widget');
      const title = 'Total received';
      const component = WarehouseTransfers_orderline_total_received_widgetComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'WarehouseTransfers_outbound_order_total_picked_gross_widget') {
      this.logger.log('WarehouseTransfers', 'outbound_order_total_picked_gross_widget');
      const title = 'Total gross picked';
      const component = WarehouseTransfers_outbound_order_total_picked_gross_widgetComponent;
      const inParams:{ orderId: number, shipmentId: number } = { orderId: null, shipmentId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'WarehouseTransfers_outbound_order_total_picked_units_widget') {
      this.logger.log('WarehouseTransfers', 'outbound_order_total_picked_units_widget');
      const title = 'Total picked';
      const component = WarehouseTransfers_outbound_order_total_picked_units_widgetComponent;
      const inParams:{ orderId: number, shipmentId: number } = { orderId: null, shipmentId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'WarehouseTransfers_outbound_order_total_units_widget') {
      this.logger.log('WarehouseTransfers', 'outbound_order_total_units_widget');
      const title = 'Total amount';
      const component = WarehouseTransfers_outbound_order_total_units_widgetComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
