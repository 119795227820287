import {
Component,
OnInit,
OnDestroy,
OnChanges,
Input,
SimpleChanges,
Output,
EventEmitter,
Inject
} from '@angular/core';

import {
FormGroup,
Validators
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import {
TextBoxModel,
NumberBoxModel,
SelectBoxModel,
ESelectBoxType,
DateBoxModel,
CheckBoxModel,
TextModel,
LabelModel,
ButtonModel,
SplitButtonModel,
SeparatorModel,
ImageModel,
DrawModel,
CodeBoxModel,
ButtonStyles
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import * as vkbeautify from 'vkbeautify';
import { BaseComponent } from './components/base.component';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { EntityImport_ShellService } from './EntityImport.shell.service';
import { EntityImport_OperationService } from './EntityImport.operation.service';
import { EntityImport_DatasourceService } from './EntityImport.datasource.index';
import { EntityImport_FlowService } from './EntityImport.flow.index';
import { EntityImport_ReportService } from './EntityImport.report.index';
import { EntityImport_LocalizationService } from './EntityImport.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './EntityImport.frontend.types'
import { $frontendTypes as $types} from './EntityImport.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'EntityImport-render_entity_import_request_code_editor',
  templateUrl: './EntityImport.render_entity_import_request_code_editor.component.html'
})
export class EntityImport_render_entity_import_request_code_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges
{

inParams: { request_id: string } = { request_id: null };
//#region Inputs
@Input('request_id') set $inParams_request_id(v: string) {
  this.inParams.request_id = v;
}
get $inParams_request_id(): string {
  return this.inParams.request_id;
}
//#endregion Inputs

//#region Outputs
@Output()
$finish = new EventEmitter();
//#endregion

//#region title
// Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
// The title is often meant to be shown from the parent (shell breadcrumb for example)
// and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
// the parent has already been checked and the child now change something on the parent 
// in dev, CD is run twice
$titleChange = new EventEmitter<string>(true);
private $_title: string;
get title(): string {
  return this.$_title;
}
set title(t: string) {
  this.$_title = t;
  this.$titleChange.emit(this.$_title);
}
//#endregion title
codeMirrorOptions = {
theme: 'base16-light',
mode: 'application/xml',
lineNumbers: true,
lineWrapping: true,
foldGutter: true,
gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
autoCloseBrackets: true,
matchBrackets: true,
lint: true
};

mode = 'application/xml';

value: string;

//#region Variables
vars: { current_page_number?: number, total_pages?: number } = { };
//#endregion
//#region Events

//#endregion

toolbar = {
  beautify: new ToolModel(new ButtonModel('beautify', new ButtonStyles(null, null), false, false, false, 'Beautify', 'icon-ic_fluent_align_left_20_regular', null)
, false),
  separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
, false),
  first_chunk: new ToolModel(new ButtonModel('first_chunk', new ButtonStyles(null, null), true, false, false, ' ', 'icon-ic_fluent_previous_20_regular', null)
, false),
  previous_chunk: new ToolModel(new ButtonModel('previous_chunk', new ButtonStyles(null, null), true, false, false, ' ', 'icon-ic_fluent_previous_frame_20_regular', null)
, false),
  page: new ToolModel(new ButtonModel('page', new ButtonStyles(null, null), true, false, false, 'Page 1', '', null)
, false),
  next_chunk: new ToolModel(new ButtonModel('next_chunk', new ButtonStyles(null, null), true, false, false, ' ', 'icon-ic_fluent_next_frame_20_regular', null)
, false),
  last_chunk: new ToolModel(new ButtonModel('last_chunk', new ButtonStyles(null, null), true, false, false, ' ', 'icon-ic_fluent_next_20_regular', null)
, false)
};

constructor(
private utils: UtilsService,
private settings: SettingsValuesService,
private shell: EntityImport_ShellService,
private datasources: EntityImport_DatasourceService,
private flows: EntityImport_FlowService,
private reports: EntityImport_ReportService,
private localization: EntityImport_LocalizationService,
private operations: EntityImport_OperationService,
private logger: CleanupLoggerService,
) {
  super();
}

ngOnInit(): void {
  this.$checkRequiredInParams();
  if (!this.$hasMissingRequiredInParams) {
    this.$init();
  } else {
    this.$initEmpty();
  }
}

private $isFirstNgOnChanges = true;
ngOnChanges(changes: SimpleChanges): void {
  if (this.$isFirstNgOnChanges) {
    this.$isFirstNgOnChanges = false;
  } else {
    this.$checkRequiredInParams();
    if(!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
}

private $unsubscribe$ = new Subject();
ngOnDestroy(): void {
  this.$unsubscribe$.next(null);
  this.$unsubscribe$.complete();
}
$missingRequiredInParams = [];
get $hasMissingRequiredInParams(): boolean {
  return !!this.$missingRequiredInParams.length;
}

$checkRequiredInParams() {
  this.$missingRequiredInParams = [];
    if(isNil(this.inParams.request_id)) {
      this.$missingRequiredInParams.push('request_id');
    }
}

initialized = false;

async $init() {
this.title = 'Entity import request XML';

const $codeEditor = this;
const $utils = this.utils;


await this.on_init();

this.initialized = true;
}

onCodeMirrorLoaded(editor: CodemirrorComponent){
  //temporary fix to reset the left margin on the gutters. on load an extra left space is being added to the CodeMirror-gutters div element, which causes it to overlap the code content. this refresh re-calculates the space needed and removes the gap. a better implementation could be possible.
  setTimeout(() =>{editor.codeMirror.refresh() }, 300);
}

close() {
this.$finish.emit();
}

beautify(): void {
this.value = this.mode === 'application/xml' ? vkbeautify.xml(this.value) : vkbeautify.json(this.value);
}

minify(): void {
this.value = this.mode === 'application/xml' ? vkbeautify.xmlmin(this.value, [, true]) : vkbeautify.jsonmin(this.value);
}

refresh(
skipParent = false,
skipChildren = false,
childToSkip: string = null) {
}

//#region private flows
on_init(event = null) {
  return this.on_initInternal(
    this,
this.shell,
    this.datasources,
    this.flows,
    this.reports,
    this.settings,
    this.operations,
    this.utils,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    // this.localization,
    event);
}
async on_initInternal(
  $codeEditor: EntityImport_render_entity_import_request_code_editorComponent,

  $shell: EntityImport_ShellService,
  $datasources: EntityImport_DatasourceService,
  $flows: EntityImport_FlowService,
  $reports: EntityImport_ReportService,
  $settings: SettingsValuesService,
  $operations: EntityImport_OperationService,
  $utils: UtilsService,
  // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
  //$l10n: EntityImport_LocalizationService,
  $event: any
) {
$codeEditor.vars.current_page_number = 1;

await $codeEditor.load_chunk();

$codeEditor.set_state();
/**************************************
 * FUNCTIONS
***************************************/

}
load_chunk(event = null) {
  return this.load_chunkInternal(
    this,
this.shell,
    this.datasources,
    this.flows,
    this.reports,
    this.settings,
    this.operations,
    this.utils,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    // this.localization,
    event);
}
async load_chunkInternal(
  $codeEditor: EntityImport_render_entity_import_request_code_editorComponent,

  $shell: EntityImport_ShellService,
  $datasources: EntityImport_DatasourceService,
  $flows: EntityImport_FlowService,
  $reports: EntityImport_ReportService,
  $settings: SettingsValuesService,
  $operations: EntityImport_OperationService,
  $utils: UtilsService,
  // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
  //$l10n: EntityImport_LocalizationService,
  $event: any
) {
$codeEditor.value = '';

let result = await $flows.EntityImport.get_entity_import_request_xml_chunk_flow({
    request_id: $codeEditor.inParams.request_id,
    page_number: $codeEditor.vars.current_page_number
});

$codeEditor.vars.total_pages = result.total_chunks;

if (result.chunk.endsWith('</ei:EntityImport>')) {
    $codeEditor.value = '<EntityImport>' + result.chunk;
    $codeEditor.beautify();
    let lines = $codeEditor.value.split('\n');
    lines.shift();

    $codeEditor.value = lines.join('\n');

} else {
    $codeEditor.value = result.chunk;
    $codeEditor.beautify();
}



}
on_next_chunk_clicked(event = null) {
  return this.on_next_chunk_clickedInternal(
    this,
this.shell,
    this.datasources,
    this.flows,
    this.reports,
    this.settings,
    this.operations,
    this.utils,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    // this.localization,
    event);
}
async on_next_chunk_clickedInternal(
  $codeEditor: EntityImport_render_entity_import_request_code_editorComponent,

  $shell: EntityImport_ShellService,
  $datasources: EntityImport_DatasourceService,
  $flows: EntityImport_FlowService,
  $reports: EntityImport_ReportService,
  $settings: SettingsValuesService,
  $operations: EntityImport_OperationService,
  $utils: UtilsService,
  // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
  //$l10n: EntityImport_LocalizationService,
  $event: any
) {
$codeEditor.vars.current_page_number++;


$codeEditor.toolbar.first_chunk.control.readOnly = true;
$codeEditor.toolbar.previous_chunk.control.readOnly = true;
$codeEditor.toolbar.next_chunk.control.readOnly = true;
$codeEditor.toolbar.last_chunk.control.readOnly = true;

$codeEditor.toolbar.next_chunk.control.icon = 'datex-default-spinner';
await $codeEditor.load_chunk();
$codeEditor.toolbar.next_chunk.control.icon = 'icon icon-ic_fluent_next_frame_20_regular';

$codeEditor.set_state();
}
on_previous_chunk_clicked(event = null) {
  return this.on_previous_chunk_clickedInternal(
    this,
this.shell,
    this.datasources,
    this.flows,
    this.reports,
    this.settings,
    this.operations,
    this.utils,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    // this.localization,
    event);
}
async on_previous_chunk_clickedInternal(
  $codeEditor: EntityImport_render_entity_import_request_code_editorComponent,

  $shell: EntityImport_ShellService,
  $datasources: EntityImport_DatasourceService,
  $flows: EntityImport_FlowService,
  $reports: EntityImport_ReportService,
  $settings: SettingsValuesService,
  $operations: EntityImport_OperationService,
  $utils: UtilsService,
  // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
  //$l10n: EntityImport_LocalizationService,
  $event: any
) {
$codeEditor.vars.current_page_number--;


$codeEditor.toolbar.first_chunk.control.readOnly = true;
$codeEditor.toolbar.previous_chunk.control.readOnly = true;
$codeEditor.toolbar.next_chunk.control.readOnly = true;
$codeEditor.toolbar.last_chunk.control.readOnly = true;

$codeEditor.toolbar.previous_chunk.control.icon = 'datex-default-spinner';
await $codeEditor.load_chunk();
$codeEditor.toolbar.previous_chunk.control.icon = 'icon icon-ic_fluent_previous_frame_20_regular';

$codeEditor.set_state();
}
on_beautify_clicked(event = null) {
  return this.on_beautify_clickedInternal(
    this,
this.shell,
    this.datasources,
    this.flows,
    this.reports,
    this.settings,
    this.operations,
    this.utils,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    // this.localization,
    event);
}
async on_beautify_clickedInternal(
  $codeEditor: EntityImport_render_entity_import_request_code_editorComponent,

  $shell: EntityImport_ShellService,
  $datasources: EntityImport_DatasourceService,
  $flows: EntityImport_FlowService,
  $reports: EntityImport_ReportService,
  $settings: SettingsValuesService,
  $operations: EntityImport_OperationService,
  $utils: UtilsService,
  // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
  //$l10n: EntityImport_LocalizationService,
  $event: any
) {
$codeEditor.beautify();
}
on_first_chunk_clicked(event = null) {
  return this.on_first_chunk_clickedInternal(
    this,
this.shell,
    this.datasources,
    this.flows,
    this.reports,
    this.settings,
    this.operations,
    this.utils,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    // this.localization,
    event);
}
async on_first_chunk_clickedInternal(
  $codeEditor: EntityImport_render_entity_import_request_code_editorComponent,

  $shell: EntityImport_ShellService,
  $datasources: EntityImport_DatasourceService,
  $flows: EntityImport_FlowService,
  $reports: EntityImport_ReportService,
  $settings: SettingsValuesService,
  $operations: EntityImport_OperationService,
  $utils: UtilsService,
  // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
  //$l10n: EntityImport_LocalizationService,
  $event: any
) {
$codeEditor.vars.current_page_number = 1;


$codeEditor.toolbar.first_chunk.control.readOnly = true;
$codeEditor.toolbar.previous_chunk.control.readOnly = true;
$codeEditor.toolbar.next_chunk.control.readOnly = true;
$codeEditor.toolbar.last_chunk.control.readOnly = true;

$codeEditor.toolbar.first_chunk.control.icon = 'datex-default-spinner';
await $codeEditor.load_chunk();
$codeEditor.toolbar.first_chunk.control.icon = 'icon icon-ic_fluent_previous_20_regular';

$codeEditor.set_state();
}
on_last_chunk_clicked(event = null) {
  return this.on_last_chunk_clickedInternal(
    this,
this.shell,
    this.datasources,
    this.flows,
    this.reports,
    this.settings,
    this.operations,
    this.utils,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    // this.localization,
    event);
}
async on_last_chunk_clickedInternal(
  $codeEditor: EntityImport_render_entity_import_request_code_editorComponent,

  $shell: EntityImport_ShellService,
  $datasources: EntityImport_DatasourceService,
  $flows: EntityImport_FlowService,
  $reports: EntityImport_ReportService,
  $settings: SettingsValuesService,
  $operations: EntityImport_OperationService,
  $utils: UtilsService,
  // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
  //$l10n: EntityImport_LocalizationService,
  $event: any
) {
$codeEditor.vars.current_page_number = $codeEditor.vars.total_pages;


$codeEditor.toolbar.first_chunk.control.readOnly = true;
$codeEditor.toolbar.previous_chunk.control.readOnly = true;
$codeEditor.toolbar.next_chunk.control.readOnly = true;
$codeEditor.toolbar.last_chunk.control.readOnly = true;


$codeEditor.toolbar.last_chunk.control.icon = 'datex-default-spinner';
await $codeEditor.load_chunk();
$codeEditor.toolbar.last_chunk.control.icon = 'icon icon-ic_fluent_next_20_regular';

$codeEditor.set_state();
}
move_to_top(event = null) {
  return this.move_to_topInternal(
    this,
this.shell,
    this.datasources,
    this.flows,
    this.reports,
    this.settings,
    this.operations,
    this.utils,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    // this.localization,
    event);
}
async move_to_topInternal(
  $codeEditor: EntityImport_render_entity_import_request_code_editorComponent,

  $shell: EntityImport_ShellService,
  $datasources: EntityImport_DatasourceService,
  $flows: EntityImport_FlowService,
  $reports: EntityImport_ReportService,
  $settings: SettingsValuesService,
  $operations: EntityImport_OperationService,
  $utils: UtilsService,
  // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
  //$l10n: EntityImport_LocalizationService,
  $event: any
) {
  this.logger.log('EntityImport', 'render_entity_import_request_code_editor.move_to_top');
const element = document.querySelector('[data-cy[]]') as HTMLTextAreaElement;

if (element) {
    element.selectionStart = 0;
    element.selectionEnd = 0;

    element.focus();
}
}
set_state(event = null) {
  return this.set_stateInternal(
    this,
this.shell,
    this.datasources,
    this.flows,
    this.reports,
    this.settings,
    this.operations,
    this.utils,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    // this.localization,
    event);
}
async set_stateInternal(
  $codeEditor: EntityImport_render_entity_import_request_code_editorComponent,

  $shell: EntityImport_ShellService,
  $datasources: EntityImport_DatasourceService,
  $flows: EntityImport_FlowService,
  $reports: EntityImport_ReportService,
  $settings: SettingsValuesService,
  $operations: EntityImport_OperationService,
  $utils: UtilsService,
  // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
  //$l10n: EntityImport_LocalizationService,
  $event: any
) {
if ($codeEditor.vars.current_page_number === 1) {
    $codeEditor.toolbar.previous_chunk.control.readOnly = true;
    $codeEditor.toolbar.first_chunk.control.readOnly = true;
} else {
    $codeEditor.toolbar.previous_chunk.control.readOnly = false;
    $codeEditor.toolbar.first_chunk.control.readOnly = false;
}

if ($codeEditor.vars.current_page_number === $codeEditor.vars.total_pages) {
    $codeEditor.toolbar.next_chunk.control.readOnly = true;
    $codeEditor.toolbar.last_chunk.control.readOnly = true;
} else {
    $codeEditor.toolbar.next_chunk.control.readOnly = false;
    $codeEditor.toolbar.last_chunk.control.readOnly = false;
}

$codeEditor.toolbar.page.control.label = `Page ${$codeEditor.vars.current_page_number} of ${$codeEditor.vars.total_pages}`;
}
//#endregion private flows
}