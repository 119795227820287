import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { CleanupLoggerService } from './cleanup.logging.service';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IReports_custom_inventory_adjustment_by_current_date_reportServiceInParams {
  projectId: number}

interface IReports_custom_inventory_adjustment_by_current_date_reportServiceData {
  details?: { result?: { Id?: number, ActualPackagedAmount?: number, CompletedDateTime?: string, CreatedSysDateTime?: string, Employee?: string, OperationCodeId?: number, OperationCode?: { Name?: string }, Material?: { Description?: string, LookupCode?: string }, Lot?: { CommodityCode?: string, CountryOfOrigin?: string, LookupCode?: string, ProcedureCode?: string, ReceiveDate?: string }, VendorLot?: { LookupCode?: string, Vat?: string }, Warehouse?: { Name?: string }, ActualSourceLocation?: { Name?: string } }[] }
}

@Injectable({ providedIn: 'root' })
export class Reports_custom_inventory_adjustment_by_current_date_reportService extends ReportBaseService<IReports_custom_inventory_adjustment_by_current_date_reportServiceInParams, IReports_custom_inventory_adjustment_by_current_date_reportServiceData> {

  protected reportReferenceName = 'custom_inventory_adjustment_by_current_date_report';
  protected appReferenceName = 'Reports';

  constructor(
    utils: UtilsService,
    private datasources: Reports_DatasourceService,
    private logger: CleanupLoggerService
    ) {
      super(utils);
      logger.log('Reports', 'custom_inventory_adjustment_by_current_date_report');
  }

  override throwIfMissingRequiredInParams (inParams: IReports_custom_inventory_adjustment_by_current_date_reportServiceInParams) {
  }

  protected async getData(inParams: IReports_custom_inventory_adjustment_by_current_date_reportServiceInParams): Promise<IReports_custom_inventory_adjustment_by_current_date_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IReports_custom_inventory_adjustment_by_current_date_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IReports_custom_inventory_adjustment_by_current_date_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        projectId:  $report.inParams.projectId 
      };
      
      const dsData = await this.datasources.Reports.custom_ds_inventory_adjustment_by_current_date_report.get(dsParams);
      
      data.details = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
