import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_get_sales_order_by_owner_referenceService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { ownerReference: string }): 
  Promise<{ result: { Id?: number, OrderStatusId?: number, OwnerReference?: string, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { TrackingIdentifier?: string } }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.ownerReference)) {
      missingRequiredInParams.push('\'ownerReference\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_get_sales_order_by_owner_reference/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { ownerReference: string, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, OrderStatusId?: number, OwnerReference?: string, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { TrackingIdentifier?: string } }[] }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.ownerReference)) {
      missingRequiredInParams.push('\'ownerReference\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_get_sales_order_by_owner_reference/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { ownerReference: string, $keys: number[] }): 
  Promise<{ result: { Id?: number, OrderStatusId?: number, OwnerReference?: string, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { TrackingIdentifier?: string } }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.ownerReference)) {
      missingRequiredInParams.push('\'ownerReference\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_get_sales_order_by_owner_reference/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      ownerReference: string    }) {
        if(isNil(inParams.ownerReference)) {
          return true; 
        }
      return false;
    }
}
