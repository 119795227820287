import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'
import { Materials_materials_by_project_dd_singleComponent } from './Materials.materials_by_project_dd_single.component'
import { Materials_material_packagings_dd_singleComponent } from './Materials.material_packagings_dd_single.component'
import { Locations_locations_dd_singleComponent } from './Locations.locations_dd_single.component'
import { Inventory_licenseplates_dd_singleComponent } from './Inventory.licenseplates_dd_single.component'
import { FootPrintManager_lots_dd_singleComponent } from './FootPrintManager.lots_dd_single.component'
import { Inventory_reasoncodes_dd_singleComponent } from './Inventory.reasoncodes_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Locations_warehouses_dd_singleComponent),
    forwardRef(() => Materials_materials_by_project_dd_singleComponent),
    forwardRef(() => Materials_material_packagings_dd_singleComponent),
    forwardRef(() => Locations_locations_dd_singleComponent),
    forwardRef(() => Inventory_licenseplates_dd_singleComponent),
    forwardRef(() => FootPrintManager_lots_dd_singleComponent),
    forwardRef(() => Inventory_reasoncodes_dd_singleComponent),
  ],
  selector: 'FootPrintManager-inventory_creation_form',
  templateUrl: './FootPrintManager.inventory_creation_form.component.html'
})
export class FootPrintManager_inventory_creation_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { projectId?: number, warehouseId?: number, materialId?: number, packagingId?: number, lotId?: number, licenseplateId?: number, ownerId?: number, locationId?: number } = { projectId: null, warehouseId: null, materialId: null, packagingId: null, lotId: null, licenseplateId: null, ownerId: null, locationId: null };
  //#region Inputs
  @Input('projectId') set $inParams_projectId(v: number) {
    this.inParams.projectId = v;
  }
  get $inParams_projectId(): number {
    return this.inParams.projectId;
  }
  @Input('warehouseId') set $inParams_warehouseId(v: number) {
    this.inParams.warehouseId = v;
  }
  get $inParams_warehouseId(): number {
    return this.inParams.warehouseId;
  }
  @Input('materialId') set $inParams_materialId(v: number) {
    this.inParams.materialId = v;
  }
  get $inParams_materialId(): number {
    return this.inParams.materialId;
  }
  @Input('packagingId') set $inParams_packagingId(v: number) {
    this.inParams.packagingId = v;
  }
  get $inParams_packagingId(): number {
    return this.inParams.packagingId;
  }
  @Input('lotId') set $inParams_lotId(v: number) {
    this.inParams.lotId = v;
  }
  get $inParams_lotId(): number {
    return this.inParams.lotId;
  }
  @Input('licenseplateId') set $inParams_licenseplateId(v: number) {
    this.inParams.licenseplateId = v;
  }
  get $inParams_licenseplateId(): number {
    return this.inParams.licenseplateId;
  }
  @Input('ownerId') set $inParams_ownerId(v: number) {
    this.inParams.ownerId = v;
  }
  get $inParams_ownerId(): number {
    return this.inParams.ownerId;
  }
  @Input('locationId') set $inParams_locationId(v: number) {
    this.inParams.locationId = v;
  }
  get $inParams_locationId(): number {
    return this.inParams.locationId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { confirm?: boolean } = { confirm: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ confirm?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    owner: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    material: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    packaging: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    quantity: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    location: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    new_licenseplate_checkbox: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    licenseplate: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    new_licenseplate: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    lot: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    reasoncode: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm_and_close: new ToolModel(new ButtonModel('confirm_and_close', new ButtonStyles(['primary'], null), false, false, false, 'Create and close', '', null)
    , false),
      confirm_and_new: new ToolModel(new ButtonModel('confirm_and_new', new ButtonStyles(['tertiary'], null), false, false, false, 'Create and new', '', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Owner', true, false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Project', true, false),
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', true, false),
    material: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['material'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Material', true, false),
    packaging: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['packaging'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Packaging', true, false),
    quantity: new FieldModel(new NumberBoxModel(this.formGroup.controls['quantity'] as DatexFormControl, null, false, false, '0', '', null)
, new ControlContainerStyles(null, null), 'Quantity', true, false),
    location: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['location'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Location', true, false),
    new_licenseplate_checkbox: new FieldModel(new CheckBoxModel(this.formGroup.controls['new_licenseplate_checkbox'] as DatexFormControl, null, false, false, 'New License Plate?', null)
, new ControlContainerStyles(null, null), '', false, false),
    licenseplate: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['licenseplate'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'License plate', true, false),
    new_licenseplate: new FieldModel(new TextBoxModel(this.formGroup.controls['new_licenseplate'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'License plate', true, false),
    lot: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['lot'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Lot code', true, false),
    reasoncode: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['reasoncode'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Reason code', true, false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true, false),
};

  //#region fields inParams
  get $fields_owner_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_owner_selector_inParams_projectId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.project.control.value;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.owner.control.value;
    
    return expr;
  }

  get $fields_material_selector_inParams_projectId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.project.control.value;
    
    return expr;
  }

  get $fields_packaging_selector_inParams_materialId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.material.control.value;
    
    return expr;
  }

  get $fields_location_selector_inParams_warehouseId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.warehouse.control.value;
    
    return expr;
  }

  get $fields_location_selector_inParams_typeId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 3;
    
    return expr;
  }

  get $fields_licenseplate_selector_inParams_locationId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.location.control.value;
    
    return expr;
  }

  get $fields_licenseplate_selector_inParams_typeId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_licenseplate_selector_inParams_archived(): boolean {
    const $form = this;
    const $utils = this.utils;
    const expr = false;
    
    return expr;
  }

  get $fields_lot_selector_inParams_materialId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.material.control.value;
    
    return expr;
  }

  get $fields_reasoncode_selector_inParams_parentId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_reasoncode_selector_inParams_parentEntity(): string {
    const $form = this;
    const $utils = this.utils;
    const expr = 'OperationType';
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    quantity: validateControlOnChange(this.fields.quantity.control, () => this.quantity_validation(this.fields.quantity.control))
    
    .pipe(shareReplay()),
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    warehouse: this.fields.warehouse.control.valueChanges
    ,
    material: this.fields.material.control.valueChanges
    ,
    packaging: this.fields.packaging.control.valueChanges
    ,
    location: this.fields.location.control.valueChanges
    ,
    new_licenseplate_checkbox: this.fields.new_licenseplate_checkbox.control.valueChanges
    ,
    licenseplate: this.fields.licenseplate.control.valueChanges
    ,
    new_licenseplate: this.fields.new_licenseplate.control.valueChanges
    ,
    lot: this.fields.lot.control.valueChanges
    ,
    reasoncode: this.fields.reasoncode.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Create Inventory';
  
    const $form = this;
    const $utils = this.utils;

    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_change();
      });
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_warehouse_change();
      });
    this.$formGroupFieldValidationObservables
      .material
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_material_change();
      });
    this.$formGroupFieldValidationObservables
      .packaging
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .quantity
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .location
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_location_change();
      });
    this.$formGroupFieldValidationObservables
      .new_licenseplate_checkbox
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_new_licenseplate_clicked();
      });
    this.$formGroupFieldValidationObservables
      .licenseplate
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .new_licenseplate
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .lot
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .reasoncode
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  common_confirm(event = null) {
    return this.common_confirmInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async common_confirmInternal(
    $form: FootPrintManager_inventory_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  //Get Glot
  const materialId = $form.fields.material.control.value
  const isMaterialSelected = $utils.isDefined(materialId);
  
  if (isMaterialSelected) {
      const fetchMaterialResult = await $datasources.Materials.ds_get_material_by_materialId.get({
          materialId: materialId
      });
      const materials = fetchMaterialResult.result;
      if (materials.length > 0) {
          if (materials[0].ControllerTypeId === 1 || materials[0].ControllerTypeId === 3) {
              const lots = (await $datasources.Materials.ds_get_lots_by_materialId.get({ materialId: materialId })).result;
              if ($utils.isDefined(lots) && lots[0].TypeId === 1) {
                  $form.fields.lot.control.value = lots[0].Id;
              }
              else {
                  // Create GLot
  
                  const lot = (await $flows.Lots.create_lot_and_vendor_lot({
                      materialId: materialId,
                      lotLookupCode: materials[0].LookupCode + "G-Lot",
                      vendorLotLookupCode: materials[0].LookupCode + "G-Lot",
                      lotStatusId: 1,
                      expirationDate: $utils.date.now(),
                      manufactureDate: $utils.date.now()
                  }));
  
                  var nextLotId = lot.LotId;
                  $form.fields.lot.control.value = nextLotId
              }
          }
      }
  }
  
  
  // Get Loose LP
  var licenseplateId;
  licenseplateId = $form.fields.licenseplate.control.value;
  const locationId = $form.fields.location.control.value;
  
  if ($utils.isDefined(locationId)) {
      const location = (await $datasources.Locations.ds_get_location_by_locationId.get({ locationId: locationId })).result;
      if ($utils.isDefined(location)) {
          if (location[0].IsLoose) {
  
              const licenseplate = (await $datasources.Inventory.ds_get_licenseplates_by_type_and_locationId.get({ locationId: locationId, typeId: 3 })).result;
  
              if ($utils.isDefined(licenseplate)) {
                  $form.fields.licenseplate.control.value = licenseplate[0].LookupCode;
                  licenseplateId = licenseplate[0].Id;
              }
              else {
                  throw new Error('Loose LP missing for selected location.');
  
              }
  
          }
  
      }
  }
  
  // Check required fields
  let missingFields: string[] = [];
  
  if (!$utils.isDefined($form.fields.owner.control.value)) { missingFields.push('owner'); }
  
  if (!$utils.isDefined($form.fields.project.control.value)) { missingFields.push('project'); }
  
  if (!$utils.isDefined($form.fields.warehouse.control.value)) { missingFields.push('warehouse'); }
  else if (!$utils.isDefined($form.fields.location.control.value)) { missingFields.push('location'); }
  else if ($form.fields.new_licenseplate_checkbox.control.value ?
      !$utils.isDefined($form.fields.new_licenseplate.control.value) :
      !$utils.isDefined($form.fields.licenseplate.control.value)
  ) { missingFields.push('license plate'); }
  
  if (!$utils.isDefined($form.fields.material.control.value)) { missingFields.push('material'); }
  else if (!$utils.isDefined($form.fields.lot.control.value)) { missingFields.push('lot'); }
  
  if (!$utils.isDefined($form.fields.packaging.control.value)) { missingFields.push('packaging'); }
  
  if (!$utils.isDefined($form.fields.quantity.control.value)) { missingFields.push('quantity'); }
  
  if (!$utils.isDefined($form.fields.reasoncode.control.value)) { missingFields.push('reason code'); }
  
  
  if (missingFields.length > 0) {
      $form.toolbar.confirm_and_close.control.readOnly = false;
      $form.toolbar.confirm_and_new.control.readOnly = false;
  
      let message: string;
  
      message = `Please enter in data in all required fields. Missing ${missingFields.join(', ').replace(/, ([^,]*)$/, (missingFields.length > 2 ? ',' : '') + ' and $1')}.`;
  
      throw new Error(message);
  
  }
  
  // Check new licenseplate lookupcode
  if ($utils.isDefined($form.fields.new_licenseplate_checkbox.control.value) && $form.fields.new_licenseplate_checkbox.control.value === true) {
      var containsNewLicensePlate = $utils.isAllDefined(
          $form.fields.new_licenseplate.control.value
      );
      if (!containsNewLicensePlate) {
          $form.toolbar.confirm_and_close.control.readOnly = false;
          $form.toolbar.confirm_and_new.control.readOnly = false;
          throw new Error('Please enter in new licenseplate lookupcode.');
      }
  
  }
  
  
  
  // Check existing licenseplate 
  if ($utils.isDefined($form.fields.new_licenseplate_checkbox.control.value) && $form.fields.new_licenseplate_checkbox.control.value === false) {
      var containsLicensePlate = $utils.isAllDefined(
          $form.fields.licenseplate.control.value
      );
  
  
      if (!containsLicensePlate) {
          $form.toolbar.confirm_and_close.control.readOnly = false;
          $form.toolbar.confirm_and_new.control.readOnly = false;
          throw new Error('Please select license plate.');
      }
  }
  
  const materials = (await $datasources.Materials.ds_get_material_by_materialId.get({
      materialId: $form.fields.material.control.value
  })).result;
  if ($utils.isDefined(materials)) {
      var controllerTypeId = materials[0].ControllerTypeId;
      var isFixedWeight = materials[0].IsFixedWeight;
  
  } else {
      throw new Error('Unable to determine material properties.')
  }
  
  if (!$utils.isDefined($form.fields.new_licenseplate_checkbox.control.value) || $form.fields.new_licenseplate_checkbox.control.value === false) {
      var licenseplateContents = (await $datasources.Inventory.ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId.get({
          licenseplateId: licenseplateId,
          lotId: $form.fields.lot.control.value,
          packagingId: $form.fields.packaging.control.value
      })).result;
  }
  
  
  
  // Check if material is serial controlled
  if (controllerTypeId === 3 || controllerTypeId === 4 || isFixedWeight === false) {
      const dialogResult = await $shell.FootPrintManager.openserialnumbers_creation_gridDialog({
          packagingId: $form.fields.packaging.control.value,
          materialId: $form.fields.material.control.value,
          packagedAmount: $form.fields.quantity.control.value
      }, 'modal');
  
  
      var serialnumbersResult = dialogResult.serialNumbers;
      var confirmResult = dialogResult.confirm;
  
      if (confirmResult === true && $utils.isDefined(serialnumbersResult) && serialnumbersResult.length === $form.fields.quantity.control.value) {
          if ($form.fields.new_licenseplate_checkbox.control.value === true) {
              let result = (await $flows.Inventory.create_serials_inventory_on_new_licenseplate_flow
                  ({
                      licenseplateLookupcode: $form.fields.new_licenseplate.control.value,
                      locationId: $form.fields.location.control.value,
                      lotId: $form.fields.lot.control.value,
                      packagingId: $form.fields.packaging.control.value,
                      serialNumbers: serialnumbersResult,
                      reasoncodeId: $form.fields.reasoncode.control.value,
                      operationcodeId: 7  // create inventory operation code
                  }))
              if ($utils.isDefined(result.reasons)) {
                  throw new Error(result.reasons.join(' ,'))
              }
          }
          else {
              if ($utils.isDefined(licenseplateContents)) {
                  let result = (await $flows.Inventory.add_serials_inventory_on_existing_licenseplate_flow({
  
                      licenseplateId: licenseplateId,
                      lotId: $form.fields.lot.control.value,
                      packagingId: $form.fields.packaging.control.value,
                      serialNumbers: serialnumbersResult,
                      reasoncodeId: $form.fields.reasoncode.control.value,
                      operationcodeId: 1 // adjust operation code
                  }))
                  if ($utils.isDefined(result.reasons)) {
                      throw new Error(result.reasons.join(' ,'))
                  }
              }
              else {
                  let result = (await $flows.Inventory.create_serials_inventory_on_existing_licenseplate_flow({
                      licenseplateId: licenseplateId,
                      lotId: $form.fields.lot.control.value,
                      packagingId: $form.fields.packaging.control.value,
                      serialNumbers: serialnumbersResult,
                      reasoncodeId: $form.fields.reasoncode.control.value,
                      operationcodeId: 7  // create inventory operation code
                  }))
                  if ($utils.isDefined(result.reasons)) {
                      throw new Error(result.reasons.join(' ,'))
                  }
              }
          }
      }
      else {
          throw new Error('Error creating serial numbers.')
      }
  }
  else {
      if ($form.fields.new_licenseplate_checkbox.control.value === true) {
  
          let result = (await $flows.Inventory.create_inventory_on_new_licenseplate_flow
              ({
                  licenseplateLookupcode: $form.fields.new_licenseplate.control.value,
                  locationId: $form.fields.location.control.value,
                  lotId: $form.fields.lot.control.value,
                  packagingId: $form.fields.packaging.control.value,
                  packagedAmount: $form.fields.quantity.control.value,
                  reasoncodeId: $form.fields.reasoncode.control.value,
                  operationcodeId: 7  // create inventory operation code
              }))
          if ($utils.isDefined(result.reasons)) {
              throw new Error(result.reasons.join(' ,'))
          }
      }
      else {
  
          if ($utils.isDefined(licenseplateContents)) {
              let result = (await $flows.Inventory.add_inventory_on_existing_licenseplate_flow({
                  licenseplateId: licenseplateId,
                  lotId: $form.fields.lot.control.value,
                  packagingId: $form.fields.packaging.control.value,
                  packagedAmount: $form.fields.quantity.control.value,
                  reasoncodeId: $form.fields.reasoncode.control.value,
                  operationcodeId: 1 // adjust operation code
              }))
              if ($utils.isDefined(result.reasons)) {
                  throw new Error(result.reasons.join(' ,'))
              }
          }
          else {
              let result = (await $flows.Inventory.create_inventory_on_existing_licenseplate_flow({
                  licenseplateId: licenseplateId,
                  lotId: $form.fields.lot.control.value,
                  packagingId: $form.fields.packaging.control.value,
                  packagedAmount: $form.fields.quantity.control.value,
                  reasoncodeId: $form.fields.reasoncode.control.value,
                  operationcodeId: 7  // create inventory operation code
              }))
              if ($utils.isDefined(result.reasons)) {
                  throw new Error(result.reasons.join(' ,'))
              }
          }
      }
  }
  
  
  
  
  
  
  
  
  
  }
  on_cancel(event = null) {
    return this.on_cancelInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancelInternal(
    $form: FootPrintManager_inventory_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.outParams.confirm = false;
  
  $form.close();
  
  }
  on_confirm_and_close(event = null) {
    return this.on_confirm_and_closeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_and_closeInternal(
    $form: FootPrintManager_inventory_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Handle double click scenario
  $form.toolbar.confirm_and_close.control.readOnly = true;
  
  
  
  try {
      await $form.common_confirm();
  }
  
  catch (error) {
      $form.toolbar.confirm_and_close.control.readOnly = false;
      throw error; // to prevent displayMode 
  
  }
  
  
  $form.toolbar.confirm_and_close.control.readOnly = false;
  
  
  $form.outParams.confirm = true;
  $form.close();
  
  }
  on_confirm_and_new(event = null) {
    return this.on_confirm_and_newInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_and_newInternal(
    $form: FootPrintManager_inventory_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Handle double click scenario
  $form.toolbar.confirm_and_new.control.readOnly = true;
  // Check required fields
  
  
  try {
      await $form.common_confirm();
  }
  
  catch (error) {
      $form.toolbar.confirm_and_new.control.readOnly = false;
      throw error; // to prevent displayMode 
  
  }
  
  
  $form.fields.material.control.value = null;
  $form.fields.packaging.control.value = null;
  $form.fields.quantity.control.value = null;
  $form.fields.location.control.value = null;
  $form.fields.lot.control.value = null;
  $form.fields.licenseplate.control.value = null;
  $form.fields.new_licenseplate.control.value = null;
  $form.fields.new_licenseplate_checkbox.control.value = null;
  
  
  
  $form.toolbar.confirm_and_new.control.readOnly = false;
  $form.outParams.confirm = true;
  await $form.on_init();
  
  
  }
  on_create_new_lot(event = null) {
    return this.on_create_new_lotInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_new_lotInternal(
    $form: FootPrintManager_inventory_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'inventory_creation_form.on_create_new_lot');
  const dialogResult = await $shell.FootPrintManager.openlot_creation_formDialog({ materialId: $form.fields.material.control.value }, 'modal');
  const userConfirmed = dialogResult.confirm;
  const lotId = dialogResult.lotId;
  
  const isLotCreated = $utils.isDefined(lotId);
  
  if (isLotCreated) {
      var materialId = $form.fields.material.control.value;
      $form.fields.material.control.value = null;
      $form.fields.material.control.value = materialId;
      $form.fields.lot.control.value = lotId;
  
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootPrintManager_inventory_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.outParams.confirm = false;
  
  // Hide specific fields on initial load
  $form.fields.location.hidden = true;
  $form.fields.lot.hidden = true;
  $form.fields.licenseplate.hidden = true;
  $form.fields.new_licenseplate.hidden = true;
  $form.fields.new_licenseplate_checkbox.hidden = true;
  
  $form.outParams.confirm = false;
  
  // Delay to give the form time to load before setting the focus logic
  
  await new Promise(resolve => setTimeout(resolve, 200));
  
  
  
  // On reload of form
  if ($utils.isDefined($form.fields.warehouse.control.value)) {
      $form.fields.location.control.value = null;
      $form.fields.location.hidden = false;
  }
  
  
  
  // Handle input parameter scenario(s)
  
  // Warehouse input scenario
  const warehouseId = $form.inParams.warehouseId
  if ($utils.isDefined(warehouseId)) {
  
      // Set the warehouse and show the location selector
      $form.fields.warehouse.control.value = warehouseId;
      $form.fields.location.hidden = false;
  
  
  }
  
  // Location input scneario
  const locationId = $form.inParams.locationId;
  if ($utils.isDefined(locationId)) {
  
      // Get the location and set the warehouse fields and open the licenseplate field if non loose.
      const location = (await $datasources.Locations.ds_get_location_by_locationId.get({ locationId: locationId })).result;
      if ($utils.isDefined(location)) {
          $form.fields.location.hidden = false;
          $form.fields.warehouse.control.value = location[0].WarehouseId;
          $form.fields.location.control.value = locationId;
          // If not loose show the license plate field
          if (!location[0].IsLoose) {
              $form.fields.licenseplate.hidden = false;
              $form.fields.new_licenseplate_checkbox.hidden = false;
          }
      }
  
  }
  
  // Licenseplate input scenario
  const licenseplateId = $form.inParams.licenseplateId;
  if ($utils.isDefined(licenseplateId)) {
  
      // Get the licenseplate and set the warehouse and location fields
      const licenseplate = (await $datasources.Inventory.ds_get_licenseplates_by_licenseplateIds.get({ licenseplateIds: [licenseplateId] })).result;
      if ($utils.isDefined(licenseplate)) {
          $form.fields.location.hidden = false;
          $form.fields.licenseplate.hidden = false;
          $form.fields.new_licenseplate_checkbox.hidden = false;
          $form.fields.warehouse.control.value = licenseplate[0].WarehouseId;
          $form.fields.location.control.value = licenseplate[0].LocationId;
          $form.fields.licenseplate.control.value = licenseplateId;
  
      }
  
  }
  
  // Owner input scenario
  const ownerId = $form.inParams.ownerId
  if ($utils.isDefined(ownerId)) {
  
      // Set the owner
      $form.fields.owner.control.value = ownerId;
  
  }
  
  // Project input scenario
  
  if ($utils.isDefined($form.inParams.projectId)) {
      const project = (await $datasources.Owners.ds_get_project_by_projectId.get({ projectId: $form.inParams.projectId })).result;
      $form.fields.owner.control.value = project[0].OwnerId;
      $form.fields.project.control.value = project[0].Id;
  
  }
  
  
  // Material input scenario
  const materialId = $form.inParams.materialId;
  if ($utils.isDefined(materialId)) {
      $form.fields.material.control.value = materialId;
  
      // Get the projectId and set the project field
      const material = (await $datasources.Materials.ds_get_material_by_materialId.get({ materialId: materialId })).result;
  
      // Lock down creation of inventory if the material requires multi level scanning
      const scanSerialsAllPackages = material[0].ScanSerialOfAllPackageLevels;
      if (scanSerialsAllPackages) {
  
          $form.toolbar.confirm_and_close.control.readOnly = true;
          $form.toolbar.confirm_and_new.control.readOnly = true;
  
          await $shell.FootPrintManager.openInfoDialog('Multi level serial controlled material', 'Selected material cannot be used as it requires serial scanning across all of the package levels.')
          $form.close();
      }
  
  
      const projectId = material[0].ProjectId;
      $form.fields.project.control.value = projectId;
  
      // Get the ownerId and set the owner field
      const project = (await $datasources.Owners.ds_get_project_by_projectId.get({ projectId: projectId })).result;
      $form.fields.owner.control.value = project[0].OwnerId;
  
  
      // Handle lot controlled materials
      if (material[0].ControllerTypeId === 2 || material[0].ControllerTypeId === 4) {
          $form.fields.lot.hidden = false;
  
      }
      else {
          $form.fields.lot.hidden = true;
      }
  
  
      // Set the packaging if only one found
      const packaging = (await $datasources.Materials.ds_get_material_packagings_by_materialId.get({ materialId: materialId })).result;
      if ($utils.isDefined(packaging) && packaging.length == 1) {
          $form.fields.packaging.control.value = packaging[0].PackagingId
      }
  }
  
  
  
  // Lot input scenario
  var lotId = $form.inParams.lotId;
  if ($utils.isDefined(lotId)) {
      // Set the lot field
      $form.fields.lot.control.value = lotId;
  
      // Get the materialId and set the material field
      const lot = (await $datasources.Lots.ds_get_lot_by_lotId.get({ lotId: lotId })).result;
      const materialId = lot[0].MaterialId;
      $form.fields.material.control.value = materialId
  
      // Set the packaging if only one found
      const packaging = (await $datasources.Materials.ds_get_material_packagings_by_materialId.get({ materialId: materialId })).result;
      if ($utils.isDefined(packaging) && packaging.length == 1) {
          $form.fields.packaging.control.value = packaging[0].PackagingId
      }
  
      // Get the projectId and set the project field
      const material = (await $datasources.Materials.ds_get_material_by_materialId.get({ materialId: materialId })).result;
  
      // Lock down creation of inventory if the material requires multi level scanning
      const scanSerialsAllPackages = material[0].ScanSerialOfAllPackageLevels;
      if (scanSerialsAllPackages) {
  
          $form.toolbar.confirm_and_close.control.readOnly = true;
          $form.toolbar.confirm_and_new.control.readOnly = true;
          
          await $shell.FootPrintManager.openInfoDialog('Multi level serial controlled material', 'Selected material cannot be used as it requires serial scanning across all of the package levels.')
          $form.close();
      }
      const projectId = material[0].ProjectId;
      $form.fields.project.control.value = projectId;
  
      // Get the ownerId and set the owner field
      const project = (await $datasources.Owners.ds_get_project_by_projectId.get({ projectId: projectId })).result;
      $form.fields.owner.control.value = project[0].OwnerId;
  
      // Handle lot controlled materials
      if (material[0].ControllerTypeId === 2 || material[0].ControllerTypeId === 4) {
          $form.fields.lot.hidden = false;
      } else {
          $form.fields.lot.hidden = true;
      }
  
  
  
  }
  
  
  // Packaging input scenario
  var packagingId = $form.inParams.packagingId;
  if ($utils.isDefined(packagingId)) {
      $form.fields.packaging.control.value = packagingId;
  }
  
  
  
  // Handle default focus
  
  // Owner/Project not set, set focus to owner
  if (!$utils.isAllDefined($form.fields.owner.control.value, $form.fields.project.control.value)) {
      $form.fields.owner.control.focus();
  }
  
  // Owner set and project not set focus to project
  if ($utils.isDefined($form.fields.owner.control.value && !$utils.isDefined($form.fields.project.control.value))) {
      $form.fields.project.control.focus();
  }
  
  // Owner/Project set and warehouse not set focus to warehouse
  if ($utils.isAllDefined($form.fields.owner.control.value, $form.fields.project.control.value)
      && !$utils.isDefined($form.fields.warehouse.control.value)) {
      $form.fields.warehouse.control.focus();
  }
  
  // Owner/Project/Warehouse set and material not set focus to material
  if ($utils.isAllDefined($form.fields.owner.control.value, $form.fields.project.control.value, $form.fields.warehouse.control.value)
      && !$utils.isDefined($form.fields.material.control.value)) {
      $form.fields.material.control.focus();
  }
  
  // Owner/Project/Warehouse set and material not set focus to material
  if ($utils.isAllDefined($form.fields.owner.control.value, $form.fields.project.control.value, $form.fields.warehouse.control.value)
      && !$utils.isDefined($form.fields.material.control.value)) {
      $form.fields.material.control.focus();
  }
  
  // Owner/Project/Warehouse/Material set and packaging not set focus to packaging
  if ($utils.isAllDefined($form.fields.owner.control.value, $form.fields.project.control.value, $form.fields.warehouse.control.value, $form.fields.material.control.value)
      && !$utils.isDefined($form.fields.packaging.control.value)) {
      $form.fields.packaging.control.focus();
  }
  
  // Owner/Project/Warehouse/Material/Packaging set and qty not set focus to qty
  if ($utils.isAllDefined($form.fields.owner.control.value, $form.fields.project.control.value, $form.fields.warehouse.control.value, $form.fields.material.control.value, $form.fields.packaging.control.value)
      && !$utils.isDefined($form.fields.quantity.control.value)) {
      $form.fields.quantity.control.focus();
  }
  }
  on_location_change(event = null) {
    return this.on_location_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_location_changeInternal(
    $form: FootPrintManager_inventory_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Location selection
  // Hide License Plate field
  const locationId = $form.fields.location.control.value
  const isLocationSelected = $utils.isDefined(locationId)
  if (locationId == null) {
      $form.fields.licenseplate.control.value = null;
      $form.fields.new_licenseplate.control.value = null;
      $form.fields.licenseplate.hidden = true;
      $form.fields.new_licenseplate.hidden = true;
      $form.fields.new_licenseplate_checkbox.hidden = true;
      $form.fields.new_licenseplate_checkbox.control.value = false;
  
  }
  
  // Check if location selected is loose hide license plate
  if (isLocationSelected) {
      const location = (await $datasources.Locations.ds_get_location_by_locationId.get({ locationId: locationId })).result;
      if ($utils.isDefined(location)) {
          const IsLoose = location[0].IsLoose;
          if (IsLoose) {
              $form.fields.licenseplate.control.value = null;
              $form.fields.licenseplate.hidden = true;
  
              $form.fields.new_licenseplate.control.value = null;
              $form.fields.new_licenseplate.hidden = true;
              $form.fields.new_licenseplate_checkbox.hidden = true;
          }
          else {
              $form.fields.new_licenseplate_checkbox.hidden = false;
              $form.fields.licenseplate.hidden = false;
          }
      }
  }
  }
  on_material_change(event = null) {
    return this.on_material_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_changeInternal(
    $form: FootPrintManager_inventory_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const materialId = $form.fields.material.control.value
  
  if (materialId == null) {
      $form.fields.lot.control.value = null;
      $form.fields.packaging.control.value = null;
      $form.fields.lot.hidden = true;
  }
  
  // Show lot creation if material selected requires it
  if (materialId !== null) {
      const fetchMaterialResult = (await $datasources.Materials.ds_get_material_by_materialId.get({
          materialId: materialId
      })).result;
  
      if ($utils.isDefined(fetchMaterialResult)) {
  
          // Lock down creation of inventory if the material requires multi level scanning
          const scanSerialsAllPackages = fetchMaterialResult[0].ScanSerialOfAllPackageLevels;
          if (scanSerialsAllPackages) {
  
              $form.toolbar.confirm_and_close.control.readOnly = true;
              $form.toolbar.confirm_and_new.control.readOnly = true;
              await $shell.FootPrintManager.openInfoDialog('Multi level serial controlled material', 'Selected material cannot be used as it requires serial scanning across all of the package levels.')
  
          }
          else {
              $form.toolbar.confirm_and_close.control.readOnly = false;
              $form.toolbar.confirm_and_new.control.readOnly = false;
          }
  
          $form.fields.project.control.value = fetchMaterialResult[0].ProjectId;
  
          if (fetchMaterialResult[0].ControllerTypeId === 2 || fetchMaterialResult[0].ControllerTypeId === 4) {
              $form.fields.lot.hidden = false;
  
          }
  
      }
  
  }
  
  }
  on_new_licenseplate_clicked(event = null) {
    return this.on_new_licenseplate_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_new_licenseplate_clickedInternal(
    $form: FootPrintManager_inventory_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const newLicensePlate = $form.fields.new_licenseplate_checkbox.control.value;
  
  if (newLicensePlate) {
      $form.fields.licenseplate.control.value = null;
      $form.fields.new_licenseplate.control.value = null;
      $form.fields.licenseplate.hidden = true;
      $form.fields.new_licenseplate.hidden = false;
      $form.fields.new_licenseplate.control.focus();
  
  }
  else {
  
      $form.fields.licenseplate.control.value = null;
      $form.fields.new_licenseplate.control.value = null;
      $form.fields.licenseplate.hidden = false;
      $form.fields.new_licenseplate.hidden = true;
  }
  
  
  }
  on_project_change(event = null) {
    return this.on_project_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changeInternal(
    $form: FootPrintManager_inventory_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const projectId = $form.fields.project.control.value
  
  
  // Set Owner from Project
  if ($utils.isDefined(projectId) && !$utils.isDefined($form.fields.owner.control.value)) {
      const project = (await $datasources.Owners.ds_get_project_by_projectId.get({
          projectId: projectId
      })).result;
     
      if ($utils.isDefined(project)) {
  
          $form.fields.owner.control.value = project[0].OwnerId;
  
      }
  
  }
  
  }
  on_warehouse_change(event = null) {
    return this.on_warehouse_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_warehouse_changeInternal(
    $form: FootPrintManager_inventory_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const warehouseId = $form.fields.warehouse.control.value
  const isWarehouseSelected = $utils.isDefined(warehouseId)
  
  if (isWarehouseSelected) {
      $form.fields.location.hidden = false;
  }
  
  if (warehouseId == null) {
      $form.fields.location.control.value = null;
      $form.fields.licenseplate.control.value = null;
      $form.fields.new_licenseplate.control.value = null;
      $form.fields.location.hidden = true;
      $form.fields.licenseplate.hidden = true;
      $form.fields.new_licenseplate.hidden = true;
      $form.fields.new_licenseplate_checkbox.hidden = true;
  }
  
  }
  //#endregion private flows
  //#region validation flows
  quantity_validation<TC extends ValueControlModel>(field: TC) {
    if (!this.initialized){
      return Promise.resolve([]);
    }
    return this.quantity_validationInternal(
      { control: field, errors: [] },
      this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization
      );
  }
  async quantity_validationInternal<TC extends ValueControlModel>(
    $validation:{
      errors: Pick<string[], 'push'>,
      control: TC
    },
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
  ) {
    // Ensure updated packaged amount is a positive number
  if ($validation.control.value < 0) {
      $validation.errors.push('Quantity cannot be a negative value.')
  }
  if (isNaN(Number($validation.control.value))) {
      $validation.errors.push('Quantity must be a numeric value.');
  }
  
    return $validation.errors as string[];
  }
  //#endregion validation flows
}
