import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Notifications_ShellService } from './Notifications.shell.service';
import { Notifications_OperationService } from './Notifications.operation.service';
import { Notifications_DatasourceService } from './Notifications.datasource.index';
import { Notifications_FlowService } from './Notifications.flow.index';
import { Notifications_ReportService } from './Notifications.report.index';
import { Notifications_LocalizationService } from './Notifications.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Notifications.frontend.types'
import { $frontendTypes as $types} from './Notifications.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';



interface INotifications_alerts_filters_gridComponentEntity {
  id?: string, application_name?: string, alert_id?: string, filter_name?: string, filter_value?: string}

interface INotifications_alerts_filters_gridComponentInParams {
  application_name?: string, alert_id?: string}


class Notifications_alerts_filters_gridComponentRowModel extends GridRowModel {
  grid: Notifications_alerts_filters_gridComponent;
  entity: INotifications_alerts_filters_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    application_name_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    alert_id_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    filter_name_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    filter_value_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
  });

  override cells = {
    application_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['application_name_edit'] as DatexFormControl, null, false, false, '', null)
      ),
    alert_id: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['alert_id_edit'] as DatexFormControl, null, false, false, '', null)
      ),
    filter_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['filter_name_edit'] as DatexFormControl, null, false, false, '', null)
      ),
    filter_value: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['filter_value_edit'] as DatexFormControl, null, false, false, '', null)
      ),
    delete: new GridCellModel(
      new CellStyles(null, null),
      new ButtonModel('', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'ms-Icon ms-Icon--Delete', null)
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Notifications_ShellService,
private datasources: Notifications_DatasourceService,
private flows: Notifications_FlowService,
private reports: Notifications_ReportService,
private localization: Notifications_LocalizationService,
private operations: Notifications_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: Notifications_alerts_filters_gridComponent, entity: INotifications_alerts_filters_gridComponentEntity) {
    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: Notifications_alerts_filters_gridComponent, entity?: INotifications_alerts_filters_gridComponentEntity) {
    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_new_clicked();
    }

    this.rowId = [this.entity.id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.id;
    const inParams = {
      $keys:[$resultKey],
      fullTextSearch:  $grid.fullTextSearch ,
      application_name:  $grid.inParams.application_name ,
      alert_id:  $grid.inParams.alert_id ,
    };
    const data = await this.datasources.Notifications.ds_storage_alerts_filters.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as INotifications_alerts_filters_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.application_name.displayControl as TextModel).text = $row.entity.application_name;
    (this.cells.application_name.editControl as TextBoxModel).reset($row.entity.application_name);
    (this.cells.alert_id.displayControl as TextModel).text = $row.entity.alert_id;
    (this.cells.alert_id.editControl as TextBoxModel).reset($row.entity.alert_id);
    (this.cells.filter_name.displayControl as TextModel).text = $row.entity.filter_name;
    (this.cells.filter_name.editControl as TextBoxModel).reset($row.entity.filter_name);
    (this.cells.filter_value.displayControl as TextModel).text = $row.entity.filter_value;
    (this.cells.filter_value.editControl as TextBoxModel).reset($row.entity.filter_value);

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_existing_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: Notifications_alerts_filters_gridComponentRowModel,
  $grid: Notifications_alerts_filters_gridComponent, 
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
    this.logger.log('Notifications', 'alerts_filters_grid.on_save_existing_row');
  //O.Arias - 03/18/2024
  
  const allRequiredFieldHaveValue =
      $utils.isDefined($row.cells.filter_name.editControl.value) &&
      $utils.isDefined($row.cells.filter_value.editControl.value)
  
  if (allRequiredFieldHaveValue === false) {
      $shell.Notifications.openErrorDialog('Save', 'Missing Required fields');
      throw new Error('Missing Required fields'); // to prevent displayMode 
  }
  
  if (
      $row.cells.filter_name.editControl.isChanged ||
      $row.cells.filter_value.editControl.isChanged
  ) {
  
      let id: string = $row.entity?.id ?? "";
      let application_name: string = $grid.inParams.application_name ?? "";
  
      if (application_name.toUpperCase() === "NULL") {
          application_name = "";
      }
  
      if (application_name === "") {
          application_name = $row.cells.application_name.editControl.value ?? "";
      }
  
      let alert_id: string = $grid.inParams.alert_id ?? "";
  
      if (alert_id.toUpperCase() === "NULL") {
          alert_id = "";
      }
  
      if (alert_id === "") {
          alert_id = $row.cells.alert_id.editControl.value ?? "";
      }
  
      try {
  
          if (application_name === "") {
              throw new Error("You need to specify the integration to insert an alert!")
          }
  
          if (alert_id === "") {
              throw new Error("The alert id cannot be blank!")
          }
  
          if (($row.cells.filter_name.editControl.value ?? "") === "") {
              throw new Error("The filter name cannot be blank!")
          }
  
          if (($row.cells.filter_value.editControl.value ?? "") === "") {
              throw new Error("The to filter value cannot be blank!")
          }
  
          let action: string = "Update";
  
          if (id === "") {
              action = "Write";
          }
  
          try {
  
              await $flows.Notifications.storage_alerts_filters({
                  action: action,
                  payload: {
                      id: id,
                      alert_id: alert_id,
                      filter_name: $row.cells.filter_name.editControl.value,
                      filter_value: $row.cells.filter_value.editControl.value
                  },
                  application_name: application_name
              });
  
          } catch (error) {
  
              let targetError = error;
              while ($utils.isDefined(targetError?.error)) {
                  targetError = targetError.error;
              }
              if (!$utils.isDefined(targetError?.message)) {
                  targetError = { "message": `Uncaught exception! ${JSON.stringify(targetError)}` }
              }
          }
  
          $row.cells.delete.displayControl.readOnly = false;
  
          await $grid.refresh();
  
      } catch (error) {
          $shell.Notifications.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      }
  }
  }
  on_new_clicked(event = null) {
    return this.on_new_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_new_clickedInternal(
    $row: Notifications_alerts_filters_gridComponentRowModel,
  $grid: Notifications_alerts_filters_gridComponent, 
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
    this.logger.log('Notifications', 'alerts_filters_grid.on_new_clicked');
  //O.Arias - 11/06/2024
  
  let application_name: string = $grid.inParams.application_name ?? "";
  
  if (application_name.toUpperCase() === "NULL") {
      application_name = "";
  }
  
  if (application_name !== "") {
      $row.cells.application_name.displayControl.text = application_name;
      $row.cells.application_name.editControl.value = application_name;
  }
  
  let alert_id: string = $grid.inParams.alert_id ?? "";
  
  if (alert_id.toUpperCase() === "NULL") {
      alert_id = "";
  }
  
  if (alert_id !== "") {
      $row.cells.alert_id.displayControl.text = alert_id;
      $row.cells.alert_id.editControl.value = alert_id;
  }
  
  $row.cells.delete.displayControl.readOnly = true;
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $row: Notifications_alerts_filters_gridComponentRowModel,
  $grid: Notifications_alerts_filters_gridComponent, 
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
    this.logger.log('Notifications', 'alerts_filters_grid.on_delete_clicked');
  //O.Arias - 03/18/2024
  
  let errors: { id: string, errorMessages: string[] }[] = [];
  let deleted_records: string[] = [];
  
  let current_error: string;
  let success = false;
  current_error = null;
  
  try {
  
      let entity = $row.entity
  
      await alert_delete({
          "id": entity.id
      }, entity.application_name);
  
      success = true;
  
  } catch (error) {
  
      let target_error = error;
  
      if (typeof target_error === "string") {
          target_error = { message: target_error };
      } else {
          while ($utils.isDefined(target_error.error)) {
              target_error = target_error.error;
          };
      };
  
      if (!$utils.isDefined(target_error.message)) {
          target_error = { message: `Uncaught exception! ${JSON.stringify(target_error)}` };
      };
  
      current_error = target_error.message;
  };
  
  if (success) {
      deleted_records.push($row.entity.id.toString())
      $grid.refresh();
  } else {
      errors.push({
          id: $row.entity.id, errorMessages: [current_error]
      });
  };
  
  if ($utils.isDefined(errors)) {
      await $shell.Notifications.openErrorDialog(
          `Delete record`,
          deleted_records.length > 0 ? `Record successfully deleted.` : `No records were deleted.`,
          null,
          `Errors deleting record`,
          errors.map(error => ({ detail: error.errorMessages[0], message: `Id: ${error.id}` })))
  };
  
  const alert_schema = (await $flows.Notifications.storage_alerts_filters({ action: "", application_name: "" })).payload;
  async function alert_delete(input: typeof alert_schema[0], application_name: string) {
      await $flows.Notifications.storage_alerts_filters({ action: "Delete", payload: input, application_name: application_name });
  };
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Notifications-alerts_filters_grid',
  templateUrl: './Notifications.alerts_filters_grid.component.html'
})
export class Notifications_alerts_filters_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: INotifications_alerts_filters_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('fixedWidth', ['set-width-table']);

  fullTextSearch: string;

  inParams: INotifications_alerts_filters_gridComponentInParams = { application_name: null, alert_id: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     application_name: new GridHeaderModel(new HeaderStyles(null, null), 'application_name', 'Application name', false, false, 150, false),       alert_id: new GridHeaderModel(new HeaderStyles(null, null), 'alert_id', 'Alert ID', false, false, 150, false),       filter_name: new GridHeaderModel(new HeaderStyles(null, null), 'filter_name', 'Filter name', true, false, 200, false),       filter_value: new GridHeaderModel(new HeaderStyles(null, null), 'filter_value', 'Filter value', true, false, 200, false),       delete: new GridHeaderModel(new HeaderStyles(null, null), 'delete', 'Delete', false, false, 100, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Notifications_alerts_filters_gridComponentRowModel[] = [];

  @Input('application_name') set $inParams_application_name(value: any) {
    this.inParams['application_name'] = value;
  }
  get $inParams_application_name(): any {
    return this.inParams['application_name'] ;
  }
  @Input('alert_id') set $inParams_alert_id(value: any) {
    this.inParams['alert_id'] = value;
  }
  get $inParams_alert_id(): any {
    return this.inParams['alert_id'] ;
  }

  topToolbar = {
      delete_selected: new ToolModel(new ButtonModel('delete_selected', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false)
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, false, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Notifications_ShellService,
    private datasources: Notifications_DatasourceService,
    private flows: Notifications_FlowService,
    private reports: Notifications_ReportService,
    private localization: Notifications_LocalizationService,
    private operations: Notifications_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Alerts filters grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 25;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      fullTextSearch:  $grid.fullTextSearch ,
      application_name:  $grid.inParams.application_name ,
      alert_id:  $grid.inParams.alert_id ,
    };
    try {
      const data = await this.datasources.Notifications.ds_storage_alerts_filters.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as INotifications_alerts_filters_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Notifications_alerts_filters_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push(row.$initializeExisting(this, entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: Notifications_alerts_filters_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  async addRow(entity?: INotifications_alerts_filters_gridComponentEntity) {
    const row = new Notifications_alerts_filters_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations,
      this.logger);
    await row.$initializeNew(this, entity);
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: Notifications_alerts_filters_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
    this.logger.log('Notifications', 'alerts_filters_grid.on_init');
  //O.Arias - 03/18/2024
  
  $grid.title = 'Alerts Filters Grid'
  
  let application_name: string = $grid.inParams.application_name ?? "";
  
  if (application_name.toUpperCase() === "NULL") {
      application_name = "";
  };
  
  if (application_name !== "") {
      $grid.headers.application_name.required = false;
      $grid.headers.application_name.hidden = true;
  };
  
  let alert_id: string = $grid.inParams.alert_id ?? "";
  
  if (alert_id.toUpperCase() === "NULL") {
      alert_id = "";
  };
  
  if (alert_id !== "") {
      $grid.headers.alert_id.required = false;
      $grid.headers.alert_id.hidden = true;
  };
  
  if (alert_id === "") {
      $grid.headers.delete.hidden = true;
  }
  
  $grid.topToolbar.delete_selected.control.readOnly = true;
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: Notifications_alerts_filters_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
    this.logger.log('Notifications', 'alerts_filters_grid.on_delete_clicked');
  //O.Arias - 03/18/2024
  
  const selected_rows = $grid.selectedRows;
  let errors: { id: string, errorMessages: string[] }[] = [];
  let deleted_records: string[] = [];
  
  if (selected_rows.length === 0) {
      $shell.Notifications.openErrorDialog('Delete error!', 'No records were selected!');
      return;
  } else {
  
      const confirm_purge = (await $shell.Notifications.openConfirmationDialog(`Confirm delete!`, `Do you want to delete ${selected_rows.length} records?`, `Delete`, `Cancel`));
  
      if (confirm_purge) {
  
          for (const row of selected_rows) {
  
              let current_error: string;
              let success = false;
              current_error = null;
  
              try {
  
                  let entity = row.entity
  
                  await alert_delete({
                      "id": entity.id
                  }, entity.application_name);
  
                  success = true;
  
              } catch (error) {
  
                  let target_error = error;
  
                  if (typeof target_error === "string") {
                      target_error = { message: target_error };
                  } else {
                      while ($utils.isDefined(target_error.error)) {
                          target_error = target_error.error;
                      };
                  };
  
                  if (!$utils.isDefined(target_error.message)) {
                      target_error = { message: `Uncaught exception! ${JSON.stringify(target_error)}` };
                  };
  
                  current_error = target_error.message;
              };
  
              if (success) {
                  deleted_records.push(row.entity.id.toString())
              } else {
                  errors.push({
                      id: row.entity.id, errorMessages: [current_error]
                  });
              };
          };
  
          if ($utils.isDefined(errors)) {
              await $shell.Notifications.openErrorDialog(
                  `Delete record${selected_rows.length > 1 ? 's' : ''}`,
                  deleted_records.length > 0 ? `Record${selected_rows.length > 1 ? 's' : ''} successfully deleted.` : `No records were deleted.`,
                  null,
                  `Errors deleting record${selected_rows.length > 1 ? 's' : ''}`,
                  errors.map(error => ({ detail: error.errorMessages[0], message: `Id: ${error.id}` })))
          };
      };
  };
  
  $grid.refresh();
  const alert_schema = (await $flows.Notifications.storage_alerts_filters({ action: "", application_name: "" })).payload;
  async function alert_delete(input: typeof alert_schema[0], application_name: string) {
      await $flows.Notifications.storage_alerts_filters({ action: "Delete", payload: input, application_name: application_name });
  };
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: Notifications_alerts_filters_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
    this.logger.log('Notifications', 'alerts_filters_grid.on_row_selected');
  //O.Arias - 03/15/2024
  
  const selectedRowsCount = $grid.selectedRows.length;
  
  $grid.topToolbar.delete_selected.control.readOnly = (selectedRowsCount > 0 ? false : true);
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
