import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_export_duty_vendorlotsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { projectId?: number, createdFrom: string, createdTo: string, ownerId?: number }): 
  Promise<{ result: { TotalPackagedAmount?: number, OperationCodeId?: number, CompletedDateTime?: string, VendorLot?: { LookupCode?: string, Vat?: string, Material?: { CommodityCode?: string, DutyRate?: number } }, Order?: { ProcedureCode?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.createdFrom)) {
      missingRequiredInParams.push('\'createdFrom\'');
    }
    if (isNil(inParams.createdTo)) {
      missingRequiredInParams.push('\'createdTo\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_export_duty_vendorlots/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { projectId?: number, createdFrom: string, createdTo: string, ownerId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { TotalPackagedAmount?: number, OperationCodeId?: number, CompletedDateTime?: string, VendorLot?: { LookupCode?: string, Vat?: string, Material?: { CommodityCode?: string, DutyRate?: number } }, Order?: { ProcedureCode?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.createdFrom)) {
      missingRequiredInParams.push('\'createdFrom\'');
    }
    if (isNil(inParams.createdTo)) {
      missingRequiredInParams.push('\'createdTo\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_export_duty_vendorlots/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { projectId?: number, createdFrom: string, createdTo: string, ownerId?: number, $keys: { VendorLot?: { LookupCode?: string, Material?: { CommodityCode?: string, DutyRate?: number }, Vat?: string }, OperationCodeId?: number, CompletedDateTime?: string, Order?: { ProcedureCode?: string } }[] }): 
  Promise<{ result: { TotalPackagedAmount?: number, OperationCodeId?: number, CompletedDateTime?: string, VendorLot?: { LookupCode?: string, Vat?: string, Material?: { CommodityCode?: string, DutyRate?: number } }, Order?: { ProcedureCode?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.createdFrom)) {
      missingRequiredInParams.push('\'createdFrom\'');
    }
    if (isNil(inParams.createdTo)) {
      missingRequiredInParams.push('\'createdTo\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_export_duty_vendorlots/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      projectId?: number, createdFrom: string, createdTo: string, ownerId?: number    }) {
        if(isNil(inParams.createdFrom)) {
          return true; 
        }
        if(isNil(inParams.createdTo)) {
          return true; 
        }
      return false;
    }
}
