import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_ds_outbound_summary_pick_slip_reportService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId?: number }): 
  Promise<{ result: { ExpectedPackagedAmount?: number, OrderId?: number, MaterialId?: number, ExpectedPackagedPackId?: number, Material?: { LookupCode?: string, Description?: string }, OrderLine?: { MaterialPackagingLookup?: { UpcCode?: string } } }[] }> 
  {
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_outbound_summary_pick_slip_report/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { ExpectedPackagedAmount?: number, OrderId?: number, MaterialId?: number, ExpectedPackagedPackId?: number, Material?: { LookupCode?: string, Description?: string }, OrderLine?: { MaterialPackagingLookup?: { UpcCode?: string } } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_outbound_summary_pick_slip_report/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId?: number, $keys: { OrderId?: number, MaterialId?: number, ExpectedPackagedPackId?: number, Material?: { LookupCode?: string, Description?: string }, OrderLine?: { MaterialPackagingLookup?: { UpcCode?: string } } }[] }): 
  Promise<{ result: { ExpectedPackagedAmount?: number, OrderId?: number, MaterialId?: number, ExpectedPackagedPackId?: number, Material?: { LookupCode?: string, Description?: string }, OrderLine?: { MaterialPackagingLookup?: { UpcCode?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_outbound_summary_pick_slip_report/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      orderId?: number    }) {
      return false;
    }
}
