<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
    <div class="toolContainer">
      <button mat-button
              class="datex-button"
              (click)="$exportExcel()">
        <div class="button-label">
          <div class="button-icon">
            <i class="icon icon-ic_fluent_document_arrow_down_20_regular"></i>
          </div>
          <div class="button-text">Export</div>
        </div>
      </button>
    </div>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-created_from" *ngIf="!filters.created_from.hidden && !filters.created_from.removed" 
            class="field-container standard {{filters.created_from.invalid ? 'invalid' : ''}} {{filters.created_from.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.created_from.styles.style"
            [ngClass]="filters.created_from.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.created_from.label + (filters.created_from.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.created_from.label}}<span *ngIf="filters.created_from.required" class="required-asterisk">*</span>
          </label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="created_from"
            [format]="filters.created_from.control.format"
            [mode]="filters.created_from.control.mode"
            [ngStyle]="filters.created_from.control.styles.style"
            [ngClass]="filters.created_from.control.styles.classes"
            [tooltip]="filters.created_from.control.tooltip">
        </app-datebox>
        <ng-container *ngIf="filters.created_from.invalid">
          <ng-container *ngFor="let error of filters.created_from.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-created_to" *ngIf="!filters.created_to.hidden && !filters.created_to.removed" 
            class="field-container standard {{filters.created_to.invalid ? 'invalid' : ''}} {{filters.created_to.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.created_to.styles.style"
            [ngClass]="filters.created_to.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.created_to.label + (filters.created_to.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.created_to.label}}<span *ngIf="filters.created_to.required" class="required-asterisk">*</span>
          </label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="created_to"
            [format]="filters.created_to.control.format"
            [mode]="filters.created_to.control.mode"
            [ngStyle]="filters.created_to.control.styles.style"
            [ngClass]="filters.created_to.control.styles.classes"
            [tooltip]="filters.created_to.control.tooltip">
        </app-datebox>
        <ng-container *ngIf="filters.created_to.invalid">
          <ng-container *ngFor="let error of filters.created_to.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="inbound_order">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.inbound_order.displayControl.styles.style"
          [ngClass]="row.cells.inbound_order.displayControl.styles.classes"
          [matTooltip]="row.cells.inbound_order.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.inbound_order.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="order_lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.order_lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.order_lookupcode.displayControl.styles.classes"
          [matTooltip]="row.cells.order_lookupcode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.order_lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="procedure_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.procedure_code.displayControl.styles.style"
          [ngClass]="row.cells.procedure_code.displayControl.styles.classes"
          [matTooltip]="row.cells.procedure_code.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.procedure_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material_lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.material_lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.material_lookupcode.displayControl.styles.classes"
          [matTooltip]="row.cells.material_lookupcode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.material_lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material_description">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.material_description.displayControl.styles.style"
          [ngClass]="row.cells.material_description.displayControl.styles.classes"
          [matTooltip]="row.cells.material_description.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.material_description.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="operationcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.operationcode.displayControl.styles.style"
          [ngClass]="row.cells.operationcode.displayControl.styles.classes"
          [matTooltip]="row.cells.operationcode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.operationcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.created_date.displayControl.styles.style"
          [ngClass]="row.cells.created_date.displayControl.styles.classes"
          [matTooltip]="row.cells.created_date.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.created_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="completed_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.completed_date.displayControl.styles.style"
          [ngClass]="row.cells.completed_date.displayControl.styles.classes"
          [matTooltip]="row.cells.completed_date.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.completed_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="project_lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.project_lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.project_lookupcode.displayControl.styles.classes"
          [matTooltip]="row.cells.project_lookupcode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.project_lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="employee">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.employee.displayControl.styles.style"
          [ngClass]="row.cells.employee.displayControl.styles.classes"
          [matTooltip]="row.cells.employee.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.employee.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="owner_reference">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.owner_reference.displayControl.styles.style"
          [ngClass]="row.cells.owner_reference.displayControl.styles.classes"
          [matTooltip]="row.cells.owner_reference.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.owner_reference.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="vendor_reference">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.vendor_reference.displayControl.styles.style"
          [ngClass]="row.cells.vendor_reference.displayControl.styles.classes"
          [matTooltip]="row.cells.vendor_reference.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.vendor_reference.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="actual_packaged_amount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.actual_packaged_amount.displayControl.styles.style"
          [ngClass]="row.cells.actual_packaged_amount.displayControl.styles.classes"
          [matTooltip]="row.cells.actual_packaged_amount.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.actual_packaged_amount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lot.displayControl.styles.style"
          [ngClass]="row.cells.lot.displayControl.styles.classes"
          [matTooltip]="row.cells.lot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="received_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.received_date.displayControl.styles.style"
          [ngClass]="row.cells.received_date.displayControl.styles.classes"
          [matTooltip]="row.cells.received_date.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.received_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="vendorlot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.vendorlot.displayControl.styles.style"
          [ngClass]="row.cells.vendorlot.displayControl.styles.classes"
          [matTooltip]="row.cells.vendorlot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.vendorlot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="vat">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.vat.displayControl.styles.style"
          [ngClass]="row.cells.vat.displayControl.styles.classes"
          [matTooltip]="row.cells.vat.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.vat.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="commodity_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.commodity_code.displayControl.styles.style"
          [ngClass]="row.cells.commodity_code.displayControl.styles.classes"
          [matTooltip]="row.cells.commodity_code.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.commodity_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="duty_rate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.duty_rate.displayControl.styles.style"
          [ngClass]="row.cells.duty_rate.displayControl.styles.classes"
          [matTooltip]="row.cells.duty_rate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.duty_rate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="country_of_origin">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.country_of_origin.displayControl.styles.style"
          [ngClass]="row.cells.country_of_origin.displayControl.styles.classes"
          [matTooltip]="row.cells.country_of_origin.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.country_of_origin.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="actual_source_location">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.actual_source_location.displayControl.styles.style"
          [ngClass]="row.cells.actual_source_location.displayControl.styles.classes"
          [matTooltip]="row.cells.actual_source_location.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.actual_source_location.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="actual_target_location">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.actual_target_location.displayControl.styles.style"
          [ngClass]="row.cells.actual_target_location.displayControl.styles.classes"
          [matTooltip]="row.cells.actual_target_location.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.actual_target_location.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="warehouse">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.warehouse.displayControl.styles.style"
          [ngClass]="row.cells.warehouse.displayControl.styles.classes"
          [matTooltip]="row.cells.warehouse.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.warehouse.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="destination_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.destination_name.displayControl.styles.style"
          [ngClass]="row.cells.destination_name.displayControl.styles.classes"
          [matTooltip]="row.cells.destination_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.destination_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="destination_line1">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.destination_line1.displayControl.styles.style"
          [ngClass]="row.cells.destination_line1.displayControl.styles.classes"
          [matTooltip]="row.cells.destination_line1.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.destination_line1.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="destination_line2">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.destination_line2.displayControl.styles.style"
          [ngClass]="row.cells.destination_line2.displayControl.styles.classes"
          [matTooltip]="row.cells.destination_line2.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.destination_line2.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="destination_city">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.destination_city.displayControl.styles.style"
          [ngClass]="row.cells.destination_city.displayControl.styles.classes"
          [matTooltip]="row.cells.destination_city.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.destination_city.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="destination_postal_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.destination_postal_code.displayControl.styles.style"
          [ngClass]="row.cells.destination_postal_code.displayControl.styles.classes"
          [matTooltip]="row.cells.destination_postal_code.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.destination_postal_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="destination_country">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.destination_country.displayControl.styles.style"
          [ngClass]="row.cells.destination_country.displayControl.styles.classes"
          [matTooltip]="row.cells.destination_country.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.destination_country.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
