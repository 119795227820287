<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="!$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="confirm" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden && !fieldsets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-expected_date" *ngIf="!fields.expected_date.hidden && !fields.expected_date.removed" 
                            class="field-container standard {{fields.expected_date.invalid ? 'invalid' : ''}} {{fields.expected_date.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.expected_date.styles.style"
                            [ngClass]="fields.expected_date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.expected_date.label + (fields.expected_date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.expected_date.label}}<span *ngIf="fields.expected_date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="expected_date"
                            [format]="fields.expected_date.control.format"
                            [mode]="fields.expected_date.control.mode"
                            [ngStyle]="fields.expected_date.control.styles.style"
                            [ngClass]="fields.expected_date.control.styles.classes"
                            [tooltip]="fields.expected_date.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.expected_date.invalid">
                          <ng-container *ngFor="let error of fields.expected_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-tracking" *ngIf="!fields.tracking.hidden && !fields.tracking.removed" 
                            class="field-container standard {{fields.tracking.invalid ? 'invalid' : ''}} {{fields.tracking.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.tracking.styles.style"
                            [ngClass]="fields.tracking.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.tracking.label + (fields.tracking.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.tracking.label}}<span *ngIf="fields.tracking.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="tracking"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.tracking.control.readOnly || fields.tracking.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.tracking.control.placeholder}}"
                                [ngStyle]="fields.tracking.control.styles.style"
                                [ngClass]="fields.tracking.control.styles.classes"
                                [matTooltip]="fields.tracking.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.tracking.invalid">
                          <ng-container *ngFor="let error of fields.tracking.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-carrier" *ngIf="!fields.carrier.hidden && !fields.carrier.removed" 
                            class="field-container standard {{fields.carrier.invalid ? 'invalid' : ''}} {{fields.carrier.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.carrier.styles.style"
                            [ngClass]="fields.carrier.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.carrier.label + (fields.carrier.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.carrier.label}}<span *ngIf="fields.carrier.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Carriers-carriers_dd_single 
                            data-cy="selector"
                            [type]="fields.carrier.control.type"
                            formControlName="carrier"
                            (displayTextChange)="fields.carrier.control.displayText=$event"
                            [placeholder]="fields.carrier.control.placeholder"
                            [styles]="fields.carrier.control.styles"
                            [tooltip]="fields.carrier.control.tooltip"
                        >
                        </Carriers-carriers_dd_single>
                        <ng-container *ngIf="fields.carrier.invalid">
                          <ng-container *ngFor="let error of fields.carrier.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-carrier_service" *ngIf="!fields.carrier_service.hidden && !fields.carrier_service.removed" 
                            class="field-container standard {{fields.carrier_service.invalid ? 'invalid' : ''}} {{fields.carrier_service.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.carrier_service.styles.style"
                            [ngClass]="fields.carrier_service.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.carrier_service.label + (fields.carrier_service.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.carrier_service.label}}<span *ngIf="fields.carrier_service.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Carriers-carrierservicetype_dd_single 
                            data-cy="selector"
                            [type]="fields.carrier_service.control.type"
                            formControlName="carrier_service"
                            (displayTextChange)="fields.carrier_service.control.displayText=$event"
                            [placeholder]="fields.carrier_service.control.placeholder"
                            [styles]="fields.carrier_service.control.styles"
                            [tooltip]="fields.carrier_service.control.tooltip"
                          [carrierId]="$fields_carrier_service_selector_inParams_carrierId"
                        >
                        </Carriers-carrierservicetype_dd_single>
                        <ng-container *ngIf="fields.carrier_service.invalid">
                          <ng-container *ngFor="let error of fields.carrier_service.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-freight_charge" *ngIf="!fields.freight_charge.hidden && !fields.freight_charge.removed" 
                            class="field-container standard {{fields.freight_charge.invalid ? 'invalid' : ''}} {{fields.freight_charge.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.freight_charge.styles.style"
                            [ngClass]="fields.freight_charge.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.freight_charge.label + (fields.freight_charge.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.freight_charge.label}}<span *ngIf="fields.freight_charge.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="freight_charge"
                                matInput
                                numberBox
                                [format]="fields.freight_charge.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.freight_charge.control.readOnly || fields.freight_charge.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.freight_charge.control.placeholder}}"
                                [ngStyle]="fields.freight_charge.control.styles.style"
                                [ngClass]="fields.freight_charge.control.styles.classes"
                                [matTooltip]="fields.freight_charge.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.freight_charge.invalid">
                          <ng-container *ngFor="let error of fields.freight_charge.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-incoterms" *ngIf="!fields.incoterms.hidden && !fields.incoterms.removed" 
                            class="field-container standard {{fields.incoterms.invalid ? 'invalid' : ''}} {{fields.incoterms.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.incoterms.styles.style"
                            [ngClass]="fields.incoterms.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.incoterms.label + (fields.incoterms.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.incoterms.label}}<span *ngIf="fields.incoterms.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Manifesting-incoterms_dd_single 
                            data-cy="selector"
                            [type]="fields.incoterms.control.type"
                            formControlName="incoterms"
                            (displayTextChange)="fields.incoterms.control.displayText=$event"
                            [placeholder]="fields.incoterms.control.placeholder"
                            [styles]="fields.incoterms.control.styles"
                            [tooltip]="fields.incoterms.control.tooltip"
                        >
                        </Manifesting-incoterms_dd_single>
                        <ng-container *ngIf="fields.incoterms.invalid">
                          <ng-container *ngFor="let error of fields.incoterms.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-pva" *ngIf="!fields.pva.hidden && !fields.pva.removed" 
                            class="field-container standard {{fields.pva.invalid ? 'invalid' : ''}} {{fields.pva.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.pva.styles.style"
                            [ngClass]="fields.pva.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.pva.label + (fields.pva.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.pva.label}}<span *ngIf="fields.pva.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="pva"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.pva.control.styles.style"
                                      [ngClass]="fields.pva.control.styles.classes"
                                      [matTooltip]="fields.pva.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.pva.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.pva.invalid">
                          <ng-container *ngFor="let error of fields.pva.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>