import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_ds_packing_list_zagg_report_linesService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId?: number }): 
  Promise<{ result: { packagedAmount?: number, orderedAmount?: number, ShipmentLine?: { ShipmentId?: number, OrderId?: number, OrderLineNumber?: number, OrderLine?: { PackagedAmount?: number, Material?: { LookupCode?: string, Description?: string, ProjectId?: number } } } }[] }> 
  {
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_packing_list_zagg_report_lines/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { packagedAmount?: number, orderedAmount?: number, ShipmentLine?: { ShipmentId?: number, OrderId?: number, OrderLineNumber?: number, OrderLine?: { PackagedAmount?: number, Material?: { LookupCode?: string, Description?: string, ProjectId?: number } } } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_packing_list_zagg_report_lines/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId?: number, $keys: { ShipmentLine?: { ShipmentId?: number, OrderLine?: { Material?: { LookupCode?: string, Description?: string, ProjectId?: number }, PackagedAmount?: number }, OrderId?: number, OrderLineNumber?: number } }[] }): 
  Promise<{ result: { packagedAmount?: number, orderedAmount?: number, ShipmentLine?: { ShipmentId?: number, OrderId?: number, OrderLineNumber?: number, OrderLine?: { PackagedAmount?: number, Material?: { LookupCode?: string, Description?: string, ProjectId?: number } } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_packing_list_zagg_report_lines/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      orderId?: number    }) {
      return false;
    }
}
