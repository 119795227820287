import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'
import { Locations_locations_dd_singleComponent } from './Locations.locations_dd_single.component'
import { Inventory_licenseplates_dd_singleComponent } from './Inventory.licenseplates_dd_single.component'
import { Inventory_materials_by_licenseplate_dd_singleComponent } from './Inventory.materials_by_licenseplate_dd_single.component'
import { Inventory_vendorlots_by_licenseplate_dd_singleComponent } from './Inventory.vendorlots_by_licenseplate_dd_single.component'
import { Inventory_lots_by_licenseplate_dd_singleComponent } from './Inventory.lots_by_licenseplate_dd_single.component'
import { Inventory_packagings_by_licenseplate_material_dd_singleComponent } from './Inventory.packagings_by_licenseplate_material_dd_single.component'
import { Inventory_reasoncodes_dd_singleComponent } from './Inventory.reasoncodes_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Locations_warehouses_dd_singleComponent),
    forwardRef(() => Locations_locations_dd_singleComponent),
    forwardRef(() => Inventory_licenseplates_dd_singleComponent),
    forwardRef(() => Inventory_materials_by_licenseplate_dd_singleComponent),
    forwardRef(() => Inventory_vendorlots_by_licenseplate_dd_singleComponent),
    forwardRef(() => Inventory_lots_by_licenseplate_dd_singleComponent),
    forwardRef(() => Inventory_packagings_by_licenseplate_material_dd_singleComponent),
    forwardRef(() => Inventory_reasoncodes_dd_singleComponent),
  ],
  selector: 'FootPrintManager-inventory_and_licenseplate_move_form',
  templateUrl: './FootPrintManager.inventory_and_licenseplate_move_form.component.html'
})
export class FootPrintManager_inventory_and_licenseplate_move_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { warehouseId?: number, locationId?: number, materialId?: number, vendorlotId?: number, licenseplates?: { id?: number, content?: { lotId?: number, packagingId?: number, packagedAmount?: number, availablePackagedAmount?: number }[] }[], serialNumberIds?: number[] } = { warehouseId: null, locationId: null, materialId: null, vendorlotId: null, licenseplates: [], serialNumberIds: [] };
  //#region Inputs
  @Input('warehouseId') set $inParams_warehouseId(v: number) {
    this.inParams.warehouseId = v;
  }
  get $inParams_warehouseId(): number {
    return this.inParams.warehouseId;
  }
  @Input('locationId') set $inParams_locationId(v: number) {
    this.inParams.locationId = v;
  }
  get $inParams_locationId(): number {
    return this.inParams.locationId;
  }
  @Input('materialId') set $inParams_materialId(v: number) {
    this.inParams.materialId = v;
  }
  get $inParams_materialId(): number {
    return this.inParams.materialId;
  }
  @Input('vendorlotId') set $inParams_vendorlotId(v: number) {
    this.inParams.vendorlotId = v;
  }
  get $inParams_vendorlotId(): number {
    return this.inParams.vendorlotId;
  }
  @Input('licenseplates') set $inParams_licenseplates(v: { id?: number, content?: { lotId?: number, packagingId?: number, packagedAmount?: number, availablePackagedAmount?: number }[] }[]) {
    this.inParams.licenseplates = v;
  }
  get $inParams_licenseplates(): { id?: number, content?: { lotId?: number, packagingId?: number, packagedAmount?: number, availablePackagedAmount?: number }[] }[] {
    return this.inParams.licenseplates;
  }
  @Input('serialNumberIds') set $inParams_serialNumberIds(v: number[]) {
    this.inParams.serialNumberIds = v;
  }
  get $inParams_serialNumberIds(): number[] {
    return this.inParams.serialNumberIds;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { confirm?: boolean } = { confirm: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { canLicensePlateMove?: boolean, canInventoryMove?: boolean, isMultiLicensePlateMove?: boolean, isMultiInventoryMove?: boolean, sourceLicensePlateShipmentId?: number } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ confirm?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    is_licenseplate_move: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    warehouse: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    source_location: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    source_licenseplate: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    material: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    vendorlot: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    lot: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    packaging: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    moved_packaged_amount: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    details: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    target_location: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    is_new_target_licenseplate: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    target_licenseplate: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    new_target_licenseplate: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    reason_code: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, false, false, 'Confirm', '', null)
    , false),
      release: new ToolModel(new ButtonModel('release', new ButtonStyles(['tertiary'], null), false, false, false, 'Release', '', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
    is_licenseplate_move: new FieldModel(new CheckBoxModel(this.formGroup.controls['is_licenseplate_move'] as DatexFormControl, null, false, false, 'Move license plate?', null)
, new ControlContainerStyles(null, null), '', false, false),
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  true,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', true, false),
    source_location: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['source_location'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Location', true, false),
    source_licenseplate: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['source_licenseplate'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'License plate', true, false),
    material: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['material'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Material', true, false),
    vendorlot: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['vendorlot'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Vendor lot', true, false),
    lot: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['lot'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Lot', true, false),
    packaging: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['packaging'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Packaging', true, false),
    current_packaged_amount: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Current packaged amount', false, false),
    available_packaged_amount: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Available packaged amount', false, false),
    moved_packaged_amount: new FieldModel(new NumberBoxModel(this.formGroup.controls['moved_packaged_amount'] as DatexFormControl, null, false, false, '', '', null)
, new ControlContainerStyles(null, null), 'Packaged amount to move', true, false),
    details: new FieldModel(new TextBoxModel(this.formGroup.controls['details'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), 'Details', false, false),
    target_location: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['target_location'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Target location', false, false),
    is_new_target_licenseplate: new FieldModel(new CheckBoxModel(this.formGroup.controls['is_new_target_licenseplate'] as DatexFormControl, null, false, false, 'New license plate?', null)
, new ControlContainerStyles(null, null), '', false, false),
    target_licenseplate: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['target_licenseplate'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'License plate', true, false),
    new_target_licenseplate: new FieldModel(new TextBoxModel(this.formGroup.controls['new_target_licenseplate'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'New license plate', true, false),
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Notes', false, false),
    reason_code: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['reason_code'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Reason code', false, false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Source', true, false, true, false),
  newGroup2: new FieldsetModel('Target', false, false, true, false),
  newGroup3: new FieldsetModel('', false, false, true, false),
};

  //#region fields inParams
  get $fields_source_location_selector_inParams_warehouseId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.warehouse.control.value;
    
    return expr;
  }

  get $fields_source_location_selector_inParams_typeId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 3;
    
    return expr;
  }

  get $fields_source_location_selector_inParams_isLoose(): boolean {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.is_licenseplate_move.control.value ? false : null;
    
    return expr;
  }

  get $fields_source_licenseplate_selector_inParams_locationId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.source_location.control.value;
    
    return expr;
  }

  get $fields_source_licenseplate_selector_inParams_typeId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_source_licenseplate_selector_inParams_archived(): boolean {
    const $form = this;
    const $utils = this.utils;
    const expr = false;
    
    return expr;
  }

  get $fields_material_selector_inParams_licenseplateId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.source_licenseplate.control.value;
    
    return expr;
  }

  get $fields_vendorlot_selector_inParams_licenseplateId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.source_licenseplate.control.value;
    
    return expr;
  }

  get $fields_vendorlot_selector_inParams_materialId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.material.control.value;
    
    return expr;
  }

  get $fields_lot_selector_inParams_licenseplateId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.source_licenseplate.control.value;
    
    return expr;
  }

  get $fields_lot_selector_inParams_materialId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.material.control.value;
    
    return expr;
  }

  get $fields_lot_selector_inParams_vendorlotId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.vendorlot.control.value;
    
    return expr;
  }

  get $fields_packaging_selector_inParams_licenseplateId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.source_licenseplate.control.value;
    
    return expr;
  }

  get $fields_packaging_selector_inParams_materialId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.material.control.value;
    
    return expr;
  }

  get $fields_target_location_selector_inParams_warehouseId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.warehouse.control.value;
    
    return expr;
  }

  get $fields_target_location_selector_inParams_typeId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 3;
    
    return expr;
  }

  get $fields_target_location_selector_inParams_isLoose(): boolean {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.is_licenseplate_move.control.value ? false : null;
    
    return expr;
  }

  get $fields_target_licenseplate_selector_inParams_locationId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.target_location.control.value;
    
    return expr;
  }

  get $fields_target_licenseplate_selector_inParams_typeId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_target_licenseplate_selector_inParams_archived(): boolean {
    const $form = this;
    const $utils = this.utils;
    const expr = false;
    
    return expr;
  }

  get $fields_target_licenseplate_selector_inParams_shipmentId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.vars.sourceLicensePlateShipmentId;
    
    return expr;
  }

  get $fields_reason_code_selector_inParams_parentId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.is_licenseplate_move.control.value ? 5 : 9;
    
    return expr;
  }

  get $fields_reason_code_selector_inParams_parentEntity(): string {
    const $form = this;
    const $utils = this.utils;
    const expr = 'OperationType';
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    is_licenseplate_move: this.fields.is_licenseplate_move.control.valueChanges
    ,
    warehouse: this.fields.warehouse.control.valueChanges
    ,
    source_location: this.fields.source_location.control.valueChanges
    ,
    source_licenseplate: this.fields.source_licenseplate.control.valueChanges
    ,
    material: this.fields.material.control.valueChanges
    ,
    vendorlot: this.fields.vendorlot.control.valueChanges
    ,
    lot: this.fields.lot.control.valueChanges
    ,
    packaging: this.fields.packaging.control.valueChanges
    ,
    moved_packaged_amount: this.fields.moved_packaged_amount.control.valueChanges
    ,
    details: this.fields.details.control.valueChanges
    ,
    target_location: this.fields.target_location.control.valueChanges
    ,
    is_new_target_licenseplate: this.fields.is_new_target_licenseplate.control.valueChanges
    ,
    target_licenseplate: this.fields.target_licenseplate.control.valueChanges
    ,
    new_target_licenseplate: this.fields.new_target_licenseplate.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
    reason_code: this.fields.reason_code.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Move Inventory';
  
    const $form = this;
    const $utils = this.utils;

    
    (this.fields.is_new_target_licenseplate.control as CheckBoxModel).reset(false);
    
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .is_licenseplate_move
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_is_licenseplate_move_change();
      });
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .source_location
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .source_licenseplate
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.set_form_state();
      });
    this.$formGroupFieldValidationObservables
      .material
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_material_change();
      });
    this.$formGroupFieldValidationObservables
      .vendorlot
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_vendorlot_change();
      });
    this.$formGroupFieldValidationObservables
      .lot
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .packaging
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .moved_packaged_amount
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .details
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .target_location
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_target_location_change();
      });
    this.$formGroupFieldValidationObservables
      .is_new_target_licenseplate
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_is_new_target_licenseplate_change();
      });
    this.$formGroupFieldValidationObservables
      .target_licenseplate
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .new_target_licenseplate
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .reason_code
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: FootPrintManager_inventory_and_licenseplate_move_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  $form.outParams.confirm = false;
  $form.close();
  
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: FootPrintManager_inventory_and_licenseplate_move_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.toolbar.confirm.control.readOnly = true;
  $form.toolbar.release.control.readOnly = true;
  $form.toolbar.cancel.control.readOnly = true;
  
  let isConfirm = true;
  
  try {
      if ($form.fields.is_licenseplate_move.control.value) { // LicensePlate Move
          if ($form.vars.isMultiLicensePlateMove) { // Multiple LicensePlates
              // Check Required Fields
              if (!$utils.isAllDefined(
                  $form.inParams.licenseplates,
                  $form.fields.target_location.control.value
              )) {
                  throw new Error('Please provide value for all required fields.');
              }
  
              let promises: any[] = [];
              for (let licenseplate of $form.inParams.licenseplates) {
                  // Complete LicensePlateMove
                  promises.push($flows.Inventory.complete_licenseplate_move_task_flow({
                      licenseplateId: licenseplate.id,
                      targetLocationId: $form.fields.target_location.control.value,
                      notes: $form.fields.notes.control.value,
                      reasoncodeId: $form.fields.reason_code.control.value
                  }));
              }
  
              await Promise.all(promises)
                  .then()
                  .catch(error => {
                      $shell.FootPrintManager.openErrorDialog('Error moving license plates', error.message);
                  });
          }
          else { // Single LicensePlate
              // Check Required Fields
              if (!$utils.isAllDefined(
                  $form.fields.source_licenseplate.control.value,
                  $form.fields.target_location.control.value
              )) {
                  throw new Error('Please provide value for all required fields.');
              }
  
              // Complete LicensePlateMove
              let result = (await $flows.Inventory.complete_licenseplate_move_task_flow({
                  licenseplateId: $form.fields.source_licenseplate.control.value,
                  targetLocationId: $form.fields.target_location.control.value,
                  notes: $form.fields.notes.control.value,
                  reasoncodeId: $form.fields.reason_code.control.value
              }));
  
              if ($utils.isDefined(result.reasons)) {
                  $shell.FootPrintManager.openErrorDialog('Error moving license plate', result.reasons.join(', '));
              }
          }
      }
      else {
          if ($form.vars.isMultiInventoryMove) { // Multiple Inventory
              // Check Required Fields
              if (!$utils.isAllDefined(
                  $form.inParams.licenseplates,
                  $form.fields.target_location.control.value
              ) || (
                      (!$form.fields.is_new_target_licenseplate.control.value && !$utils.isDefined($form.fields.target_licenseplate.control.value)) ||
                      ($form.fields.is_new_target_licenseplate.control.value && !$utils.isDefined($form.fields.new_target_licenseplate.control.value))
                  )
              ) {
                  throw new Error('Please provide value for all required fields.');
              }
  
              for (let licenseplate of $form.inParams.licenseplates) {
                  if (!$utils.isDefined(licenseplate.content)) {
                      throw new Error(`Input license plate ${licenseplate.id} is missing the content to be moved.`);
                  }
  
                  for (let content of licenseplate.content) {
                      if (!$utils.isAllDefined(
                          content?.lotId,
                          content?.packagedAmount,
                          content?.packagingId
                      )) {
                          throw new Error(`Content of input license plate ${licenseplate.id} is missing a value.`);
                      }
  
                      // Complete InventoryMoves
                      await $flows.Inventory.complete_inventory_move_task_flow({
                          sourceLicenseplateId: licenseplate.id,
                          targetLicenseplate: {
                              id: $form.fields.target_licenseplate.control.value,
                              lookupcode: $form.fields.new_target_licenseplate.control.value
                          },
                          lotId: content.lotId,
                          packagingId: content.packagingId,
                          packagedAmount: content.packagedAmount,
                          targetLocationId: $form.fields.target_location.control.value,
                          notes: $form.fields.notes.control.value,
                          reasoncodeId: $form.fields.reason_code.control.value
                      });
                  }
              }
          }
          else { // Single Inventory
              // Check Required Fields
              if (!$utils.isAllDefined(
                  $form.fields.source_licenseplate.control.value,
                  $form.fields.target_location.control.value,
                  $form.fields.lot.control.value,
                  $form.fields.packaging.control.value,
                  $form.fields.moved_packaged_amount.control.value,
              ) || (
                      (!$form.fields.is_new_target_licenseplate.control.value && !$utils.isDefined($form.fields.target_licenseplate.control.value)) ||
                      ($form.fields.is_new_target_licenseplate.control.value && !$utils.isDefined($form.fields.new_target_licenseplate.control.value))
                  )
              ) {
                  throw new Error('Please provide value for all required fields.');
              }
  
              // Check if Serialized
              if ($form.fields.moved_packaged_amount.control.value <= 0) {
                  throw new Error('Requested move amount must be greater than zero.');
              }
              else if ($form.fields.moved_packaged_amount.control.value > Number($form.fields.current_packaged_amount.control.text)) {
                  throw new Error('Requested move amount exceeds the amount in inventory.');
              }
              else if ($form.fields.moved_packaged_amount.control.value > Number($form.fields.available_packaged_amount.control.text)) {
                  throw new Error('Requested move amount exceeds the currently available amount.');
              }
              else if ($form.fields.moved_packaged_amount.control.value < Number($form.fields.current_packaged_amount.control.text)) {
                  // Check if material is serialized
                  let material = (await $datasources.Materials.ds_get_material_by_materialId.get({ materialId: $form.fields.material.control.value })).result[0];
  
                  if (material.ControllerTypeId === 3 || material.ControllerTypeId === 4 || !material.IsFixedWeight || !material.IsFixedLength || !material.IsFixedVolume) {
                      if (!$utils.isDefined($form.inParams.serialNumberIds)) {
                          // Get Serial Numbers to move
                          let serialNumberResult = (await $shell.FootPrintManager.openserialnumbers_selection_gridDialog({
                              lotId: $form.fields.lot.control.value,
                              licenseplateId: $form.fields.source_licenseplate.control.value,
                              packagingId: $form.fields.packaging.control.value,
                              packagedAmountRequest: $form.fields.moved_packaged_amount.control.value
                          }));
  
                          var serialNumberIds = serialNumberResult.SerialNumberIds;
                          isConfirm = serialNumberResult.confirm;
                      }
                      else {
                          serialNumberIds = $form.inParams.serialNumberIds;
                      }
                  }
              }
  
              if (isConfirm) {
                  // Complete InventoryMove
                  await $flows.Inventory.complete_inventory_move_task_flow({
                      sourceLicenseplateId: $form.fields.source_licenseplate.control.value,
                      targetLicenseplate: {
                          id: $form.fields.target_licenseplate.control.value,
                          lookupcode: $form.fields.new_target_licenseplate.control.value
                      },
                      lotId: $form.fields.lot.control.value,
                      packagingId: $form.fields.packaging.control.value,
                      packagedAmount: $form.fields.moved_packaged_amount.control.value,
                      targetLocationId: $form.fields.target_location.control.value,
                      notes: $form.fields.notes.control.value,
                      reasoncodeId: $form.fields.reason_code.control.value,
                      serialNumberIds: serialNumberIds
                  });
              }
          }
      }
  
      $form.outParams.confirm = true;
      $form.close();
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
      
      await $shell.FootPrintManager.openErrorDialog('Error confirming move', targetError.message);
  }
  
  $form.toolbar.confirm.control.readOnly = false;
  $form.toolbar.release.control.readOnly = false;
  $form.toolbar.cancel.control.readOnly = false;
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootPrintManager_inventory_and_licenseplate_move_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.outParams.confirm = false;
  if (!$utils.isDefined($form.inParams.licenseplates)) {
      $form.inParams.licenseplates = [];
  }
  
  
  $form.toolbar.confirm.hidden = true;
  $form.toolbar.release.hidden = true;
  $form.toolbar.cancel.hidden = true;
  
  
  try {
      let inputLicenseplates = (await $datasources.Inventory.ds_get_licenseplates_by_licenseplateIds_detailed.get({ licenseplateIds: $form.inParams.licenseplates.map(lp => lp.id) })).result;
  
      await getCurrentState(inputLicenseplates);
  
      // Check and lock down input fields for user-entry
      // Warehouse
      if ($utils.isDefined($form.inParams.warehouseId)) {
          $form.fields.warehouse.control.value = $form.inParams.warehouseId;
          $form.fields.warehouse.control.readOnly = true;
      }
  
      // Source Location
      if ($utils.isDefined($form.inParams.locationId)) {
          $form.fields.source_location.control.value = $form.inParams.locationId;
          $form.fields.source_location.control.readOnly = true;
  
          // Get Warehouse from Location
          if (!$utils.isDefined($form.fields.warehouse.control.value) && !$utils.isDefined($form.inParams.licenseplates)) {
              let location = (await $datasources.Locations.ds_get_location_by_locationId.get({ locationId: $form.fields.source_location.control.value })).result[0];
  
              if ($utils.isDefined(location)) {
                  $form.fields.warehouse.control.value = location.WarehouseId;
                  $form.fields.warehouse.control.readOnly = true;
              }
          }
      }
  
      // Source LicensePlate
      if ($utils.isDefined($form.inParams.licenseplates)) {
          let licenseplate = inputLicenseplates[0];
  
          $form.fields.source_licenseplate.control.value = licenseplate.Id;
          $form.fields.source_licenseplate.control.readOnly = true;
  
          // Get SourceLocation and Warehouse from LicensePlate
          if (!$utils.isDefined($form.fields.source_location.control.value) || !$utils.isDefined($form.fields.warehouse.control.value)) {
              if ($utils.isDefined(licenseplate)) {
                  $form.fields.source_location.control.value = licenseplate.LocationId;
                  $form.fields.source_location.control.readOnly = true;
                  $form.fields.warehouse.control.value = licenseplate.WarehouseId;
                  $form.fields.warehouse.control.readOnly = true;
              }
          }
      }
  
  
      // Material
      if ($utils.isDefined($form.inParams.materialId)) {
          // Ensure LicensePlate is provided
          if (!$utils.isDefined($form.fields.source_licenseplate.control.value)) {
              throw new Error(`LicensePlate was not provided when requesting to move material ${$form.inParams.materialId}`);
          }
  
          $form.fields.material.control.value = $form.inParams.materialId;
          $form.fields.material.control.readOnly = true;
      }
  
      // VendorLot
      if ($utils.isDefined($form.inParams.vendorlotId)) {
          // Ensure LicensePlate is provided
          if (!$utils.isDefined($form.fields.source_licenseplate.control.value)) {
              throw new Error(`LicensePlate was not provided when requesting to move vendor lot ${$form.inParams.vendorlotId}`);
          }
  
          $form.fields.vendorlot.control.value = $form.inParams.vendorlotId;
          $form.fields.vendorlot.control.readOnly = true;
  
          // Get Material from VendorLot
          if (!$utils.isDefined($form.fields.material.control.value) && !$utils.isDefined($form.inParams.licenseplates.find(lp => $utils.isDefined(lp.content)))) {
              let vendorlot = (await $datasources.Lots.ds_get_vendorlot_by_vendorlotId.get({ vendorLotId: $form.fields.vendorlot.control.value })).result;
  
              if ($utils.isDefined(vendorlot)) {
                  $form.fields.material.control.value = vendorlot.MaterialId;
                  $form.fields.material.control.readOnly = true;
              }
          }
      }
  
      // Content
      if ($utils.isDefined($form.inParams.licenseplates.find(lp => $utils.isDefined(lp.content)))) {
          // Lot
          $form.fields.lot.control.value = $form.inParams.licenseplates[0].content[0].lotId;
          $form.fields.lot.control.readOnly = true;
  
          // Packaging
          $form.fields.packaging.control.value = $form.inParams.licenseplates[0].content[0].packagingId;
          $form.fields.packaging.control.readOnly = true;
  
          // Ensure LicensePlate is provided
          if (!$utils.isDefined($form.fields.source_licenseplate.control.value)) {
              throw new Error(`LicensePlate was not provided when requesting to move lot ${$form.fields.lot.control.value}`);
          }
  
          // If license plate is a shipping license plate, capture the shipment
          let licenseplate = (await $datasources.Inventory.ds_get_licenseplate_by_licenseplateId.get({ licenseplateId: $form.fields.source_licenseplate.control.value })).result;
          if ($utils.isDefined(licenseplate.ShipmentId)) {
              $form.vars.sourceLicensePlateShipmentId = licenseplate.ShipmentId;
          }
  
          $form.fields.current_packaged_amount.control.text = (inputLicenseplates[0].LicensePlateContents.find(lpc => lpc.Lot.Id === $form.fields.lot.control.value && lpc.Packaged.Id === $form.fields.packaging.control.value)?.PackagedAmount ?? 0).toString();
          $form.fields.available_packaged_amount.control.text = ($form.inParams.licenseplates[0].content[0].availablePackagedAmount ?? Number($form.fields.current_packaged_amount.control.text)).toString();
  
          // Get VendorLot and Material from Lot
          if (!$utils.isDefined($form.fields.vendorlot.control.value) || !$utils.isDefined($form.fields.material.control.value)) {
              let lot = (await $datasources.Lots.ds_get_lot_by_lotId.get({ lotId: $form.fields.lot.control.value })).result[0];
  
              if ($utils.isDefined(lot)) {
                  $form.fields.vendorlot.control.value = lot.VendorLotId;
                  $form.fields.vendorlot.control.readOnly = true;
                  $form.fields.material.control.value = lot.MaterialId;
                  $form.fields.material.control.readOnly = true;
              }
          }
      }
  
  
  
      // Set focus on updated packaged amount field    
      $form.fields.moved_packaged_amount.control.focus();
  
  
      // Call common flow to apply the role based permissions
      await $form.set_form_state();
      await $form.apply_operations();
  
  }
  catch (exception) {
      await $shell.FootPrintManager.openErrorDialog('Error on inventory move', exception.message);
      $form.close();
  }
  
  /*****************************************************
   * FUNCTIONS
  ******************************************************/
  async function getCurrentState(licenseplates: any[]) {
      $form.vars.canInventoryMove = true;
      $form.vars.canLicensePlateMove = true;
      $form.vars.isMultiLicensePlateMove = [...new Set($form.inParams.licenseplates?.map(lp => lp?.id))].length > 1;
      $form.vars.isMultiInventoryMove = $form.vars.isMultiLicensePlateMove || $utils.isDefined($form.inParams.licenseplates.find(lp => lp.content.length > 1));
  
      let isAllLicenseplateContents = true;
      for (let licenseplate of licenseplates) {
          let index = licenseplate.LicensePlateContents.length - 1;
          while (index >= 0) {
              let foundIndex = $form.inParams.licenseplates.find(lp => lp.id === licenseplate.Id).content.findIndex(c => c.lotId === licenseplate.LicensePlateContents[index].Lot.Id && c.packagingId === licenseplate.LicensePlateContents[index].Packaged.Id);
              if (foundIndex < 0) {
                  licenseplate.LicensePlateContents.splice(index, 1);
                  isAllLicenseplateContents = false;
              }
  
              index--;
          }
      }
  
      // Can't InventoryMove
      if (!$utils.isDefined($form.inParams.licenseplates.find(lp => lp.content?.find(c => c?.lotId != null)))) {
          $form.vars.canInventoryMove = false;
      }
  
      // Can't LicensePlateMove
      if (!$utils.isDefined($form.inParams.licenseplates) || licenseplates.find(lp => lp.TypeId === 3)) {
          $form.vars.canLicensePlateMove = false;
      }
  
      // Set initial state
      if (isAllLicenseplateContents && $form.vars.canLicensePlateMove) {
          $form.fields.is_licenseplate_move.control.value = true;
      }
      else if ($form.vars.canInventoryMove) {
          $form.fields.is_licenseplate_move.control.value = false;
      }
      else if ($form.vars.canLicensePlateMove) {
          $form.fields.is_licenseplate_move.control.value = true;
      }
      else {
          // If no action can be taken, throw error
          throw new Error('This request is not eligible for either inventory or license plate move.');
      }
  
      // Lock/Unlock ability to toggle
      if (!($form.vars.canInventoryMove && $form.vars.canLicensePlateMove)) {
          $form.fields.is_licenseplate_move.hidden = true;
          $form.fields.is_licenseplate_move.control.readOnly = true;
      }
  }
  
  }
  on_target_location_change(event = null) {
    return this.on_target_location_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_target_location_changeInternal(
    $form: FootPrintManager_inventory_and_licenseplate_move_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Location selection
  // Hide License Plate field
  const locationId = $form.fields.target_location.control.value
  const isLocationSelected = $utils.isDefined(locationId)
  if (locationId == null) {
      $form.fields.target_licenseplate.control.value = null;
      $form.fields.new_target_licenseplate.control.value = null;
      $form.fields.target_licenseplate.hidden = true;
      $form.fields.new_target_licenseplate.hidden = true;
      $form.fields.is_new_target_licenseplate.hidden = true;
  
  }
  
  
  if ($form.fields.is_licenseplate_move.control.value) { // LicensePlate Move
  
  }
  else { // Inventory Move
      if (isLocationSelected) {
          // Check if location selected is loose hide license plate
          const location = (await $datasources.Locations.ds_get_location_by_locationId.get({ locationId: locationId })).result;
          if ($utils.isDefined(location)) {
              const IsLoose = location[0].IsLoose;
              if (IsLoose) {
                  let licenseplate = (await $datasources.Inventory.ds_get_licenseplates_by_type_and_locationId.get({ typeId: 3, locationId: locationId })).result[0];
  
                  if (!$utils.isDefined(licenseplate)) {
                      throw new Error('No loose licenseplate found for selected target location.');
                  }
  
                  $form.fields.target_licenseplate.control.value = licenseplate.Id;
                  $form.fields.target_licenseplate.hidden = true;
  
                  $form.fields.new_target_licenseplate.control.value = null;
                  $form.fields.new_target_licenseplate.hidden = true;
                  $form.fields.is_new_target_licenseplate.hidden = true;
              }
              else {
                  $form.fields.is_new_target_licenseplate.hidden = false;
                  $form.fields.target_licenseplate.hidden = false;
                  $form.fields.target_licenseplate.control.value = null;
                  $form.fields.new_target_licenseplate.control.value = null;
              }
          }
      }
  }
  }
  on_new_licenseplate_clicked(event = null) {
    return this.on_new_licenseplate_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_new_licenseplate_clickedInternal(
    $form: FootPrintManager_inventory_and_licenseplate_move_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'inventory_and_licenseplate_move_form.on_new_licenseplate_clicked');
  
  // const newLicensePlate = $form.fields.new_licenseplate_checkbox.control.value;
  
  // if (newLicensePlate) {
  //     $form.fields.licenseplate.control.value = null;
  //     $form.fields.new_licenseplate.control.value = null;
  //     $form.fields.licenseplate.hidden = true;
  //     $form.fields.new_licenseplate.hidden = false;
  //     $form.fields.new_licenseplate.control.focus();
  
  // }
  // else {
  
  //     $form.fields.licenseplate.control.value = null;
  //     $form.fields.new_licenseplate.control.value = null;
  //     $form.fields.licenseplate.hidden = false;
  //     $form.fields.new_licenseplate.hidden = true;
  // }
  
  
  }
  on_is_licenseplate_move_change(event = null) {
    return this.on_is_licenseplate_move_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_is_licenseplate_move_changeInternal(
    $form: FootPrintManager_inventory_and_licenseplate_move_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $form.set_form_state();
  
  }
  set_form_state(event = null) {
    return this.set_form_stateInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async set_form_stateInternal(
    $form: FootPrintManager_inventory_and_licenseplate_move_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Set based on context
  if ($utils.isDefined($form.inParams.serialNumberIds)) { // Lock down fields if serial numbers were provided
      let firstPackagedAmount = $form.inParams.licenseplates[0]?.content[0]?.packagedAmount;
  
      $form.fields.moved_packaged_amount.control.value = firstPackagedAmount;
      $form.fields.moved_packaged_amount.control.readOnly = true;
  
      // Hide license plate move option
      $form.fields.is_licenseplate_move.control.value = false;
      $form.fields.is_licenseplate_move.hidden = true;
  }
  
  // Get Data
  let licenseplates = (await $datasources.Inventory.ds_get_licenseplates_by_licenseplateIds_detailed.get({ licenseplateIds: $form.inParams.licenseplates.map(lp => lp.id) })).result;
  licenseplates.sort((a, b) => a.Id - b.Id);
  
  // Remove unrelated LicensePlateContents
  for (let licenseplate of licenseplates) {
      let index = licenseplate.LicensePlateContents.length - 1;
      while (index >= 0) {
          if (!$utils.isDefined($form.inParams.licenseplates.find(lp => lp.id === licenseplate.Id && lp.content.find(c => c.lotId === licenseplate.LicensePlateContents[index].Lot.Id && c.packagingId === licenseplate.LicensePlateContents[index].Packaged.Id)))) {
              licenseplate.LicensePlateContents.splice(index, 1);
          }
  
          index--;
      }
  }
  
  if ($utils.isDefined($form.fields.source_licenseplate.control.value)) {
      let licenseplate = (await $datasources.Inventory.ds_get_licenseplate_by_licenseplateId.get({ licenseplateId: $form.fields.source_licenseplate.control.value })).result;
  
      $form.vars.sourceLicensePlateShipmentId = licenseplate.ShipmentId;
  }
  
  if ($form.fields.is_licenseplate_move.control.value) { // LicensePlate Move
      $form.toolbar.release.hidden = false;
      $form.toolbar.confirm.hidden = false;
      $form.toolbar.cancel.hidden = false;
  
      if ($form.vars.isMultiLicensePlateMove) {
          $form.fields.warehouse.hidden = true;
          $form.fields.source_location.hidden = true;
          $form.fields.source_licenseplate.hidden = true;
          $form.fields.material.hidden = true;
          $form.fields.vendorlot.hidden = true;
          $form.fields.lot.hidden = true;
          $form.fields.packaging.hidden = true;
          $form.fields.current_packaged_amount.hidden = true;
          $form.fields.available_packaged_amount.hidden = true;
          $form.fields.moved_packaged_amount.hidden = true;
  
          $form.fields.target_location.hidden = false;
          $form.fields.target_licenseplate.hidden = true;
          $form.fields.is_new_target_licenseplate.hidden = true;
          $form.fields.new_target_licenseplate.hidden = true;
  
          $form.fields.details.hidden = false;
          $form.fields.details.control.value = 'License Plates\n\t' + licenseplates.map(licenseplate => `[${licenseplate.LookupCode}]`).join('\n\t');
  
          // Set Title
          if ($form.inParams.licenseplates.length <= 2) {
              $form.title = `Move license plates ${licenseplates.map(lp => lp.LookupCode).join(' and ')} to a new location?`;
          }
          else if ($form.inParams.licenseplates.length > 5) {
              $form.title = `Move ${$form.inParams.licenseplates.length} license plates to a new location?`;
          }
          else {
              $form.title = `Move license plates ${licenseplates.map(lp => lp.LookupCode).join(', ').replace(/, ([^,]*)$/, ', and $1')} to a new location?`;
          }
      }
      else {
          $form.fields.warehouse.hidden = false;
          $form.fields.source_location.hidden = false;
          $form.fields.source_licenseplate.hidden = false;
          $form.fields.material.hidden = true;
          $form.fields.vendorlot.hidden = true;
          $form.fields.lot.hidden = true;
          $form.fields.packaging.hidden = true;
          $form.fields.current_packaged_amount.hidden = true;
          $form.fields.available_packaged_amount.hidden = true;
          $form.fields.moved_packaged_amount.hidden = true;
  
          $form.fields.target_location.hidden = false;
          $form.fields.target_licenseplate.hidden = true;
          $form.fields.is_new_target_licenseplate.hidden = true;
          $form.fields.new_target_licenseplate.hidden = true;
  
          $form.fields.details.hidden = true;
          $form.fields.details.control.value = null;
  
          // Set Title
          $form.title = `Move license plate ${licenseplates[0]?.LookupCode} to a new location?`;
      }
  }
  else { // InventoryMove
      $form.toolbar.release.hidden = false;
      $form.toolbar.confirm.hidden = false;
      $form.toolbar.cancel.hidden = false;
  
      // Set Title
      $form.title = `Move Inventory?`;
  
      if ($form.vars.isMultiInventoryMove) { // MultiInventoryMOve
          $form.fields.warehouse.hidden = true;
          $form.fields.source_location.hidden = true;
          $form.fields.source_licenseplate.hidden = true;
          $form.fields.material.hidden = true;
          $form.fields.vendorlot.hidden = true;
          $form.fields.lot.hidden = true;
          $form.fields.packaging.hidden = true;
          $form.fields.current_packaged_amount.hidden = true;
          $form.fields.available_packaged_amount.hidden = true;
          $form.fields.moved_packaged_amount.hidden = true;
  
          $form.fields.target_location.hidden = false;
          if ($utils.isDefined($form.fields.target_location.control.value)) {
              $form.fields.is_new_target_licenseplate.hidden = false;
              if ($form.fields.is_new_target_licenseplate.control.value) {
                  $form.fields.new_target_licenseplate.hidden = false;
                  $form.fields.target_licenseplate.hidden = true;
                  $form.fields.target_licenseplate.control.value = null;
              }
              else {
                  $form.fields.new_target_licenseplate.hidden = true;
                  $form.fields.target_licenseplate.hidden = false;
                  $form.fields.new_target_licenseplate.control.value = null;
              }
          }
          else {
              $form.fields.is_new_target_licenseplate.hidden = true;
              $form.fields.new_target_licenseplate.hidden = true;
              $form.fields.target_licenseplate.hidden = true;
          }
  
          $form.fields.details.hidden = false;
          $form.fields.details.control.value = licenseplates.map(licenseplate => licenseplate.LicensePlateContents.map(content => `Moving [${content.PackagedAmount}] units of \n\tLicensePlate: [${licenseplate.LookupCode}]\n\tLot: [${content.Lot.LookupCode}]\n\tPackaging: [${content.Packaged.Name}]`).join('\n\n')).join('\n\n');
      }
      else { // Single InventoryMove
          $form.fields.warehouse.hidden = false;
          $form.fields.source_location.hidden = false;
          $form.fields.source_licenseplate.hidden = false;
          $form.fields.material.hidden = false;
          $form.fields.vendorlot.hidden = false;
          $form.fields.lot.hidden = false;
          $form.fields.packaging.hidden = false;
          $form.fields.current_packaged_amount.hidden = false;
          $form.fields.available_packaged_amount.hidden = false;
          $form.fields.moved_packaged_amount.hidden = false;
  
          $form.fields.target_location.hidden = false;
          if ($utils.isDefined($form.fields.target_location.control.value)) {
              $form.fields.is_new_target_licenseplate.hidden = false;
              if ($form.fields.is_new_target_licenseplate.control.value) {
                  $form.fields.new_target_licenseplate.hidden = false;
                  $form.fields.target_licenseplate.hidden = true;
                  $form.fields.target_licenseplate.control.value = null;
              }
              else {
                  $form.fields.new_target_licenseplate.hidden = true;
                  $form.fields.target_licenseplate.hidden = false;
                  $form.fields.new_target_licenseplate.control.value = null;
              }
          }
          else {
              $form.fields.is_new_target_licenseplate.hidden = true;
              $form.fields.new_target_licenseplate.hidden = true;
              $form.fields.target_licenseplate.hidden = true;
          }
  
          $form.fields.details.hidden = true;
          $form.fields.details.control.value = null;
      }
  }
  
  $form.fields.warehouse.control.readOnly = true;
  
  await $form.apply_operations();
  }
  on_material_change(event = null) {
    return this.on_material_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_changeInternal(
    $form: FootPrintManager_inventory_and_licenseplate_move_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.fields.vendorlot.control.value = null;
  $form.fields.lot.control.value = null;
  $form.fields.packaging.control.value = null;
  }
  on_vendorlot_change(event = null) {
    return this.on_vendorlot_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_vendorlot_changeInternal(
    $form: FootPrintManager_inventory_and_licenseplate_move_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.fields.lot.control.value = null;
  }
  on_release_clicked(event = null) {
    return this.on_release_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_release_clickedInternal(
    $form: FootPrintManager_inventory_and_licenseplate_move_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.toolbar.confirm.control.readOnly = true;
  $form.toolbar.release.control.readOnly = true;
  $form.toolbar.cancel.control.readOnly = true;
  
  try {
      // Either target location or reason code should have a value set when releasing a task
      if (!$utils.isDefined($form.fields.target_location.control.value)) {
          if (!$utils.isDefined($form.fields.reason_code.control.value)) {
              throw new Error('Please provide a value for either the target location or reason code before releasing the task.');
          }
      }
  
  
      if ($form.fields.is_licenseplate_move.control.value) { // LicensePlate Move
          if ($form.vars.isMultiLicensePlateMove) { // Multiple LicensePlates
              let promises: any[] = [];
              for (let licenseplate of $form.inParams.licenseplates) {
                  // Complete LicensePlateMove
                  promises.push($flows.Inventory.create_released_licenseplate_move_task_flow({
                      licenseplateId: licenseplate.id,
                      targetLocationId: $form.fields.target_location.control.value,
                      notes: $form.fields.notes.control.value,
                      reasoncodeId: $form.fields.reason_code.control.value
                  }));
              }
  
              let allReasons: string[] = [];
              await Promise.all(promises)
                  .then(results => {
                      results.forEach(result => allReasons.push(...result.reasons))
                  })
                  .catch(error => {
                      throw error.message;
                  });
  
              if ($utils.isDefined(allReasons)) {
                  throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: allReasons })).formattedValue);
              }
          }
          else { // Single LicensePlate
              // Complete LicensePlateMove
              let result = (await $flows.Inventory.create_released_licenseplate_move_task_flow({
                  licenseplateId: $form.fields.source_licenseplate.control.value,
                  targetLocationId: $form.fields.target_location.control.value,
                  notes: $form.fields.notes.control.value,
                  reasoncodeId: $form.fields.reason_code.control.value
              }));
  
              if ($utils.isDefined(result.reasons)) {
                  throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
              }
          }
      }
      else {
          if ($form.vars.isMultiInventoryMove) { // Multiple Inventory
              // Check Required Fields
              if (!$utils.isAllDefined(
                  $form.inParams.licenseplates
              )) {
                  throw new Error('Please provide value for all required fields.');
              }
  
              let promises: any[] = [];
  
              for (let licenseplate of $form.inParams.licenseplates) {
                  if (!$utils.isDefined(licenseplate.content)) {
                      throw new Error(`Input license plate ${licenseplate.id} is missing the content to be moved.`);
                  }
  
                  for (let content of licenseplate.content) {
                      if (!$utils.isAllDefined(
                          content?.lotId,
                          content?.packagedAmount,
                          content?.packagingId
                      )) {
                          throw new Error(`Content of input license plate ${licenseplate.id} is missing a value.`);
                      }
  
  
  
              //create new lp 
               let target_lp:number
                if ($form.fields.is_new_target_licenseplate.control.value === true) { // New LicensePlate
                  let lp_id = (await $flows.Inventory.create_licenseplate_flow({
                      archived: true,
                      dimensionsUomId: null,
                      grossVolume: null,
                      grossWeight: null,
                      height: null,
                      length: null,
                      licensePlateContainerTypeId: null,
                      locationId: $form.fields.target_location.control.value,
                      lookupCode: $form.fields.new_target_licenseplate.control.value,
                      maximumWeight: null,
                      netVolume: null,
                      netWeight: null,
                      parentId: null,
                      shipmentId: null,
                      shippingContainerId: null,
                      statusId: 1,
                      typeId: 1,
                      volumeUomId: null,
                      warehouseId: $form.fields.warehouse.control.value,
                      weightUomId: null,
                      width: null
                  })).licensePlateId
                 target_lp=lp_id
                    }
                  else{
                          target_lp=$form.fields.target_licenseplate.control.value
                      }
  
  
                      // Complete InventoryMoves
                      promises.push(await $flows.Inventory.create_released_inventory_move_task_flow({
                          sourceLicenseplateId: licenseplate.id,
                          targetLicenseplate: {
                              id: target_lp,
                              lookupcode: $form.fields.new_target_licenseplate.control.value
                          },
                          lotId: content.lotId,
                          packagingId: content.packagingId,
                          packagedAmount: content.packagedAmount,
                          targetLocationId: $form.fields.target_location.control.value,
                          notes: $form.fields.notes.control.value,
                          reasoncodeId: $form.fields.reason_code.control.value,
                          serialNumberIds: null
                      }));
                  }
              }
  
              let allReasons: string[] = [];
              await Promise.all(promises)
                  .then(results => {
                      results.forEach(result => allReasons.push(...result.reasons))
                  })
                  .catch(error => {
                      throw error.message;
                  });
  
              if ($utils.isDefined(allReasons)) {
                  throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: allReasons })).formattedValue);
              }
          }
          else { // Single Inventory
              // Check Required Fields
              if (!$utils.isAllDefined(
                  $form.fields.source_licenseplate.control.value,
                  $form.fields.lot.control.value,
                  $form.fields.packaging.control.value,
                  $form.fields.moved_packaged_amount.control.value,
              )) {
                  throw new Error('Please provide value for all required fields.');
              }
  
              // Check if Serialized
              if ($form.fields.moved_packaged_amount.control.value <= 0) {
                  throw new Error('Requested move amount must be greater than zero.');
              }
              else if ($form.fields.moved_packaged_amount.control.value > Number($form.fields.current_packaged_amount.control.text)) {
                  throw new Error('Requested move amount exceeds the amount in inventory.');
              }
              //else if ($form.fields.moved_packaged_amount.control.value > Number($form.fields.available_packaged_amount.control.text)) {
              //    throw new Error('Requested move amount exceeds the currently available amount.');
              //}
  
              
  
              //create new lp 
               let target_lp:number
                if ($form.fields.is_new_target_licenseplate.control.value === true) { // New LicensePlate
                  let lp_id = (await $flows.Inventory.create_licenseplate_flow({
                      archived: true,
                      dimensionsUomId: null,
                      grossVolume: null,
                      grossWeight: null,
                      height: null,
                      length: null,
                      licensePlateContainerTypeId: null,
                      locationId: $form.fields.target_location.control.value,
                      lookupCode: $form.fields.new_target_licenseplate.control.value,
                      maximumWeight: null,
                      netVolume: null,
                      netWeight: null,
                      parentId: null,
                      shipmentId: null,
                      shippingContainerId: null,
                      statusId: 1,
                      typeId: 1,
                      volumeUomId: null,
                      warehouseId: $form.fields.warehouse.control.value,
                      weightUomId: null,
                      width: null
                  })).licensePlateId
                   target_lp=lp_id
                       }
                  else{
                          target_lp=$form.fields.target_licenseplate.control.value
                      }
  
              // Complete InventoryMove
              let result = (await $flows.Inventory.create_released_inventory_move_task_flow({
                  sourceLicenseplateId: $form.fields.source_licenseplate.control.value,
                  targetLicenseplate: {
                      id: target_lp,                  
                      lookupcode: $form.fields.new_target_licenseplate.control.value
                  },
                  lotId: $form.fields.lot.control.value,
                  packagingId: $form.fields.packaging.control.value,
                  packagedAmount: $form.fields.moved_packaged_amount.control.value,
                  targetLocationId: $form.fields.target_location.control.value,
                  notes: $form.fields.notes.control.value,
                  reasoncodeId: $form.fields.reason_code.control.value,
                  serialNumberIds: null
              }));
  
              if ($utils.isDefined(result.reasons)) {
                  throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
              }
          }
      }
  
      $form.outParams.confirm = true;
      $form.close();
  }
  catch (error) {
      while ($utils.isDefined(error.error)) {
          error = error.error;
      }
  
      $shell.FootPrintManager.openErrorDialog('Error releasing move task', error.message);
  }
  
  $form.toolbar.confirm.control.readOnly = false;
  $form.toolbar.release.control.readOnly = false;
  $form.toolbar.cancel.control.readOnly = false;
  }
  on_is_new_target_licenseplate_change(event = null) {
    return this.on_is_new_target_licenseplate_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_is_new_target_licenseplate_changeInternal(
    $form: FootPrintManager_inventory_and_licenseplate_move_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $form.set_form_state();
  }
  apply_operations(event = null) {
    return this.apply_operationsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async apply_operationsInternal(
    $form: FootPrintManager_inventory_and_licenseplate_move_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($form.inParams.serialNumberIds)) {
      $form.toolbar.confirm.hidden = false;
      $form.toolbar.release.hidden = true;
  }
  else if (await $operations.FootPrintManager.Disable_Inventory_MovementConfirmation.isAuthorized()){
      $form.toolbar.confirm.hidden = true;
  }
  }
  //#endregion private flows
}
