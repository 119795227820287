import { Inject, Injectable, Injector }from '@angular/core';


import { Reports_custom_ds_commercial_invoice_report_headerService } from './Reports.datasource.index';
import { Reports_custom_ds_commercial_invoice_report_linesService } from './Reports.datasource.index';
import { Reports_custom_ds_flow_material_barcode_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_get_currency_by_currency_idService } from './Reports.datasource.index';
import { Reports_custom_ds_get_material_packaging_by_materialId_and_packagingId_top1Service } from './Reports.datasource.index';
import { Reports_custom_ds_get_order_by_orderIdService } from './Reports.datasource.index';
import { Reports_custom_ds_get_order_owner_contact_by_typeIdService } from './Reports.datasource.index';
import { Reports_custom_ds_get_order_project_contact_by_typeIdService } from './Reports.datasource.index';
import { Reports_custom_ds_get_owner_address_logo_attachmentService } from './Reports.datasource.index';
import { Reports_custom_ds_get_owner_logo_attachmentService } from './Reports.datasource.index';
import { Reports_custom_ds_get_upper_packaging_by_materialIdService } from './Reports.datasource.index';
import { Reports_custom_ds_inventory_adjustment_by_current_date_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_material_barcode_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_outbound_detail_pick_slip_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_outbound_header_pick_slip_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_outbound_summary_pick_slip_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_packing_list_report_detailsService } from './Reports.datasource.index';
import { Reports_custom_ds_packing_list_report_headerService } from './Reports.datasource.index';
import { Reports_custom_ds_packing_list_report_zagg_headerService } from './Reports.datasource.index';
import { Reports_custom_ds_packing_list_zagg_report_linesService } from './Reports.datasource.index';
import { Reports_custom_ds_receiving_details_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_receiving_details_report_flow_basedService } from './Reports.datasource.index';
import { Reports_custom_ds_receiving_serial_details_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_sales_order_export_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_so_report_detailsService } from './Reports.datasource.index';
import { Reports_custom_ds_so_report_headerService } from './Reports.datasource.index';
import { Reports_ds_get_inbound_totals_by_linenumber_orderIdService } from './Reports.datasource.index';
import { Reports_ds_get_lot_by_lotIdService } from './Reports.datasource.index';
import { Reports_ds_get_material_by_materialIdService } from './Reports.datasource.index';
import { Reports_ds_get_material_packaging_by_materialId_and_packagingId_top1Service } from './Reports.datasource.index';
import { Reports_ds_get_order_account_address_by_orderIdService } from './Reports.datasource.index';
import { Reports_ds_get_order_address_by_orderId_top1Service } from './Reports.datasource.index';
import { Reports_ds_get_order_owner_contact_top1Service } from './Reports.datasource.index';
import { Reports_ds_get_order_owner_contact_top1_flow_basedService } from './Reports.datasource.index';
import { Reports_ds_get_order_ship_account_vs_order_addressService } from './Reports.datasource.index';
import { Reports_ds_get_order_ship_from_account_vs_order_addressService } from './Reports.datasource.index';
import { Reports_ds_get_order_ship_to_account_vs_order_addressService } from './Reports.datasource.index';
import { Reports_ds_get_order_warehouse_contact_top1Service } from './Reports.datasource.index';
import { Reports_ds_get_order_warehouse_contact_top1_flow_basedService } from './Reports.datasource.index';
import { Reports_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_materialIdService } from './Reports.datasource.index';
import { Reports_ds_get_serialnumbers_non_fixed_weight_by_shipmentId_materialIdService } from './Reports.datasource.index';
import { Reports_ds_get_shipments_by_shipmentIdService } from './Reports.datasource.index';
import { Reports_ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_materialIdService } from './Reports.datasource.index';
import { Reports_ds_order_total_picked_weight_by_orderId_materialIdService } from './Reports.datasource.index';
import { Reports_ds_purchase_order_details_reportService } from './Reports.datasource.index';
import { Reports_ds_purchase_order_details_report_flow_basedService } from './Reports.datasource.index';
import { Reports_ds_purchase_order_header_reportService } from './Reports.datasource.index';
import { Reports_ds_receiving_header_reportService } from './Reports.datasource.index';

@Injectable({ providedIn: 'root' })
export class Reports_DatasourceService {

  constructor(
    private injector: Injector
  ) {
  }

  public Reports: Reports_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _custom_ds_commercial_invoice_report_header: Reports_custom_ds_commercial_invoice_report_headerService;
  public get custom_ds_commercial_invoice_report_header(): Reports_custom_ds_commercial_invoice_report_headerService {
    if(!this._custom_ds_commercial_invoice_report_header) {
      this._custom_ds_commercial_invoice_report_header = this.injector.get(Reports_custom_ds_commercial_invoice_report_headerService);
    }
    return this._custom_ds_commercial_invoice_report_header;
  }
  private _custom_ds_commercial_invoice_report_lines: Reports_custom_ds_commercial_invoice_report_linesService;
  public get custom_ds_commercial_invoice_report_lines(): Reports_custom_ds_commercial_invoice_report_linesService {
    if(!this._custom_ds_commercial_invoice_report_lines) {
      this._custom_ds_commercial_invoice_report_lines = this.injector.get(Reports_custom_ds_commercial_invoice_report_linesService);
    }
    return this._custom_ds_commercial_invoice_report_lines;
  }
  private _custom_ds_flow_material_barcode_report: Reports_custom_ds_flow_material_barcode_reportService;
  public get custom_ds_flow_material_barcode_report(): Reports_custom_ds_flow_material_barcode_reportService {
    if(!this._custom_ds_flow_material_barcode_report) {
      this._custom_ds_flow_material_barcode_report = this.injector.get(Reports_custom_ds_flow_material_barcode_reportService);
    }
    return this._custom_ds_flow_material_barcode_report;
  }
  private _custom_ds_get_currency_by_currency_id: Reports_custom_ds_get_currency_by_currency_idService;
  public get custom_ds_get_currency_by_currency_id(): Reports_custom_ds_get_currency_by_currency_idService {
    if(!this._custom_ds_get_currency_by_currency_id) {
      this._custom_ds_get_currency_by_currency_id = this.injector.get(Reports_custom_ds_get_currency_by_currency_idService);
    }
    return this._custom_ds_get_currency_by_currency_id;
  }
  private _custom_ds_get_material_packaging_by_materialId_and_packagingId_top1: Reports_custom_ds_get_material_packaging_by_materialId_and_packagingId_top1Service;
  public get custom_ds_get_material_packaging_by_materialId_and_packagingId_top1(): Reports_custom_ds_get_material_packaging_by_materialId_and_packagingId_top1Service {
    if(!this._custom_ds_get_material_packaging_by_materialId_and_packagingId_top1) {
      this._custom_ds_get_material_packaging_by_materialId_and_packagingId_top1 = this.injector.get(Reports_custom_ds_get_material_packaging_by_materialId_and_packagingId_top1Service);
    }
    return this._custom_ds_get_material_packaging_by_materialId_and_packagingId_top1;
  }
  private _custom_ds_get_order_by_orderId: Reports_custom_ds_get_order_by_orderIdService;
  public get custom_ds_get_order_by_orderId(): Reports_custom_ds_get_order_by_orderIdService {
    if(!this._custom_ds_get_order_by_orderId) {
      this._custom_ds_get_order_by_orderId = this.injector.get(Reports_custom_ds_get_order_by_orderIdService);
    }
    return this._custom_ds_get_order_by_orderId;
  }
  private _custom_ds_get_order_owner_contact_by_typeId: Reports_custom_ds_get_order_owner_contact_by_typeIdService;
  public get custom_ds_get_order_owner_contact_by_typeId(): Reports_custom_ds_get_order_owner_contact_by_typeIdService {
    if(!this._custom_ds_get_order_owner_contact_by_typeId) {
      this._custom_ds_get_order_owner_contact_by_typeId = this.injector.get(Reports_custom_ds_get_order_owner_contact_by_typeIdService);
    }
    return this._custom_ds_get_order_owner_contact_by_typeId;
  }
  private _custom_ds_get_order_project_contact_by_typeId: Reports_custom_ds_get_order_project_contact_by_typeIdService;
  public get custom_ds_get_order_project_contact_by_typeId(): Reports_custom_ds_get_order_project_contact_by_typeIdService {
    if(!this._custom_ds_get_order_project_contact_by_typeId) {
      this._custom_ds_get_order_project_contact_by_typeId = this.injector.get(Reports_custom_ds_get_order_project_contact_by_typeIdService);
    }
    return this._custom_ds_get_order_project_contact_by_typeId;
  }
  private _custom_ds_get_owner_address_logo_attachment: Reports_custom_ds_get_owner_address_logo_attachmentService;
  public get custom_ds_get_owner_address_logo_attachment(): Reports_custom_ds_get_owner_address_logo_attachmentService {
    if(!this._custom_ds_get_owner_address_logo_attachment) {
      this._custom_ds_get_owner_address_logo_attachment = this.injector.get(Reports_custom_ds_get_owner_address_logo_attachmentService);
    }
    return this._custom_ds_get_owner_address_logo_attachment;
  }
  private _custom_ds_get_owner_logo_attachment: Reports_custom_ds_get_owner_logo_attachmentService;
  public get custom_ds_get_owner_logo_attachment(): Reports_custom_ds_get_owner_logo_attachmentService {
    if(!this._custom_ds_get_owner_logo_attachment) {
      this._custom_ds_get_owner_logo_attachment = this.injector.get(Reports_custom_ds_get_owner_logo_attachmentService);
    }
    return this._custom_ds_get_owner_logo_attachment;
  }
  private _custom_ds_get_upper_packaging_by_materialId: Reports_custom_ds_get_upper_packaging_by_materialIdService;
  public get custom_ds_get_upper_packaging_by_materialId(): Reports_custom_ds_get_upper_packaging_by_materialIdService {
    if(!this._custom_ds_get_upper_packaging_by_materialId) {
      this._custom_ds_get_upper_packaging_by_materialId = this.injector.get(Reports_custom_ds_get_upper_packaging_by_materialIdService);
    }
    return this._custom_ds_get_upper_packaging_by_materialId;
  }
  private _custom_ds_inventory_adjustment_by_current_date_report: Reports_custom_ds_inventory_adjustment_by_current_date_reportService;
  public get custom_ds_inventory_adjustment_by_current_date_report(): Reports_custom_ds_inventory_adjustment_by_current_date_reportService {
    if(!this._custom_ds_inventory_adjustment_by_current_date_report) {
      this._custom_ds_inventory_adjustment_by_current_date_report = this.injector.get(Reports_custom_ds_inventory_adjustment_by_current_date_reportService);
    }
    return this._custom_ds_inventory_adjustment_by_current_date_report;
  }
  private _custom_ds_material_barcode_report: Reports_custom_ds_material_barcode_reportService;
  public get custom_ds_material_barcode_report(): Reports_custom_ds_material_barcode_reportService {
    if(!this._custom_ds_material_barcode_report) {
      this._custom_ds_material_barcode_report = this.injector.get(Reports_custom_ds_material_barcode_reportService);
    }
    return this._custom_ds_material_barcode_report;
  }
  private _custom_ds_outbound_detail_pick_slip_report: Reports_custom_ds_outbound_detail_pick_slip_reportService;
  public get custom_ds_outbound_detail_pick_slip_report(): Reports_custom_ds_outbound_detail_pick_slip_reportService {
    if(!this._custom_ds_outbound_detail_pick_slip_report) {
      this._custom_ds_outbound_detail_pick_slip_report = this.injector.get(Reports_custom_ds_outbound_detail_pick_slip_reportService);
    }
    return this._custom_ds_outbound_detail_pick_slip_report;
  }
  private _custom_ds_outbound_header_pick_slip_report: Reports_custom_ds_outbound_header_pick_slip_reportService;
  public get custom_ds_outbound_header_pick_slip_report(): Reports_custom_ds_outbound_header_pick_slip_reportService {
    if(!this._custom_ds_outbound_header_pick_slip_report) {
      this._custom_ds_outbound_header_pick_slip_report = this.injector.get(Reports_custom_ds_outbound_header_pick_slip_reportService);
    }
    return this._custom_ds_outbound_header_pick_slip_report;
  }
  private _custom_ds_outbound_summary_pick_slip_report: Reports_custom_ds_outbound_summary_pick_slip_reportService;
  public get custom_ds_outbound_summary_pick_slip_report(): Reports_custom_ds_outbound_summary_pick_slip_reportService {
    if(!this._custom_ds_outbound_summary_pick_slip_report) {
      this._custom_ds_outbound_summary_pick_slip_report = this.injector.get(Reports_custom_ds_outbound_summary_pick_slip_reportService);
    }
    return this._custom_ds_outbound_summary_pick_slip_report;
  }
  private _custom_ds_packing_list_report_details: Reports_custom_ds_packing_list_report_detailsService;
  public get custom_ds_packing_list_report_details(): Reports_custom_ds_packing_list_report_detailsService {
    if(!this._custom_ds_packing_list_report_details) {
      this._custom_ds_packing_list_report_details = this.injector.get(Reports_custom_ds_packing_list_report_detailsService);
    }
    return this._custom_ds_packing_list_report_details;
  }
  private _custom_ds_packing_list_report_header: Reports_custom_ds_packing_list_report_headerService;
  public get custom_ds_packing_list_report_header(): Reports_custom_ds_packing_list_report_headerService {
    if(!this._custom_ds_packing_list_report_header) {
      this._custom_ds_packing_list_report_header = this.injector.get(Reports_custom_ds_packing_list_report_headerService);
    }
    return this._custom_ds_packing_list_report_header;
  }
  private _custom_ds_packing_list_report_zagg_header: Reports_custom_ds_packing_list_report_zagg_headerService;
  public get custom_ds_packing_list_report_zagg_header(): Reports_custom_ds_packing_list_report_zagg_headerService {
    if(!this._custom_ds_packing_list_report_zagg_header) {
      this._custom_ds_packing_list_report_zagg_header = this.injector.get(Reports_custom_ds_packing_list_report_zagg_headerService);
    }
    return this._custom_ds_packing_list_report_zagg_header;
  }
  private _custom_ds_packing_list_zagg_report_lines: Reports_custom_ds_packing_list_zagg_report_linesService;
  public get custom_ds_packing_list_zagg_report_lines(): Reports_custom_ds_packing_list_zagg_report_linesService {
    if(!this._custom_ds_packing_list_zagg_report_lines) {
      this._custom_ds_packing_list_zagg_report_lines = this.injector.get(Reports_custom_ds_packing_list_zagg_report_linesService);
    }
    return this._custom_ds_packing_list_zagg_report_lines;
  }
  private _custom_ds_receiving_details_report: Reports_custom_ds_receiving_details_reportService;
  public get custom_ds_receiving_details_report(): Reports_custom_ds_receiving_details_reportService {
    if(!this._custom_ds_receiving_details_report) {
      this._custom_ds_receiving_details_report = this.injector.get(Reports_custom_ds_receiving_details_reportService);
    }
    return this._custom_ds_receiving_details_report;
  }
  private _custom_ds_receiving_details_report_flow_based: Reports_custom_ds_receiving_details_report_flow_basedService;
  public get custom_ds_receiving_details_report_flow_based(): Reports_custom_ds_receiving_details_report_flow_basedService {
    if(!this._custom_ds_receiving_details_report_flow_based) {
      this._custom_ds_receiving_details_report_flow_based = this.injector.get(Reports_custom_ds_receiving_details_report_flow_basedService);
    }
    return this._custom_ds_receiving_details_report_flow_based;
  }
  private _custom_ds_receiving_serial_details_report: Reports_custom_ds_receiving_serial_details_reportService;
  public get custom_ds_receiving_serial_details_report(): Reports_custom_ds_receiving_serial_details_reportService {
    if(!this._custom_ds_receiving_serial_details_report) {
      this._custom_ds_receiving_serial_details_report = this.injector.get(Reports_custom_ds_receiving_serial_details_reportService);
    }
    return this._custom_ds_receiving_serial_details_report;
  }
  private _custom_ds_sales_order_export_report: Reports_custom_ds_sales_order_export_reportService;
  public get custom_ds_sales_order_export_report(): Reports_custom_ds_sales_order_export_reportService {
    if(!this._custom_ds_sales_order_export_report) {
      this._custom_ds_sales_order_export_report = this.injector.get(Reports_custom_ds_sales_order_export_reportService);
    }
    return this._custom_ds_sales_order_export_report;
  }
  private _custom_ds_so_report_details: Reports_custom_ds_so_report_detailsService;
  public get custom_ds_so_report_details(): Reports_custom_ds_so_report_detailsService {
    if(!this._custom_ds_so_report_details) {
      this._custom_ds_so_report_details = this.injector.get(Reports_custom_ds_so_report_detailsService);
    }
    return this._custom_ds_so_report_details;
  }
  private _custom_ds_so_report_header: Reports_custom_ds_so_report_headerService;
  public get custom_ds_so_report_header(): Reports_custom_ds_so_report_headerService {
    if(!this._custom_ds_so_report_header) {
      this._custom_ds_so_report_header = this.injector.get(Reports_custom_ds_so_report_headerService);
    }
    return this._custom_ds_so_report_header;
  }
  private _ds_get_inbound_totals_by_linenumber_orderId: Reports_ds_get_inbound_totals_by_linenumber_orderIdService;
  public get ds_get_inbound_totals_by_linenumber_orderId(): Reports_ds_get_inbound_totals_by_linenumber_orderIdService {
    if(!this._ds_get_inbound_totals_by_linenumber_orderId) {
      this._ds_get_inbound_totals_by_linenumber_orderId = this.injector.get(Reports_ds_get_inbound_totals_by_linenumber_orderIdService);
    }
    return this._ds_get_inbound_totals_by_linenumber_orderId;
  }
  private _ds_get_lot_by_lotId: Reports_ds_get_lot_by_lotIdService;
  public get ds_get_lot_by_lotId(): Reports_ds_get_lot_by_lotIdService {
    if(!this._ds_get_lot_by_lotId) {
      this._ds_get_lot_by_lotId = this.injector.get(Reports_ds_get_lot_by_lotIdService);
    }
    return this._ds_get_lot_by_lotId;
  }
  private _ds_get_material_by_materialId: Reports_ds_get_material_by_materialIdService;
  public get ds_get_material_by_materialId(): Reports_ds_get_material_by_materialIdService {
    if(!this._ds_get_material_by_materialId) {
      this._ds_get_material_by_materialId = this.injector.get(Reports_ds_get_material_by_materialIdService);
    }
    return this._ds_get_material_by_materialId;
  }
  private _ds_get_material_packaging_by_materialId_and_packagingId_top1: Reports_ds_get_material_packaging_by_materialId_and_packagingId_top1Service;
  public get ds_get_material_packaging_by_materialId_and_packagingId_top1(): Reports_ds_get_material_packaging_by_materialId_and_packagingId_top1Service {
    if(!this._ds_get_material_packaging_by_materialId_and_packagingId_top1) {
      this._ds_get_material_packaging_by_materialId_and_packagingId_top1 = this.injector.get(Reports_ds_get_material_packaging_by_materialId_and_packagingId_top1Service);
    }
    return this._ds_get_material_packaging_by_materialId_and_packagingId_top1;
  }
  private _ds_get_order_account_address_by_orderId: Reports_ds_get_order_account_address_by_orderIdService;
  public get ds_get_order_account_address_by_orderId(): Reports_ds_get_order_account_address_by_orderIdService {
    if(!this._ds_get_order_account_address_by_orderId) {
      this._ds_get_order_account_address_by_orderId = this.injector.get(Reports_ds_get_order_account_address_by_orderIdService);
    }
    return this._ds_get_order_account_address_by_orderId;
  }
  private _ds_get_order_address_by_orderId_top1: Reports_ds_get_order_address_by_orderId_top1Service;
  public get ds_get_order_address_by_orderId_top1(): Reports_ds_get_order_address_by_orderId_top1Service {
    if(!this._ds_get_order_address_by_orderId_top1) {
      this._ds_get_order_address_by_orderId_top1 = this.injector.get(Reports_ds_get_order_address_by_orderId_top1Service);
    }
    return this._ds_get_order_address_by_orderId_top1;
  }
  private _ds_get_order_owner_contact_top1: Reports_ds_get_order_owner_contact_top1Service;
  public get ds_get_order_owner_contact_top1(): Reports_ds_get_order_owner_contact_top1Service {
    if(!this._ds_get_order_owner_contact_top1) {
      this._ds_get_order_owner_contact_top1 = this.injector.get(Reports_ds_get_order_owner_contact_top1Service);
    }
    return this._ds_get_order_owner_contact_top1;
  }
  private _ds_get_order_owner_contact_top1_flow_based: Reports_ds_get_order_owner_contact_top1_flow_basedService;
  public get ds_get_order_owner_contact_top1_flow_based(): Reports_ds_get_order_owner_contact_top1_flow_basedService {
    if(!this._ds_get_order_owner_contact_top1_flow_based) {
      this._ds_get_order_owner_contact_top1_flow_based = this.injector.get(Reports_ds_get_order_owner_contact_top1_flow_basedService);
    }
    return this._ds_get_order_owner_contact_top1_flow_based;
  }
  private _ds_get_order_ship_account_vs_order_address: Reports_ds_get_order_ship_account_vs_order_addressService;
  public get ds_get_order_ship_account_vs_order_address(): Reports_ds_get_order_ship_account_vs_order_addressService {
    if(!this._ds_get_order_ship_account_vs_order_address) {
      this._ds_get_order_ship_account_vs_order_address = this.injector.get(Reports_ds_get_order_ship_account_vs_order_addressService);
    }
    return this._ds_get_order_ship_account_vs_order_address;
  }
  private _ds_get_order_ship_from_account_vs_order_address: Reports_ds_get_order_ship_from_account_vs_order_addressService;
  public get ds_get_order_ship_from_account_vs_order_address(): Reports_ds_get_order_ship_from_account_vs_order_addressService {
    if(!this._ds_get_order_ship_from_account_vs_order_address) {
      this._ds_get_order_ship_from_account_vs_order_address = this.injector.get(Reports_ds_get_order_ship_from_account_vs_order_addressService);
    }
    return this._ds_get_order_ship_from_account_vs_order_address;
  }
  private _ds_get_order_ship_to_account_vs_order_address: Reports_ds_get_order_ship_to_account_vs_order_addressService;
  public get ds_get_order_ship_to_account_vs_order_address(): Reports_ds_get_order_ship_to_account_vs_order_addressService {
    if(!this._ds_get_order_ship_to_account_vs_order_address) {
      this._ds_get_order_ship_to_account_vs_order_address = this.injector.get(Reports_ds_get_order_ship_to_account_vs_order_addressService);
    }
    return this._ds_get_order_ship_to_account_vs_order_address;
  }
  private _ds_get_order_warehouse_contact_top1: Reports_ds_get_order_warehouse_contact_top1Service;
  public get ds_get_order_warehouse_contact_top1(): Reports_ds_get_order_warehouse_contact_top1Service {
    if(!this._ds_get_order_warehouse_contact_top1) {
      this._ds_get_order_warehouse_contact_top1 = this.injector.get(Reports_ds_get_order_warehouse_contact_top1Service);
    }
    return this._ds_get_order_warehouse_contact_top1;
  }
  private _ds_get_order_warehouse_contact_top1_flow_based: Reports_ds_get_order_warehouse_contact_top1_flow_basedService;
  public get ds_get_order_warehouse_contact_top1_flow_based(): Reports_ds_get_order_warehouse_contact_top1_flow_basedService {
    if(!this._ds_get_order_warehouse_contact_top1_flow_based) {
      this._ds_get_order_warehouse_contact_top1_flow_based = this.injector.get(Reports_ds_get_order_warehouse_contact_top1_flow_basedService);
    }
    return this._ds_get_order_warehouse_contact_top1_flow_based;
  }
  private _ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_materialId: Reports_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_materialIdService;
  public get ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_materialId(): Reports_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_materialIdService {
    if(!this._ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_materialId) {
      this._ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_materialId = this.injector.get(Reports_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_materialIdService);
    }
    return this._ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId_materialId;
  }
  private _ds_get_serialnumbers_non_fixed_weight_by_shipmentId_materialId: Reports_ds_get_serialnumbers_non_fixed_weight_by_shipmentId_materialIdService;
  public get ds_get_serialnumbers_non_fixed_weight_by_shipmentId_materialId(): Reports_ds_get_serialnumbers_non_fixed_weight_by_shipmentId_materialIdService {
    if(!this._ds_get_serialnumbers_non_fixed_weight_by_shipmentId_materialId) {
      this._ds_get_serialnumbers_non_fixed_weight_by_shipmentId_materialId = this.injector.get(Reports_ds_get_serialnumbers_non_fixed_weight_by_shipmentId_materialIdService);
    }
    return this._ds_get_serialnumbers_non_fixed_weight_by_shipmentId_materialId;
  }
  private _ds_get_shipments_by_shipmentId: Reports_ds_get_shipments_by_shipmentIdService;
  public get ds_get_shipments_by_shipmentId(): Reports_ds_get_shipments_by_shipmentIdService {
    if(!this._ds_get_shipments_by_shipmentId) {
      this._ds_get_shipments_by_shipmentId = this.injector.get(Reports_ds_get_shipments_by_shipmentIdService);
    }
    return this._ds_get_shipments_by_shipmentId;
  }
  private _ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_materialId: Reports_ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_materialIdService;
  public get ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_materialId(): Reports_ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_materialIdService {
    if(!this._ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_materialId) {
      this._ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_materialId = this.injector.get(Reports_ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_materialIdService);
    }
    return this._ds_get_shippingcontents_fixed_weight_by_orderId_shipmentId_materialId;
  }
  private _ds_order_total_picked_weight_by_orderId_materialId: Reports_ds_order_total_picked_weight_by_orderId_materialIdService;
  public get ds_order_total_picked_weight_by_orderId_materialId(): Reports_ds_order_total_picked_weight_by_orderId_materialIdService {
    if(!this._ds_order_total_picked_weight_by_orderId_materialId) {
      this._ds_order_total_picked_weight_by_orderId_materialId = this.injector.get(Reports_ds_order_total_picked_weight_by_orderId_materialIdService);
    }
    return this._ds_order_total_picked_weight_by_orderId_materialId;
  }
  private _ds_purchase_order_details_report: Reports_ds_purchase_order_details_reportService;
  public get ds_purchase_order_details_report(): Reports_ds_purchase_order_details_reportService {
    if(!this._ds_purchase_order_details_report) {
      this._ds_purchase_order_details_report = this.injector.get(Reports_ds_purchase_order_details_reportService);
    }
    return this._ds_purchase_order_details_report;
  }
  private _ds_purchase_order_details_report_flow_based: Reports_ds_purchase_order_details_report_flow_basedService;
  public get ds_purchase_order_details_report_flow_based(): Reports_ds_purchase_order_details_report_flow_basedService {
    if(!this._ds_purchase_order_details_report_flow_based) {
      this._ds_purchase_order_details_report_flow_based = this.injector.get(Reports_ds_purchase_order_details_report_flow_basedService);
    }
    return this._ds_purchase_order_details_report_flow_based;
  }
  private _ds_purchase_order_header_report: Reports_ds_purchase_order_header_reportService;
  public get ds_purchase_order_header_report(): Reports_ds_purchase_order_header_reportService {
    if(!this._ds_purchase_order_header_report) {
      this._ds_purchase_order_header_report = this.injector.get(Reports_ds_purchase_order_header_reportService);
    }
    return this._ds_purchase_order_header_report;
  }
  private _ds_receiving_header_report: Reports_ds_receiving_header_reportService;
  public get ds_receiving_header_report(): Reports_ds_receiving_header_reportService {
    if(!this._ds_receiving_header_report) {
      this._ds_receiving_header_report = this.injector.get(Reports_ds_receiving_header_reportService);
    }
    return this._ds_receiving_header_report;
  }
}

