import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { Reports_reports_homeComponent } from './Reports.reports_home.component';
import { Reports_custom_commercial_invoice_reportComponent } from './Reports.custom_commercial_invoice_report.component';
import { Reports_custom_inventory_adjustment_by_current_date_reportComponent } from './Reports.custom_inventory_adjustment_by_current_date_report.component';
import { Reports_custom_material_barcode_reportComponent } from './Reports.custom_material_barcode_report.component';
import { Reports_custom_packing_list_b2c_reportComponent } from './Reports.custom_packing_list_b2c_report.component';
import { Reports_custom_packing_list_reportComponent } from './Reports.custom_packing_list_report.component';
import { Reports_custom_pick_slip_reportComponent } from './Reports.custom_pick_slip_report.component';
import { Reports_custom_purchase_order_reportComponent } from './Reports.custom_purchase_order_report.component';
import { Reports_custom_receiving_reportComponent } from './Reports.custom_receiving_report.component';
import { Reports_custom_sales_order_export_reportComponent } from './Reports.custom_sales_order_export_report.component';
import { Reports_custom_so_reportComponent } from './Reports.custom_so_report.component';


@Injectable({ providedIn: 'root' })
export class Reports_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
  ) {
    super(dialog, toastr);
  }

  public Reports: Reports_ShellService = this;

  public openreports_home(replaceCurrentView?: boolean) {
    this.logger.log('Reports', 'reports_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Reports',
        referenceName: 'Reports_reports_home',
        component: Reports_reports_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openreports_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Reports', 'reports_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Reports_reports_homeComponent,
      'Reports',
      mode,
      dialogSize
    )
  }
  public opencustom_commercial_invoice_report(inParams:{ orderId?: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Commercial Invoice Report',
        referenceName: 'Reports_custom_commercial_invoice_report',
        component: Reports_custom_commercial_invoice_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_commercial_invoice_reportDialog(
    inParams:{ orderId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_commercial_invoice_reportComponent,
      'Commercial Invoice Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_inventory_adjustment_by_current_date_report(inParams:{ projectId: number }, replaceCurrentView?: boolean) {
    this.logger.log('Reports', 'custom_inventory_adjustment_by_current_date_report');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Inventory adjustments by current date by project report',
        referenceName: 'Reports_custom_inventory_adjustment_by_current_date_report',
        component: Reports_custom_inventory_adjustment_by_current_date_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_inventory_adjustment_by_current_date_reportDialog(
    inParams:{ projectId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Reports', 'custom_inventory_adjustment_by_current_date_report');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_inventory_adjustment_by_current_date_reportComponent,
      'Inventory adjustments by current date by project report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_material_barcode_report(inParams:{ order_id: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Material Barcode Report',
        referenceName: 'Reports_custom_material_barcode_report',
        component: Reports_custom_material_barcode_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_material_barcode_reportDialog(
    inParams:{ order_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_material_barcode_reportComponent,
      'Material Barcode Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_packing_list_b2c_report(inParams:{ orderId?: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'B2C Packing List Report',
        referenceName: 'Reports_custom_packing_list_b2c_report',
        component: Reports_custom_packing_list_b2c_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_packing_list_b2c_reportDialog(
    inParams:{ orderId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_packing_list_b2c_reportComponent,
      'B2C Packing List Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_packing_list_report(inParams:{ orderId?: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Generic Packing List Report',
        referenceName: 'Reports_custom_packing_list_report',
        component: Reports_custom_packing_list_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_packing_list_reportDialog(
    inParams:{ orderId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_packing_list_reportComponent,
      'Generic Packing List Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_pick_slip_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Pick Slip',
        referenceName: 'Reports_custom_pick_slip_report',
        component: Reports_custom_pick_slip_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_pick_slip_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_pick_slip_reportComponent,
      'Pick Slip',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_purchase_order_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Purchase order report',
        referenceName: 'Reports_custom_purchase_order_report',
        component: Reports_custom_purchase_order_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_purchase_order_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_purchase_order_reportComponent,
      'Purchase order report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_receiving_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Receiving Report',
        referenceName: 'Reports_custom_receiving_report',
        component: Reports_custom_receiving_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_receiving_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_receiving_reportComponent,
      'Receiving Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_sales_order_export_report(inParams:{ order_id: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Sales order export report',
        referenceName: 'Reports_custom_sales_order_export_report',
        component: Reports_custom_sales_order_export_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_sales_order_export_reportDialog(
    inParams:{ order_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_sales_order_export_reportComponent,
      'Sales order export report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_so_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Sales Order Report',
        referenceName: 'Reports_custom_so_report',
        component: Reports_custom_so_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_so_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_so_reportComponent,
      'Sales Order Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Reports_reports_home') {
      this.logger.log('Reports', 'reports_home');
      const title = 'Reports';
      const component = Reports_reports_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_commercial_invoice_report') {
      const title = 'Commercial Invoice Report';
      const component = Reports_custom_commercial_invoice_reportComponent;
      const inParams:{ orderId?: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_inventory_adjustment_by_current_date_report') {
      this.logger.log('Reports', 'custom_inventory_adjustment_by_current_date_report');
      const title = 'Inventory adjustments by current date by project report';
      const component = Reports_custom_inventory_adjustment_by_current_date_reportComponent;
      const inParams:{ projectId: number } = { projectId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_material_barcode_report') {
      const title = 'Material Barcode Report';
      const component = Reports_custom_material_barcode_reportComponent;
      const inParams:{ order_id: number } = { order_id: null };
      if (!isNil(params.get('order_id'))) {
        const paramValueString = params.get('order_id');
        inParams.order_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_packing_list_b2c_report') {
      const title = 'B2C Packing List Report';
      const component = Reports_custom_packing_list_b2c_reportComponent;
      const inParams:{ orderId?: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_packing_list_report') {
      const title = 'Generic Packing List Report';
      const component = Reports_custom_packing_list_reportComponent;
      const inParams:{ orderId?: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_pick_slip_report') {
      const title = 'Pick Slip';
      const component = Reports_custom_pick_slip_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_purchase_order_report') {
      const title = 'Purchase order report';
      const component = Reports_custom_purchase_order_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_receiving_report') {
      const title = 'Receiving Report';
      const component = Reports_custom_receiving_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_sales_order_export_report') {
      const title = 'Sales order export report';
      const component = Reports_custom_sales_order_export_reportComponent;
      const inParams:{ order_id: number } = { order_id: null };
      if (!isNil(params.get('order_id'))) {
        const paramValueString = params.get('order_id');
        inParams.order_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_so_report') {
      const title = 'Sales Order Report';
      const component = Reports_custom_so_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    return result;
  }
}
